import React, { ChangeEvent, MouseEvent, useState } from 'react';
import * as Yup from 'yup';
import Decimal from 'decimal.js';
import { Tab } from '@headlessui/react'
import { PlusIcon, PrinterIcon } from '@heroicons/react/24/solid'
import { Field, FormikProps } from 'formik';

import { useData } from "graphql/useCollection";
import yupLocale from "contexts/yupLocale"
import relations from "realm/Yanagisawa/data_sources/mongodb-atlas/Business/tree/relationships.json"
import { getObjectData, getSchemaValues, CreateBase, EditBase, FormFrame, FieldGroup, FieldArrayGroup, FieldBase, RelationField, getData, FoldableFields } from 'components/edit'
import { ListItem, EditLinkTd } from "components/list"
import { Create as CreateCustomer, Edit as EditCustomer } from 'pages/customer/edit'
import ViewCustomer from 'pages/customer/view'

import Loading from "components/Loading";
import options from "contexts/options.json"
import CustomerSearch from 'components/customerSearch'
import UserSelect from 'components/userSelect'
import ItemEdit from 'pages/tree/itemEdit'
import DialogFrame from 'components/dialog'
import FormFieldsItemTable from './itemTable';
import { IconButtonClass, formatNumber } from 'utils';
import { SimpleListFrame } from 'components/ListFrame';
import { PrintDialog } from './list';
import { JSTDate } from 'contexts/dateUtils';
import { useNavigate } from 'react-router-dom';
import { useDateEffect } from 'contexts/utils';

const schema = require("realm/Yanagisawa/data_sources/mongodb-atlas/Business/tree/schema.json").properties;

export const getInvoiceAdjust = (quote:KV) => (quote.invoiceAdjust || 0) + (quote.invoiceAdjust2 || 0) + (quote.invoiceAdjust3 || 0)

Yup.setLocale(yupLocale)
const formatCurrency = (num:number) => String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') + '円'

const InputCheck = ({ index, name, tabIndex }:{ index:number, name:string|null, tabIndex:number }) => <td><Field name={`costItem[${index}].${name}`}>{({field}:{field:KV}) => <input type="checkbox" className="focus:border focus:border-theme-500 focus:shadow-tableCell py-0 px-2" tabIndex={tabIndex} checked={field.value} {...field} />}</Field></td>

// quoteIndex is quotes[quoteIndex] | null, id is quoteId | undefined.  Set either quoteIndex or id.
// BuyAmount is deducted from totalCost
export const recalcTotal = (formik:FormikProps<any>, quoteIndex:number|null, id?:number) => {
    const i = quoteIndex !== null ? quoteIndex : formik.values.quotes.findIndex((v:KV) => v.quoteId === id)
    const quoteId = id !== undefined ? id : formik.values.quotes[i].quoteId
    formik.values.quotes[i].totalCost = formik.values.costItem.filter((v:KV) => v.quoteId === quoteId).reduce((a:number, r:KV) => a + parseInt(r.amount || 0), 0)
    formik.values.quotes[i].totalActualCost = formik.values.costItem.filter((v:KV) => v.quoteId === quoteId).reduce((a:number, r:KV) => a + parseInt(r.actualAmount || 0), 0)
    formik.values.quotes[i].totalListCost = formik.values.costItem.filter((v:KV) => v.quoteId === quoteId).reduce((a:number, r:KV) => a + parseInt(r.listAmount || 0), 0)
    const totalCost = new Decimal(formik.values.quotes[i].totalCost || 0)
    const marginRate = new Decimal(1).minus(new Decimal(formik.values.quotes[i].margin || 0).dividedBy(100))
    const amount = totalCost.dividedBy(marginRate).minus(new Decimal(formik.values.quotes[i].adjust || 0)).minus(new Decimal(formik.values.quotes[i].adjust2 || 0)).floor().toFixed()

    const { recalcAmount } = calcNonstoredNumbersNew(formik.values, i, false, parseInt(amount === "Infinity" ? "0" : amount))
    formik.values.quotes[i].amount = recalcAmount//parseInt(amount === "Infinity" ? "0" : amount)
}

// 原価合計+利益=総額
// 総額-管理費非対称かつ表示する間接費=(合計直接費+管理費)
// (合計直接費+管理費)/(1+管理費率)=合計直接費
// 合計直接費-表示する直接費=技術料
// i is quoteIndex
const calcNonstoredNumbersOld = (data:KV, i=0) => {
    const quoteId = data.quotes[i].quoteId
    const buyAmount = new Decimal(data.quotes[i].buyQuantity || 0).times(data.quotes[i].buyUnitPrice || 0).floor().toNumber()
    //const amountForOverhead = data.quotes[i].amount - data.costItem.filter((item:KV) => item.quoteId === quoteId && item.costType === "indirect").reduce((a:number, r:KV) => a + parseInt(r.listAmount || r.amount || 0), 0)
    const amountAfterBuy = data.quotes[i].amount + buyAmount
    const overhead = new Decimal(amountAfterBuy || 0).times(new Decimal(data.quotes[i].overhead || 0)).dividedBy(new Decimal(data.quotes[i].overhead || 0).plus(100)).floor().toNumber()
    //const remainOverhead = new Decimal(amountForOverhead || 0).times(new Decimal(data.quotes[i].overhead || 0)).dividedBy(new Decimal(100)).floor().toNumber()
    const remainOverhead = overhead - data.costItem.filter((item:KV) => item.quoteId === quoteId && ["include", "indirect"].includes(item.costType)).reduce((a:number, r:KV) => a + parseInt(r.listAamount || r.amount || 0), 0)
    const humanCost = parseInt(amountAfterBuy || 0)
         - data.costItem.filter((item:KV) => item.quoteId === quoteId && item.code > 199 && !item.hide).reduce((a:number, r:KV) => a + parseInt(r.listAmount || r.amount || 0), 0) // All Listing Items
         - remainOverhead
         + parseInt(data.quotes[i].adjust || 0) + parseInt(data.quotes[i].adjust2 || 0)
    const tax = new Decimal(data.quotes[i].amount || 0).times(new Decimal(data.quotes[i].taxRate || 0).dividedBy(100)).floor().toNumber()
    const amountWithTax = parseInt(data.quotes[i].amount || 0) + tax
    return { buyAmount, remainOverhead, humanCost, tax, amountWithTax }// 買取額:buyAmount, 間接費:remainOverhead, 技術料:humanCost, 合計金額(税込):amountWithTax

}

// displayingIndirectCosts:管理費非対象(indirect,other)かつ個別項目を表示する(!hide)間接費
// costWithAdmin:(管理費対象費用合計 + 管理費)  =  総額 ー 管理費非対象(indirect,other)かつ個別項目を表示する(!hide)間接費
// costForAdmin:管理費対象費用合計  =  (管理費対象費用合計 + 管理費) ÷ (1 + 管理費率) 
// adminCost:管理費  =  管理費対象費用合計 x 管理費率 または (管理費対象費用合計 + 管理費) - 管理費対象費用合計
// humanCostBase:特殊伐採技術料 = 管理費対象費用合計 ー (個別項目を表示する直接費 + 管理費対象かつ個別項目を表示する間接費)
// adminCostNew:管理費  =  管理費対象費用合計 x 管理費率 または (管理費対象費用合計 + 管理費) - 管理費対象費用合計
export const calcNonstoredNumbersNew = (data:KV, i = 0, isInvoice = false, amountBeforeAdjust?:number) => {
    const quote = data.quotes[i]
    const quoteId = quote.quoteId
    const amount = amountBeforeAdjust || quote.amount
    const displayingIndirectCosts = data.costItem.filter((item:KV) => item.quoteId === quoteId && ["indirect", "other"].includes(item.costType) && !item.hide).reduce((a:number, r:KV) => a + parseInt(r.listAmount || r.amount || 0), 0)
    const costWithAdmin = amount + parseInt(quote.adjust || 0) + parseInt(quote.adjust2 || 0) - displayingIndirectCosts
    const costForAdmin = new Decimal(costWithAdmin).dividedBy(new Decimal(100).plus(new Decimal(quote.adminRatio || 25)).dividedBy(100)).floor().toNumber()
    const adminCostBase = costWithAdmin - costForAdmin
    const displayingDirectCosts = data.costItem.filter((item:KV) => item.quoteId === quoteId && item.code > 199 && !["indirect", "other"].includes(item.costType) && !item.hide).reduce((a:number, r:KV) => a + parseInt(r.listAmount || r.amount || 0), 0) // All Listing Items
    const humanCostBase = costForAdmin - displayingDirectCosts
    
    const humanCost = Math.ceil(humanCostBase / 1000) * 1000
    const recalcCostForAdmin = costForAdmin + humanCost - humanCostBase
    const adminCostForCalc = Math.ceil(recalcCostForAdmin * (quote.adminRatio || 25) / 100 / 100) * 100
    const adminCost = amount + parseInt(quote.adjust || 0) + parseInt(quote.adjust2 || 0) - displayingDirectCosts - displayingIndirectCosts - humanCost
    const recalcAmount = amount + humanCost - humanCostBase + adminCostForCalc - adminCostBase// - parseInt(quote.adjust || 0) - parseInt(quote.adjust2 || 0)
    const actualAmount = (amount || 0) + (isInvoice ? getInvoiceAdjust(quote) : 0)
    const tax = new Decimal(actualAmount).times(new Decimal(data.quotes[i].taxRate || 0).dividedBy(100)).floor().toNumber()
    const amountWithTax = parseInt(actualAmount || 0) + tax
    return { recalcAmount, humanCost, adminCost, tax, amountWithTax }
}

const FormFieldsNormal = ({ formik, quoteIndex }:{ formik:FormikProps<any>, quoteIndex:number }) => {
    const [showCreateCustomer, setShowCreateCustomer] = useState(false)
    const [showEditCustomer, setShowEditCustomer] = useState(false)
    const [showViewCustomer, setShowViewCustomer] = useState(false)
    const [showCustomer, setShowCustomer] = useState(false)
    const [currentName, setCurrentName] = useState<string|null>(null)
    // use only key1.index.key2 type name
    const handleChangeNumber = (e:ChangeEvent<HTMLInputElement>) => {
        const [key1, index, key2] = e.target.name.split(".")
        formik.values[key1][index][key2] = e.target.value
        recalcTotal(formik, quoteIndex)
        formik.handleChange(e)
    }
    const handleCustomerClick = (e:MouseEvent<HTMLDivElement|SVGSVGElement>) => {
        const name = e.currentTarget.getAttribute("name")
        if (name === "openCreate") setShowCreateCustomer(true)
        else if (name === "openView") setShowViewCustomer(true)
        else setShowCustomer(true)
        e.preventDefault()
    }
    const handleCreateCustomer = (id?:string, values?:KV) => {
        let data = values ? getObjectData(values) : null
        data && (data._id = id)
        id && data && formik.setFieldValue("customer", data)
        setShowCreateCustomer(false)
    }
    const { adminCost, humanCost, amountWithTax } = calcNonstoredNumbersNew(formik.values, quoteIndex)

    useDateEffect({formik})
    
    return (
        <div className="grid grid-cols-6 gap-6">
            <FormFrame formik={formik} schema={schema}>
                <FieldBase name="fileID" />
                <FieldBase name="status" />
                <FieldBase name="subject" />
                <FieldBase name="abbreviation" />
                <FieldBase name="place" />
                <RelationField
                    label="受付者"
                    onClick={e => setCurrentName("receptionist")}
                    item={formik.values.receptionist}
                    span={2}
                />
                <FieldBase name="receptionDate" />
                <RelationField
                    label="担当者"
                    onClick={e => setCurrentName("inCharge")}
                    item={formik.values.inCharge}
                    span={2}
                />
                <UserSelect name={currentName} setName={setCurrentName} formik={formik} />
                <FieldBase name="siteSurveyDate" />
                <FieldBase name="treeSurveyDate" />
                <FieldBase name="distance" />
                <RelationField
                    label="顧客名"
                    onClick={handleCustomerClick}
                    item={formik.values.customer}
                    withButton
                />
                <CustomerSearch show={showCustomer} setShow={setShowCustomer} formik={formik} />
                <FieldBase name="insurance" />
                <FieldBase name="humanCostTitle" />
                <FieldArrayGroup name={`quotes.${quoteIndex}`}>
                    <FieldBase name="subject" />
                    <FieldBase name="abbreviation" />
                    <FieldBase name="quoteDate" />
                    <FieldBase name="quoteExpiry" />
                    <FieldBase name="constructionPeriod" />
                    <FieldBase name="sendMethod" />
                    <FieldBase name="sendDeadline" />
                    <FieldBase name="active" />
                    <FieldBase name="workDetail" />
                    <FieldBase name="totalCost" />
                    <FieldBase name="totalActualCost" />
                    <FieldBase name="totalListCost" />
                    <FieldBase name="margin" onChange={handleChangeNumber} />
                    <FieldBase name="adminRatio" onChange={handleChangeNumber} />
                    <FieldBase name="adjustTitle" />
                    <FieldBase name="adjust" onChange={handleChangeNumber} />
                    <FieldBase name="adjustTitle2" />
                    <FieldBase name="adjust2" onChange={handleChangeNumber} />
                    <FoldableFields title="請求書調整">
                        <FieldBase name="invoiceAdjustTitle" />
                        <FieldBase name="invoiceAdjust" onChange={handleChangeNumber} />
                        <FieldBase name="invoiceAdjustTitle2" />
                        <FieldBase name="invoiceAdjust2" onChange={handleChangeNumber} />
                        <FieldBase name="invoiceAdjustTitle3" />
                        <FieldBase name="invoiceAdjust3" onChange={handleChangeNumber} />
                    </FoldableFields>
                    <FieldBase name="taxRate" />
                    <FieldBase name="amount" />
                    <div className="bg-gray-200 p-2 col-span-6 grid grid-cols-3">
                        <div>{`管理費:　${formatCurrency(adminCost)}`}</div>
                        <div>{`技術料:　${formatCurrency(humanCost)}`}</div>
                        <div>{`合計金額(税込):　${formatCurrency(amountWithTax)}`}</div>
                    </div>
                    <FieldBase name="remark" />
                </FieldArrayGroup>
                <FieldGroup name="operationDates">
                     <FieldBase name="startDate" />
                     <FieldBase name="endDate" />
                </FieldGroup>
                 <FieldBase name="handleDate" />
                 <FieldBase name="invoiceDate" />
                 <RelationField
                     label="請求書作成者"
                     onClick={e => setCurrentName("invoiceAuthor")}
                     item={formik.values.invoiceAuthor}
                     span={2}
                 />
                 <FieldBase name="receivedDate" />
                 <FieldBase name="attendActive" />
            </FormFrame>
            { showCreateCustomer && <DialogFrame show={showCreateCustomer} setShow={setShowCreateCustomer} wide><CreateCustomer completed={(id, values) => handleCreateCustomer(id, values)} /></DialogFrame> }
            { showEditCustomer && <DialogFrame show={showEditCustomer} setShow={setShowEditCustomer} wide><EditCustomer id={formik.values.customer._id} completed={() => setShowEditCustomer(false)} /></DialogFrame> }
             {showViewCustomer && <DialogFrame show={showViewCustomer} setShow={setShowViewCustomer} wide><ViewCustomer id={formik.values.customer._id} edit={(id) => {setShowViewCustomer(false);setShowEditCustomer(true)}} canceled={e => setShowViewCustomer(false)} /></DialogFrame>}
        </div>
    )
}


const heads = ["コード", "大分類", "小分類", "項目名", "設計数量", "実績数量", "原価単価", "実績単価", "表示単価", "原価金額", "実績金額", "表示金額", "非表示", ""]

const FormFieldsItemList = ({ schema, formik, quoteIndex, quoteId }:{ schema:KV, formik:FormikProps<any>, quoteIndex:number, quoteId:number }) => {
    const fullName = "costItem"
    const [newItem, setNewItem] = useState(false)
    const [showItemInput, setShowItemInput] = useState<number|undefined>(undefined)
    const [redrawFlag, setRedrawFlag] = useState(false)
    const handleClick = (e:MouseEvent, costId:number) => {
        setNewItem(false)
        setShowItemInput(costId)
    }
    const handleDeleteClick = (e:MouseEvent, id:number) => {
        let obj = formik.values.costItem.find((value:KV) => value.costId === id)
        obj.quantity = ""
        if (obj.unit === 'yen') {
            obj.unitPrice = ''
            obj.listPrice = ''
        }
        obj.amount = ""
        obj.listAmount = ""
        recalcTotal(formik, null, obj.quoteId)
        setRedrawFlag(!redrawFlag)
    }
    const handleAddItemClick = (e:MouseEvent) => {
        setNewItem(true)
        setShowItemInput(0)
    }
    return (<>
        <div className="px-4 flex justify-between">
            <button type="button" onClick={handleAddItemClick} className="flex justify-center items-center bg-theme-50 hover:bg-theme-100 text-theme-800 w-8 h-8 rounded-2xl transition ease-in duration-200"><PlusIcon className="w-4 h-4" /></button>
            <div className="font-medium">{`合計原価: ${formatCurrency(formik.values.quotes[quoteIndex].totalCost)}　合計実績原価: ${formatCurrency(formik.values.quotes[quoteIndex].totalActualCost)}　合計表示金額: ${formatCurrency(formik.values.quotes[quoteIndex].totalListCost)}`}</div>
        </div>
        <SimpleListFrame heads={heads} noAdd>
            {(formik.values.costItem?.filter((v:KV) => v.quoteId === quoteId).sort((a:KV, b:KV) => a.code - b.code) || []).filter((datum:KV) => datum.quantity || datum.actualQuantity || (datum.unit === "yen" && (datum.unitPrice || datum.actualUnitPrice || datum.listPrice))).map((datum:KV, i:number) => (
            <tr key={`${fullName}${i}`} className="text-sm text-gray-900">
                <ListItem data={[datum.code]} />
                <ListItem data={[datum.category1]} />
                <ListItem data={[datum.category2]} />
                <ListItem data={[datum.name]} />
                <ListItem data={datum.unit === 'yen' ? ['1式'] : [`${datum.quantity}${options.costUnit[datum.unit]}`]} />
                <ListItem data={datum.unit === 'yen' ? ['1式'] : [`${datum.actualQuantity}${options.costUnit[datum.unit]}`]} />
                <ListItem data={[datum.unitPrice]} />
                <ListItem data={[datum.actualUnitPrice]} />
                <ListItem data={[datum.listPrice]} />
                <ListItem data={[datum.amount]} />
                <ListItem data={[datum.actualAmount]} />
                <ListItem data={[datum.listAmount]} />
                <InputCheck index={formik.values.costItem.indexOf(datum)} name="hide" tabIndex={i+1} />
                <EditLinkTd id={String(i)} onClickDelete={e => handleDeleteClick(e, datum.costId)} onClickEdit={e => handleClick(e, datum.costId)} />
            </tr>
        ))}
    </SimpleListFrame>
        <ItemEdit newItem={newItem} showItem={showItemInput} setShowItem={setShowItemInput} quoteId={quoteId} schema={schema.costItem.items} formik={formik} recalcTotal={recalcTotal}/>
    </>)
}

const summaryHeads = ["項目名", "設計", "実績", "設計", "実績", "差額"]

const FieldsActualSummary = ({ formik, quoteIndex, quoteId }:{ formik:FormikProps<any>, quoteIndex:number, quoteId:number }) => {
    const quote = formik.values.quotes[quoteIndex]
    const costItem = formik.values.costItem
    const [margin, actualMargin] = [quote.amount - quote.totalCost, quote.amount + getInvoiceAdjust(quote) - quote.totalActualCost]
    const [marginRatio, actualMarginRatio] = [margin * 100 / quote.amount, actualMargin * 100 / (quote.amount + getInvoiceAdjust(quote))]
    return (<>
        <div>{`${formik.values.subject}（${formik.values.abbreviation}）`}</div>
        <div>{(formik.values.operationDates?.startDate && formik.values.operationDates?.endDate) ? `${JSTDate(new Date(formik.values.operationDates.startDate))} ー ${JSTDate(new Date(formik.values.operationDates.endDate))}` : ''}</div>
        <div className="mx-auto p-px grid grid-cols-4 gap-px text-sm text-right">
            {['', '設計', '実績', '差額'].map(v => <div key={v} className='text-center bg-theme-100'>{v}</div>)}
            <div className='px-2 text-left bg-theme-50'>原価合計</div>
            <div>{formatCurrency(quote.totalCost)}</div>
            <div>{formatCurrency(quote.totalActualCost)}</div>
            <div>{formatCurrency(quote.totalActualCost - quote.totalCost)}</div>
            <div className='px-2 text-left bg-theme-50'>利益率</div>
            <div>{formatNumber(marginRatio)}%</div>
            <div>{formatNumber(actualMarginRatio)}%</div>
            <div>{formatNumber(actualMarginRatio - marginRatio)}%</div>
            <div className='px-2 text-left bg-theme-50'>請求額</div>
            <div>{formatCurrency(quote.amount)}</div>
            <div>{formatCurrency(quote.amount + getInvoiceAdjust(quote))}</div>
            <div>{formatCurrency(getInvoiceAdjust(quote))}</div>
        </div>
        <SimpleListFrame heads={summaryHeads} noAdd><>
            {(costItem?.filter((v:KV) => v.quoteId === quoteId).sort((a:KV, b:KV) => a.code - b.code) || []).filter((datum:KV) => datum.quantity || datum.actualQuantity || (datum.unit === "yen" && (datum.unitPrice || datum.actualUnitPrice))).map((datum:KV, i:number) => 
            <tr key={`costItem${i}`} className="text-sm text-gray-900">
                <ListItem data={[datum.name]} />
                <ListItem data={datum.unit === 'yen' ? ['1式'] : [`${datum.quantity}${options.costUnit[datum.unit]}`]} />
                <ListItem data={datum.unit === 'yen' ? ['1式'] : [`${datum.actualQuantity}${options.costUnit[datum.unit]}`]} />
                <ListItem data={[datum.amount]} />
                <ListItem data={[datum.actualAmount]} />
                <ListItem data={[String(datum.actualAmount - datum.amount)]} />
            </tr>
        )}
    </></SimpleListFrame>
    </>)
}


const validationSchema = Yup.object({
//    _id: Yup.string().required(),
})

const prepareCostItem = (datum:KV, quoteId = 1) => ({ ...(({ _id, ...rest }) => rest)(datum), code: datum._id, costId: quoteId * 1000 + datum._id, quoteId:quoteId, quantity: "", actualUnitPrice: "", amount: "", actualAmount: "", listAmount: "", hide: false, costType: datum.costType  })


const readPrepare = (data:KV, itemData?:KV[]) => {
    data.quotes.forEach((quote:KV) => {
        let allItems = (itemData || []).map(datum => {
            return prepareCostItem(datum, quote.quoteId)
        })
        allItems.forEach((item:KV) => {
            delete item.__typename
            delete item._id
            Object.keys(item).forEach(key => item[key] = item[key] || "")
        })
        const existingItem = data.costItem.filter((item:KV) => item.quoteId === quote.quoteId).map((item:KV) => item.code)
        data.costItem = [...data.costItem, ...allItems.filter((item:KV) => !existingItem.includes(item.code))]
    })
}
    

// Remove costItem whose quantity is empty or zero
const writePrepare = (updates:KV, original?:KV) => {
    updates.costItem = updates.costItem.filter((item:KV) => (item.quantity && item.quantity !== "0") || (item.unit === 'yen' && ((item.unitPrice && item.unitPrice !== "0") || (item.listPrice && item.listPrice !== "0"))))
    if (updates.status !== original?.status) updates.statusBefore = original?.status
    updates.dealAmount = updates.quotes.reduce((a:number, quote:KV) => quote.active ? a + parseInt(quote.amount) : a, 0)
    updates.planCost = updates.quotes.reduce((a:number, quote:KV) => quote.active ? a + parseInt(quote.totalCost) : a, 0)
    updates.dealCost = updates.quotes.reduce((a:number, quote:KV) => quote.active ? a + parseInt(quote.totalActualCost) : a, 0)
}

export const Create = () => {
    const navigate = useNavigate()
    const { loading, Data: itemData } = useData("treeItem")
    const [selectedQuoteId, setSelectedQuoteId] = React.useState(1)
    const openEdit = (id?:string) => id && navigate(`/tree/edit/${id}`)

    if (loading) return <Loading />
        const FormFields = ({ formik, handleClick }:{ formik:FormikProps<any>, handleClick?:() => void }) => {
        const quoteIndex = formik.values.quotes.findIndex((v:KV) => v.quoteId === selectedQuoteId)
        const handleClickLocal = (e:MouseEvent<HTMLButtonElement>) => {
            e.preventDefault()
            if (e.currentTarget.name === "") {
                let next = formik.values.quotes.reduce((a:number, r:KV) => Math.max(parseInt(r.quoteId), a), 0) + 1
                let values = getSchemaValues(schema.quotes.items.properties)
                values.quoteId = next
                formik.values.quotes = [...formik.values.quotes, values]
                formik.values.costItem = [...formik.values.costItem, ...getData(undefined, { costItem: (itemData || []).map(datum => {
                    return prepareCostItem(datum, next)
                }) }).costItem]
                setSelectedQuoteId(next)
            } else {
                setSelectedQuoteId(parseInt(e.currentTarget.name))
            }
        }
        return (<Tab.Group>
            <Tab.List className="p-0.5 rounded-lg bg-theme-700">
                {["一般項目", "費用項目(全て)", "費用項目(抜粋)"].map((tab, i) => <Tab key={`tab${i}`}
                    className={({ selected }) =>
                        (selected ? 'bg-white text-theme-800' : 'text-theme-100 bg-transparent hover:bg-theme-600')
                        + ' mr-2 px-3 py-2 rounded-md text-sm font-medium'
                    }
                >{tab}</Tab>
                )}
            </Tab.List>
            <div className="p-0.5 rounded-lg bg-theme-600">
                {formik.values.quotes.map((v:KV, i:number) => selectedQuoteId === v.quoteId ?
                    <button key={v.quoteId} name={v.quoteId} className="bg-white text-theme-700 mr-2 px-3 py-2 rounded-md text-sm font-medium" onClick={handleClickLocal}>見積{v.quoteId}</button>
                    :
                    <button key={v.quoteId} name={v.quoteId} className="text-theme-100 bg-transparent hover:bg-theme-500 mr-2 px-3 py-2 rounded-md text-sm font-medium" onClick={handleClickLocal}>見積{v.quoteId}</button>
                )}
                <button name="" className="text-theme-100 bg-transparent hover:bg-theme-500 mr-2 px-3 py-2 rounded-md text-sm font-medium" onClick={handleClickLocal}>+</button>
            </div>
            <Tab.Panels>
                <Tab.Panel><FormFieldsNormal formik={formik} quoteIndex={quoteIndex}/></Tab.Panel>
                <Tab.Panel><FormFieldsItemTable formik={formik} quoteIndex={quoteIndex} quoteId={selectedQuoteId} /></Tab.Panel>
                <Tab.Panel><FormFieldsItemList schema={schema} formik={formik} quoteIndex={quoteIndex} quoteId={selectedQuoteId} /></Tab.Panel>
            </Tab.Panels>
        </Tab.Group >)
    }
    let quoteValues = getSchemaValues(schema.quotes.items.properties)
    quoteValues.quoteId = 1
    const values = {
        ...getSchemaValues(schema), quotes: [quoteValues], ...getData(undefined, {
            costItem: (itemData || []).map(datum => {
                return prepareCostItem(datum)
            })
        })}
    return (<CreateBase title={"樹木情報"} values={values} collection="tree" relations={relations} Fields={FormFields} writePrepare={writePrepare} validation={validationSchema} completed={openEdit} Dialog={PrintDialog} />);
};

export const Edit = () => {
    const { loading, Data: itemData } = useData("treeItem")
    const [selectedQuoteId, setSelectedQuoteId] = React.useState(1)
    if (loading) return <Loading />
    const FormFields = ({ formik, handleClick }:{ formik:FormikProps<any>, handleClick?:() => void }) => {
        const quoteIndex = formik.values.quotes.findIndex((v:KV) => v.quoteId === selectedQuoteId)
        const handleClickLocal = (e:MouseEvent<HTMLButtonElement>) => {
            e.preventDefault()
            if (e.currentTarget.name === "") {
                let next = formik.values.quotes.reduce((a:number, r:KV) => Math.max(r.quoteId, a), 0) + 1
                let values = getSchemaValues(schema.quotes.items.properties)
                values.quoteId = next
                formik.values.quotes = [...formik.values.quotes, values]
                formik.values.costItem = [...formik.values.costItem, ...getData(undefined, { costItem: (itemData || []).map(datum => {
                    return prepareCostItem(datum, next)
                }) }).costItem]
                setSelectedQuoteId(next)
            } else {
                setSelectedQuoteId(parseInt(e.currentTarget.name))
            }
        }
        return (<Tab.Group>
            <div className="p-0.5 flex justify-between items-center rounded-lg bg-theme-700">
            <Tab.List className="p-0.5 rounded-lg bg-theme-700">
                {["一般項目", "費用項目(全て)", "費用項目(抜粋)", "実績比較"].map((tab, i) => <Tab key={`tab${i}`}
                    className={({ selected }) =>
                        (selected ? 'bg-white text-theme-800' : 'text-theme-100 bg-transparent hover:bg-theme-600')
                        + ' mr-2 px-3 py-2 rounded-md text-sm font-medium'
                    }
                >{tab}</Tab>
                )}
            </Tab.List>
            <button className={IconButtonClass} name="print" type="button" onClick={handleClick}><PrinterIcon className="w-6 h-6" /></button> 
            </div>
            <div className="p-0.5 rounded-lg bg-theme-600">
                {formik.values.quotes.map((v:KV, i:number) => selectedQuoteId === v.quoteId ?
                    <button key={v.quoteId} name={v.quoteId} className="bg-white text-theme-700 mr-2 px-3 py-2 rounded-md text-sm font-medium" onClick={handleClickLocal}>見積{v.quoteId}</button>
                    :
                    <button key={v.quoteId} name={v.quoteId} className="text-theme-100 bg-transparent hover:bg-theme-500 mr-2 px-3 py-2 rounded-md text-sm font-medium" onClick={handleClickLocal}>見積{v.quoteId}</button>
                )}
                <button name="" className="text-theme-100 bg-transparent hover:bg-theme-500 mr-2 px-3 py-2 rounded-md text-sm font-medium" onClick={handleClickLocal}>+</button>
            </div>
            <Tab.Panels>
                <Tab.Panel><FormFieldsNormal formik={formik} quoteIndex={quoteIndex} /></Tab.Panel>
                <Tab.Panel><FormFieldsItemTable formik={formik} quoteIndex={quoteIndex} quoteId={selectedQuoteId} /></Tab.Panel>
                <Tab.Panel><FormFieldsItemList schema={schema} formik={formik} quoteIndex={quoteIndex} quoteId={selectedQuoteId} /></Tab.Panel>
                <Tab.Panel><FieldsActualSummary formik={formik} quoteIndex={quoteIndex} quoteId={selectedQuoteId} /></Tab.Panel>
            </Tab.Panels>
        </Tab.Group >)
    }
    let quoteValues = getSchemaValues(schema.quotes.items.properties)
    quoteValues.quoteId = 1
    const values = {
        ...getSchemaValues(schema), quotes: [quoteValues], ...getData(undefined, {
            costItem: (itemData || []).map(datum => {
                return prepareCostItem(datum)
            })
        }) }
    return (<EditBase title={"樹木情報"} values={values} collection="tree" relations={relations} Fields={FormFields} readPrepare={(d) => readPrepare(d, itemData)} writePrepare={writePrepare} validation={validationSchema} completed={null} Dialog={PrintDialog} />);
};
export default Edit
