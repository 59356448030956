import React, {
  ReactElement,
  ChangeEvent,
  MouseEventHandler,
  KeyboardEventHandler,
  useState,
  useMemo,
  ReactNode,
} from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Form, Formik, FormikProps } from "formik";
import {
  PlusCircleIcon,
  CheckCircleIcon,
  EyeIcon,
  XCircleIcon,
  ChevronUpIcon,
  PrinterIcon
} from "@heroicons/react/24/solid";

import { useReplace, useAdd, useDatum, useData } from "graphql/useCollection";
import {
  FieldPlain,
  FieldNumber,
  FieldArea,
  FieldCheck,
  FieldWithMark,
  FieldSelect,
} from "./uiparts";
import DatePicker from './datepicker'
import Loading from "./Loading";
import options from "contexts/options.json";
import { setAddress, isKeyValueObject, ButtonClass } from "utils";
import { AnyObject, OptionalObjectSchema, TypeOfShape } from "yup/lib/object";
import { useRealmApp } from "RealmApp";
import { JSTDateTime } from "contexts/dateUtils";
import { useAppContext } from "contexts/AppContext";

export type ItemEditFunction = ({
  newItem,
  showItem,
  setShowItem,
  name,
  parent,
  schema,
  formik,
  quoteId,
  recalcTotal
}: {
  newItem?: boolean,
  showItem?: number,
  setShowItem: React.Dispatch<number | undefined>,
  name?: string,
  parent?: string,
  schema: KV,
  formik: FormikProps<any>,
  quoteId?: number
  recalcTotal?: (formik: FormikProps<any>, quoteIndex: number | null, id?: number) => void
}) => JSX.Element
export type FieldArrayFunction = ({
  schema,
  formik,
  name,
  parent,
  showItem,
  setShowItem,
  newItem,
  setNewItem
}: {
  schema: KV,
  formik: FormikProps<any>,
  name: string,
  parent?: string,
  showItem?: number,
  setShowItem: React.Dispatch<number | undefined>,
  newItem?: boolean,
  setNewItem: React.Dispatch<boolean>
}) => JSX.Element

export type DialogFunction = ({
  current,
  show,
  setShow,
  formik,
  updateOnPrint
}: {
  current?: KV;
  show?: boolean;
  setShow: React.Dispatch<React.SetStateAction<any>>;
  formik?: FormikProps<any>,
  updateOnPrint?: boolean
}) => JSX.Element;


export const getError = (
  formik: FormikProps<any> | undefined,
  name: string
) => {
  if (!formik) return false;
  return formik.touched[name] && formik.errors[name];
};

const FormHeader = ({
  title,
  create = false,
}: {
  title: string;
  create?: boolean;
}) => (
  <div className="md:col-span-1 print:hidden">
    <div className="p-4">
      <h3 className="text-lg font-medium leading-6 text-gray-900">{title}</h3>
      <p className="mt-1 text-sm text-gray-600">
        {title}を{create ? "新規作成" : "編集"}します
      </p>
    </div>
  </div>
);

export const FormButton = ({
  onPrint,
  completed,
}: {
  onPrint?: (() => void) | null;
  completed?: (() => void) | null;
}) => {
  const location = useLocation().pathname
  const { state: { userStatus } } = useAppContext()
  const navigate = useNavigate();
  const editAllowed: boolean = useMemo(() => {
    if (userStatus.status === "admin") return true
    if (location.includes("attendance") || location.includes("machine") || location.includes("aim") || location.includes("message")) return true
    if (userStatus.status === "regular") {
      if (location.includes("customer")) return true
      if (location.includes("firewood")) return userStatus.divisions.includes("firewood")
      if (location.includes("tree")) return userStatus.divisions.includes("tree") || (location.includes("treeitem") && userStatus.divisions.includes("forest"))
      if (location.includes("forest") || location.includes("timber") || location.includes("transport")) return userStatus.divisions.includes("forest")
    }
    return false
  }, [location, userStatus])
  return <>
    {onPrint && <button type="submit" className={ButtonClass}>
      <PrinterIcon className="w-5 h-5" />
      書面印刷
    </button>
    }
    {editAllowed && <button type="submit" className={ButtonClass}>
      <CheckCircleIcon className="w-5 h-5" />
      確定
    </button>
    }
    <button
      className={ButtonClass}
      onClick={(e) => {
        e.preventDefault();
        completed && completed();
        completed === null && location && navigate(location.replace(/\/edit\/[^/]*$/, ''));
      }}
    >
      <XCircleIcon className="w-5 h-5" />
      戻る
    </button>
  </>
};

const handleZipChange = async (
  formik: FormikProps<any> | undefined,
  event: ChangeEvent<HTMLInputElement>
) => {
  const name = event.target.name;
  let value = event.target.value || "";
  setAddress(value, name.replace(".zip", ""), formik);
  formik?.handleChange(event);
};

// put item if customer or user, else set value
export const RelationField = ({
  label,
  onClick,
  value,
  item,
  withButton = false,
  span = 3,
}: {
  label: string;
  onClick: MouseEventHandler<HTMLDivElement | SVGSVGElement>;
  value?: string;
  item?: KV;
  withButton?: boolean;
  span?: number;
}) => (
  <div className={`relative col-span-6 sm:col-span-${span}`}>
    <label className="block text-sm font-medium text-gray-700">{label}</label>
    <div
      className="mt-1 py-2 px-3 text-sm h-9 border border-gray-300 rounded cursor-pointer"
      onClick={onClick}
    >
      {item &&
        `${item.corporateName ? item.corporateName + "　　" : ""}${item.surname || item.name?.surname || ""
        }　${item.givenName || item.name?.givenName || ""}`}
      {value && value}
    </div>
    {withButton && (
      <EyeIcon
        name="openView"
        className="absolute right-9 bottom-1 w-8 h-8 cursor-pointer"
        onClick={onClick}
      />
    )}
    {withButton && (
      <PlusCircleIcon
        name="openCreate"
        className="absolute right-1 bottom-1 w-8 h-8 cursor-pointer"
        onClick={onClick}
      />
    )}
  </div>
);

export const DivField = ({
  label,
  value,
  span = 3,
}: {
  label: string;
  value: string;
  span?: number;
}) => (
  <div className={`relative col-span-6 sm:col-span-${span}`}>
    <label className="block text-sm font-medium text-gray-700">{label}</label>
    <div className="mt-1 py-2 px-3 text-sm h-9 text-gray-600 bg-gray-100 border border-gray-300 rounded">
      {value && value}
    </div>
  </div>
);

// Get initial default values from schema
export const getSchemaValues = (schema: any): KV => {
  return Object.keys(schema).reduce((a, r) => {
    if (schema[r].bsonType === "object")
      return {
        ...a,
        [r]: getSchemaValues(schema[r].properties),
      };
    if (schema[r].bsonType === "array") return { ...a, [r]: [] };
    let value: any = schema[r].description?.match(/[^{}]*(?=\})/g)?.[0];
    if (value === "TODAY") {
      let date = new Date();
      date.setHours(12, 0, 0, 0);
      value = date;
    } else if (value === "NOW") {
      value = new Date()
    } else if (value === "false") {
      value = false
    }
    return { ...a, [r]: value || "" };
  }, {});
};

/**
 * Add schema and formik to children.  Return null if show is false.
 * @param  param0 {show, children, schema, formik}
 * @returns
 */
export const FormFrame = ({
  show = true,
  children,
  schema,
  formik,
}: {
  show?: boolean;
  children: JSX.Element | JSX.Element[] | ReactNode;
  schema?: KV;
  formik?: FormikProps<any>;
}) => {
  if (!show) return null;
  return (
    <>
      {React.Children.toArray(children).map((child) =>
        React.cloneElement(child as ReactElement<any>, {
          schema: schema,
          formik: formik,
        })
      )}
    </>
  );
};

export const FieldBase = ({
  formik,
  schema,
  parent,
  name,
  mark,
  span,
  onChange,
  onBlur,
}: {
  formik?: FormikProps<any>;
  schema?: KV;
  parent?: string;
  name: string;
  mark?: string;
  span?: number;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
  const fullName = (parent ? parent + "." : "") + name;
  //    const obj = fullName.split(".").reduce((a, r) => a[r] || a.properties[r], schema)
  const obj = schema && schema[name.replace(/\[[\s\S]*?\]/g, "")];
  const type = obj.description
    ?.replace(/\([\s\S]*?\)/g, "")
    .replace(/\{[\s\S]*?\}/g, "")
    .replace(/<[\s\S]*?>/g, "")
    .split(":") || ["text"];
  //    const title = obj.title || obj.description?.match(/(?<=<)[^\][]*(?=>)/g)?.[0]
  const title = obj.title || obj.description?.match(/[^<>]*(?=>)/g)?.[0];
  const actualSpan =
    span || obj.description?.match(/[^()]*(?=\))/g)?.[0] || "6";
  switch (type[0]) {
    case "select":
      return (
        <FieldSelect
          label={title}
          name={fullName}
          span={actualSpan}
          options={options[type[1]]}
          props={{
            ...(formik?.getFieldProps(fullName) || {}),
            ...(onChange ? { onChange: onChange } : {}),
            ...(onBlur ? { onBlur: onBlur } : {}),
          }}
        />
      );
    case "date":
      return (
        <DatePicker
          label={title}
          name={fullName}
          span={actualSpan}
          formik={formik}
        />
      );
    case "check":
      return (
        <FieldCheck
          label={title}
          name={fullName}
          span={actualSpan}
          props={{
            ...(formik?.getFieldProps(fullName) || {}),
            ...(onChange ? { onChange: onChange } : {}),
            ...(onBlur ? { onBlur: onBlur } : {}),
          }}
          setFieldValue={formik?.setFieldValue}
        />
      );
    case "zip":
      return (
        <FieldPlain
          label={title}
          name={fullName}
          span={actualSpan}
          props={{
            ...(formik?.getFieldProps(fullName) || {}),
            onChange: (e: ChangeEvent<HTMLInputElement>) =>
              handleZipChange(formik, e),
          }}
          error={String(getError(formik, fullName) || "")}
        />
      );
    case "disabled":
      return type[1] ? (
        <FieldWithMark
          label={title}
          mark={type[1]}
          name={fullName}
          span={actualSpan}
          props={{ ...(formik?.getFieldProps(fullName) || {}), disabled: true }}
          error=""
        />
      ) : (
        <FieldPlain
          label={title}
          name={fullName}
          span={actualSpan}
          props={{ ...(formik?.getFieldProps(fullName) || {}), disabled: true }}
        />
      );
    case "disabledDate":
      return (
        <FieldPlain
          label={title}
          name={fullName}
          span={actualSpan}
          props={{ ...(formik?.getFieldProps(fullName) || {}), value: (formik?.values[fullName] ? JSTDateTime(new Date(formik?.values[fullName])) : undefined), disabled: true }}
        />
      );
    case "decimal":
    case "int":
    case "number":
      return type[1] ? (
        <FieldWithMark
          label={title}
          mark={type[1]}
          name={fullName}
          span={actualSpan}
          props={{
            ...(formik?.getFieldProps(fullName) || {}),
            ...(onChange ? { onChange: onChange } : {}),
            ...(onBlur ? { onBlur: onBlur } : {}),
          }}
          error={String(getError(formik, fullName) || "")}
        />
      ) : (
        <FieldNumber
          label={title}
          name={fullName}
          span={actualSpan}
          props={{
            ...(formik?.getFieldProps(fullName) || {}),
            ...(onChange ? { onChange: onChange } : {}),
            ...(onBlur ? { onBlur: onBlur } : {}),
          }}
          error={String(getError(formik, fullName) || "")}
        />
      );
    case "mark":
      return (
        <FieldWithMark
          label={title}
          name={fullName}
          mark={mark || ""}
          span={actualSpan}
          props={{
            ...(formik?.getFieldProps(fullName) || {}),
            ...(onChange ? { onChange: onChange } : {}),
            ...(onBlur ? { onBlur: onBlur } : {}),
          }}
          error={String(getError(formik, fullName) || "")}
        />
      );
    case "area":
      return (
        <FieldArea
          label={title}
          name={fullName}
          span={actualSpan}
          props={{
            ...(formik?.getFieldProps(fullName) || {}),
            ...(onChange ? { onChange: onChange } : {}),
            ...(onBlur ? { onBlur: onBlur } : {}),
          }}
          error={String(getError(formik, fullName) || "")}
        />
      );
    default:
      return (
        <FieldPlain
          label={title}
          name={fullName}
          span={actualSpan}
          props={{
            ...(formik?.getFieldProps(fullName) || {}),
            ...(onChange ? { onChange: onChange } : {}),
            ...(onBlur ? { onBlur: onBlur } : {}),
          }}
          error={String(getError(formik, fullName) || "")}
        />
      );
  }
};

// Pass formik, schema and parent name to child(parent is object)
export const FieldGroup = ({
  formik,
  schema,
  parent,
  name,
  title,
  children,
}: {
  formik?: FormikProps<any>;
  schema?: KV;
  parent?: string;
  name: string;
  title?: string;
  children: JSX.Element[];
}) => {
  const obj = schema?.[name.replace(/\[[\s\S]*?\]/g, "")] || schema;
  const actualTitle =
    title || obj.title || obj.description?.match(/[^<>]*(?=>)/g)?.[0];
  const actualSpan = obj.description?.match(/[^()]*(?=\))/g)?.[0] || "6";

  return (
    <div
      className={`my-2 p-2 col-span-6 sm:col-span-${actualSpan} grid grid-cols-6 gap-4 bg-gray-100`}
    >
      <div className="col-span-6 text-theme-800">{actualTitle}</div>
      {React.Children.toArray(children).map((child) =>
        React.cloneElement(child as ReactElement<any>, {
          parent: (parent ? parent + "." : "") + name,
          schema: obj.properties,
          formik: formik,
        })
      )}
    </div>
  );
};

// Pass formik, schema and parent name to child(parent is array)
export const FieldArrayGroup = ({
  formik,
  schema,
  parent,
  name,
  title,
  children,
}: {
  formik?: FormikProps<any>;
  schema?: KV;
  parent?: string;
  name: string;
  title?: string;
  children: JSX.Element[];
}) => {
  const obj = schema?.[name.split(".")[0]] || schema;
  //    const obj = schema[name.replace(/\[[\s\S]*?\]/g, '')] || schema
  const actualTitle =
    title || obj.title || obj.description?.match(/[^<>]*(?=>)/g)?.[0];
  const actualSpan = obj.description?.match(/[^()]*(?=\))/g)?.[0] || "6";

  return (
    <div
      className={`my-2 p-2 col-span-6 sm:col-span-${actualSpan} grid grid-cols-6 gap-4 bg-gray-100`}
    >
      <div className="col-span-6 text-theme-800">{actualTitle}</div>
      {React.Children.toArray(children).map((child) =>
        React.cloneElement(child as ReactElement<any>, {
          parent: (parent ? parent + "." : "") + name,
          schema: obj.items.properties,
          formik: formik,
        })
      )}
    </div>
  );
};

export const FoldableFields = ({
  formik,
  schema,
  parent,
  title,
  children,
}: {
  formik?: FormikProps<any>;
  schema?: KV;
  parent?: string;
  title?: string;
  children: JSX.Element[];
}) => {
  const [open, setOpen] = useState(false);

  return <div className={`my-2 p-2 col-span-6 bg-gray-200`}>
      <button
        className="flex justify-between px-4 py-1 w-full text-theme-800 bg-gray-200 rounded-lg"
        onClick={(e) => {
          e.preventDefault();
          setOpen(!open);
        }}
      >
        {title && <span>{title}</span>}
        <ChevronUpIcon
          className={`${open ? "transform rotate-180" : ""
            } w-5 h-5 text-theme-800`}
        />
      </button>
      <div className={`grid grid-cols-6 gap-4 ${open ? "" : "hidden"}`}>
        {React.Children.toArray(children).map((child) =>
          React.cloneElement(child as ReactElement<any>, {
            parent: parent,
            schema: schema,
            formik: formik,
          })
        )}
      </div>
  </div>;
};


// Pass formik, schema and parent name to child
export const FoldableFieldGroup = ({
  formik,
  schema,
  parent,
  name,
  title,
  children,
}: {
  formik?: FormikProps<any>;
  schema?: KV;
  parent?: string;
  name: string;
  title?: string;
  children: JSX.Element[];
}) => {
  const [open, setOpen] = useState(false);
  const obj = schema?.[name.replace(/\[[\s\S]*?\]/g, "")] || schema;
  const actualTitle =
    title || obj.title || obj.description?.match(/[^<>]*(?=>)/g)?.[0];
  const actualSpan = obj.description?.match(/[^()]*(?=\))/g)?.[0] || "6";
  return (
    <div
      className={`my-2 p-2 col-span-6 sm:col-span-${actualSpan} bg-gray-100`}
    >
      <button
        className="flex justify-between px-4 py-2 w-full text-theme-800 bg-gray-200 rounded-lg"
        onClick={(e) => {
          e.preventDefault();
          setOpen(!open);
        }}
      >
        <span>{actualTitle}</span>
        <ChevronUpIcon
          className={`${open ? "transform rotate-180" : ""
            } w-5 h-5 text-theme-800`}
        />
      </button>
      <div className={`grid grid-cols-6 gap-4 ${open ? "" : "hidden"}`}>
        {React.Children.toArray(children).map((child) =>
          React.cloneElement(child as ReactElement<any>, {
            parent: (parent ? parent + "." : "") + name,
            schema: obj.properties,
            formik: formik,
          })
        )}
      </div>
    </div>
  );
};

/**
 * Pass formik, schema and parent name to child
 * @param {*} param0
 * @returns React.component
 */
export const FieldArray = ({
  formik,
  schema,
  parent,
  name,
  children,
}: {
  formik: FormikProps<any>;
  schema: KV;
  parent: string;
  name: string;
  children: JSX.Element;
}) => {
  const obj = schema[name];
  const title = obj.title || obj.description?.match(/[^<>]*(?=>)/g)?.[0];
  const actualSpan = obj.description?.match(/[^()]*(?=\))/g)?.[0] || "6";

  return (
    <div
      className={`my-2 p-2 col-span-6 sm:col-span-${actualSpan} grid grid-cols-6 gap-4 bg-gray-100`}
    >
      <div className="col-span-6 text-theme-800">{title}</div>
      {React.Children.toArray(children).map((child) =>
        React.cloneElement(child as ReactElement<any>, {
          parent: (parent ? parent + "." : "") + name + "[*]",
          schema: obj.items,
          formik: formik,
        })
      )}
    </div>
  );
};

/**
 *
 * @param {*} Data
 * @returns
 */
export const getObjectData = (Data: {
  [key: string]: any;
}): KV =>
  Object.keys(Data).reduce((a, key) => {
    if (key === "__typename") return a;
    if (!Data[key]) return { ...a, [key]: "" };
    return {
      ...a,
      [key]: isKeyValueObject(Data[key])
        ? getObjectData(Data[key])
        : Array.isArray(Data[key])
          ? Data[key].map((datum: any) =>
            isKeyValueObject(datum) ? getObjectData(datum) : datum
          )
          : Data[key],
    };
  }, {});

/**
 * Clean database response data removing null item and __typename. Add data from initial value if not exist in Data.
 * if Data[key] don't exist, use values[key] if exist.
 * @param {*} values Default value object. This value is used if value is not provided by Data
 * @param {*} Data Value object returned from GraphQL query.  Contains __typename field for object.
 * @returns Cleaned value objects
 */
export const getData = (
  values: KV | undefined,
  Data: KV
): KV => {
  return Object.keys(values || Data).reduce((a, key) => {
    if (key === "__typename") return a;
    return {
      ...a,
      [key]: !Data?.[key]
        ? values
          ? values[key]
          : "" // All type including object and array
        : isKeyValueObject(Data[key])
          ? getData(values?.[key], Data[key])
          : Array.isArray(Data[key])
            ? Data[key].map((datum: any) =>
              isKeyValueObject(datum) ? getObjectData(datum) : datum
            )
            : Data[key],
    };
  }, {});
};

// remove no value item(incl. empty array or object) and set relation link.  Number to string.  Get foreign_key value from spreaded relation values and set link value.  Deep copy.
export const getUpdatingData = (
  Data: KV,
  relations: KV = {}
): KV => {
  const relationKeys = Object.keys(relations);
  return Object.keys(Data).reduce((a, key) => {
    const value: any = Data[key];
    if (!value || value === "0") return a;
    if (key === "__typename") return a;
    if (relationKeys.includes(key))
      return { ...a, [key]: { link: value[relations[key].foreign_key] } };
    if (Array.isArray(value)) {
      if (value.length)
        return {
          ...a,
          [key]: value.map((v) =>
            isKeyValueObject(v)
              ? getUpdatingData(v)
              : typeof v === "number"
                ? v.toString()
                : v
          ),
        };
      return a;
    }
    if (isKeyValueObject(value)) {
      const objectValue = getUpdatingData(value);
      if (Object.keys(objectValue).length) return { ...a, [key]: objectValue };
      return a;
    }
    return {
      ...a,
      [key]: typeof value === "number" ? value.toString() : value,
    };
  }, {});
};

const onKeyDown: KeyboardEventHandler<HTMLFormElement> = (keyEvent) => {
  if (
    (keyEvent.target as HTMLElement).localName !== "textarea" &&
    keyEvent.key === "Enter"
  )
    keyEvent.preventDefault();
};

export const EditBase = ({
  title,
  collection,
  id,
  values,
  location,
  relations = {},
  Fields,
  validation,
  readPrepare,
  writePrepare,
  Dialog,
  completed
}: {
  title: string;
  collection: string;
  id?: string;
  values?: KV;
  location?: string;
  relations?: KV;
  Fields: ({ formik, handleClick }: { formik: FormikProps<any>, handleClick?: () => void }) => JSX.Element;
  validation: OptionalObjectSchema<
    KV,
    AnyObject,
    TypeOfShape<any>
  >;
  readPrepare?: (values: KV) => void;
  writePrepare?: (
    updates: KV,
    values?: KV
  ) => void;
  Dialog?: DialogFunction;
  completed?: null | ((id?: string, values?: KV, originalValues?: KV) => void);
}) => {
  const { id: idFromParam } = useParams<KV>();
  const navigate = useNavigate();
  const actualCompleted = completed === undefined ? (() => navigate(location || `/${collection.toLowerCase()}`)) : completed
  const [show, setShow] = useState<boolean>();
  const { loading, Data } = useDatum(collection, { _id: id || idFromParam });
  const updateData = useReplace(collection);
  const modifiedData = loading ? {} : getData(values, Data);
  const currentData = loading ? {} : getData(values, Data);
  const handleClick = () => setShow(true);
  if (!loading && readPrepare) readPrepare(modifiedData);
  return (
    <>
      {loading ? <Loading /> : <div className="2xl:grid 2xl:grid-cols-5 2xl:gap-6">
          <FormHeader title={title} />
          <div className="mt-5 2xl:mt-0 2xl:col-span-4">
            <Formik
              initialValues={modifiedData}
              validationSchema={validation}
              onSubmit={async (values, { setSubmitting }) => {
                let updates = getUpdatingData(values, relations);
                if (writePrepare) {
                  try {
                    writePrepare(updates, modifiedData);
                  } catch (e) {
                    if (e instanceof Error) alert("エラー：" + e.message)
                    else alert("保存前処理が正常に行われなかったため処理が中断されました。")
                    setSubmitting(false);
                    return null
                  }
                }
                const { replacedData, error } = await updateData(
                  modifiedData._id,
                  updates
                );
                setSubmitting(false);
                if (error) alert("エラー：" + error.message)
                else if (!actualCompleted) alert("データの保存が完了しました。")
                else if (replacedData) actualCompleted(modifiedData._id, updates, modifiedData)
              }}
            >
              {(formik) => (formik.isSubmitting ? <Loading /> : <>
                <Form onKeyDown={onKeyDown}>
                  <div className="shadow sm:rounded-md">
                    <div className="px-4 py-5 bg-white space-y-4 sm:p-6">
                      <Fields formik={formik} handleClick={handleClick} />
                    </div>
                    <div className="px-4 bg-gray-50 text-right sm:px-6 print:hidden">
                      <FormButton completed={actualCompleted} />
                    </div>
                  </div>
                </Form>
                {Dialog && <Dialog current={currentData} show={show} setShow={setShow} formik={formik} updateOnPrint />}
                </>)}
            </Formik>
          </div>
        </div>
      }
    </>
  );
};

// FInd latest record within 24 hours
export const EditBaseLatest = ({
  title,
  collection,
  values,
  location,
  relations = {},
  Fields,
  validation,
  readPrepare,
  writePrepare,
  Dialog,
  completed
}: {
  title: string;
  collection: string;
  values: KV;
  location: string;
  relations: KV;
  Fields: ({ formik, handleClick }: { formik: FormikProps<any>, handleClick?: () => void }) => JSX.Element;
  validation: OptionalObjectSchema<
    KV,
    AnyObject,
    TypeOfShape<any>
  >;
  readPrepare: (values: KV) => void;
  writePrepare: (
    updates: KV,
    values: KV
  ) => void;
  Dialog?: DialogFunction;
  completed?: null | ((id?: string, values?: KV) => void);
}) => {
  const app = useRealmApp()
  const navigate = useNavigate();
  const actualCompleted = completed === undefined ? (() => navigate(location || `/${collection.toLowerCase()}`)) : completed
  const [show, setShow] = useState<boolean>();
  const dateLimit = useMemo(() => new Date(new Date().getTime() - 86400000), [])
  const { loading, Data, error } = useData(collection, null, { user: { userID: app.currentUser?.customData?.userID }, start_gt: dateLimit, item: { holiday_exists: false } }, "START_DESC", 1)
  const updateData = useReplace(collection)
  const modifiedData = !Data?.[0] ? {} : getData(values, Data[0])
  if (!loading && Data?.[0] && readPrepare) readPrepare(modifiedData)
  const handleClick = () => setShow(true)
  // Data[0] && !Object.entries(formik.values).length is escaping for time lag between Data ready and formik updating initial value
  return (<>{loading ? (<Loading />) : !modifiedData ? <div className="flex flex-col items-center">
    <div className="p-4 text-red-600">24時間以内に打刻された出勤データがありません</div>
    <button className={ButtonClass} onClick={() => actualCompleted ? actualCompleted() : navigate(location || `/${collection.toLowerCase()}`)}>戻る</button>
  </div>
    : <div className="2xl:grid 2xl:grid-cols-5 2xl:gap-6">
      <FormHeader title={title} />
      <div className="mt-5 2xl:mt-0 2xl:col-span-4">
        <Formik
          initialValues={modifiedData}
          validationSchema={validation}
          onSubmit={async (values, { setSubmitting }) => {
            let updates = getUpdatingData(values, relations)
            if (writePrepare) {
              try {
                writePrepare(updates, modifiedData || {});
              } catch (e) {
                if (e instanceof Error) alert("エラー：" + e.message)
                else alert("保存前処理が正常に行われなかったため処理が中断されました。")
                setSubmitting(false);
                return null
              }
            }
            const { replacedData, error } = await updateData(modifiedData?._id, updates)
            setSubmitting(false);
            if (error) alert("エラー：" + error.message)
            else if (!actualCompleted) alert("データの保存が完了しました。")
            else if (replacedData) actualCompleted()
          }}
        >
          {formik => formik.isSubmitting ? <Loading /> : <>
            <Form onKeyDown={onKeyDown}>
              <div className="shadow sm:rounded-md">
                <div className="px-4 py-5 bg-white space-y-4 sm:p-6">
                  <Fields formik={formik} handleClick={handleClick} />
                </div>
                <div className="px-4 bg-gray-50 text-right sm:px-6 print:hidden">
                  <FormButton completed={actualCompleted} />
                </div>
              </div>
            </Form>
            {Dialog && <Dialog current={getData(values, Data?.[0] || {})} show={show} setShow={setShow} formik={formik} updateOnPrint />}
          </>}
        </Formik>
      </div>
    </div>
  }</>);
};

export const CreateBase = ({
  title,
  collection,
  values,
  location,
  relations = {},
  Fields,
  completed,
  validation,
  writePrepare,
  Dialog
}: {
  title: string;
  collection: string;
  values: KV;
  location?: string;
  relations?: KV;
  Fields: ({ formik, completed = null }: { formik: FormikProps<any>, completed?: (() => void) | null }) => JSX.Element;
  completed?: null | ((id?: string, values?: KV) => void);
  validation: OptionalObjectSchema<
    KV,
    AnyObject,
    TypeOfShape<any>
  >;
  writePrepare?: (
    updates: KV,
    values: KV
  ) => void;
  Dialog?: DialogFunction;
}) => {
  const navigate = useNavigate();
  const actualCompleted = completed === undefined ? (() => navigate(location || `/${collection.toLowerCase()}`)) : completed
  const [show, setShow] = useState<boolean>();
  //React.useCallback(() => { history.push(location || `/${collection.toLowerCase()}`) }, [history, location, collection])
  const addData = useAdd(collection);
  const addingData = async (
    setSubmitting: (isSubmitting: boolean) => void,
    updates: KV,
    updatingValues: KV
  ) => {
    try {
      const { addedData } = await addData(updates);
      setSubmitting(false);
      if (addedData && actualCompleted) actualCompleted(addedData._id, updatingValues);
    } catch (e: any) {
      if (e.message.indexOf("Duplicate key error") > -1) {
        if (updates._id) {
          alert(
            "エラー：\n指定したIDはすでに存在します。別のIDを指定してください。"
          );
        } else {
          await addingData(setSubmitting, updates, updatingValues);
        }
      } else {
        alert("エラー：\n" + e.message);
      }
      setSubmitting(false);
    }
  };

  return (
    <div className="2xl:grid 2xl:grid-cols-5 2xl:gap-6">
      <FormHeader title={title} create />
      <div className="mt-5 2xl:mt-0 2xl:col-span-4">
        <Formik
          initialValues={values}
          validationSchema={validation}
          onSubmit={async (updatingValues, { setSubmitting }) => {
            let updates = getUpdatingData(updatingValues, relations);
            if (writePrepare) {
              try {
                writePrepare(updates, values);
              } catch (e) {
                if (e instanceof Error) alert("エラー：" + e.message)
                else alert("保存前処理が正常に行われなかったため処理が中断されました。")
                setSubmitting(false);
                return null
              }
            }
            addingData(setSubmitting, updates, updatingValues);
          }}
        >
          {(formik) => formik.isSubmitting ? <Loading /> : <Form onKeyDown={onKeyDown}>
              <div className="shadow sm:rounded-md">
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                  <Fields formik={formik} completed={actualCompleted} />
                </div>
                <div className="px-4 bg-gray-50 text-right sm:px-6 print:hidden">
                  <FormButton completed={actualCompleted} onPrint={undefined} />
                </div>
              </div>
            </Form>
          }
        </Formik>
      </div>
    </div>
  );
};
