import React from "react"
import { Link } from "react-router-dom"
import { ButtonClass } from "utils"

const location = "/attendance"

const DashItemMobile = () => <div className="container flex flex-col w-full min-h-full items-center justify-center">
    <Link to={`${location}/create`}><button className={ButtonClass}>出勤打刻</button></Link>
    <Link to={`${location}/editend`}><button className={ButtonClass}>退勤打刻</button></Link>
    <Link to={`/machine/create`}><button className={ButtonClass}>機械使用</button></Link>
    <span className="p-4">これ以外の操作は左上メニューから勤怠を選んでください</span>
</div>

export default DashItemMobile