import React, { ChangeEvent, MouseEvent, useMemo } from "react";
import * as Yup from 'yup';
import Decimal from "decimal.js";
import { FormikProps } from "formik";

import relations from "realm/Yanagisawa/data_sources/mongodb-atlas/Business/transport/relationships.json"
import { CreateBase, EditBase, FieldBase, FormFrame, getObjectData, getSchemaValues, RelationField } from "components/edit";
import { FieldSelect } from "components/uiparts";
import { Create as CreateCustomer } from 'pages/customer/edit'
import ViewCustomer from 'pages/customer/view'
import CustomerSearch from "components/customerSearch";
import DialogFrame from "components/dialog";
import { useAppContext } from "contexts/AppContext";
import options from "contexts/options.json";
import { useData } from "graphql/useCollection";
import Loading from "components/Loading";

const schema = require("realm/Yanagisawa/data_sources/mongodb-atlas/Business/transport/schema.json").properties;

const initialValues = getSchemaValues(schema)

const projectingDivision = (key:string) => ['forest', 'tree'].includes(key)

// const truckTypeConversion = {
//     "8t(HIAB)":"8t Hiab付",
//     "4t":"4t クレーン付",
//     "2t":"2t ダンプ",
//     "軽トラ":"軽トラック"
// }

const FormFields = ({ formik } : {formik: FormikProps<any>}) => {
    const context = useAppContext()
    // customer selection dialog bool
    const [showCustomer, setShowCustomer] = React.useState(false)
    // Newc ustomer creation dialog bool
    const [showCreateCustomer, setShowCreateCustomer] = React.useState(false)
    const [showViewCustomer, setShowViewCustomer] = React.useState(false)
    const [totalCostChanged, setTotalCostChanged] = React.useState(false)
    const { Data:treeItemData, loading } = useData("treeItem")
    const getTruckCost = (truckType:string) => {
        const oil = treeItemData?.find(v => v.category1 === "燃料費" && v.category2 === "車両" && v.name.startsWith(truckType.slice(0,2)))?.unitPrice || 0
        const machine = treeItemData?.find(v => v.category1 === "機械経費" && v.category2 === "車両" && v.name.startsWith(truckType.slice(0,2)))?.unitPrice || 0
        return oil + machine
    }
    const humanCost = useMemo(() => new Decimal(treeItemData?.find(v => v.category1 === "労務費" && v.name === "現場出役")?.unitPrice || 0), [treeItemData])
    
    initialValues.unitPrice = initialValues.unitPrice || getTruckCost(initialValues.truckType) ||  ""
    const truckTypes = treeItemData?.filter(v => v.category1 === "機械経費" && v.category2 === "車両" && !v.name.includes("通勤"))?.reduce((a, r) => ({...a, [r.name]:r.name}), {"外注車":"外注車"})

    const handleCustomerClick = (e:MouseEvent<HTMLDivElement>) => {
        const name = e.currentTarget.getAttribute("name")
        if (name === "openCreate") setShowCreateCustomer(true)
        else if (name === "openView") setShowViewCustomer(true)
        else setShowCustomer(true)
        e.preventDefault()
    }
    // Function after creating customer
    const handleCreate = (id?:String, values?:KV) => {
        let data = values ? getObjectData(values) : null
        data && (data._id = id)
        id && data && formik.setFieldValue("customer", data)
        setShowCreateCustomer(false)
    }

    const handleItemChange = (e:ChangeEvent<HTMLInputElement|HTMLSelectElement>) => {
        const name = e.currentTarget.name
        if (name === "totalCost") {
            setTotalCostChanged(true)
            return null
        }
        if (totalCostChanged) {
            if (!window.confirm("手動で変更されています。自動で再計算しますか？")) return null
            setTotalCostChanged(false)
        }
        const humanUnits = new Decimal((name === "human" ? e.target.value : formik.values.human) || 0)
        const unitPrice = new Decimal((name === "unitPrice" ? e.target.value : name === "truckType" ? getTruckCost(e.currentTarget.value) : formik.values.unitPrice) || 0)
        if (name === "truckType") {
            formik.setFieldValue("unitPrice", unitPrice.toFixed() || "")
        }
        if ((name === "outsource" && e.target.value) || (name !== "outsource" && formik.values.outsource)) {
            const woodVolume = new Decimal((name === "woodVolume" ? e.target.value : formik.values.woodVolume) || 0)
            formik.values.totalCost = woodVolume.times(unitPrice).floor().toFixed()
            console.log(name,  woodVolume.times(unitPrice).floor().toFixed())
        } else {
            const distance = new Decimal((name === "distance" ? e.target.value : formik.values.distance) || 0)
            const toll = new Decimal((name === "toll" ? e.target.value : formik.values.toll) || 0)
            formik.values.totalCost = humanCost.times(humanUnits).plus(distance.times(unitPrice).floor().plus(toll)).toFixed()
        }
        formik.handleChange(e)
    }

    const handleProjectChange = (e:ChangeEvent<HTMLSelectElement>) => {
        const fieldName = e.currentTarget.name
        const { __typename, ...data } = context.state[formik.values.division]?.ongoing?.find((item:KV) => item._id === e.currentTarget.value) || { __typename: "" }
        formik.setFieldValue(`${fieldName}.${formik.values.division}`, { ...data })
    }

    const getProjectOptions = () => {
        if (!projectingDivision(formik.values.division || "")) return ({ "": "" })
        let options = (context.state[formik.values.division]?.ongoing || []).reduce((a:KV, r:KV) => ({ ...a, [r._id]: `${r.fileID} - ${r.subject}` }), { "": "" })
        const initialValue = formik.initialValues.project
        if (initialValue && initialValue._id) options = { ...options, [initialValue._id]: `${initialValue.fileID} - ${initialValue.subject}` }
        return options
    }

    const getFromOptions = () => {
        let fromOptions = (context.state[formik.values.division]?.ongoing || []).reduce((a:KV, r:KV) => ({ ...a, [r.subject]: r.subject }), { "": "" })
        const initialValue = formik.initialValues.from
        return { ...fromOptions, ...(initialValue ? {[initialValue]: initialValue} : {}), ...options.yard }
    }

    if (loading) return <Loading />
    return <div className="grid grid-cols-6 gap-6">
        <FormFrame formik={formik} schema={schema}>
            <FieldBase name="date" />
            <FieldSelect label="車種" name="truckType" span={1} options={truckTypes} props={{ value: formik.values.truckType, onChange: handleItemChange }} />
            <FieldBase name="human" onChange={handleItemChange} />
            <FieldBase name="division" />
            <>{projectingDivision(formik.values.division) && <FieldSelect label="事業名" name={`project`} span={2} options={getProjectOptions()} props={{ value: formik.values.project?.[formik.values.division]?._id, onChange: handleProjectChange }} />}</>
            <FieldSelect label="発地【現場名】" name="from" span={2} options={getFromOptions()} props={formik.getFieldProps('from')} />
            <FieldBase name="to" />
            <RelationField
                label="卸先"
                onClick={handleCustomerClick}
                item={formik.values.customer}
                withButton value={""}
            />
            <CustomerSearch show={showCustomer} setShow={setShowCustomer} formik={formik} />
            <FieldBase name="outsource" onChange={handleItemChange} />
            <FieldBase name="woodVolume" onChange={handleItemChange} />
            <FieldBase name="unitPrice" onChange={handleItemChange} />
            <FieldBase name="distance" onChange={handleItemChange} />
            <FieldBase name="toll" onChange={handleItemChange} />
            <FieldBase name="totalCost" onChange={handleItemChange} />
            <FieldBase name="remarks" />
        </FormFrame>
        {showCreateCustomer && <DialogFrame show={showCreateCustomer} setShow={setShowCreateCustomer} wide={true}><CreateCustomer completed={(id?:String, values?:KV) => handleCreate(id, values)} /></DialogFrame>}
        {showViewCustomer && <DialogFrame show={showViewCustomer} setShow={setShowViewCustomer} wide={true}><ViewCustomer id={formik.values.customer._id} canceled={(e:MouseEvent) => setShowViewCustomer(false)} /></DialogFrame>}
    </div>
}

const validationSchema =  Yup.object({
})


const writePrepare = (updates:KV) => {
    return null;
}


export const Create = () => {
    return (<>
        <CreateBase title="運材情報" values={initialValues} collection="transport" relations={relations} Fields={FormFields} writePrepare={writePrepare} validation={validationSchema} location={""} />
        </>);
};

export const Edit = () => {
    return (<EditBase title="運材情報" values={initialValues} collection="transport" relations={relations} Fields={FormFields} writePrepare={writePrepare} validation={validationSchema} />);
};
export default Edit