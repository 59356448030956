const humanCostCode = 101;
const machineCostTitle = "機械経費"
const oilCostTitle = "燃料費"


const getMachine = (name: string) => {
    switch (name) {
        case "チェンソー":
        case "チェンソー大":
            return "チェンソー大"
        case "刈払機":
            return "刈払機"
        default:
            return "高性能林業機械"
    }
}
const oilOptions: KV = {
    "mix": "混合油",
    "chain": "チェーンオイル",
    "heavychain": "チェーンオイル",
    "diesel": "免税軽油(L単価)"
    //その他軽油
    //その他ガソリン
}

const humanWorks: KV = {
    "11": "主作業",
    "12": "主作業",
    "13": "主作業",
    "14": "主作業",
    "15": "主作業",
    "16": "主作業",
    "17": "作業道",
    "21": "共通仮設",
    "22": "共通仮設",
    "31": "運材",
    "41": "その他",
}
/*
"11":"主作業 - 伐倒",
"12":"主作業 - 集材",
"13":"主作業 - 造材",
"14":"主作業 - 小運搬",
"15":"主作業 - 植栽",
"16":"主作業 - 地拵",
"17":"作業道 - 作業道",
"21":"共通仮設 - 共通仮設",
"22":"共通仮設 - 災害対応",
"31":"運材 - 運材",
"41":"その他 - 事業原価対象",
"42":"その他 - 事業原価対象外",
*/

type MachineCategories = "主作業" | "作業道";
type HumanCategories = "主作業" | "作業道" | "共通仮設" | "運材" | "その他";

export interface MachineSummaryInterface {
    [key: string]/*Machine Name*/: { machineCost: number, oilCost: { [key: string]/*Oil Name*/: number } }
}


export interface OilSummaryInterface {
    [key: string]/*Oil Name(oilOptions.value)*/: number
}

export interface TransportSummaryInterface {
    human: number,
    machine: { [key: string]: { machineCost: number, oilCost: number } },
    toll: number,
    outsource: number//machine is omitted if oursource is set
}

const getItemCost = (costItem: KV[], category: string, name: string) => !name ? 0 : (costItem.find(v => v.category1 === category && v.name === name)?.unitPrice || 0)

export const getMachineSummary = (machineData: KV, costItem: KV[]): { [key in MachineCategories]: MachineSummaryInterface } => machineData.reduce((a: { [key: string]: MachineSummaryInterface }, r: KV) => {
    const machine = getMachine(r.machine)
    const category: MachineCategories = r.category || "主作業"
    return {
        ...a, [category]: {
            ...a[category],
            [machine]: {
                machineCost: Number(a[category]?.[machine]?.machineCost || 0) + Number(r.unit || 0) * getItemCost(costItem, machineCostTitle, machine),
                oilCost: {
                    ...(r.oilType1 ? { [r.oilType1]: Number(a[category]?.[machine]?.oilCost?.[r.oilType1] || 0) + Number(r.oil1 || 0) * getItemCost(costItem, oilCostTitle, oilOptions[r.oilType1]) } : {}),
                    ...(r.oilType2 ? { [r.oilType2]: Number(a[category]?.[machine]?.oilCost?.[r.oilType2] || 0) + Number(r.oil2 || 0) * getItemCost(costItem, oilCostTitle, oilOptions[r.oilType2]) } : {})
                }
            }
        }
    }
}, {"主作業":{}, "作業道":{}})
export const getOilSummary = (machineData: KV, costItem: KV[]): { [key in MachineCategories]: OilSummaryInterface } => machineData.reduce((a: { [key: string]: OilSummaryInterface }, r: KV) => ({
    ...a, [r.category]: {
        ...a[r.category],
        ...(r.oilType1 ? { [oilOptions[r.oilType1]]: Number(a[r.category]?.[oilOptions[r.oilType1]] || 0) + Number(r.oil1 || 0) * getItemCost(costItem, oilCostTitle, oilOptions[r.oilType1]) } : {}),
        ...(r.oilType2 ? { [oilOptions[r.oilType2]]: Number(a[r.category]?.[oilOptions[r.oilType2]] || 0) + Number(r.oil2 || 0) * getItemCost(costItem, oilCostTitle, oilOptions[r.oilType2]) } : {})
    }
}), {"主作業":{}, "作業道":{}})

export const getTransportSummary = (transportData: KV, costItem: KV[]): TransportSummaryInterface => {
    const humanUnitPrice = costItem.find(v => v._id === humanCostCode)?.unitPrice || 0
    return transportData.reduce((a: TransportSummaryInterface, r: KV) => ({
        ...a, ...(r.outsource ? ({
            outsource: a.outsource + Number(r.totalCost || 0)
        }) : ({
            human: a.human + Number(r.human || 0) * humanUnitPrice,
            machine: {
                ...a.machine, [r.truckType]: {
                    machineCost: (a.machine[r.truckType]?.machineCost || 0) + Number(r.unitPrice || 0) * Number(r.distance || 0),
                    oilCost: (a.machine[r.truckType]?.oilCost || 0) + Number(r.unitPrice || 0) * Number(r.distance || 0)
                }
            }, toll: a.toll + Number(r.toll || 0),
        }))
    }), { human: 0, machine: {}, toll: 0, outsource: 0 })
}


export const getHumanSummary = (humanData: KV, costItem: KV[]): { [key in HumanCategories]: number } => {
    const humanUnitPrice = costItem.find(v => v._id === humanCostCode)?.unitPrice || 0
    return humanData.reduce((a: { [key in HumanCategories]: number }, r: KV) => {
        r.item.forEach((eachItem: KV) => {
            const category: HumanCategories = eachItem.work?.category
            if (eachItem.work?.item === "事業原価対象外") return
            //const item = eachItem.work?.item
            a = { ...a, [category]: (a[category] || 0) + humanUnitPrice / (eachItem.halfday ? 2 : 1) }
        })
        return a;
    }, {"主作業":0, "作業道":0, "共通仮設":0, "運材":0, "その他":0})
}

