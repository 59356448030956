import React from 'react';
import { FormikProps } from 'formik';
import * as Yup  from 'yup';

import yupLocale from "contexts/yupLocale"
import { getSchemaValues, CreateBase, EditBase, FormFrame, FieldBase } from 'components/edit'

const schema = require("realm/Yanagisawa/data_sources/mongodb-atlas/Business/work/schema.json").properties;

Yup.setLocale(yupLocale)
const keys = ["_id", "costID", "division", "category", "item", "active","remarks"]

const FormFields = ({ formik }:{ formik:FormikProps<any> }) => (
    <div className="grid grid-cols-6 gap-4">
        <FormFrame formik={formik} schema={schema}>
            { keys.map(key=> (
                <FieldBase key={key} name={key} />
            ))}
        </FormFrame>
    </div>
)

const validationSchema = Yup.object({
})

export const Create = () => {
    return (<CreateBase title="作業項目情報" collection="work" values={getSchemaValues(schema)} Fields={FormFields} validation={validationSchema} />);
};

export const Edit = () => {
    return (<EditBase title="作業項目情報" collection="work" Fields={FormFields} validation={validationSchema} />);
};
export default Edit