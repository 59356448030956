import React, { MouseEvent } from 'react';
import { FormikProps } from 'formik';
import * as Yup from 'yup';

import relations from "realm/Yanagisawa/data_sources/mongodb-atlas/Business/customer/relationships.json"
import { getObjectData, getSchemaValues, CreateBase, EditBase, FormFrame, FieldBase, FieldGroup, FoldableFieldGroup, RelationField } from 'components/edit'
import { FieldCheck } from 'components/uiparts';
import CustomerSearch from 'components/customerSearch';
import DialogFrame from 'components/dialog';
import { Create as CreateCustomer } from 'pages/customer/edit'
import ViewCustomer from 'pages/customer/view'

const schema = require("realm/Yanagisawa/data_sources/mongodb-atlas/Business/customer/schema.json").properties;


const FormFields = ({ formik }:{ formik: FormikProps<KV>}) => {
    // Newc ustomer creation dialog bool
    const [showCreateCustomer, setShowCreateCustomer] = React.useState(false)
    const [showViewCustomer, setShowViewCustomer] = React.useState(false)
    // customer selection dialog bool
    const [showNextManager, setShowNextManager] = React.useState(false)

    const handleNextManagerClick = (e:MouseEvent) => {
        const name = e.currentTarget.getAttribute("name")
        if (name === "openCreate") setShowCreateCustomer(true)
        else if (name === "openView") setShowViewCustomer(true)
        else setShowNextManager(true)
        e.preventDefault()
    }
    // Function after creating customer
    const handleCreate = (id?:string, values?:KV) => {
        let data = values ? getObjectData(values) : null
        data && (data._id = id)
        id && data && formik.setFieldValue("nextManager", data)
        setShowCreateCustomer(false)
    }

    return <div className="grid grid-cols-6 gap-4">
        <FormFrame formik={formik} schema={schema}>
            <FieldGroup name="type">
                <FieldBase name="firewood" />
                <FieldBase name="tree" />
                <FieldBase name="forest" />
                <FieldBase name="timber" />
            </FieldGroup>
            <FieldBase name="corporation" />
            <FormFrame show={formik.values.corporation}>
                <FieldBase name="corporateName" />
                <FieldBase name="corporateNameKana" />
                <FieldBase name="division" />
            </FormFrame>
            <FieldGroup name="name" title={formik.values.corporation ? "担当者名" : "顧客名"}>
                <FieldBase name="surname" />
                <FieldBase name="givenName" />
                <FieldBase name="surnameKana" />
                <FieldBase name="givenNameKana" />
            </FieldGroup>
            <FieldGroup name="address">
                <FieldBase name="zip" />
                <FieldBase name="state" />
                <FieldBase name="citystr" />
                <FieldBase name="room" />
            </FieldGroup>
            <FieldBase name="tel" />
            <FieldBase name="tel2" />
            <FieldBase name="fax" />
            <FieldBase name="email" />
            <FieldCheck label="現山林管理者" name="isCurrentManager" props={formik.getFieldProps("isCurrentManager")} setFieldValue={formik.setFieldValue} />
            { !formik.values.isCurrentManager && <>
                <RelationField
                    label="次の管理者"
                    onClick={handleNextManagerClick}
                    item={formik.values.nextManager}
                    withButton
                />
                <CustomerSearch show={showNextManager} setShow={setShowNextManager} formik={formik} field="nextManager"/>
            </>}
            <FoldableFieldGroup name="invoiceAddress">
                <FieldBase name="zip" />
                <FieldBase name="state" />
                <FieldBase name="citystr" />
                <FieldBase name="room" />
            </FoldableFieldGroup>
            <FieldBase name="forest" />
            <FieldBase name="remarks" />
        </FormFrame>
        {showCreateCustomer && <DialogFrame show={showCreateCustomer} setShow={setShowCreateCustomer} wide={true}><CreateCustomer completed={(id?:string, values?:KV) => handleCreate(id, values)} /></DialogFrame>}
        {showViewCustomer && <DialogFrame show={showViewCustomer} setShow={setShowViewCustomer} wide={true}><ViewCustomer id={formik.values.nextManager._id} canceled={(e:MouseEvent) => setShowViewCustomer(false)} /></DialogFrame>}
    </div>
}

const validationSchema = Yup.object({
    name: Yup.object({
        surname: Yup.string()
            .max(15, 'Must be 15 characters or less'),
        surnameKana: Yup.string()
            .max(15, 'Must be 15 characters or less'),
        givenName: Yup.string()
            .max(20, 'Must be 20 characters or less'),
    }),
    email: Yup.string().email('無効なメールアドレスです'),
})


const readPrepare = (values:KV) => {
    values.isCurrentManager = !Boolean(values.nextManager)
}

const writePrepare = (updates:KV) => {
    if (!updates.corporation) {
        delete updates.corporateName
        delete updates.division
    }
    if (updates.isCurrentManager) delete updates.nextManager
    delete updates.isCurrentManager
}

export const Create = ({ completed }:{ completed?:null | ((id?: string, values?: KV) => void);}) => {
    return (<CreateBase title="顧客情報" values={{ ...getSchemaValues(schema), isCurrentManager: true }} collection="customer" relations={relations} Fields={FormFields} writePrepare={writePrepare} completed={completed} validation={validationSchema} />);
};

export const Edit = ({ id, completed }:{ id?: string, completed?:null | (() => void);}) => {
    return (<EditBase title="顧客情報" values={{ ...getSchemaValues(schema), isCurrentManager: true }} collection="customer" id={id} relations={relations} Fields={FormFields} readPrepare={readPrepare} writePrepare={writePrepare} completed={completed} validation={validationSchema} />);
};
export default Edit