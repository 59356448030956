import React, { ChangeEvent, FocusEvent, KeyboardEvent, useState } from 'react';
import { FormikProps } from 'formik';
import { FieldPlain } from 'components/uiparts'
import { useLazyData } from 'graphql/useCollection'
import DialogFrame from "components/dialog"

const CustomerSearch = ({show, setShow, formik, field}:{show:boolean, setShow:React.Dispatch<boolean>, formik:FormikProps<any>, field?:string}) => {
    const [givenNameKana, setGivenNameKana] = useState("")
    const [tel, setTel] = useState("")
    const [surnameKana, setSurnameKana] = useState("")
    const [corporateName, setCorporateName] = useState("")
    const [corporateNameKana, setCorporateNameKana] = useState("")
//    const { getData, loading, Data } = useSearchCustomer()
    const { getData, Data } = useLazyData('customer', "search", {fetchPolicy: "cache-and-network"})

    const handleKeyUp = (e:KeyboardEvent<HTMLInputElement>) => {
        e.which = e.which || e.keyCode;
        if (e.which === 13) {
            handleBlur(e)
        }
    }
    const handleBlur = (e:KeyboardEvent<HTMLInputElement>|FocusEvent<HTMLInputElement>) => {
        if (!surnameKana && !givenNameKana && !tel && !corporateName && !corporateNameKana) return
        let query:KV = {}
        if (surnameKana || givenNameKana) query.name = {}
        if (surnameKana) query.name.surnameKana = surnameKana
        if (givenNameKana) query.name.givenNameKana = givenNameKana
        if (tel) query.tel = tel
        if (corporateName) query.corporateName = corporateName
        if (corporateNameKana) query.corporateNameKana = corporateNameKana
//        getData({ variables: { input: { id: id, surnameKana: namekana, tel: tel }}})
        getData({ variables: { query: query }})
    }

    const handleClick = (data:KV) => {
        const udpate = { ...data }
        delete udpate.__typename
        formik.setFieldValue(field || "customer", udpate)
//        formik.setFieldValue("customer.name", data.name)
        setShow(false)
    }

    return (<DialogFrame show={show} setShow={setShow}>
        <div className="grid grid-cols-8 gap-2">
            <FieldPlain label="姓（かな）" name="surnameKana" props={{ value: surnameKana, onChange: (e:ChangeEvent<HTMLInputElement>) => setSurnameKana(e.target.value), onKeyUp: handleKeyUp, onBlur: handleBlur }} span = {1} />
            <FieldPlain label="名（かな）" name="nameKana" props={{ value: givenNameKana, onChange: (e:ChangeEvent<HTMLInputElement>) => setGivenNameKana(e.target.value), onKeyUp: handleKeyUp, onBlur: handleBlur  }} span = {1} />
            <FieldPlain label="会社名" name="corporateName" props={{ value: corporateName, onChange: (e:ChangeEvent<HTMLInputElement>) => setCorporateName(e.target.value), onKeyUp: handleKeyUp, onBlur: handleBlur }} span = {2} />
            <FieldPlain label="会社名（かな）" name="corporateNameKana" props={{ value: corporateNameKana, onChange: (e:ChangeEvent<HTMLInputElement>) => setCorporateNameKana(e.target.value), onKeyUp: handleKeyUp, onBlur: handleBlur }} span = {2} />
            <FieldPlain label="TEL" name="tel" props={{ value: tel, onChange: (e:ChangeEvent<HTMLInputElement>) => setTel(e.target.value), onKeyUp: handleKeyUp, onBlur: handleBlur  }} span = {2} />
        </div>
        {Data ? (<div className="container flex flex-col mx-auto w-full items-center justify-center bg-white dark:bg-gray-800 rounded-lg shadow">
            { !Data.length && <div　className="p-2 w-full text-center">該当するデータはありません</div>}
            <ul className="flex flex-col divide divide-y">
                { Data.map(data => (
                    <li key={data._id} className="flex flex-row">
                        <div className="select-none cursor-pointer flex flex-1 items-center p-2 text-sm" onClick={e => handleClick(data)}>
                            <div className="text-gray-600 dark:text-gray-200 w-24">
                                {data._id}
                            </div>
                            <div className="flex-1 pl-1 mr-4 w-32">
                                <div className="text-gray-600 dark:text-gray-200">
                                    {`${data.name?.surnameKana || ''}　${data.name?.givenNameKana || ''}`}
                                </div>
                                <div className="font-medium dark:text-white">
                                    {`${data.name?.surname || ''}　${data.name?.givenName || ''}`}
                                </div>
                            </div>
                            <div className="text-gray-600 dark:text-gray-200 w-32">
                                <div className="dark:text-white">
                                    {data.corporateName}
                                </div>
                                <div>
                                    {data.division}
                                </div>
                            </div>
                            <div className="text-gray-600 dark:text-gray-200 w-32">
                                <div className="dark:text-white">
                                    〒{data.address?.zip || ''}
                                </div>
                                <div>
                                    {data.address?.citystr || ''}
                                </div>
                            </div>
                            <div className="text-gray-600 dark:text-gray-200 w-28">
                                <div className="dark:text-white">
                                    {data.tel}
                                </div>
                                <div>
                                    {data.email}
                                </div>
                            </div>
                            <button className="w-18 text-right flex justify-end">
                                <svg width="20" fill="currentColor" height="20" className="hover:text-gray-800 dark:hover:text-white dark:text-gray-200 text-gray-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z">
                                    </path>
                                </svg>
                            </button>
                        </div>
                    </li>
                ))}
            </ul>
        </div>) : <></>}
    </DialogFrame>)
}

export default CustomerSearch