import React, { ChangeEvent, useState } from 'react';
import { FormikProps } from 'formik';
import { FieldSelect } from 'components/uiparts'
import { useData } from 'graphql/useCollection'
import DialogFrame from "components/dialog"
import Loading from "components/Loading"
import options from 'contexts/options.json';

const ProductSearch = ({show, setShow, formik, existing}:{show:boolean, setShow:React.Dispatch<React.SetStateAction<boolean>>, formik:FormikProps<any>, existing:boolean}) => {
    const [type, setType] = useState("")
    const [species, setSpecies] = useState("")
    const [data, setData] = useState<KV[]>([])

//    const [id, setId] = useState("")
    const { loading, Data:allData } = useData("firewoodProduct", null, { active: true})
    const speciesOptions = allData?.filter((item, index, self) => index === self.findIndex(obj => obj.species === item.species)).reduce((a, r) => ({...a, [r.species]:r.species}), { "": "全て"}) || {}
    const typeOptions = allData?.filter((item, index, self) => index === self.findIndex(obj => obj.type === item.type)).reduce((a, r) => ({...a, [r.type]:r.type}), { "": "全て"}) || {}

//    const handleKeyUp = (e) => {
//        e.which = e.which || e.keyCode;
//        if (e.which === 13) {
//            executeQuery(e)
//        }
//    }
    const executeQuery = (e:ChangeEvent<HTMLSelectElement>) => {
//        const tid = (e && (e.target.name === "_id")) ? e.target.value : id
        const tspecies = (e && (e.target.name === "species")) ? e.target.value : species
        const tType = (e && (e.target.name === "type")) ? e.target.value : type
//        if (tid) query._id = tid
        if (!tspecies && !tType) {
            alert("樹種、商品種類のうち少なくとも１つは特定してください")
            return
        }
        setData((allData || []).filter(datum => (!tspecies || datum.species === tspecies) && (!tType || datum.type === tType)))
    }

    const handleClick = (data:KV) => {
        const udpate = { ...data }
        delete udpate.__typename
        formik.values.species = data.species
        formik.values.type = data.type
        formik.values.productId = data._id
        formik.values.name = data.name
        formik.values.length = data.length
        formik.values.unit = data.unit
        formik.values.unitPrice = (existing && data.discountPrice) ? data.discountPrice : data.price
        setShow(false)
    }

    const handleChange = (e:ChangeEvent<HTMLSelectElement>) => {
        switch (e.currentTarget.name) {
            case "species":
                setSpecies(e.currentTarget.value);break;
            case "type":
                setType(e.currentTarget.value);break;
            default:
        }
        executeQuery(e);        
    }

    return (<DialogFrame show={show} setShow={setShow}>
        <div className="grid grid-cols-6 gap-6">
            <FieldSelect label="樹種" name="species" options={speciesOptions} props={{ value: species, onChange: handleChange}} span = {3} />
            <FieldSelect label="商品種類" name="type" options={typeOptions} props={{ value: type, onChange:handleChange}} span={3} />
            {/*<FieldPlain label="商品ID" name="_id" props={{ value: id, onChange: e => setId(e.target.value), onKeyUp: handleKeyUp, onBlur: executeQuery  }} span="2" />*/}
        </div>
        {data && (<div className="container flex flex-col mx-auto w-full items-center justify-center bg-white dark:bg-gray-800 rounded-lg shadow">
            {loading && <div className="m-4"><Loading /></div>}
            <ul className="flex flex-col divide divide-y">
                { data.map(datum => (
                    <li key={datum._id} className="flex flex-row">
                        <div className="select-none cursor-pointer flex flex-1 items-center p-4" onClick={e => handleClick(datum)}>
                            <div className="text-gray-600 dark:text-gray-200 w-20">
                                {datum._id}
                            </div>
                            <div className="flex-1 pl-1 mr-4 w-28 text-gray-600 dark:text-gray-200">
                                <div className="">
                                    {datum.species}
                                </div>
                                <div className="">
                                    {datum.type}
                                </div>
                            </div>
                            <div className="text-gray-600 dark:text-gray-200 w-28">
                                {datum.name || ""}
                            </div>
                            <div className="text-gray-600 dark:text-gray-200 w-20">
                                {datum.length || ""}
                            </div>
                            <div className="text-gray-600 dark:text-gray-200 w-20">
                                1{options.unit[datum.unit]}
                            </div>
                            <div className="text-gray-600 dark:text-gray-200 w-24">
                                <div className="font-medium dark:text-white">
                                    {datum.price}
                                </div>
                                <div className="text-gray-600 dark:text-gray-200 text-sm">
                                    {datum.discountPrice}
                                </div>
                            </div>
                            <button className="w-18 text-right flex justify-end">
                                <svg width="20" fill="currentColor" height="20" className="hover:text-gray-800 dark:hover:text-white dark:text-gray-200 text-gray-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z">
                                    </path>
                                </svg>
                            </button>
                        </div>
                    </li>
                ))}
            </ul>
        </div>)}
    </DialogFrame>)
}

export default ProductSearch