import { timeString } from "contexts/dateUtils";
import { ExportToCsv } from "export-to-csv";
import { flatten } from "utils";

const csvExporter = new ExportToCsv({
  showLabels: true,
  useKeysAsHeaders: true,
});

export const makeReport = (data:KV[], userData:KV[], yearMonth:string) => {


const workData = (userID:string) => data.filter(v => v.user.userID === userID).reduce((a, r) => ({ 
    workday: a.workday + (((r.item.length === 1 && r.item[0].holiday) || (r.item.length === 2 && r.item[0].holiday && r.item[1].holiday)) ? 0 : 1),
    work: a.work + (r.end ? (new Date(r.end).getTime() - new Date(r.start).getTime() - (r.rest || 0) - (r.nightRest || 0) - (r.over || 0)) : 0),
    over: a.over + r.over,
    night: a.night + r.night,
    holidayWork: a.holidayWork + r.holidayWork,
    gearCost: a.gearCost + r.item.reduce((a1:number, r1:KV) => a1 + r1.gearCost, 0),
    car: a.car + r.item.reduce((a1:number, r1:KV) => a1 + r1.car, 0),
    paid: a.paid + r.item.reduce((a1:number, r1:KV) => a1 + (r1.holiday === "有休" ? (r.item.length === 1 ? 1 : 0.5) : 0), 0),
    alternate: a.alternate + r.item.reduce((a1:number, r1:KV) => a1 + (r1.holiday === "振休" ? (r.item.length === 1 ? 1 : 0.5) : 0), 0),
    absent: a.absent + r.item.reduce((a1:number, r1:KV) => a1 + (r1.holiday === "欠勤" ? (r.item.length === 1 ? 1 : 0.5) : 0), 0)
}), { workday: 0, work: 0, over: 0, night: 0, holidayWork: 0, gearCost:0, car:0, paid:0, alternate:0, absent:0 })

let output = userData.map(v => {
  const data = workData(v.userID)
  return { 
    社員名:`${v.surname}　${v.givenName}`,
    出勤日数: data.workday || "",
    労働時間: timeString(data.work),
    残業時間: timeString(data.over),
    休日出勤: timeString(data.holidayWork),
    深夜労働時間: timeString(data.night),
    器具等使用損料: data.gearCost || "",
    自家用車使用料: data.car*(v.status === "temporary" ? 11 : 15) || "",
    欠勤日数: data.absent,
    有給休暇: data.paid
  }
})

csvExporter.generateCsv(output.map((v) => flatten(v)));


} 