import React, {
  Fragment,
  useState,
  FocusEvent,
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
} from "react";
import { Link, NavigateFunction } from "react-router-dom";
import { Switch } from "@headlessui/react";
import {
  RectangleStackIcon,
  EyeIcon,
  PencilSquareIcon,
  TrashIcon,
  PrinterIcon,
  MagnifyingGlassIcon,
  ArrowPathIcon,
  ArrowDownTrayIcon,
  EllipsisHorizontalCircleIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import { ExportToCsv } from "export-to-csv";

import { useRealmApp } from "RealmApp";
import { useData } from "graphql/useCollection";
import { ButtonClass, IconButtonClass, IconHeaderButtonClass } from "utils";
import Loading from "components/Loading";
import options from "contexts/options.json";
import { JSTDay, JSTMonth, JSTNewDate, JSTYear, dayOptions, getTermDates, halfyearOptions, yearOpitons } from 'contexts/dateUtils';
import { useAppContext } from 'contexts/AppContext';
import { SortableListFrame } from "./ListFrame";
import { objectCompare } from "contexts/utils";
import { DialogFunction } from "./edit";

export interface DateParam {
  year: string,
  month: string,
  day: string,
  update?: (e: ChangeEvent<HTMLSelectElement>) => void
}
export type FiltersFunction = (filter: KV, updateFilter: (key: string | string[], value: any) => void, dateParam?: DateParam) => JSX.Element
export interface FilterInterface {
  collection: string,
  Filters: FiltersFunction,
  instantFilter?: boolean,
  count: number,
  limit?: number,
  dateField?: string,
  displays?: boolean,
  handleDownload?: () => void,
  refetch?: () => Promise<any>
}
export type RowFunction = (
  { datum,
    clickDelete,
    clickCollection,
    clickPrint,
    clickUpdate,
    navigate
  } : { 
    datum: KV,
    clickDelete?: (e: MouseEvent<HTMLButtonElement>) => void,
    clickCollection?: (e: MouseEvent<HTMLButtonElement>) => void,
    clickPrint?: (e: MouseEvent<HTMLButtonElement>) => void,
    clickUpdate?: (e: MouseEvent<HTMLButtonElement>) => void
    navigate?:NavigateFunction
  }) => JSX.Element

/**
 * Read nested data from DataObject
 * @param object DataObject to read property
 * @param prop Property name to read
 * @returns Property value
 */
//const getDataProp = (object: any, prop: string) =>
//  (object instanceof Object && prop in object && object[prop]) || null;

/**
 * Make styled button
 *
 * @param {{icon:any, onClick:()=>void, value:string}} { icon: Icon to show in button, onClick:Function to run when button is clicked, value: value of button }
 * @returns Button element
 */
const Button = ({
  icon,
  name,
  onClick,
  value,
}: {
  icon: any;
  name?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  value: string;
}) => (
  <button
    name={name}
    type="button"
    onClick={onClick}
    className={IconButtonClass}
    value={value}
  >
    {icon}
  </button>
);

/**
 * Set value to specific key including dot
 * @param obj Object to set value
 * @param key Key to set value(can include dot for ensted key)
 * @param value Value to set. null and undefined are allowed
 * @returns Object after setting value
 */
const changeNestedValue = (obj: KV | undefined, key: string, value: any): KV | undefined => {
  const keys = key.split('.')
  if (keys.length > 1 && keys[0]) {
    if ((value === "" || value === null || value === undefined) && !obj?.[keys[0]]) return obj
    const nestObj = changeNestedValue(obj?.[keys[0]], key.replace(`${keys[0]}.`, ''), value)
    if (nestObj === undefined) {
      const newObj = { ...obj }
      delete newObj[keys[0]]
      if (!Object.keys(newObj).length) return undefined
      return newObj
    }
    return { ...obj, [keys[0]]: nestObj }
  }
  if (value === "" || value === null || value === undefined) {
    const newObj = { ...obj }
    delete newObj[key]
    if (!Object.keys(newObj).length) return undefined // if {}, return undefined
    return newObj
  }
  return { ...obj, [key]: value } // Add
}

export const StatusIcon = ({ status, disabled }: { status: string, disabled?:boolean }) => <>{
  !status ? <EllipsisHorizontalCircleIcon className={`w-6 h-6 text-yellow-300 ${disabled ? '' : 'hover:text-yellow-500'}`} /> :
      status === "OK" ? <CheckCircleIcon className={`w-6 h-6 text-theme-400 ${disabled ? '' : 'hover:text-theme-600'}`} /> : <XCircleIcon className={`w-6 h-6 text-red-400 ${disabled ? '' : 'hover:text-red-600'}`} />
}</>


/**
 * Make Filter component
 * @param obj Property object
 * @param obj.collection Collection name
 * @param obj.Filters Filter components
 * @param obj.instantFilter True if refetch without clicking seasrch button
 * @param obj.count Number of returned data
 * @param obj.limit Limit of data
 * @param obj.dateField Field name to filter by date
 * @param obj.displays True if flip display
 * @param obj.handleDownload Function to download csv
 * @returns Filter component
 */
export const BaseFilter = ({
  collection,
  Filters,
  instantFilter,
  count,
  limit = 50,
  dateField,
  displays,
  handleDownload,
  refetch
}: FilterInterface) => {
  const context = useAppContext()
  const [filter, setFilter] = useState<KV>(context.state[collection]?.filter || {})
  const actualLimit: number = context.state[collection]?.limit || limit
  const display = context.state[collection]?.display
  const year = (dateField && (String(filter[`${dateField}_termyear`] || "") || (filter[`${dateField}_gte`] ? String(JSTYear(new Date(filter[`${dateField}_gte`]))) : ""))) ?? ""
  const month = dateField && (filter[`${dateField}_term`] || ((filter[`${dateField}_gte`] && (new Date(filter[`${dateField}_lt`]).getTime() - new Date(filter[`${dateField}_gte`]).getTime()) < 86400000 * 32) ? String(JSTMonth(new Date(filter[`${dateField}_gte`]))) : ""))
  const day = dateField && (filter[`${dateField}_gte`] && ((new Date(filter[`${dateField}_lt`]).getTime() - new Date(filter[`${dateField}_gte`]).getTime()) < 86400000 * 2)) ? String(JSTDay(new Date(filter[`${dateField}_gte`]))) : ""

  const updateFilter = (key: string | string[], value: any) => {
    let filterValues:KV = {}
    if (Array.isArray(key)) {
      filterValues = (key as string[]).reduce((acc,rcc, i) => changeNestedValue(acc, rcc, value[i]) || {}, filter)
    } else {
      filterValues = changeNestedValue(filter, key, value) || {}
    }
    setFilter(filterValues)
    if (instantFilter) context.setState(`${collection}.filter`, filterValues)
  }
  const handleDisplay = () => {
    if (!display || display.type === "normal") context.setState(`${collection}.display`, { type: "sum" })
    else if (display.type === "sum") context.setState(`${collection}.display`, { type: "normal" })
  }
  const handleSearch = (e: MouseEvent) => {
    if (objectCompare(context.state[collection]?.filter || {}, filter)) {
      refetch && refetch()
    } else {
      context.setState(`${collection}.filter`, filter)
    }
  }
  const handleLimitChange = (e:ChangeEvent<HTMLSelectElement>) => {
    context.setState(`${collection}.limit`, parseInt(e.target.value))
  }
  const updateDateFilter = ((e: ChangeEvent<HTMLSelectElement>) => {
    let [newYear, newMonth, newDay] = [year, month, day]
    switch (e.currentTarget.name) {
      case 'year': newYear = e.currentTarget.value; break;
      case 'month': newMonth = e.currentTarget.value; break;
      case 'day': newDay = e.currentTarget.value; break;
    }
    if (!newYear) {
      const newFilter = { ...filter }
      delete newFilter[`${dateField}_gte`]
      delete newFilter[`${dateField}_lt`]
      delete newFilter[`${dateField}_term`]
      delete newFilter[`${dateField}_termyear`]
      setFilter(newFilter)
      if (instantFilter) context.setState(`${collection}.filter`, newFilter)
    } else if (newMonth && !Number(newMonth)) {
      const dates = getTermDates(newYear, newMonth)
      const newFilter = ({ ...filter, [`${dateField}_gte`]: dates[0], [`${dateField}_lt`]: dates[1], [`${dateField}_term`]: newMonth, [`${dateField}_termyear`]: newYear })
      setFilter(newFilter)
      if (instantFilter) context.setState(`${collection}.filter`, newFilter)
    } else {
      const fromDate = JSTNewDate(Number(newYear), newMonth ? Number(newMonth) : 1, newDay ? Number(newDay) : 1)
      // If day is active, month stay same. If no day, next month. If no month, next Jan
      const toDate = JSTNewDate(Number(newYear), newMonth ? (Number(newMonth) + (newDay ? 0 : 1)) : 13, newDay ? Number(newDay) + 1 : 1)
      const newFilter = ({ ...filter, [`${dateField}_gte`]: fromDate, [`${dateField}_lt`]: toDate })
      delete newFilter[`${dateField}_term`]
      delete newFilter[`${dateField}_termyear`]
      setFilter(newFilter)
      if (instantFilter) context.setState(`${collection}.filter`, newFilter)
    }
  })

  return <div className="p-2 relative flex justify-between gap-4">
    <div className="flex flex-wrap items-center gap-1">{Filters(filter, updateFilter, { year: year, month: month, day: day, update: updateDateFilter })}
      {!instantFilter && <MagnifyingGlassIcon className={IconHeaderButtonClass} onClick={handleSearch} />}
      {handleDownload && <ArrowDownTrayIcon className={IconHeaderButtonClass} onClick={handleDownload}/>}
      {displays && <button className={ButtonClass.replace('m-3 py-2 px-4 w-28', 'm-0 p-1 w-16')} onClick={handleDisplay}><ArrowPathIcon className="w-4 h-4" />{display?.type === "sum" ? "個別" : "集計"}</button>}
    </div>
    <div className="hidden sm:flex items-center gap-4">
      <FilterSelect
        name="limit"
        value={String(actualLimit)}
        optionName="limit"
        onChange={handleLimitChange}
        nodefault
        nolabel
        nohyphen
      />
      <span className="whitespace-nowrap">{count}件</span>{count > actualLimit - 1 && <span className="whitespace-nowrap">{` (以降非表示)`}</span>}
    </div>
  </div>
}

export const FilterDates = ({ dateParam }: { dateParam?: DateParam }) => dateParam ? <>
  <FilterSelect
    label="年"
    name="year"
    value={dateParam.year || ""}
    width={24}
    optionName="year"
    onBlur={dateParam.update}
    nolabel
    nohyphen
  />
  {dateParam.year && <FilterSelect
    label="月"
    name="month"
    value={dateParam.month || ""}
    width={24}
    optionName="month"
    onBlur={dateParam.update}
    nolabel
    nohyphen
  />}
  {dateParam.year && Boolean(Number(dateParam.month)) && <FilterSelect
    label="日"
    name="day"
    value={dateParam.day || ""}
    width={24}
    optionName="day"
    onBlur={dateParam.update}
    nolabel
    nohyphen
  />}
</> : <></>

/**
 * Make input used in filter. Pressing return key calls onBlur
 * @param {{label:string, name:string, value:string, setState:React.Dispatch<React.SetStateAction<any>>, onBlur:(e:FocusEvent<HTMLInputElement>)=>void}}
 *  {label:Label for input, name:name of input, value:value of input, setState:Funciton to update value on change, onBlur:Funciton to run when focus is lost}
 * @returns Input element
 */
export const FilterInput = ({
  label,
  name,
  value,
  setState,
  onBlur,
  nolabel
}: {
  label: string;
  name: string;
  value: string;
  setState: React.Dispatch<React.SetStateAction<any>>;
  onBlur?: (
    e: FocusEvent<HTMLInputElement> | KeyboardEvent<HTMLInputElement>
  ) => void;
  nolabel?: boolean
}) => {
  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && onBlur) onBlur(e);
  };
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setState(e.target.value);
  };
  return (
    <>
      {!nolabel && <label htmlFor={name} className="px-2 text-sm font-medium text-gray-700">{label}</label>}
      <input
        name={name}
        className="px-2 py-1 border w-32 shadow-sm sm:text-sm rounded-md disabled:bg-gray-100 read-only:bg-gray-100 focus:ring-indigo-500 focus:border-indigo-500  border-gray-300"
        placeholder={nolabel ? label : undefined}
        value={value}
        onChange={onChange}
        onKeyUp={handleKeyUp}
        onBlur={onBlur}
      />
    </>
  );
};

/**
 * Make check switch used in filter.
 * @param obj Property object
 * @param obj.label Label for input
 * @param obj.name name of input
 * @param obj.state state of input
 * @param obj.setState Funciton to update value on change
 * @param obj.onBlur Funciton to run when state changed}
 * @returns Input element(Check using Switch of headlessui)
 */
export const FilterCheck = ({
  label,
  name,
  state,
  setState,
  onBlur,
}: {
  label: string;
  name: string;
  state: boolean;
  setState: React.Dispatch<React.SetStateAction<boolean>> | ((v: boolean) => void);
  onBlur?: (e: { currentTarget: { name: string; value: boolean } }) => void;
}) => {
  const onChange = (e: any) => {
    setState(!Boolean(state));
    onBlur && onBlur({ currentTarget: { name: name, value: !Boolean(state) } });
  };
  return (
    <>
      <label htmlFor={name} className="px-2 text-sm font-medium text-gray-700">
        {label}
      </label>
      <Switch
        id={name}
        name={name}
        checked={state}
        onChange={onChange}
        className={`${state ? "bg-theme-600" : "bg-gray-200"
          } relative my-auto inline-flex items-center h-6 rounded-full w-11 focus:outline-none focus:ring-2 ring-indigo-500 ring-offset-1`}
      >
        <span
          className={`${state ? "translate-x-6" : "translate-x-1"
            } inline-block w-4 h-4 transform bg-white rounded-full`}
        />
      </Switch>
    </>
  );
};

/**
 * Make selection used in filter.
 * @param obj Property object
 * @param obj.label Label for selection
 * @param obj.name name of selection
 * @param obj.value initial value of selection
 * @param obj.width width of component as tailwind unit 4=1rem
 * @param obj.option options object
 * @param obj.optionName name of options object
 * @param obj.setState Funciton to update value on change
 * @param obj.onBlur Funciton to run when state changed
 * @param obj.status If true, add active status to options
 * @param obj.nolabel If true, hide label, and remove label from select-all option
 * @param obj.nodefault If true, remove select-all option from options
 * @param obj.nohyphen If true, remove prefix hyphen from options
 * @returns Select element
 */
export const FilterSelect = ({
  label = "",
  name,
  value,
  width,
  option,
  optionName,
  setState,
  onChange,
  onBlur,
  status,
  nolabel,
  nodefault,
  nohyphen,
}: {
  label?: string;
  name: string;
  value: string;
  width?: number;
  option?: KV;
  optionName?: string;
  setState?: React.Dispatch<React.SetStateAction<string>>;
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLSelectElement>) => void;
  status?: boolean;
  nolabel?: boolean;
  nodefault?: boolean;
  nohyphen?: boolean;
}) => {
  const actualOnChange = onChange ? onChange : (e: ChangeEvent<HTMLSelectElement>) => {
    setState && setState(e.target.value);
    onBlur && onBlur(e);
  };
  let actualOptions: KV = option || {}
  if (!option && optionName) {
    switch (optionName) {
      case "year":
        actualOptions = yearOpitons
        break
      case "halfyear":
        actualOptions = halfyearOptions
        break
      case "month":
        actualOptions = options.term
        break
      case "day":
        actualOptions = dayOptions
        break
      default:
        actualOptions = options[optionName]
    }
  }

  return (
    <>
      {!nolabel && <label htmlFor={name} className="px-2 text-sm font-medium text-gray-700">{label}</label>}
      <select
        name={name}
        className={`py-1 pl-2 pr-8 border ${width ? ('w-' + width) : ''} border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
        value={value}
        onChange={actualOnChange}
      >
        {!nodefault && <option value="">{nolabel ? label + "- 全て" : "全て"}</option>}
        {status && <option value="active">進行中</option>}
        {status && <option value="notpaid">入金待</option>}
        {Object.keys(actualOptions).map(key => <option key={key} value={key}>{!['paid', 'cancel'].includes(key) && !nohyphen && "-　"}{actualOptions[key]}</option>)}

      </select>
    </>
  );
};

/**
 * Make table cell including link buttons
 * @param obj Property object
 * @param obj.location URL to link
 * @param obj.id Id of data
 * @param obj.custId Customer Id used to filter
 * @param obj.noEdit True to hide edit button
 * @param obj.onClickDelete Function to delete data
 * @param obj.onClickEye Function to show data
 * @param obj.onClickEdit Function to edit data
 * @param obj.onClickPrint Function to print data
 * @param obj.onClickCollection Function to show specific customer's data
 * @returns Table cell including link buttons
 */
export const EditLinkTd = ({
  location,
  id,
  custId,
  noEdit = false,
  onClickDelete,
  onClickEye,
  onClickEdit,
  onClickPrint,
  onClickCollection,
}: {
  location?: string;
  id: string;
  custId?: string;
  noEdit?: boolean;
  onClickDelete?: (e: MouseEvent<HTMLButtonElement>) => void;
  onClickEye?: (e: MouseEvent<HTMLButtonElement>) => void;
  onClickEdit?: (e: MouseEvent<HTMLButtonElement>) => void;
  onClickPrint?: (e: MouseEvent<HTMLButtonElement>) => void;
  onClickCollection?: (e: MouseEvent<HTMLButtonElement>) => void;
}) => {
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    switch (e.currentTarget.name) {
      case "delete": onClickDelete && onClickDelete(e); break;
      case "eye": onClickEye && onClickEye(e); break;
      case "edit": onClickEdit && onClickEdit(e); break;
      case "print": onClickPrint && onClickPrint(e); break;
      case "collection": onClickCollection && onClickCollection(e); break;
      default:
    }
  }
  return (
    <td className="flex flex-row-reverse">
      {onClickDelete && <Button name="delete" icon={<TrashIcon className="w-4 h-4" />} onClick={handleClick} value={id} />}
      {onClickEye && <Button name="eye" icon={<EyeIcon className="w-4 h-4" />} onClick={handleClick} value={id} />}
      {!noEdit && (onClickEdit ? <Button name="edit" icon={<PencilSquareIcon className="w-4 h-4" />} onClick={handleClick} value={id} /> : <Link to={`${location}/edit/${id}`} onClick={e => e.stopPropagation()}><Button icon={<PencilSquareIcon className="w-4 h-4" />} value={id} /></Link>)}
      {onClickPrint && <Button name="print" icon={<PrinterIcon className="w-4 h-4" />} onClick={handleClick} value={id} />}
      {onClickCollection && custId && <Button name="collection" icon={<RectangleStackIcon className="w-4 h-4" />} onClick={handleClick} value={custId} />}
    </td>
  );
}

/**
 * Make Table cell showing data
 * @param obj Property object
 * @param obj.data Data to show
 * @param obj.badge Show data as badge
 * @param obj.left Show data left align(default is center align)
 * @param obj.date Data to show is date type string
 * @returns Table cell showing data
 */
export const ListItem = ({
  data,
  badge = false,
  left = false,
  date = false,
  wrap = false,
  onClick = () => { }
}: {
  data: string[];
  badge?: boolean;
  left?: boolean;
  date?: boolean;
  wrap?: boolean;
  onClick?: React.MouseEventHandler
}) => (
  <td className="px-2 py-1 whitespace-nowrap" data-value={data[0]} onClick={onClick}>
    {data.map((datum, i) =>
      badge ? (
        <span
          key={i}
          className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full text-theme-800 ${(datum && (datum.startsWith("薪") || datum.startsWith("個"))) ? "bg-theme-50" : "bg-theme-300"}`}
        >
          {datum || ""}
        </span>
      ) : (
        <div key={i} className={(left ? "text-left" : "") + (wrap ? " w-60 whitespace-normal" : "")}>
          {date
            ? datum
              ? new Date(datum).toLocaleDateString()
              : ""
            : datum || ""}
        </div>
      )
    )}
  </td>
);

/**
 * Make Table cell showing name including corporate name if exist
 * @param obj Property object
 * @param obj.data Data including name attributes
 * @returns Table cell showing name
 */
export const ListName = ({
  data,
}: {
  data: { [key: string]: any }[];
}) => (
  <td className="px-2 py-1 whitespace-nowrap">
    {data.map((datum, i) => (
      <Fragment key={i}>
        {datum && datum.corporateName && (
          <div>{`${datum.corporateName || ""}　${datum.division || ""}`}</div>
        )}
        <div>
          {datum &&
            `${datum.surname || datum.name?.surname || ""}　${datum.givenName || datum.name?.givenName || ""
            }`}
        </div>
      </Fragment>
    ))}
  </td>
);


const flatten = (
  obj: { [key: string]: any } | string | number | { [key: string]: any }[] | string[] | number[] | undefined | null,
  path = ""
): { [key: string]: any } => {
  if (!(obj instanceof Object)) return { [path.replace(/\.$/g, "")]: obj };
  return Object.keys(obj).reduce((output, key) => {
    return obj instanceof Array
      ? { ...output, ...flatten(obj[parseInt(key)], path + "[" + key + "].") }
      : { ...output, ...flatten(obj[key], path + key + ".") };
  }, {});
};

/**
 * Generating csv file using export-to-csv package
 *
 * @param {{[key:string]:any}[]} data data to export
 */
const handleDownload = (data: KV[]) => {
  const csvExporter = new ExportToCsv({
    showLabels: true,
    useKeysAsHeaders: true,
  });
  csvExporter.generateCsv(data.map((v) => flatten(v)));
};

/**
 * Adjusts filter object.  Status:active/notpaid and mine are translated and empty value is removed.
 * use mine for non-search and mydeal
 *
 * @param {{[key:string]:any}} filter Filter query dataw before adjustment
 * @param {string} id User's userId
 * @param {[key:string]} user Keys using user(relation)
 * @return {*}  {{[key:string]:any}} Adjusted filter
 */
const adjustFilter = (
  filter: KV,
  id: string,
  user?: string[]
): KV => Object.keys(filter).reduce((a, r) => {
    if (r === "status" && filter.status === "active") {
      return { ...a, status_nin: ["paid", "cancel"] };
    } else if (r === "status" && filter.status === "notpaid") {
      return { ...a, status_in: ["invoice", "payment"] };
    } else if (r === "mine") {
      return filter.mine ?  { ...a, OR: user?.map((item) => ({ [item]: { _id: id } })) || [] } : a;
    } else if (r === 'corporation' && filter.corporation === false) {
      return { ...a, corporation: { "$exists": false } }
    } else if (r === 'supervising') {
      if (!filter[r]) return a
      return { ...a, user: { _id_in: filter[r] } }
    } else if (Array.isArray(filter[r])) {
      return { ...a, [r]: filter[r] }
    } else if (filter[r] instanceof Date) {
      return { ...a, [r]: filter[r] };
    } else if (filter[r] instanceof Object) {
      const obj = adjustFilter(filter[r], id, user);
      if (Object.keys(obj).length < 1) return a;
      return { ...a, [r]: adjustFilter(filter[r], id, user) };
    } else if (!filter[r] && filter[r] !== false) {
      return a;
    } else if (r.endsWith('_term') || r.endsWith('_termyear')) {
      return a;
    } else {
      return { ...a, [r]: filter[r] };
    }
}, {});


/**
 * Make List View JSX element of data in collection applying filter.  Mainly in charge of getting data from MongoDB.
 * @param obj Property object
 * @param obj.collection name of MongoDB collection
 * @param obj.type search for partial string match, view for view
 * @param obj.location URL path of this page
 * @param obj.heads Header row contents
 * @param obj.filter function to make filter JSX Element
 * @param obj.instantFilter true to search without clicking search button
 * @param obj.download CSV base data for download
 * @param obj.row JSX Element to draw each row
 * @param obj.dialog Dialog to show when printing
 * @param obj.user Keys using user(relation)
 * @param obj.limit Max limit documents to find/show
 * @param obj.dateField field name to filter by date
 * @param obj.displays true to flip display(usually detail/summarize)
 * @param obj.noAdd disable adding data
 * @returns List view
 */
export const DataList = ({
  collection,
  type,
  location,
  heads,
  filter,
  instantFilter,
  download,
  row,
  dialog,
  user,
  limit,
  dateField,
  displays,
  noAdd = false,
  handleRowClick
}: {
  collection: string;
  type?: string | null;
  location: string;
  heads: { [key: string]: string }[];
  filter?: FiltersFunction;
  instantFilter?: boolean
  download?: (Data: KV[]) => KV[];
  row: ({
    datum,
    clickDelete,
    clickCollection,
    clickPrint,
    clickUpdate,
    navigate,
  }: {
    datum: KV;
    clickDelete?: (e: MouseEvent<HTMLButtonElement>) => void;
    clickCollection?: (e: MouseEvent<HTMLButtonElement>) => void;
    clickPrint?: (e: MouseEvent<HTMLButtonElement>) => void;
    clickUpdate?: (e: MouseEvent<HTMLButtonElement>) => void;
    navigate?: NavigateFunction;
  }) => JSX.Element;
  dialog?: DialogFunction;
  user?: string[];
  limit?: number;
  dateField?: string;
  displays?: boolean;
  noAdd?: boolean;
  handleRowClick?: (e: MouseEvent<HTMLElement>, navigate: NavigateFunction) => void
}) => {
  const appContext = useRealmApp();
  const context = useAppContext();
  let query = adjustFilter(
    context.state[collection]?.filter || {},
    appContext.currentUser?.id || "",
    user
  );
  const actualLimit: number = context.state[collection]?.limit || limit || 50
  const sortKey = context.state[collection]?.sort?.activeKey || "_id"
  const sortString = (!sortKey.includes(".") ? sortKey : "_id") + (context.state[collection]?.sort?.ascend === -1 ? '_DESC' : '_ASC')
  const { loading, Data, error, refetch } = useData(collection, type, query, sortString, actualLimit);
  // Render twice.  loading, Data.  SortableListFrame is called once.
  return (
    <>
      {loading || !Data ? (
        <Loading />
      ) : (error ? <div className="m-2 text-red-700"><>データ取得エラー<br />{error}</></div> : (
        <SortableListFrame
          collection={collection}
          location={location}
          limit={actualLimit}
          heads={heads}
          filter={
            filter &&
            <BaseFilter
              collection={collection}
              count={Data.length}
              handleDownload={download ? () => handleDownload(download(Data) || []) : undefined}
              Filters={filter}
              instantFilter={instantFilter}
              limit={actualLimit}
              dateField={dateField}
              displays={displays}
              refetch={refetch}
            />
          }
          noAdd={Boolean(noAdd)}
          Data={Data}
          Row={row}
          Dialog={dialog}
          handleRowClick={handleRowClick}
        />
      ))}
    </>
  );
};

export default DataList;
