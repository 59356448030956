import React, { MouseEvent } from 'react';
import * as Yup from 'yup';
import Decimal from 'decimal.js';
import { PlusIcon } from '@heroicons/react/24/solid'
import { FormikProps } from 'formik';

import relations from "realm/Yanagisawa/data_sources/mongodb-atlas/Business/firewood/relationships.json"
import { getObjectData, getSchemaValues, CreateBase, EditBase, FormFrame, FieldBase, RelationField, FieldArrayFunction } from 'components/edit'
import { ListItem, EditLinkTd } from "components/list"
import options from "contexts/options.json"
import CustomerSearch from 'components/customerSearch'
import ItemEdit from 'pages/firewood/itemEdit'
import UserSelect from 'components/userSelect'
import DialogFrame from 'components/dialog'
import { Create as CreateCustomer } from 'pages/customer/edit'
import ViewCustomer from 'pages/customer/view'
import { ButtonClass } from 'utils';
import { useRealmApp } from 'RealmApp';
import { SimpleListFrame } from 'components/ListFrame';
import { useDateEffect } from 'contexts/utils';

const schema = require("realm/Yanagisawa/data_sources/mongodb-atlas/Business/firewood/schema.json").properties;

//const digitsOnly = (value) => /^\d*[\.{1}\d*]\d*$/.test(value) || value.length === 0
const initialValues = getSchemaValues(schema)

const heads = ["商品", "数量", "単価", "運賃", "割引額", "価格", "配達日", ""]

const FieldArray:FieldArrayFunction = ({ schema, formik, name, parent, showItem, setShowItem, newItem, setNewItem }) => {
    const fullName = (parent ? parent + "." : "") + name
    const [, setItemCount] = React.useState(-1)
    const handleClick = (e:MouseEvent, i:number) => {
        setNewItem(false)
        setShowItem(i)
    }
    const handleDeleteClick = (e:MouseEvent, i:number) => {
        formik.values[fullName].splice(i, 1)
        setItemCount(formik.values[fullName].length)
    }
    return( <><SimpleListFrame heads={heads} noAdd>
        {(formik.values[name] || []).map((datum:KV, i:number) => (
            <tr key={`${name}${i}`} className="text-sm text-gray-900">
                <ListItem data={[`${datum.productId}  ${datum.species}  ${datum.type}  ${datum.name || ""} ${datum.length || ""}`]} left />
                <ListItem data={[`${datum.quantity}${options.unit[datum.unit]}`]} />
                <ListItem data={[datum.unitPrice]} />
                <ListItem data={[datum.deliveryFare]} />
                <ListItem data={[datum.discount]} />
                <ListItem data={[datum.amount]} />
                <ListItem data={[datum.deliveryDate]} date />
                <EditLinkTd id={String(i)} onClickDelete={e => handleDeleteClick(e, i)} onClickEdit={e => handleClick(e, i)} />
            </tr>
        ))}
    </SimpleListFrame>
    <ItemEdit newItem={newItem} showItem={showItem} setShowItem={setShowItem} name={name} parent={parent} schema={schema[name].items} formik={formik} />
    </>)
}

const FormFields = ({ formik }:{ formik:FormikProps<any> }) => {
    const app = useRealmApp()

    // Newc ustomer creation dialog bool
    const [showCreateCustomer, setShowCreateCustomer] = React.useState(false)
    const [showViewCustomer, setShowViewCustomer] = React.useState(false)
    // customer selection dialog bool
    const [showCustomer, setShowCustomer] = React.useState(false)
    // adding new firewoodItem bool
    const [newItem, setNewItem] = React.useState(false)
    // firewoodItem input dialog (items array index)
    const [showItemInput, setShowItemInput] = React.useState<number|undefined>(undefined)
    // field name for user selection dialog.  Also used as user selection dialog show bool
    const [currentName, setCurrentName] = React.useState<string|null|undefined>(null)
    
    const handleClick = (name:string) => {　
        setCurrentName(name)
    }
    // handling add firewoodItem button. adding new firewoodItem
    const handleAddItemClick = (e:MouseEvent) => {
        const count = formik.values.item?.length || 0
        setNewItem(true)
        setShowItemInput(count)
    }
    const handleCustomerClick =  (e:MouseEvent) => {
        const name = e.currentTarget.getAttribute("name")
        if (name === "openCreate") setShowCreateCustomer(true)
        else if (name === "openView") setShowViewCustomer(true)
        else setShowCustomer(true)
        e.preventDefault()
    }
    // Function after creating customer
    const handleCreate = (id?:string, values?:KV) => {
        let data = values ? getObjectData(values) : null
        data && (data._id = id)
        id && data && formik.setFieldValue("customer", data)
        setShowCreateCustomer(false)
    }
    const issueNo = async (e:MouseEvent) => {
        const counter = await app.currentUser?.functions.getCounter("FW")
        formik.setFieldValue("invoiceNo", `FW${counter}`)
    }
    // Recalc total amount
    React.useEffect(() => {
        const x = new Decimal((formik.values.item || []).reduce((a:number, r:KV) => a + Number(r.amount), 0))
        formik.setFieldValue("total",  x.plus(x.times(new Decimal(formik.values.taxRate || 0)).dividedBy(100).floor()).toFixed())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [formik.values.item, formik.values.taxRate])

    useDateEffect({formik})
    
    return (
    <div className="grid grid-cols-6 gap-6">
        <FormFrame formik={formik} schema={schema}>
            <FieldBase name="status" />
            <FieldBase name="receptionDate" />
            <FieldBase name="receptionRoute" />
            <RelationField 
                label="受付者"
                onClick={e => handleClick("receptionist")}
                item={formik.values.receptionist}
            />
            <RelationField
                label="営業担当者"
                onClick={e => handleClick("sales")}
                item={formik.values.sales}
            />
            <UserSelect name={currentName} setName={setCurrentName} formik={formik} />
            <RelationField
                label="顧客名"
                onClick={handleCustomerClick}
                item={formik.values.customer}
                withButton
            />
            <CustomerSearch show={showCustomer} setShow={setShowCustomer} formik={formik} />
            <FieldBase name="inquiry" />
            <FieldBase name="responseDate" />
            <FieldBase name="response" />
            <FieldBase name="contractDate" />
            <FieldBase name="deliveryType" />
            <FormFrame show={formik.values.deliveryType === "forest"}>
                <FieldBase name="deliveryPlace" />
            </FormFrame>
            <FieldBase name="deliveringDate" />
            <FieldBase name="invoiceNo" /><button type="button" className={"relative col-span-1 " + ButtonClass} onClick={issueNo}>請求No発行</button>
            <FieldBase name="invoiceDate" />
            <div className="relative col-span-3">
                <label className="block text-sm font-medium text-gray-700">請求書作成者</label>
                <div
                    className="mt-1 py-2 px-3 text-sm h-9 border border-gray-300 rounded cursor-pointer"
                    onClick={e => handleClick("invoiceAuthor")}
                >
                    {`${formik.getFieldProps("invoiceAuthor.surname").value || ""}　${formik.getFieldProps("invoiceAuthor.givenName").value || ""}`}
                </div>
            </div>
            <FieldBase name="receivedDate" />
            <FieldBase name="taxRate" />
            <FieldBase name="total" />
            <div className="relative col-span-6">
                <label className="block text-sm font-medium text-gray-700">購入商品</label>
                <button type="button" onClick={handleAddItemClick} className="absolute left-16 top-0 flex justify-center items-center bg-theme-50 hover:bg-theme-100 text-theme-800 w-8 h-8 rounded-2xl transition ease-in duration-200"><PlusIcon className="w-4 h-4" /></button>
                <div className="-mx-4"><FieldArray name="item" newItem={newItem} setNewItem={setNewItem} showItem={showItemInput} setShowItem={setShowItemInput} schema={schema} formik={formik} /></div>
            </div>
        </FormFrame>
        {showCreateCustomer && <DialogFrame show={showCreateCustomer} setShow={setShowCreateCustomer} wide={true}><CreateCustomer completed={(id, values) => handleCreate(id, values)} /></DialogFrame>}
        {showViewCustomer && <DialogFrame show={showViewCustomer} setShow={setShowViewCustomer} wide={true}><ViewCustomer id={formik.values.customer._id} canceled={e => setShowViewCustomer(false)} /></DialogFrame>}
    </div>
)}

const validationSchema = Yup.object({
})


const writePrepare = () => {
    return null;
}

export const Create = () => {
    return (<>
        <CreateBase title={"薪販売情報"} values={initialValues} collection="firewood" relations={relations} Fields={FormFields} writePrepare={writePrepare} validation={validationSchema} />
        </>);
};

export const Edit = () => {
    return (<EditBase title="薪販売情報" values={initialValues} collection="firewood" relations={relations} Fields={FormFields} writePrepare={writePrepare} validation={validationSchema} />);
};
export default Edit