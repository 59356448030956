import React from "react";
import { RealmAppContextInterface, useRealmApp } from "RealmApp";
import { ApolloClient, HttpLink, from, InMemoryCache, ApolloProvider, useQuery, gql } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { JSTToday } from "contexts/dateUtils";
import Loading from "components/Loading";
//import { properties } from './useCollection'
//import { capitalize } from "utils"

const createRealmApolloClient = (appContext:RealmAppContextInterface) => {
  const httpLink = new HttpLink({
    uri: `https://us-west-2.aws.realm.mongodb.com/api/client/v2.0/app/${appContext.app.id}/graphql`,
    fetch: async (uri, options) => {
      if (!appContext.currentUser) {
        throw new Error(`Must be logged in to use the GraphQL API`);
      }
      await appContext.currentUser.refreshCustomData();
      if (!options) options = {}
      if (!options.headers) options.headers = {} as {[key:string]:string}
      (options.headers as {[key:string]:string}).Authorization = `Bearer ${appContext.currentUser.accessToken}`;
      return fetch(uri, options);
    },
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );

    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  // get single data from cache if exist
/*  const fields = Object.keys(properties).reduce((a, key) => ({ ...a, 
    [key]: {
      read(_, { args, toReference }) {
        if (args.query?._id) return toReference({
          __typename: capitalize(key),
          _id: args.query._id,
        });
      }
    }
  }), {}) */
  const cache = new InMemoryCache()//{ typePolicies: { Query: { fields: fields } } })
  const connectToDevTools =  true
  return new ApolloClient({ link: from([errorLink, httpLink]), cache, connectToDevTools });
};

// Query to get basic data of active project
const activeQueryByDate = (collection:string) => {
  const tomorrow = JSTToday(24).toISOString()
  return gql`query {
  ${collection}s(query: { OR:[{ operationDates: { startDate_lt: "${tomorrow}", endDate_exists : false } }, { status_in: ["execute", "invoice"] } ] })
  {
    _id
    fileID
    subject
    abbreviation
  }
}`
}

// Query to get basic data of active project
const activeQuery = (collection:string) => {
  return gql`query {
  ${collection}s(query: { attendActive: true })
  {
    _id
    fileID
    subject
    abbreviation
  }
}`
}

const BaseQueryContext = React.createContext<KV|undefined>(undefined);

export const useBaseQueryContext = () => {
  const baseQueryContext = React.useContext<KV|undefined>(BaseQueryContext);
  if (!baseQueryContext) {
    throw new Error(
      `You must call useBaseQueryContext() inside of a <BaseQuery />`
    );
  }
  return baseQueryContext;
};

// Get basic data of active projects and put them to state context
const BaseQuery = ({children}:{children:JSX.Element}) => {
  const { loading, data, error } = useQuery(activeQuery("forest"))
  const { loading: treeLoading, data: treeData, error: treeError } = useQuery(activeQuery("tree"))
  React.useEffect(() => {
      if (error) alert("山林案件データの読み込みエラーが発生しました")
      else if (treeError) alert("樹木案件データの読み込みエラーが発生しました")
  }, [error, treeError])
  const baseQueryContext = data && treeData && { "forest": data.forests, "tree": treeData.trees }
  return loading || treeLoading || !baseQueryContext ? <Loading /> : <BaseQueryContext.Provider value={baseQueryContext} >{children}</BaseQueryContext.Provider>
}

export default function RealmApolloProvider({ children }:{ children:JSX.Element }) {
  const appContext = useRealmApp();
  const client = createRealmApolloClient(appContext);
  React.useEffect(() => {
    return () => {client?.clearStore();}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <ApolloProvider client={client}>
    <BaseQuery>{children}</BaseQuery>
    </ApolloProvider>;
}