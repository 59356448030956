import React from 'react'
import { useData } from 'graphql/useCollection'
import DialogFrame from "components/dialog"
import { FormikProps } from 'formik'
import { useRealmApp } from 'RealmApp'

export const getAttendanceUserObject = (data: KV) => {
    return ({
        //    _id: data._id,
        userID: data.userID,
        supervisor: data.supervisor?.userID,
        supervisorName: `${data.supervisor?.surname || ""}　${data.supervisor?.givenName || ""}`,
        division: data.division,
        name: `${data.surname}　${data.givenName}`
    })
}

const UserSelect = ({ setName, name, formik, onClick, asObj, asAttendanceObj, eligibleOnly, allowNull }: { setName: React.Dispatch<any>, name?: string | null, formik?: FormikProps<any>, onClick?: (update: KV|null) => void, asObj?: boolean, asAttendanceObj?: boolean, eligibleOnly?: boolean, allowNull?: boolean }) => {
    const app = useRealmApp()
    const { Data } = useData("user", null, eligibleOnly ? { OR: [{ _id: app.currentUser?.id }, { supervisor: { _id: app.currentUser?.id } }] } : { userID_lte: "1001" }, "USERID_ASC")

    const handleClick = (data?: KV) => {
        let update: KV|null = {}
        if (!data) {
            update = null
        } else if (asObj) {
            update =  {
                _id: data._id,
                userID: data.userID,
                supervisor: data.supervisor,
                aimSupervisor: data.aimSupervisor,
                division: data.division,
                surname: data.surname,
                givenName: data.givenName
            }
        } else if (asAttendanceObj) {
            update = getAttendanceUserObject(data)
        } else {
            update = { ...data }
            delete update.__typename
        }
        if (name && formik) formik.setFieldValue(name, update)
        if (onClick) onClick(update)
        setName(null)
    }

    return (<DialogFrame show={Boolean(name)} setShow={setName}>
        {Data && (<div className="container flex flex-col mx-auto w-full items-center justify-center bg-white dark:bg-gray-800 rounded-lg shadow">
            <ul className="flex flex-col divide divide-y">
                { allowNull && <li className="flex flex-row">
                    <div className="select-none cursor-pointer flex flex-1 items-center p-2" data-value={null} onClick={e => handleClick()}>
                        <div className="text-gray-600 dark:text-gray-200 mr-2 w-16">
                            N.A.
                        </div>
                        <div className="flex-1 pl-1 mr-2 w-32">
                            <div className="dark:text-white">
                                クリア
                            </div>
                        </div>
                        <div className="hidden sm:block text-gray-600 dark:text-gray-200 mr-2 w-28">
                        </div>
                        <div className="hidden sm:block text-gray-600 dark:text-gray-200 w-48">
                        </div>
                        <button className="w-18 text-right flex justify-end">
                            <svg width="20" fill="currentColor" height="20" className="hover:text-gray-800 dark:hover:text-white dark:text-gray-200 text-gray-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z">
                                </path>
                            </svg>
                        </button>
                    </div>
                </li>}
                {Data.map((data: KV) => (
                    <li key={data._id} className="flex flex-row">
                        <div className="select-none cursor-pointer flex flex-1 items-center p-2" data-value={data._id} onClick={e => handleClick(data)}>
                            <div className="text-gray-600 dark:text-gray-200 mr-2 w-16">
                                {data.userID}
                            </div>
                            <div className="flex-1 pl-1 mr-2 w-32">
                                <div className="dark:text-white">
                                    {`${data.surname}　${data.givenName}`}
                                </div>
                            </div>
                            <div className="hidden sm:block text-gray-600 dark:text-gray-200 mr-2 w-28">
                                <div className="dark:text-white">
                                    {data.tel}
                                </div>
                            </div>
                            <div className="hidden sm:block text-gray-600 dark:text-gray-200 w-48">
                                <div className="text-gray-600 dark:text-gray-200 text-sm">
                                    {data.email}
                                </div>
                            </div>
                            <button className="w-18 text-right flex justify-end">
                                <svg width="20" fill="currentColor" height="20" className="hover:text-gray-800 dark:hover:text-white dark:text-gray-200 text-gray-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z">
                                    </path>
                                </svg>
                            </button>
                        </div>
                    </li>
                ))}
            </ul>
        </div>)}
    </DialogFrame>)
}

export default UserSelect