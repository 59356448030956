import { JSTDateKanji } from "contexts/dateUtils";
import options from "contexts/options.json"
import { baseStyle, formatPrice, getNameLabelContent, noborderLayout, pageStyle, underlineLayout } from "contexts/pdfUtils";
import { calcNonstoredNumbersNew } from "./edit"
import { getAddressLabel, getNameLabel } from "contexts/utils";
const titlelogo = require("components/titlelogo.json").titlelogo;

const getLabels = (datum: KV, previous: KV) => ({
    columns: [
        { text: datum.category1 === previous.category1 ? '　' : datum.category1, width: 100, margin: [15, 0, 0, 0] },
        { text: datum.category2 ? ((datum.category1 === previous.category1 && datum.category2 === previous.category2) ? '　' : datum.category2) : datum.name, width: 100 },
        { text: datum.category2 ? datum.name : '　', width: 100 },
    ]
})

const getItemRow = (datum: KV, previous: KV) => datum.unit === 'yen' ?
    [
        getLabels(datum, previous),
        '',
        //        { text: formatPrice(datum.listPrice || datum.unitPrice, '▲'), alignment: 'right'},
        { text: '1', alignment: 'right' },
        { text: '式', alignment: 'center' },
        { text: formatPrice(datum.listAmount || datum.amount, '▲'), alignment: 'right' }
    ] : [
        getLabels(datum, previous),
        { text: formatPrice(datum.listPrice || datum.unitPrice, '▲'), alignment: 'right' },
        { text: formatPrice(datum.quantity), alignment: 'right' },
        { text: options.costUnit[datum.unit], alignment: 'center' },
        { text: formatPrice(datum.listAmount || datum.amount, '▲'), alignment: 'right' }
    ]

const getDirect = (data: KV[], id: number, humanCost: number, humanCostTitle?:string) => [
    [{ text: '直接工事費' }, '　', '　', '　', '　'],
    //    [{ text: humanCostTitle, margin: [15, 0, 0, 0] }, { text: formatPrice(humanCost), alignment: 'right' }, { text: '1', alignment: 'right' }, { text: '式', alignment: 'center' }, { text: formatPrice(humanCost), alignment: 'right' }],
    [{ text: humanCostTitle || "特殊伐採技術料", margin: [15, 0, 0, 0] }, '', { text: '1', alignment: 'right' }, { text: '式', alignment: 'center' }, { text: formatPrice(humanCost), alignment: 'right' }],
    ...data.filter(datum => datum.quoteId === id && datum.code > 199 && (!datum.costType || datum.costType === "direct") && !datum.hide).sort((a, b) => a.code - b.code).map((datum, i, array) => getItemRow(datum, i > 0 ? array[i - 1] : {}))
]

const getIndirect = (data: KV[], id: number, remainOverhead: number) => [
    ['　', '　', '　', '　', '　'],
    [{ text: '間接工事費' }, '　', '　', '　', '　'],
    ...data.filter(datum => datum.quoteId === id && datum.costType === "include" && !datum.hide).sort((a, b) => a.code - b.code).map((datum, i, array) => getItemRow(datum, i > 0 ? array[i - 1] : {})),
    //    [{ columns: [{ text: '諸経費', width: 100, margin: [15, 0, 0, 0] }, { text: '現場管理費・一般管理費(法定福利費含む)' }] }, { text: formatPrice(remainOverhead), alignment: 'right' }, { text: '1', alignment: 'right' }, { text: '式', alignment: 'center' }, { text: formatPrice(remainOverhead), alignment: 'right' }],
    [{ columns: [{ text: '諸経費', width: 100, margin: [15, 0, 0, 0] }, { text: '現場管理費・一般管理費(法定福利費含む)' }] }, '', { text: '1', alignment: 'right' }, { text: '式', alignment: 'center' }, { text: formatPrice(remainOverhead), alignment: 'right' }],
    ...data.filter(datum => datum.quoteId === id && ["indirect", "other"].includes(datum.costType) && !datum.hide).sort((a, b) => a.code - b.code).map((datum, i, array) => getItemRow(datum, i > 0 ? array[i - 1] : {})),
]

/*
const getSell = (data:KV, i:number, buyAmount:number) => {
    if (data.quotes[i].buyQuantity && data.quotes[i].buyUnitPrice) return ([
        ['　', '　', '　', '　', '　'],
        [{ text: '材木買取' }, '　', '　', '　', '　'],
        [{ text: '材木買取代金', margin: [15, 0, 0, 0] }, { text: formatPrice(data.quotes[i].buyUnitPrice), alignment: 'right' }, { text: formatPrice(data.quotes[i].buyQuantity), alignment: 'right' }, { text: 'トン', alignment: 'center' }, { text: "▲"+formatPrice(buyAmount), alignment: 'right' }],
    ])
    else return []
}
*/

const getTreeQuotePdf = (data: KV, values: KV) => {
    const printingQuotes = data.quotes.map((v: KV, i: number) => ({id: v.quoteId, i: i, active: v.active})).filter((v: KV) => v.active)
    const names = getNameLabel(data, true)
    const coverNameLabelContent = getNameLabelContent({names:getNameLabel(data)})
    const nameLabelContents = printingQuotes.map(() => getNameLabelContent({names, margin:[0, 10]}))// Cannot reuse.  Make object for each page
    return {
        pageSize: 'A4',
        pageMargins: baseStyle.pageMargins,
        info: { title: '見積書' },
        content: [...coverPage(data, values, coverNameLabelContent), ...printingQuotes.map((v:KV, index:number) => quotePage(data, v.id, v.i, nameLabelContents[index])).flat()],
        styles: {
        },
        defaultStyle: baseStyle,
        coverNameLabelContent,
        nameLabelContents
    }
}

const quotePage = (data: KV, quoteId: number, i: number, quoteNameLabelContent: KV) => {
    const { adminCost, humanCost, tax, amountWithTax } = calcNonstoredNumbersNew(data, i)
    return [
        {
            pageBreak: "before",
            columns: [//height 
                {
                    width: 'auto', stack: [
                        { text: ' 御 見 積 書 ', background: 'green', color: 'white', fontSize: 20 },
                        quoteNameLabelContent,
                        { text: '下記の通りお見積り申し上げます。', margin: [0, baseStyle.fontSize] },
                        {
                            table: {
                                widths: ['auto', 'auto'],
                                body: [
                                    ['件名', data.quotes[i].subject || data.subject || ""],
                                    ['場所', data.place || ""],
                                    ['工期', data.quotes[i].constructionPeriod || data.constructionPeriod || "ご相談"],
                                    ['お支払方法', '完成引渡し請求後1ヶ月以内現金100%'],
                                    ['見積有効期限', `発行日より${data.quotes[i].quoteExpiry}ヶ月`],
                                ]
                            },
                            layout: noborderLayout
                        },
                        {
                            table: {
                                widths: [100, '*'],
                                body: [
                                    [{ text: '請負金額 見積額', margin: [0, 6, 0, 0] }, { text: formatPrice(amountWithTax) + "円", bold: true, fontSize: 16, alignment: 'right' }]
                                ],

                            },
                            layout: underlineLayout,
                        },
                        { text: '(消費税及び地方消費税相当額を含む)', alignment: 'right', fontSize: 9 }
                    ]
                },
                { width: '*', text: '' },
                {
                    width: 'auto', alignment: 'right', stack: [
                        { text: `見積No.${data.fileID}-${quoteId}`, margin: [0, 10, 0, 0] },
                        { text: data.quotes[i].quoteDate && JSTDateKanji(new Date(data.quotes[i].quoteDate)) },
                        { width: 200, image: titlelogo },
                        { stack: ['長野県松本市岡田下岡田774-1', 'TEL (0263) 87-5361', 'FAX (0263) 87-5362'], width: 'auto' },
                        ' ',
                        {
                            columns: [
                                { width: "*", text: '' },
                                {
                                    width: 'auto',
                                    table: {
                                        widths: [36, 36, 36],
                                        heights: ['auto', 36],
                                        body: [
                                            //                                        ["工事責任者", "樹木事業部長", "承認者"],
                                            [" ", " ", " "],
                                            ["", "", ""]
                                        ]
                                    },
                                    fontSize: 6,
                                    layout: {
                                        hLineStyle: () => ({ dash: { length: 1 } }),
                                        vLineStyle: () => ({ dash: { length: 1 } }),
                                        paddingLeft: () => 0,
                                        paddingRight: () => 0,
                                        paddingTop: () => 0,
                                        paddingBottom: () => 0,
                                    },
                                    alignment: "center"
                                }
                            ]
                        },
                        '設計責任者　樹木事業部　　',
                        `${data.inCharge.surname} ${data.inCharge.givenName}`,
                        `TEL ${data.inCharge.tel || ""}`
                    ]
                },
            ]
        },
        {
            stack: [
                { text: '工事内容' },
                {
                    table: {
                        widths: '*',
                        heights: 48,
                        body: [[{ text: data.quotes[i].workDetail }]]
                    }
                }
            ],
        },
        ' ',
        {
            table: {
                headerRows: 1,
                widths: ['*', 55, 35, 25, 55],

                body:
                    [([
                        { text: '項目・内容', alignment: 'center' },
                        { text: '単価', alignment: 'center' },
                        { text: '数量', alignment: 'center' },
                        { text: '単位', alignment: 'center' },
                        { text: '金額', alignment: 'center' }
                    ] as KV[])]
                        .concat(getDirect(data.costItem, quoteId, humanCost, data.humanCostTitle))
                        .concat(getIndirect(data.costItem, quoteId, adminCost))
                //                        .concat(getSell(data, i, buyAmount))
            }
        },
        '　',
        {
            columns: [
                { width: '*', text: '' },
                {
                    width: 'auto',
                    unbreakable: true,
                    table: {
                        widths: [80, 89],
                        body: [
                            ...(data.quotes[i].adjust ? [[{ text: data.quotes[i].adjustTitle || '調整額' }, { text: (parseInt(data.quotes[i].adjust || 0) > 0 ? "▲" : "") + formatPrice(data.quotes[i].adjust), alignment: 'right' }]] : []),
                            ...(data.quotes[i].adjust2 ? [[{ text: data.quotes[i].adjustTitle2 || '調整額②' }, { text: (parseInt(data.quotes[i].adjust2 || 0) > 0 ? "▲" : "") + formatPrice(data.quotes[i].adjust2), alignment: 'right' }]] : []),
                            [{ text: '小計' }, { text: formatPrice(data.quotes[i].amount), alignment: 'right' }],
                            [{ text: '消費税' }, { text: formatPrice(tax), alignment: 'right' }],
                            [{ text: '合計' }, { text: formatPrice(amountWithTax), alignment: 'right' }],
                        ],
                    }
                }
            ]
        },
        '　',
        {
            stack: [
                { text: '備考' },
                {
                    table: {
                        widths: '*',
                        heights: 64,
                        body: [[{ text: data.quotes[i].remark }]]
                    }
                }
            ],
            absolutePosition: { x: baseStyle.pageMargins[0], y: 720 }
        },
    ]
}

const coverMessage = (message: string) => `拝啓　平素は格別のお引き立てを賜り厚く御礼申し上げます。\n\n　この度は、見積調査のご依頼をいただきまして、誠にありがとうございます。\nご依頼の件につきまして、御見積書のご用意ができましたので、お送りいたします。\nご確認のほど、よろしくお願いします。\n${message ? `\n${message}\n` : ''}\n\n敬具`

const coverPage = (data: KV, values: KV, coverNameLabelContent:KV) => {
    const addresses = getAddressLabel(data)
    return [
        {
            columns: [//height 
                {
                    width: 'auto', stack: [
                        { text: '御見積書', margin: [pageStyle.address.x, 0, 0, 0] },
                        { text: addresses[0], margin: [pageStyle.address.x, 0, 0, 0] },
                        { text: addresses[1], margin: [pageStyle.address.x, 0, 0, 0] },
                        { text: addresses[2], margin: [pageStyle.address.x, 0, 0, 0] },
                        coverNameLabelContent,
                    ]
                },
                { width: '*', text: '' },
                {
                    width: 'auto', alignment: 'right', stack: [
                        { text: data.quoteDate && JSTDateKanji(new Date(data.quoteDate)) },
                        { width: 200, image: titlelogo, margin: [0, 50, 0, 0] },
                        { stack: ['樹木事業部', '長野県松本市岡田下岡田774-1', 'TEL (0263) 87-5361', 'FAX (0263) 87-5362'], width: 'auto' },
                    ]
                },
            ]
        },
        { width: '*', text: "御見積書の送付について", alignment: "center", fontSize: 12, margin: [0, 20] },
        { width: '*', text: coverMessage(values.messages.inner), margin: 60 },
        { width: '*', text: values.messages.lower || '', margin: [60, 0, 60, 60] },
        {
            columns: [
                { width: '*', text: '※ご不明な点は 右記担当まで ご遠慮なくお問合わせください。', margin: [20, 0] },
                {
                    width: 'auto',
                    alignment: "right",
                    margin: [20, 0],
                    stack: [
                        `担当　　${data.inCharge.surname} ${data.inCharge.givenName}`,
                        `TEL ${data.inCharge.tel || ""}`
                    ]
                }
            ],
            absolutePosition: { x: baseStyle.pageMargins[0], y: 760 }
        },
    ]
}

export default getTreeQuotePdf