import { RealmAppContextInterface } from "RealmApp"
import options from "contexts/options.json"
import { FormikProps } from "formik"
import { useEffect } from "react"

export const invoiceRegisterNo = "登録番号：T6100001025518"

export const projectingDivision = (key: string) => ['forest', 'tree'].includes(key)

export const useDateEffect = ({ formik }:{ formik: FormikProps<any> }) => {
    useEffect(() => {
        if (formik.values && formik.values.handleDate && !['payment', 'paid', 'cancel'].includes(formik.values.status)) formik.values.status = 'invoice'
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values?.handleDate]);
    useEffect(() => {
        if (formik.values && formik.values.deliveryDate && !['payment', 'paid', 'cancel'].includes(formik.values.status)) formik.values.status = 'invoice'
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values?.deliveryDate]);
    useEffect(() => {
        if (formik.values && formik.values.invoiceDate && !['paid', 'cancel'].includes(formik.values.status)) formik.values.status = 'payment'
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values?.invoiceDate]);
    useEffect(() => {
        if (formik.values && formik.values.receivedDate && formik.values.status !== 'cancel') formik.values.status = 'paid'
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values?.receivedDate]);
}



export const isAdmin = (app: RealmAppContextInterface) => app.currentUser?.customData.status === "admin"
export const isTemporary = (app: RealmAppContextInterface) => app.currentUser?.customData.status === "temporary"
export const isRetired = (app: RealmAppContextInterface) => app.currentUser?.customData.status === "retired"

// array of [name, suffix]
export const getNameLabel = (data: KV, noDivision?:boolean) => [
    (data.customer?.corporation && (data.customer.corporateName + ((!noDivision && data.customer.division) ? ('\n' + data.customer.division) : ''))) || `${data.customer?.name?.surname || ''}　${data.customer?.name?.givenName || ''}`,
    (data.customer?.corporation && data.customer.corporateName) ? '様' : (data.customer?.name?.surname || data.customer?.name?.givenName) ? '様' : ' '
]

export const getAddressLabel = (data: KV) => [
    data.customer?.address?.zip ? ('〒' + data.customer.address.zip) : ' ',
    (data.customer?.address?.state ? options.prefecture[data.customer.address.state] : ' ') + (data.customer?.address?.citystr || ' '),
    data.customer?.address?.room || '　'
]

export const objectCompare = (obj1: KV, obj2: KV) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (const key of keys1) {
        if (!keys2.includes(key)) {
            return false;
        }

        const value1 = obj1[key];
        const value2 = obj2[key];

        if (typeof value1 === 'object' && typeof value2 === 'object') {
            if (value1 instanceof Date && value2 instanceof Date) {
                if (value1.getTime() !== value2.getTime()) return false;
            } else if (Array.isArray(value1) && Array.isArray(value2)) {
                if (!arraysAreEqual(value1, value2)) return false;
            } else if (!objectCompare(value1, value2)) {
                return false;
            }
        } else if (value1 !== value2) {
            return false;
        }
    }
    return true;
}

const arraysAreEqual = (arr1: any[], arr2: any[]) => {
    if (arr1.length !== arr2.length) {
        return false;
    }
    for (let i = 0; i < arr1.length; i++) {
        const item1 = arr1[i];
        const item2 = arr2[i];
        if (typeof item1 === 'object' && typeof item2 === 'object') {
            if (item1 instanceof Date && item2 instanceof Date) {
                if (item1.getTime() !== item2.getTime()) return false;
            } else if (Array.isArray(item1) && Array.isArray(item2)) {
                if (!arraysAreEqual(item1, item2)) return false;
            } else if (!objectCompare(item1, item2)) {
                return false;
            }
        } else if (item1 !== item2) {
            return false;
        }
    }
    return true;
}