import React, { MouseEvent } from 'react'

import { DataList, ListItem, EditLinkTd, FilterInput, FilterSelect, FiltersFunction, RowFunction } from "components/list"
import { useAppContext } from 'contexts/AppContext';
import { NavigateFunction } from 'react-router-dom';

const customer = require("realm/Yanagisawa/data_sources/mongodb-atlas/Business/customer/schema.json").properties;
const prefecture = require("contexts/options.json").prefecture;
const location = "/customer"

const getTitle = (obj: KV) => obj.description.match(/[^<>]*(?=>)/g)
const heads:KV[] = [
    { text: "区分", field: "corporation" },
    { text: customer.type.title, field: "type" },
    { text: getTitle(customer.name), field: "name.surnameKana" },
    { text: "所属", field: "corporateName" },
    { text: getTitle(customer.address), field: "address.zip" },
    { text: "TEL", field: "tel" },
    { text: "FAX", field: "fax" },
    { text: customer.email.title, field: "email" },
    { text: customer.forest.title, field: "forest" },
    { text: "次山林管理者", field: "nextManager" },
    { text: getTitle(customer.invoiceAddress), field: "invoiceAddress.zip" },
    { text: "" }
]

const Address = ({ address }: { address: KV }) => <td>
    {address && (<>
        <div className="text-left">〒{address.zip?.substring(0, 3) || ""}-{address.zip?.substring(3) || ""} {prefecture[address.state]}</div>
        <div className="text-left text-gray-900">{address.citystr}</div>
        <div className="text-xs text-gray-900">{address.room}</div>
    </>)}
</td>

const Filter:FiltersFunction = (filter, updateFilter) => {
    return <>
        <FilterInput
            label="姓(かな)"
            name="name.surnameKana"
            value={filter.name?.surnameKana || ""}
            setState={(v:any) => updateFilter("name.surnameKana", v)}
            nolabel
        />
        <FilterInput
            label="名(かな)"
            name="name.givenNameKana"
            value={filter.name?.nameKana || ""}
            setState={(v:any) => updateFilter("name.givenNameKana", v)}
            nolabel
        />
        <FilterInput
            label="会社名ふりがな"
            name="corporateNameKana"
            value={filter.corporateNameKana || ""}
            setState={(v:any) => updateFilter("corporateNameKana", v)}
            nolabel
        />
        <FilterInput
            label="電話番号"
            name="tel"
            value={filter.tel || ""}
            setState={(v:any) => updateFilter("tel", v)}
            nolabel
        />
        <FilterInput
            label="所有山林"
            name="forest"
            value={filter.forest || ""}
            setState={(v:any) => updateFilter("forest", v)}
            nolabel
        />
        <FilterSelect
            label="個人/法人・団体"
            name="corporation"
            optionName="corporation"
            value={!filter ? "" : filter.corporation ? "corporation" : filter.corporation === false ? "individual" : ""}
            setState={v => updateFilter("corporation", !v ? v : v === "corporation")}
            nolabel
        />
        <FilterSelect
            label="部門"
            name="category"
            optionName="category"
            value={filter.category || ""}
            setState={v => updateFilter("category", v)}
            nolabel
        />
        {filter && filter.category && <FilterSelect
            label="顧客種別"
            name="type"
            optionName="customerType"
            value={filter.type || ""}
            setState={v => updateFilter("type", v)}
            nolabel
        />}
    </>
}
//<div className="m-2 absolute inset-y-0 right-0 flex items-center">{count > 99 && `(*100件以降は表示されません)　　`}{count}件</div>
const download = (Data:KV[]) => {
    let data = Data.map(v => ({
        "区分": v.corporation ? "法人・団体" : "個人",
        "種別": `${v.type?.firewood === "new" ? ["薪販売　新"] : v.type?.firewood === "existing" ? ["薪販売　既"] : ""} ${v.type?.tree === "new" ? ["樹木　新"] : v.type?.tree === "existing" ? ["樹木　既"] : ""} ${v.type?.forest === "new" ? ["山林　新"] : v.type?.forest === "existing" ? ["山林　既"] : ""}`,
        "会社名": v.corporateName || "",
        "部署": v.division || "",
        [getTitle(customer.name)[0]]: `${v.name?.surname || ""} ${v.name?.givenName || ""}`,
        [getTitle(customer.address)[0]]: `${v.address?.zip ? `〒${v.address?.zip?.substring(0, 3) || ""}-${v.address?.zip?.substring(3) || ""} ` : ""}${v.address?.state ? prefecture[v.address?.state] : ""}${v.address?.citystr || ""} ${v.address?.room || ""}`,
        "TEL": v.tel || "",
        "TEL2": v.tel2 || "",
        "FAX": v.fax || "",
        [customer.email.title]: v.email || "",
        [customer.forest.title]: v.forest || "",
        "次山林管理者": `${customer.nextManager?.name?.surname || ''}　${customer.nextManager?.name?.givenName || ''}`,
        [getTitle(customer.invoiceAddress)[0]]: `${v.invoiceAddress?.zip ? `〒${v.invoiceAddress?.zip?.substring(0, 3) || ""}-${v.invoiceAddress?.zip?.substring(3) || ""} ` : ""}${v.invoiceAddress?.state ? prefecture[v.invoiceAddress?.state] : ""}${v.invoiceAddress?.citystr || ""} ${v.invoiceAddress?.room || ""}`,
    }))
    return data
}

const Row:RowFunction = ({ datum, clickDelete, navigate }) => <>
    <ListItem data={[datum.corporation ? "法人・団体" : "個人"]} badge />
    <ListItem data={[
        ...datum.type?.firewood === "new" ? ["薪販売　新"] : datum.type?.firewood === "existing" ? ["薪販売　既"] : [],
        ...datum.type?.tree === "new" ? ["樹木　新"] : datum.type?.tree === "existing" ? ["樹木　既"] : [],
        ...datum.type?.forest === "new" ? ["山林　新"] : datum.type?.forest === "existing" ? ["山林　既"] : [],
        ...datum.type?.timber === "new" ? ["素材卸　新"] : datum.type?.timber === "existing" ? ["素材卸　既"] : [],
    ]} badge />
    <td>
        <div className="text-xs">{`${datum.name?.surnameKana || ''}　${datum.name?.givenNameKana || ''}`}</div>
        <div className="text-gray-900">{`${datum.name?.surname || ''}　${datum.name?.givenName || ''}`}</div>
    </td>
    <ListItem data={[datum.corporateName, datum.division]} />
    <Address address={datum.address} />
    <ListItem data={[datum.tel, datum.tel2]} />
    <ListItem data={[datum.fax]} />
    <ListItem data={[datum.email]} />
    <ListItem data={[datum.forest]} left wrap />
    <ListItem data={[`${datum.nextManager?.name?.surname || ''}　${datum.nextManager?.name?.givenName || ''}`]} />
    <Address address={datum.invoiceAddress} />
    <EditLinkTd location={location} id={datum._id} onClickEye={e => navigate && navigate(`/customer/view/${datum._id}`)} onClickDelete={clickDelete} />
</>
const List = () => {
    const context = useAppContext()
    const handleRowClick = (e:MouseEvent<HTMLElement>, navigate:NavigateFunction) => {
        e.stopPropagation()
        const category = context.state.customer?.filter?.category || "firewood"
        context.setState(`${category}.filter`, { customer: { _id: e.currentTarget.dataset.name } })
        navigate(`/${category}/`)
    }
    return <DataList collection="customer" type="search" location={location} heads={heads} filter={Filter} download={download} row={Row} handleRowClick={handleRowClick} />
}

export default List
