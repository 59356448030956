import React, { MouseEvent } from 'react'

import { DataList, ListItem, EditLinkTd, FilterSelect, ListName, FilterInput, FilterCheck, FiltersFunction, FilterDates, RowFunction } from "components/list"
import options from 'contexts/options.json'
import { JSTMonth, JSTNewDate, JSTYear } from 'contexts/dateUtils'
import { useLazyData } from 'graphql/useCollection'
import { useHref } from 'react-router-dom'
import { makePdf } from 'contexts/pdfUtils'
import getTimberInvoicePdf from './invoice'

const location = "/timber"

const heads: KV[] = [
    { text: "ステータス", field: "status" },
    { text: "起票/納材", field: "deliveryDate" },
    { text: "営業/請求", field: "sales" },
    { text: "事業部門", field: "division" },
    { text: "事業(団地)", field: "project" },
    { text: "請求明細NO", field: "invoiceNo" },
    { text: "行先", field: "customer" },
    { text: "樹種/用途", field: "species" },
    { text: "長さ/径級", field: "length" },
    { text: "本数/数量", field: "timbers" },
    { text: "単価/金額", field: "totalPrice" },
    { text: "合計金額(税込)", field: "amount" },
    { text: "詳細表示", field: "asDetail" },
    { text: "請求/入金", field: "receivedDate" },
    { text: "備考", field: "remarks" },
    { text: "" }
]

//taxRate	decimal			decimal:%(1){10}	消費税率
const Filter: FiltersFunction = (filter, updateFilter, dateParam) => {
    const updateExists = (value: any) => {
        updateFilter(["deliveryDate_exists", "deliveryDate_gte", "deliveryDate_lt"], value ? 
        [false, undefined, undefined]
         : 
        [undefined, JSTNewDate(JSTYear(), JSTMonth(), 1), JSTNewDate(JSTYear(), JSTMonth() + 1, 1)]
        )
    }
    return <>
        <FilterSelect
            label="進捗"
            name="status"
            value={filter.status || ""}
            optionName="timberStatus"
            setState={v => updateFilter("status", v)}
            status
            nolabel
        />
        <FilterInput
            label="請求明細NO"
            name="invoiceNo"
            value={filter.invoiceNo || ""}
            setState={v => updateFilter("invoiceNo", v)}
            nolabel
        />
        {filter.deliveryDate_exists !== false && <FilterDates dateParam={dateParam} />}
        <FilterCheck
            label="日付なし"
            name="deliveryDate_exists"
            state={filter.deliveryDate_exists === false}
            setState={(v: any) => updateExists(v)}
        />
    </>
}

const download = (Data: KV[]) => Data.map(datum => ({
    "ID": datum._id,
    "起票日": datum.issueDate ? new Date(datum.issueDate).toLocaleDateString() : "",
    "営業担当者": `${datum.sales?.surname || ""} ${datum.sales?.givenName || ""}`,
    "請求書作成者": `${datum.invoiceAuthor?.surname || ""} ${datum.invoiceAuthor?.givenName || ""}`,
    "事業種別": datum.division ? options.category[datum.division] : "",
    "事業ファイルNo": (datum.division && datum.project) ? datum.project[datum.division]?.fileID : "",
    "事業名": (datum.division && datum.project) ? datum.project[datum.division]?.subject : "",
    "団地名": datum.place || "",
    "請求明細NO": datum.invoiceNo || "",
    "枝番": datum.branchNo || "",
    "納材日": datum.deliveryDate ? new Date(datum.deliveryDate).toLocaleDateString() : "",
    "行先": `${datum.customer?.corporateName || ""} ${datum.customer?.name?.surname || ""} ${datum.customer?.name?.givenName || ""}`,
    "樹種": datum.species ? options.timberSpecies[datum.species] : "",
    "用途": datum.usage ? options.usage[datum.usage] : "",
    "長さ": datum.length || "",
    "径級": datum.diameter || "",
    "本数": datum.timbers || "",
    "単位": datum.unit ? options.timberUnit[datum.unit] : "",
    "数量": datum.quantity || "",
    "単価": datum.unitPrice || "",
    "金額": datum.totalPrice || "",
    "消費税率": datum.taxRate || "",
    "合計金額": datum.amount || "",
    "販売状況": datum.status ? options.timberStatus[datum.status] : "",
    "請求書発行日": datum.invoiceDate ? new Date(datum.invoiceDate).toLocaleDateString() : "",
    "入金日": datum.receivedDate ? new Date(datum.receivedDate).toLocaleDateString() : "",
    "備考": datum.remarks || ""
}))

const Row:RowFunction = ({ datum, clickDelete }) => {
    const { getData } = useLazyData('timber');
    const baseUrl = window.location.origin + useHref('/')
    const handlePrint = async (e: MouseEvent<HTMLButtonElement>) => {
        const Data = await getData({ variables: { query: { invoiceNo: datum.invoiceNo } } })
        makePdf(getTimberInvoicePdf(Data.data.timbers), baseUrl)
    }
    return <>
        <ListItem data={[options.timberStatus[datum.status]]} badge />
        <ListItem data={[datum.issueDate, datum.deliveryDate]} date />
        <ListName data={[datum.sales, datum.invoiceAuthor]} />
        <ListItem data={[options.category[datum.division]]} />
        <ListItem data={[datum.project?.[datum.division]?.subject]} />
        <ListItem data={[datum.invoiceNo]} />
        <ListName data={[datum.customer]} />
        <ListItem data={[datum.species, datum.usage]} />
        <ListItem data={[datum.length ? datum.length + 'm' : '', datum.diameter ? datum.diameter + 'cm' : '']} />
        <ListItem data={[datum.timbers ? datum.timbers + '本' : '', String(datum.quantity) + (options.timberUnit[datum.unit] || "")]} />
        <ListItem data={[datum.unitPrice, datum.totalPrice]} />
        <ListItem data={[datum.amount]} />
        <ListItem data={[datum.asDetail ? "内訳表示" : ""]} badge />
        <ListItem data={[datum.invoiceDate, datum.receivedDate]} date />
        <ListItem data={[`${datum.remarks?.substring(0, 10) || ""}${datum.remarks?.length > 10 ? "..." : ""}`]} />
        <EditLinkTd location={location} id={datum._id} onClickDelete={clickDelete} onClickPrint={handlePrint} />
    </>
}

const List = () => {
    return <DataList collection="timber" location={location} heads={heads} user={['sales', 'invoiceAuthor']} filter={Filter} dateField="deliveryDate" download={download} row={Row} handleRowClick={undefined} />
}

export default List