import { useQuery, useLazyQuery, useMutation, gql, DocumentNode, ApolloError, OperationVariables, LazyQueryHookOptions, LazyQueryResult, Reference } from "@apollo/client";
import { customAlphabet } from "nanoid";
const aim = require("realm/Yanagisawa/data_sources/mongodb-atlas/Business/aim/schema.json").properties;
const attendance = require("realm/Yanagisawa/data_sources/mongodb-atlas/Business/attendance/schema.json").properties;
const customer = require("realm/Yanagisawa/data_sources/mongodb-atlas/Business/customer/schema.json").properties;
const firewood = require("realm/Yanagisawa/data_sources/mongodb-atlas/Business/firewood/schema.json").properties;
const firewoodProduct = require("realm/Yanagisawa/data_sources/mongodb-atlas/Business/firewoodProduct/schema.json").properties;
const tree = require("realm/Yanagisawa/data_sources/mongodb-atlas/Business/tree/schema.json").properties;
const treeItem = require("realm/Yanagisawa/data_sources/mongodb-atlas/Business/treeItem/schema.json").properties;
const forest = require("realm/Yanagisawa/data_sources/mongodb-atlas/Business/forest/schema.json").properties;
const machine = require("realm/Yanagisawa/data_sources/mongodb-atlas/Business/machine/schema.json").properties;
const timber = require("realm/Yanagisawa/data_sources/mongodb-atlas/Business/timber/schema.json").properties;
const transport = require("realm/Yanagisawa/data_sources/mongodb-atlas/Business/transport/schema.json").properties;
const user = require("realm/Yanagisawa/data_sources/mongodb-atlas/Business/user/schema.json").properties;
const work = require("realm/Yanagisawa/data_sources/mongodb-atlas/Business/work/schema.json").properties;
const message = require("realm/Yanagisawa/data_sources/mongodb-atlas/Business/message/schema.json").properties;

const userRelationFields = (relations:KV, value:string) => Object.keys(relations).reduce((a:KV[], r) => (relations[r].ref === "#/relationship/mongodb-atlas/Business/user" ? [ ...a, {[relations[r].source_key]: { [relations[r].foreign_key]: value }} ] : a), [])


/*export const reactiveVar:ReactiveVar<{[key:string]:any}> = makeVar<{[key:string]:any}>({
  firewood: { filter: { status: "inquiry" } }
});*/
/**
 * Set specific field of nested object and update reactive variable of apollo.
 * @param key Key using dot for nested object.  If empty, replace all data.
 * @param data Data to put at key
 */
/*export const setReactive = (key:string, data:{[key:string]:any}):void => {
  if (!key) {
    reactiveVar(data)
    return
  }
  let newReactive = { ...reactiveVar }
  key.split(".").reduce((a:any, r:string, i:number, array: string[]) => {
    if (i === array.length - 1) a[r] = data
    if (!a[r]) a[r] = {}
    return a[r]
  }, newReactive)
  reactiveVar(newReactive)
}*/
/**
 * Returns reactive value and setReactive function like useState.
 * @returns [reactive value, setReactive function]
 */
//export const useReactive:() => [{[key:string]:any}, (key:string, data:{[key:string]:any}) => void] = () => [useReactiveVar(reactiveVar), setReactive]

export const properties:{[key:string]:any} = {
    aim: { schema: aim, relation: { user: user } },
    attendance: { schema: attendance, relation: { user: user } },
    attendanceView: { schema: attendance, relation: { user: user } },
    customer: { schema: customer, relation: { customer: customer } },
    customerView: { schema: customer, relation: { customer: customer } },
    firewood: { schema: firewood, relation: { customer: customer, user: user } },
    firewoodView: { schema: firewood, relation: { customer: customer, user: user } },
    firewoodProduct: { schema: firewoodProduct },
    tree: { schema: tree, relation: { customer: customer, user: user } },
    treeItem: { schema: treeItem },
    forest: { schema: forest, relation: { customer: customer, user: user } },
    machine: { schema: machine, relation: { user: user } },
    machineView: { schema: machine, relation: { user: user } },
    timber: { schema: timber, relation: { customer: customer, user: user } },
    transport: { schema: transport, relation: { customer: customer } },
    user: { schema: user, relation: { user: user } },
    work: { schema: work },
    message: { schema: message },
}

const alphabet:string = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

/**
 * Function to generate random 8 characters string using numbers and alphabets only
 */
export const nanoid = customAlphabet(alphabet, 8);

/**
 * Make GraphQL query fields as string with line break from schema.
 * @param schema 
 * @param relationSchemas 
 * @param noRelation
 * @returns GraphQL query fields as string with line break
 */
export const getParams = (schema:{[key:string]:any}, relationSchemas:{[key:string]:any}={}, noRelation:boolean = false):string => {
    return Object.keys(schema).map(key => {
      const type = schema[key].description?.replace(/\([\s\S]*?\)/g, '').replace(/\{[\s\S]*?\}/g, '').replace(/<[\s\S]*?>/g, '').split(":") || ['text']
        if (type[0] === 'relation') return noRelation ? null : `${key} {\n${getParams(relationSchemas[type[1].split("/")[0]], {}, true)}\n}`// for relation, get property keys for related object
        if (schema[key].bsonType === "object") return `${key} {\n${getParams(schema[key].properties)}\n}`
        if (schema[key].bsonType === "array" && schema[key].items.bsonType === "object") return `${key} {\n${getParams(schema[key].items.properties)}\n}`
        return key
    }).join("\n")
}

/**
 * Capitalize first letter
 * @param s String to capitalize
 * @returns Capitalized string
 */
export const capitalize:(s:string)=>string = s => (s && s[0].toUpperCase() + s.slice(1)) || ""
export const uncapitalize:(s:string)=>string = s => (s && s[0].toLowerCase() + s.slice(1)) || ""

/**
 * Properties for collection as Fragment
 * @param collection 
 * @returns 
 */
export const getFragment = (collection:string):DocumentNode => gql`
  fragment ${capitalize(collection)}Fields on ${capitalize(collection)} {
    ${getParams(properties[uncapitalize(collection)].schema, properties[uncapitalize(collection)].relation || {})}
  }
`;

const getAddMutation = (collection:string):DocumentNode => gql`
  ${getFragment(collection)}
  mutation($data: ${capitalize(collection)}InsertInput!) {
    addedData: insertOne${capitalize(collection)}(data: $data) {
          ...${capitalize(collection)}Fields
    }
  }
`;

const getReplaceMutation = (collection:string):DocumentNode => gql`
  ${getFragment(collection)}
  mutation($id: ${capitalize(properties[collection].schema._id.bsonType)}!, $data: ${capitalize(collection)}InsertInput!) {
    replacedData: replaceOne${capitalize(collection)}(query: { _id: $id }, data: $data) {
          ...${capitalize(collection)}Fields
    }
  }
`;

const getUpdateMutation = (collection:string):DocumentNode => gql`
  ${getFragment(collection)}
  mutation($id: ${capitalize(properties[collection].schema._id.bsonType)}!, $set: ${capitalize(collection)}UpdateInput!) {
    updatedData: updateOne${capitalize(collection)}(query: { _id: $id }, set: $set) {
          ...${capitalize(collection)}Fields
    }
  }
`;


const getDeleteMutation = (collection:string):DocumentNode => gql`
  ${getFragment(collection)}
  mutation($id: ${capitalize(properties[collection].schema._id.bsonType)}!) {
    deletedData: deleteOne${capitalize(collection)}(query: { _id: $id }) {
          ...${capitalize(collection)}Fields
    }
  }
`;

/**
 * Get function to add data.  Cache will be updated.
 * @param collection Name of MongoDB collection
 * @param completed Function to run when GraphQL operation is completed
 * @returns 
 */
export const useAdd = (collection:string, completed?:()=>void) => {
    const [addDataMutation, { error }] = useMutation(getAddMutation(collection), {
        // Manually save added Data into the Apollo cache so that Data queries automatically update
        update: (cache, { data: { addedData } }) => {
            cache.modify({
                fields: {
                    [collection+"s"]: (existingData:Reference|(Reference|KV|undefined)[] = []):(Reference|KV|undefined)[] => [
                        ...(Array.isArray(existingData) ? existingData : [existingData]),
                        cache.writeFragment({
                            data: addedData,
                            fragment: getFragment(collection),
                        }),
                    ],
                },
            });
        },
        onCompleted: completed
    });
    /**
     * Function to add data
     * @param AddingData New data to add
     * @returns Mutation variables {addedData, error}
     */
    const addData = async (AddingData:{[key:string]:any}): Promise<{addedData:{[key:string]:any}, error:ApolloError|undefined}> => {
        const { data } = await addDataMutation({
            variables: {
                data: AddingData._id ? {
                    ...AddingData,
                } : {
                    _id: nanoid(),
                    ...AddingData,
                },

            },
        });
        const addedData:{[key:string]:any} = data?.addedData || {}
        return { addedData, error };
    };

    return addData;
}

/**
 * Get function to replace data
 * @param collection Name of MongoDB collection
 * @param completed Function to run when GraphQL operation is completed
 * @returns 
 */
export const useReplace = (collection:string, completed?:()=>void) => {
  const [replaceDataMutation] = useMutation(getReplaceMutation(collection), { onCompleted: completed });
  /**
   * Function to replace data
   * @param id _id of document to replace
   * @param replaces New data to replace
   * @returns Mutation variables {replaceData, error}
   */
  const replaceData = async (id:string | number, replaces:{[key:string]:any}): Promise<{replacedData:{[key:string]:any}, error:ApolloError|undefined}> => {
    try {
      const { data } = await replaceDataMutation({
        variables: { id: id, data: replaces },
      });
      const replacedData:{[key:string]:any} = data?.replacedData || {}
      return { replacedData, error: undefined };
    } catch (e) {
      return { replacedData:{}, error: e as ApolloError }
    }
  };
  return replaceData;
}

/**
 * Get function to update data
 * @param collection Name of MongoDB collection
 * @param completed Function to run when GraphQL operation is completed
 * @returns 
 */
 export const useUpdate = (collection:string, completed?:()=>void) => {
  const [updateDataMutation] = useMutation(getUpdateMutation(collection), { onCompleted: completed });
  /**
   * Function to replace data
   * @param id _id of document to replace
   * @param replaces New data to replace
   * @returns Mutation variables {replaceData, error}
   */
  const updateData = async (id:string | number, set:{[key:string]:any}): Promise<{updatedData:{[key:string]:any}, error:ApolloError|undefined}> => {
    try {
      const { data } = await updateDataMutation({
        variables: { id: id, set: set },
      });
      const updatedData:{[key:string]:any} = data?.updatedData || {}
      return { updatedData, error: undefined };
    } catch (e) {
      return { updatedData:{}, error: e as ApolloError }
    }
  };
  return updateData;
}


/**
 * Get function to delete data.  Cache will be updated.
 * @param {string} collection Name of MongoDB collection
 * @param {()=>void} completed Function to run when GraphQL operation is completed
 * @returns 
 */
export const useDelete = (collection:string, completed?:()=>void) => {
  const [deleteDataMutation, { error }] = useMutation(getDeleteMutation(collection), { 
    update: (cache, { data: { deletedData } }) => {
        const normalizedId = cache.identify(deletedData);
        cache.evict({ id: normalizedId });
        cache.gc();
    },
    onCompleted: completed 
  });
  /**
   * Function to delete data
   * @param id _id of document to delete
   * @returns Mutation variables {deletedData, error}
   */
  const deleteData = async (id:string|number): Promise<{deletedData:{[key:string]:any}, error:ApolloError|undefined}> => {
    const { data } = await deleteDataMutation({ variables: { id: id } });
    const deletedData:{[key:string]:any} = data?.deletedData || {}
    return { deletedData, error };
  };
  return deleteData;
}

/**
 * Get multiple document data using GraphQL
 * @param baseCollection Name of MongoDB collection without "matched" or "View"
 * @param type search for partial string match, view for view
 * @param query Query object.  Leave undefined or give {} to get all data
 * @param sortBy Specify sorting field.  Default is _ID_ASC
 * @param limit Max documents to return.  Default is 100
 * @param completed Function to run when GraphQL operation is completed
 * @returns Query variables {loading, Data, error, refetch} 
 */
export const useData = (baseCollection:string, type:string|null = null, query:{[key:string]:any} = {}, sortBy:string = "_id_ASC", limit:number = 100, completed?:()=>void):{loading:boolean, Data?:{[key:string]:any}[], error?:ApolloError, refetch:() => Promise<any>} => {
    const search = type === "search"
    const view = type === "view"
    const collection = capitalize(baseCollection) + (view ? 'View' : '')
    const { data, loading, error, refetch } = useQuery(
        gql`
      ${getFragment(collection)}
      query($query: ${search ? 'Search' : ''}${collection}${search ? '' : 'Query'}Input!, ${!search ? `$sortBy: ${collection}SortByInput!, $limit:Int` : ''}) {
        ${search ? 'matched' + collection : uncapitalize(collection)}s(${search ? 'input' : 'query'}: $query${!search ? ', sortBy: $sortBy, limit: $limit' : ''}) {
          ...${collection}Fields
        }
      }
    `,
        { variables: { query: {...query, ...(search ? {sortBy: sortBy, limit:limit } : {})}, ...(!search ? {sortBy: sortBy.toUpperCase(), limit:limit } : {})}, fetchPolicy: "cache-and-network", onCompleted: completed }
    );
    const Data:{[key:string]:any}[]|undefined = data?.[(search ? 'matched' + collection : uncapitalize(collection)) + "s"];
    return { loading, Data, error, refetch };
}

/**
 * Get function to get multiple document data using GraphQL at runtime.
 * 
 * @param baseCollection Name of MongoDB collection without "matched" or "View"
 * @param type search for partial string match, view for view
 * @param options options for query
 * @param query 
 * @returns Query variables { getData, loading, Data, error }
 */
export const useLazyData = (baseCollection:string, type?:string, options?:LazyQueryHookOptions, query?:string):{getData:(options?:LazyQueryHookOptions|undefined)=>Promise<LazyQueryResult<any, OperationVariables>>, loading:boolean, Data?:{[key:string]:any}[], error?:ApolloError, notLoaded?:boolean} => {
  const search = type === "search"
  const view = type === "view"
  const collection = capitalize(baseCollection) + (view ? 'View' : '')
  const [getData, { data, loading, error }] = useLazyQuery(query ? gql`${query}` : gql`
      ${getFragment(collection)}
      query($query: ${search ? 'Search' : ''}${collection}${search ? '' : 'Query'}Input!${!search ? `, $limit:Int` : ''}) {
        ${search ? 'matched' + collection : uncapitalize(collection)}s(${search ? 'input' : 'query'}: $query${!search ? ', limit: $limit' : ''}) {
          ...${collection}Fields
        }
      }
    `, options || {});
    const Data:{[key:string]:any}[]|undefined = data?.[(search ? 'matched' + collection : uncapitalize(collection)) + "s"];
    const notLoaded = !data
    return { getData, loading, Data, error, notLoaded };
}

/**
 * Get single document data using GraphQL
 * @param collection Name of MongoDB collection
 * @param query Query object
 * @param completed Function to run when GraphQL operation is completed
 * @returns Query variables {loading, Data, error} 
 */
export const useDatum = (collection:string, query:{[key:string]:any}, completed?:()=>void): {loading:boolean, Data:{[key:string]:any}, error?:ApolloError} => {
    const { data, loading, error } = useQuery(
        gql`
      ${getFragment(collection)}
      query($query: ${capitalize(collection)}QueryInput!) {
        ${collection}(query: $query) {
          ...${capitalize(collection)}Fields
        }
      }
    `,
        { variables: { query: query }, fetchPolicy: "cache-and-network", onCompleted: completed }
    );
    const Data:{[key:string]:any} = data?.[collection] ?? {};
    return { loading, Data, error };
}

/**
 * Get summary document data using GraphQL.  Custom resolver definition(summary, SummaryInput, SummaryPayload) is required.
 * @param input Custom query object SummaryInput
 * @param completed Function to run when GraphQL operation is completed
 * @returns Query variables {loading, Data, error}. Data includes {_id, count, amount}.
 */
export const useSummaryData = (input:{[key:string]:any} = {}, completed?:()=>void):{loading:boolean, Data:[{[key:string]:any}], error?:ApolloError} => {
    const { data, loading, error } = useQuery(
        gql`
      query($input: SummaryInput!) {
        ${input.collection}Summary(input: $input) {
          _id
          count
          amount
        }
      }
    `,
        { variables: { input: input }, onCompleted: completed }
    );
    const Data:[{[key:string]:any}] = data?.[`${input.collection}Summary`] ?? [];
    return { loading, Data, error };
}
