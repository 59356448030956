import React, { useState } from 'react'
import { useHref } from 'react-router-dom';
import { Tab } from '@headlessui/react'
import { Form, Formik, FormikProps } from 'formik';
import { XCircleIcon, PrinterIcon } from '@heroicons/react/24/solid'

import getTreeQuotePdf from "pages/tree/quote";
import getTreeInvoice from "pages/tree/invoice";

//const schema = require("realm/Yanagisawa/data_sources/mongodb-atlas/Business/forest/schema.json").properties;
import { DataList, ListItem, ListName, EditLinkTd, FilterInput, FilterSelect, FilterCheck, FiltersFunction, RowFunction, StatusIcon } from "components/list"
import { FieldPlain, FieldWithMark, FieldCheck, FieldArea } from 'components/uiparts'
import DialogFrame from 'components/dialog'
import { ButtonClass } from "utils"
import options from "contexts/options.json"
import { makePdf } from 'contexts/pdfUtils';
import relations from "realm/Yanagisawa/data_sources/mongodb-atlas/Business/forest/relationships.json"

const location = "/forest"

const heads:KV[] = [
    { text: "状況", field: "status" },
    { text:"件名", field: "subject" },
    { text:"団地名", field: "complex" },
    { text:"顧客", field: "customer.name.surnameKana" },
    { text: "取引分類", field: "datum.customer.type.forest" },
    { text:"開始日", field: "siteSurveyDate" },
    { text:"担当者", field: "inCharge.surname" },
    { text:"進捗", field: "progress" },
    { text: "勤怠" },
    { text:"" }
]

export const baseFilter = { status: "", mine: false, customer: { _id: "", corporateName: "", name: { surnameKana: "" } } }

const Filter:FiltersFunction = (filter, updateFilter) => {
    return <>
        <FilterSelect
            label="進捗"
            name="status"
            value={filter.status || ""}
            optionName="treeStatus"
            setState={v => updateFilter("status", v)}
            status
            nolabel
        />
        <FilterInput
            label="顧客ID"
            name="customer._id"
            value={filter.customer?._id || ""}
            setState={v => updateFilter("customer._id", v)}
            nolabel
        />
        <FilterInput
            label="顧客姓(かな)"
            name="customer.name.surnameKana"
            value={filter.customer?.name?.surnameKana || ""}
            setState={v => updateFilter("customer.name.surnameKana", v)}
            nolabel
        />
        <FilterInput
            label="会社名ふりがな"
            name="customer.corporateNameKana"
            value={filter.customer?.corporateNameKana || ""}
            setState={v => updateFilter("customer.corporateNameKana", v)}
            nolabel
        />
        <FilterCheck
            label="マイ案件"
            name="mine"
            state={filter.mine || false}
            setState={(v:boolean) => updateFilter("mine", v)}
        />
    </>
}

const PrintDialog = ({current, show, setShow, formik}:{current?:KV, show?:boolean, setShow:React.Dispatch<any>, formik?:FormikProps<any>}) => {
    const [tab, setTab] = useState(0)
    const baseUrl = window.location.origin + useHref('/')

    const quotePdf = (data:KV|null, values:any) => {
        if (!data?.customer) { alert("顧客情報が入力されていません"); return null; }
        if (!data.customer.name) { alert("顧客名が入力されていません"); return null; }
        if (!data.customer.address) { alert("送付先住所が入力されていません"); return null; }
        if (!data.inCharge) { alert("担当者が入力されていません"); return null; }
        makePdf(getTreeQuotePdf(data, values), baseUrl)
    }

    const handleCancel = (dialogFormik:FormikProps<any>) => {
        if (!formik) return
        formik.setFieldValue("message", dialogFormik.values.message)
        setShow(undefined)
    }
    return (<DialogFrame show={show} setShow={setShow}>
        <Formik
            initialValues={{ message: "", summarize: false, document1: "", document1Count: 0, document2: "", document2Count: 0, document3: "", document3Count: 0, }}
            onSubmit={async (values, { setSubmitting }) => {
                console.log(values)
                tab === 0 ? quotePdf(current || null, values) : (current && makePdf(getTreeInvoice(current, values), baseUrl))
                setSubmitting(false);
            }}
        >
            {dialogFormik => <Form>
                <Tab.Group onChange={(index) => setTab(index)}>
                    <Tab.List className="p-0.5 rounded-lg bg-theme-700">
                        {["見積書", "請求書"].map((tab, i) => <Tab key={`tab${i}`}
                            className={({ selected }) =>
                                (selected ? 'bg-white text-theme-800' : 'text-theme-100 bg-transparent hover:bg-theme-600')
                                + ' mr-2 px-3 py-2 rounded-md text-sm font-medium'
                            }
                        >{tab}</Tab>
                        )}
                    </Tab.List>
                    <Tab.Panels className="mt-4 h-72">
                        <Tab.Panel>
                            <FieldArea label="メッセージ" name="message" rows={6} props={dialogFormik.getFieldProps('message')} />
                        </Tab.Panel>
                        <Tab.Panel className="grid grid-cols-4 gap-2">
                            <FieldCheck label="1枚に統合" name="summarize" props={dialogFormik.getFieldProps('summarize')} setFieldValue={dialogFormik.setFieldValue} />
                            {[1,2,3].map(v => <React.Fragment key={v}>
                                <FieldPlain label={`添付書類${v}`} name={`document${v}`} span={3} props={dialogFormik.getFieldProps(`document${v}`)} />
                                <FieldWithMark label="　" name={`document${v}Count`} mark="部" span={1} props={dialogFormik.getFieldProps(`document${v}Count`)} />
                            </React.Fragment>)}
                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group >
                <div className="px-4 bg-gray-50 text-right sm:px-6">
                    <button className={ButtonClass} type="submit"><PrinterIcon className="w-5 h-5" />作成</button>
                    <button className={ButtonClass} onClick={e => { e.preventDefault(); handleCancel(dialogFormik); }}><XCircleIcon className="w-5 h-5" />戻る</button>
                </div>
            </Form>}
        </Formik>
    </DialogFrame>)
}

const download = (Data:KV[]) => Data.map(v => ({
    "担当": v.inCharge?.surname || "",
    "顧客": `${v.customer?.corporateName || ""} ${v.customer?.name?.surname || ""} ${v.customer?.name?.givenName || ""}`,
    "工事名": v.subject || "",
    "場所": v.place || "",
    "合計金額": v.dealAmount || "",
    "ステータス": v.status ? options.forestStatus[v.status] : "",
    "請求書発行日": v.invoiceDate ? new Date(v.invoiceDate).toLocaleDateString() : "",
    "入金確認日": v.receivedDate ? new Date(v.receivedDate).toLocaleDateString() : "",
}))


const Row:RowFunction = ({datum, clickDelete, clickUpdate}) => <>
    <ListItem data={[options.treeStatus[datum.status]]} badge />
    <ListItem data={[datum.subject, datum.abbreviation]} left />
    <ListItem data={[datum.complex]} left />
    <ListName data={[datum.customer]} />
    <ListItem data={[options.customerType[datum.customer?.type?.forest || 'na']]} badge />
    <ListItem data={[datum.mainPlan.beginDate]} date />
    <ListName data={[datum.receptionist, datum.inCharge]} />
    <td className="w-24 px-2 py-2 text-gray-500"><div className="relative h-6 bg-gray-100">
        <span className={`absolute left-0 top-0.5 bottom-0.5 ${datum.progress > 1 ? "bg-red-500" : "bg-theme-500"}`} style={{width:`${datum.progress*100}%`}}></span>
        <span className={`absolute top-0 bottom-0 px-2${datum.progress > 0.5 ? " text-white" : ""}`} style={datum.progress > 0.5 ? {right:`${100-datum.progress*100}%`} : {left:`${datum.progress*100}%`}}>{Math.round(datum.progress*100)}%</span>
    </div></td>

    <td><button className="w-8 h-8" data-id={datum._id} data-set={`{"attendActive":"${!datum.attendActive ? true : false}"}`} onClick={clickUpdate}><StatusIcon status={datum.attendActive ? "OK" : "NG"} /></button></td>
    <EditLinkTd location={location} id={datum._id} onClickDelete={clickDelete} />
</>

const List = () => <DataList collection="forest" location={location} heads={heads} user={['receptionist', 'designManager', 'inCharge', 'invoiceAuthor']} filter={Filter} download={download} row={Row} dialog={PrintDialog}/>

export default List
