import React, { ChangeEvent, MouseEvent, useEffect } from "react";
import * as Yup from 'yup';
import Decimal from "decimal.js";

import relations from "realm/Yanagisawa/data_sources/mongodb-atlas/Business/timber/relationships.json"
import { CreateBase, EditBase, FieldBase, FormFrame, getObjectData, getSchemaValues, RelationField } from "components/edit";
import { FieldSelect } from "components/uiparts";
import UserSelect from "components/userSelect";
import { Create as CreateCustomer } from 'pages/customer/edit'
import ViewCustomer from 'pages/customer/view'
import { FormikProps } from "formik";
import CustomerSearch from "components/customerSearch";
import DialogFrame from "components/dialog";
import { useAppContext } from "contexts/AppContext";
import { gql, useApolloClient } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useDateEffect } from "contexts/utils";

const schema = require("realm/Yanagisawa/data_sources/mongodb-atlas/Business/timber/schema.json").properties;

const initialValues = getSchemaValues(schema)

const projectingDivision = (key: string) => ['forest', 'tree'].includes(key)

const FormFields = ({ formik }: { formik: FormikProps<any> }) => {
    const context = useAppContext()
    // customer selection dialog bool
    const [showCustomer, setShowCustomer] = React.useState(false)
    // Newc ustomer creation dialog bool
    const [showCreateCustomer, setShowCreateCustomer] = React.useState(false)
    const [showViewCustomer, setShowViewCustomer] = React.useState(false)
    // field name for user selection dialog.  Also used as user selection dialog show bool
    const [currentName, setCurrentName] = React.useState<string | null>(null)

    const handleUserClick = (name: string | null) => {
        setCurrentName(name)
    }
    const handleCustomerClick = (e: MouseEvent<HTMLDivElement>) => {
        const name = e.currentTarget.getAttribute("name")
        if (name === "openCreate") setShowCreateCustomer(true)
        else if (name === "openView") setShowViewCustomer(true)
        else setShowCustomer(true)
        e.preventDefault()
    }
    // Function after creating customer
    const handleCreate = (id?: String, values?: KV) => {
        let data = values ? getObjectData(values) : null
        data && (data._id = id)
        id && data && formik.setFieldValue("customer", data)
        setShowCreateCustomer(false)
    }

    const handleItemChange = (e: ChangeEvent<HTMLInputElement>) => {
        const quantity = new Decimal((e.target.name === "quantity" ? e.target.value : formik.values.quantity) || 0)
        const unitPrice = new Decimal((e.target.name === "unitPrice" ? e.target.value : formik.values.unitPrice) || 0)
        const totalPrice = new Decimal((e.target.name === "totalPrice" ? e.target.value : formik.values.totalPrice) || 0)
        const taxRate = new Decimal((e.target.name === "taxRate" ? e.target.value : formik.values.taxRate) || 0)
        if (["quantity", "unitPrice"].includes(e.target.name)) {
            formik.values.totalPrice = quantity.times(unitPrice).floor().toFixed()
            formik.values.amount = quantity.times(unitPrice).floor().times(new Decimal(1).plus(taxRate.dividedBy(new Decimal(100)))).floor().toFixed()
        } else {
            formik.values.amount = new Decimal(totalPrice).floor().times(new Decimal(1).plus(taxRate.dividedBy(new Decimal(100)))).floor().toFixed()
        }
        formik.handleChange(e)
    }

    const handleProjectChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const fieldName = e.currentTarget.name
        const { __typename, ...data } = context.state[formik.values.division]?.ongoing?.find((item: KV) => item._id === e.currentTarget.value) || { __typename: "" }
        formik.setFieldValue(`${fieldName}.${formik.values.division}`, { ...data })
    }

    const getProjectOptions = () => {
        if (!projectingDivision(formik.values.division || "")) return ({ "": "" })
        let options = (context.state[formik.values.division]?.ongoing || []).reduce((a: KV, r: KV) => ({ ...a, [r._id]: `${r.fileID} - ${r.subject}` }), { "": "" })
        const initialValue = formik.initialValues.project
        if (initialValue && initialValue._id) options = { ...options, [initialValue._id]: `${initialValue.fileID} - ${initialValue.subject}` }
        return options
    }

    useDateEffect({formik})

    return <div className="grid grid-cols-6 gap-6">
        <FormFrame formik={formik} schema={schema}>
            <FieldBase name="status" />
            <FieldBase name="issueDate" />
            <RelationField
                label="営業担当者"
                onClick={e => handleUserClick("sales")}
                item={formik.values.sales}
                span={1}
            />
            <RelationField
                label="請求書作成者"
                onClick={e => handleUserClick("invoiceAuthor")}
                item={formik.values.invoiceAuthor}
                span={1}
            />
            <UserSelect name={currentName} setName={setCurrentName} formik={formik} />
            <FieldBase name="division" />
            <>{projectingDivision(formik.values.division) && <FieldSelect label="事業名" name={`project`} span={2} options={getProjectOptions()} props={{ value: formik.values.project?.[formik.values.division]?._id, onChange: handleProjectChange }} />}</>
            <FieldBase name="invoiceNo" />
            <FieldBase name="deliveryDate" />
            <RelationField
                label="顧客名"
                onClick={handleCustomerClick}
                item={formik.values.customer}
                withButton value={""}
            />
            <CustomerSearch show={showCustomer} setShow={setShowCustomer} formik={formik} />
            <FieldBase name="species" />
            <FieldBase name="usage" />
            <FieldBase name="length" />
            <FieldBase name="diameter" />
            <FieldBase name="timbers" />
            <FieldBase name="unit" />
            <FieldBase name="quantity" onChange={handleItemChange} />
            <FieldBase name="unitPrice" onChange={handleItemChange} />
            <FieldBase name="totalPrice" onChange={handleItemChange} />
            <FieldBase name="taxRate" onChange={handleItemChange} />
            <FieldBase name="amount" />
            <FieldBase name="asDetail" />
            <FieldBase name="invoiceDate" />
            <FieldBase name="receivedDate" />
            <FieldBase name="remarks" />
        </FormFrame>
        {showCreateCustomer && <DialogFrame show={showCreateCustomer} setShow={setShowCreateCustomer} wide={true}><CreateCustomer completed={(id?: String, values?: KV) => handleCreate(id, values)} /></DialogFrame>}
        {showViewCustomer && <DialogFrame show={showViewCustomer} setShow={setShowViewCustomer} wide={true}><ViewCustomer id={formik.values.customer._id} canceled={(e: MouseEvent) => setShowViewCustomer(false)} /></DialogFrame>}
    </div>
}

const validationSchema = Yup.object({
})


const writePrepare = (updates: KV) => {
    return null;
}


export const Create = () => {
    return (<>
        <CreateBase title="材販売情報" values={initialValues} collection="timber" relations={relations} Fields={FormFields} writePrepare={writePrepare} validation={validationSchema} location={""} />
    </>);
};

const mutation = gql`mutation($query:TimberQueryInput!, $set:TimberUpdateInput!){
    updateManyTimbers(query:$query, set:$set) {
        modifiedCount
    }
}`

export const Edit = () => {
    const client = useApolloClient()
    const navigate = useNavigate()
    const completed = async (id?: string, values?: KV, originalValues?: KV) => { // For relation, values come as { link }, originalValues as full graphQL
        if (!values || !originalValues || !values?.invoiceNo) {
            navigate(-1)
            return null
        }
        const updatingValues: KV = {}
        if (values.status !== originalValues.status) updatingValues.status = values.status
        if (!values.invoiceAuthor && originalValues.invoiceAuthor) updatingValues.invoiceAuthor = null
        if (values.invoiceAuthor && values.invoiceAuthor.link !== originalValues.invoiceAuthor?.userID) updatingValues.invoiceAuthor = values.invoiceAuthor
        if (new Date(values.invoiceDate || null).getTime() !== new Date(originalValues.invoiceDate || null).getTime()) updatingValues.invoiceDate = values.invoiceDate ? new Date(values.invoiceDate) : null
        if (new Date(values.receivedDate || null).getTime() !== new Date(originalValues.receivedDate || null).getTime()) updatingValues.receivedDate = values.receivedDate ? new Date(values.receivedDate) : null
        if (Object.keys(updatingValues).length) {
            const query:KV = { _id_ne: values._id, invoiceNo: values.invoiceNo }
            if (updatingValues.invoiceDate) query.invoiceDate_exists = false
            if (updatingValues.receivedDate) query.receivedDate_exists = false
            client.mutate({mutation:mutation, variables:{query:query, set:updatingValues}})
        }
        navigate(-1)
    }
    return (<EditBase title="材販売情報" values={initialValues} collection="timber" relations={relations} Fields={FormFields} writePrepare={writePrepare} validation={validationSchema} completed={completed} />);
};
export default Edit