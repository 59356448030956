// import Holidays from 'date-holidays'

// const hd = new Holidays('JP')
const Locale = 'ja-JP';
const TIME_ZONE = 'Asia/Tokyo';

/** @type {number} JST offset hours */
export const OFFSET_HOURS = 9;
/** @type {number} Offset milliseconds of current timezone */
export const currentOffset = new Date().getTimezoneOffset() * 60000;
/** @type {number} Milliseconds of one day */
export const ONEDAY_MS = 86400000;
/** @type {number} JST offset as positive milliseconds */
export const JA_TIMEZONE_OFFSET = OFFSET_HOURS * 3600000;
/** @type {number} Offset of JST from current as milliseconds */
export const OFFSET = currentOffset + JA_TIMEZONE_OFFSET;

/**
 *
 * @param {string} locale
 * @param {string} timezone
 * @returns
 */
export const isLocaleValid = (locale = Locale, timezone = TIME_ZONE) => {
  if (!Intl || !Intl.DateTimeFormat().resolvedOptions().timeZone) return false;
  try {
    Intl.DateTimeFormat(locale, { timeZone: timezone });
    return true;
  } catch (ex) {
    return false;
  }
};

/**
 * Add offset hours if UTC date and JST date is different(3pm or later).  Works at any timezone.
 * If local is +12h and by 3am local, local and JST is different date.  If local is -12h and after 3am, local and JST is different date. 
 * @param {Date} date Date to add offset.  Any timezone is OK
 * @returns {Date} New date object which offset hours are added to
 */
const UTCtoJSTDate = (date: Date) =>
  date.getUTCHours() >= 24 - OFFSET_HOURS ? new Date(date.getTime() + JA_TIMEZONE_OFFSET) : new Date(date); // If local is +12h, 
const weekdays = ['日', '月', '火', '水', '木', '金', '土', '日'];

/**
 * Retuerns day of week at JST.
 * @param {Date?} date Date to get day of week
 * @returns {number} Day of week as number
 */
export const JSTWeekdayNumber = (date: Date = new Date()) => UTCtoJSTDate(date).getUTCDay();
// export const JSTWeekdayHoliday = (date:Date) => JSTPublicHoliday(date) ? '祝' : JSTWeekday(date)

/**
 * Returns day of week at JST.
 * @param {Date?} date Date to get day of week
 * @returns {string} Day of week as Japanese string
 */
export const JSTWeekday = (date: Date = new Date()) => weekdays[JSTWeekdayNumber(date)];

export const JSTWeekend = (date: Date) => ['土', '日'].includes(JSTWeekday(date));
// export const JSTPublicHoliday = (date:Date) => Boolean((hd.isHoliday(date) || []).find(holiday => holiday.type === 'public'))
// export const isWEPH = (date:Date) => JSTWeekend(date) || JSTPublicHoliday(date)

export const JSTDate = (date: Date) => date.toLocaleDateString(Locale, { timeZone: TIME_ZONE });
export const JSTDateKanji = (date:Date) => date.toLocaleDateString('ja-JP-u-ca-japanese', { timeZone: TIME_ZONE, year: 'numeric', month: 'short', day: 'numeric' })

export const JSTMonthDate = (date: Date, weekday?:boolean) => date.toLocaleDateString(Locale, { timeZone: TIME_ZONE, month: 'numeric', day: 'numeric', ...(weekday ? { weekday: 'short' } : {}) });

export const JSTMonthDateString = (date: Date) => `${date.getMonth() + 1}月${date.getDate()}日`;
export const JSTTime = (date: Date) =>
  date.toLocaleTimeString(Locale, { timeZone: TIME_ZONE, hour: 'numeric', minute: 'numeric' });
export const JSTDateTime = (date: Date) =>
  date.toLocaleString(Locale, {
    timeZone: TIME_ZONE,
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
export const JSTYearMonth = (date: Date) =>
  date.toLocaleDateString(Locale, { timeZone: TIME_ZONE, year: 'numeric', month: 'short' });

export const JSTYear = (date: Date = new Date()) => UTCtoJSTDate(date).getUTCFullYear();
export const JSTMonth = (date: Date = new Date()) => UTCtoJSTDate(date).getUTCMonth() + 1;
export const JSTDay = (date: Date = new Date()) => UTCtoJSTDate(date).getUTCDate();
export const JSTHour = (date: Date = new Date()) => {
  const hours = date.getUTCHours() + OFFSET_HOURS;
  return hours > 24 ? hours - 24 : hours;
};
export const JSTHourMinute = (date: Date = new Date()) => {
  const hours = date.getUTCHours() + OFFSET_HOURS;
  return [hours > 24 ? hours - 24 : hours, date.getMinutes()];
};

// get today with JST hour or JST midnight
export const JSTToday = (hour: number = 0) =>
  new Date(UTCtoJSTDate(new Date()).setUTCHours(hour, 0, 0, 0) - JA_TIMEZONE_OFFSET);

/**
 * Returns new Date object
 * @param {number} year
 * @param {number} month Month between 1-12
 * @param {number} day
 * @param {number?} hour JST hour
 * @param {number?} minute
 * @param {number?} second
 * @returns {Date}
 */
export const JSTNewDate = (
  year: number,
  month: number,
  day: number,
  hour: number = 0,
  minute: number = 0,
  second: number = 0,
) => new Date(Date.UTC(year, month - 1, day, hour - OFFSET_HOURS, minute, second));

export const setJSTHour = (date: Date, hour: number, minute: number = 0) =>
  new Date(UTCtoJSTDate(date).setUTCHours(hour - OFFSET_HOURS, minute, 0, 0));

export const setUTCHour = (date: Date, hour: number, minute: number = 0) =>
  new Date(UTCtoJSTDate(date).setUTCHours(hour, minute, 0, 0));

/**
 * get next sunday JST hour or JST midnight.  For fridays and saturdays, get after next sunday.
 * @param {number?} hour JST hour to set
 * @returns {Date} Next sunday
 */
export const JSTNextSunday = (date: Date | undefined = undefined, hour: number = 0) => {
  const actualDate = date ? setJSTHour(date, 0) : JSTToday();
  const weekday = JSTWeekdayNumber(actualDate);
  return new Date(actualDate.getTime() + 86400000 * ((weekday > 4 ? 14 : 7) - weekday) + 3600000 * hour);
};
// get last sunday(or same day for sundays) JST midnight.  For saturdays, get next sunday(tomorrow).
export const JSTLastSunday = (date: Date | undefined = undefined, hour: number = 0) => {
  const actualDate = date ? setJSTHour(date, 0) : JSTToday();
  const weekday = JSTWeekdayNumber(actualDate);
  return new Date(actualDate.getTime() - 86400000 * (weekday - (weekday > 5 ? 7 : 0)) + 3600000 * hour);
};

export const timesBetween = (time1:string|number|Date, time2:string|number|Date) => {
  const diffMs = !time2 ? Number(time1) : (new Date(time2).getTime() - new Date(time1).getTime());
  const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
  const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
  return `${diffHrs || "0"}:${diffMins > 9 ? diffMins : diffMins ? `0${diffMins}` : "00"}`
}

export const yearOpitons = new Array(JSTYear() - 2021).fill(0).reduce((a, r, i) => ({...a, [(2022+i).toString()]:`${2022+i}年`}), {})
export const halfyearOptions = new Array((JSTYear() - 2021) * 2 + 1).fill(0).reduce((a, r, i) => ({...a, [`${2022+Math.floor(i/2)}年${(i%2)?"下期":"上期"}`]:`${2022+Math.floor(i/2)}年${(i%2)?"下期":"上期"}`}), {})
export const monthOptions = new Array(12).fill(0).reduce((a, r, i) => ({...a, [(i+1).toString()]:`${i+1}月`}), {})
export const dayOptions = new Array(31).fill(0).reduce((a, r, i) => ({...a, [(i+1).toString()]:`${i+1}日`}), {})
export const hourOptions = new Array(24).fill(0).reduce((a, r, i) => ({...a, [i.toString()]:`${i}時`}), {"":"未選択"})
export const minuteOptions = (step:number) => new Array(60/step).fill(0).reduce((a, r, i) => ({...a, [(i*step).toString()]:`${i*step}分`}), {"":"未選択"})


export const yearmonthOpitons = (count:number=12) => {
  let currentYear = JSTYear()
  let currentMonth = JSTMonth()
  return new Array(count).fill(0).map((v, i) => `${currentYear - Math.floor((count-1-i +12-currentMonth)/12)}/${(currentMonth + 11 - (count-1-i)%12)%12 + 1}`).reduce((a, r) => ({...a,[r]:r.replace("/","年") + "月"}), {})
}

const numberOfDaysInclusive = (d0:Date, d1:Date) => {
  return 1 + Math.round((d1.getTime()-d0.getTime())/(86400000));
}

const numberOfWeekends = (d0:Date, d1:Date) => {
    const days = numberOfDaysInclusive(d0, d1); // total number of days
    const sundays = Math.floor((days + (d0.getDay() + 6) % 7) / 7); // number of sundays
    return 2*sundays + (d1.getDay() === 6 ? 1 : 0) - (d0.getDay() === 0 ? 1 : 0); // multiply sundays by 2 to get both sat and sun, +1 if d1 is saturday, -1 if d0 is sunday
}

export const numberOfWeekdays = (d0:Date, d1:Date) => {
    return numberOfDaysInclusive(d0, d1) - numberOfWeekends(d0, d1);
}

export const addWeekdays = (d:Date, weekdays:number) => {
  const weeks = Math.floor(weekdays / 5)
  const days = weekdays % 5
  let date = new Date(d.getTime() + 86400000 * (7 * weeks + days))
  const day = date.getDay()
  if (day === 0) return new Date(date.getTime() + 86400000)
  if (day > 5) return new Date(date.getTime() + 86400000 * 2)
  return date
}
export const timeString = (time:number, zero:string="") => !time ? zero : `${Math.floor(time/3600000)}:${("0" + String(Math.floor((time%3600000)/60000))).slice(-2)}`

export const msToHourMinute = (ms:string|number) => {
  const mss = Number(ms)
  const hours = Math.floor(Math.abs(mss) / 3600000)
  const minutes = Math.abs(mss) % 3600000 / 60000
  return `${(hours && mss < 0) ? "-" : ""}${hours || ""}${hours ? "時間" : ""}${(!hours && mss < 0) ? "-" : ""}${minutes || ""}${minutes ? "分" : ""}`
}

export const getTermDates = (year: any, term: string) => {
  const actualYear = Number(year)
  const index = Number(term[term.length - 1]);
  const actualTerm = term.startsWith("quarter") ? 3 : term.startsWith("semi") ? 6 : term.startsWith("annual") ? 12 : 1
  if (actualTerm === 1) return [new Date(actualYear, Number(term) - 1, 1), new Date(actualYear, Number(term), 1)]
  return [new Date(actualYear, 5 + actualTerm * (index - 1), 1), new Date(actualYear, 5 + actualTerm * index, 1)]
}