import React, { ChangeEvent } from 'react';
import * as Yup from 'yup';
import Decimal from 'decimal.js';
import { Form, Formik, FormikProps } from 'formik';
import DialogFrame from "components/dialog"
import { getSchemaValues, FormFrame, FormButton, FieldBase, getUpdatingData, ItemEditFunction } from 'components/edit'

const validationSchema = Yup.object({
})

// Dialog to input aimItem
const ItemEdit:ItemEditFunction = ({ newItem, showItem, setShowItem, name, parent, schema, formik }) => {
    const fullName = (parent ? parent + "." : "") + name
    const handleChange = (e:ChangeEvent<HTMLInputElement>, itemFormik:FormikProps<any>) => {
        const selfScore = new Decimal((e.target.name === "selfScore" ? e.target.value : itemFormik.values.selfScore) || 0)
        //const firstScore = new Decimal((e.target.name === "firstScore" ? e.target.value : itemFormik.values.firstScore) || 0)
        //const secondScore = new Decimal((e.target.name === "secondScore" ? e.target.value : itemFormik.values.secondScore) || 0)
        const weight = new Decimal((e.target.name === "weight" ? e.target.value : itemFormik.values.weight) || 0)
        itemFormik.values.amount = selfScore.times(weight).floor().toFixed()
        itemFormik.handleChange(e)
    }

    return (<DialogFrame show={Boolean(showItem) || (showItem === 0)} setShow={setShowItem}>
        {(Boolean(showItem) || (showItem === 0)) &&
            <Formik
            initialValues={newItem ? getSchemaValues(schema.properties) : {...formik.values[fullName][showItem || 0]}}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting }) => {
                    let updates = getUpdatingData(values)
//                    writePrepare(updates)
                    formik.setFieldValue(`${fullName}[${showItem}]`, updates)
                    setSubmitting(false);
                    setShowItem(undefined)
                }}
            >
                {itemFormik => {
                    return (<Form>
                        <div className="grid grid-cols-6 gap-6">
                        <FormFrame schema={schema.properties} formik={itemFormik}>
                            <FieldBase name="abstract" />
                            <FieldBase name="detail" />
                            <FieldBase name="selfAssessment" />
                            <FieldBase name="firstComment" />
                            <FieldBase name="secondComment" />
                            <FieldBase name="selfScore" onChange={e => handleChange(e, itemFormik)} />
                            <FieldBase name="firstScore" onChange={e => handleChange(e, itemFormik)} />
                            <FieldBase name="secondScore" onChange={e => handleChange(e, itemFormik)} />
                            <FieldBase name="weight" onChange={e => handleChange(e, itemFormik)} />
                        </FormFrame>
                        </div>
                        <FormButton completed={() => setShowItem(undefined)} />
                    </Form>)
                }}
            </Formik>}
    </DialogFrame>)
}

export default ItemEdit