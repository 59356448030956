import React, { MouseEventHandler } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { XCircleIcon, PencilSquareIcon } from '@heroicons/react/24/solid'
import { useDatum } from 'graphql/useCollection'
import Loading from "components/Loading";
import options from "contexts/options.json"

const customer = require("realm/Yanagisawa/data_sources/mongodb-atlas/Business/customer/schema.json").properties;

const TitleCell = ({ title, children }:{ title:string, children:JSX.Element|JSX.Element[] }) => <tr><td className="text-right bg-theme-200">{title}</td><td>{children}</td></tr>
const title = (key:string) => customer[key].title || customer[key].description?.match(/[^<>]*(?=>)/g)?.[0]
const DataView = ({id, edit, canceled}:{id?:string, edit?:(id?:string) => void, canceled?:MouseEventHandler}) => {
    let onCancel = canceled || (e => { navigate(-1) })
    let { id: idParam } = useParams();
    let actualId = id || idParam
    const navigate = useNavigate();
    const { loading, Data } = useDatum("customer", { _id: actualId })
    console.log(Data)
    return ( loading ? <Loading /> : 
        <div className="relative m-4 flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200 text-left text-sm text-gray-500">
                            <tbody>{Object.keys(Data).filter(key => key !== "__typename").map(key => (<TitleCell key={key} title={title(key)}>{
                                ((key === "type") && (<>{`薪販売：${options.customerType[Data.type.firewood]}　樹木：${options.customerType[Data.type.tree]}　山林：${options.customerType[Data.type.forest]}　素材卸：${options.customerType[Data.type.timber]}`}</>)) ||
                                ((key === "name") && (<><div>{`${Data.name?.surnameKana || ""} ${Data.name?.givenNameKana || ""}`}</div><div>{`${Data.name?.surname || ""} ${Data.name?.givenName || ""}`}</div></>)) ||
                                ((key === "address") && (<>{`〒${Data.address?.zip || ""} ${options.prefecture[Data.address?.state]}${Data.address?.citystr || ""} ${Data.address?.room || ""}`}</>)) || 
                                ((key === "invoiceAddress") && (<>{`〒${Data.invoiceAddress?.zip || ""} ${(Data.invoiceAddress?.state && options.prefecture[Data.invoiceAddress?.state]) || ""}${Data.invoiceAddress?.citystr || ""} ${Data.invoiceAddress?.room || ""}`}</>)) ||
                                ((key === "dealType") && (<>{options.category[Data[key]]}</>)) ||
                                (Data[key] || "")
                            }</TitleCell>))}</tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="px-4 bg-gray-50 text-right sm:px-6">
                <button
                    className="inline-flex justify-center m-3 py-2 px-4 w-28 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-theme-600 hover:bg-theme-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-500"
                    onClick={e => edit ? edit(actualId) : navigate(`/customer/edit/${actualId}`)}
                >
                    <PencilSquareIcon className="w-5 h-5" />編集
                </button>
            <button
                className="inline-flex justify-center m-3 py-2 px-4 w-28 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-theme-600 hover:bg-theme-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-500"
                onClick={onCancel}
            >
                <XCircleIcon className="w-5 h-5" />
                戻る
            </button>
            </div>
        </div>
    )
}

export default DataView