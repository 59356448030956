import React, { ChangeEvent } from 'react';
import * as Yup from 'yup';
import Decimal from 'decimal.js';
import { Form, Formik, FormikProps } from 'formik';
import DialogFrame from "components/dialog"
import { getSchemaValues, FormFrame, FormButton, FieldBase, RelationField, getUpdatingData, ItemEditFunction } from 'components/edit'
import ProductSearch from 'pages/firewood/productSearch'

const validationSchema = Yup.object({
})

// Dialog to input firewoodItem
const ItemEdit:ItemEditFunction = ({ newItem, showItem, setShowItem, name, parent, schema, formik }) => {
    const [showProduct, setShowProduct] = React.useState(true)
    const fullName = (parent ? parent + "." : "") + name
    const existing = formik.values.customer?.type?.firewood === 'existing'
    const handleChange = (e:ChangeEvent<HTMLInputElement>, itemFormik:FormikProps<any>) => {
        const quantity = new Decimal((e.target.name === "quantity" ? e.target.value : itemFormik.values.quantity) || 0)
        const unitPrice = new Decimal((e.target.name === "unitPrice" ? e.target.value : itemFormik.values.unitPrice) || 0)
        const deliveryFare = new Decimal((e.target.name === "deliveryFare" ? e.target.value : itemFormik.values.deliveryFare) || 0)
        const discount = new Decimal((e.target.name === "discount" ? e.target.value : itemFormik.values.discount) || 0)
        itemFormik.values.amount = quantity.times(unitPrice).plus(deliveryFare).minus(discount).floor().toFixed()
        itemFormik.handleChange(e)
    }

    return (<DialogFrame show={Boolean(showItem) || (showItem === 0)} setShow={setShowItem}>
        {(Boolean(showItem) || (showItem === 0)) &&
            <Formik
            initialValues={newItem ? getSchemaValues(schema.properties) : {...formik.values[fullName][showItem || 0]}}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting }) => {
                    let updates = getUpdatingData(values)
//                    writePrepare(updates)
                    formik.setFieldValue(`${fullName}[${showItem}]`, updates)
                    setSubmitting(false);
                    setShowItem(undefined)
                }}
            >
                {itemFormik => {
                    return (<Form>
                        <div className="grid grid-cols-6 gap-6">
                        <FormFrame schema={schema.properties} formik={itemFormik}>
                            <RelationField
                                label="商品"
                                onClick={e => setShowProduct(true)}
                                value={`${itemFormik.values.type} ${itemFormik.values.species} ${itemFormik.values.name || ""}`}
                                span={6}
                            />
                            <FieldBase name="quantity" onChange={e => handleChange(e, itemFormik)} />
                            <FieldBase name="length" />
                            <FieldBase name="unit" />
                            <FieldBase name="unitPrice" onChange={e => handleChange(e, itemFormik)} />
                            <FieldBase name="deliveryFare" onChange={e => handleChange(e, itemFormik)} />
                            <FieldBase name="discount" onChange={e => handleChange(e, itemFormik)} />
                            <FieldBase name="amount" />
                            <FieldBase name="deliveryDate" />
                        </FormFrame>
                        </div>
                        <FormButton completed={() => setShowItem(undefined)} />
                        <ProductSearch show={showProduct} setShow={setShowProduct} formik={itemFormik} existing={existing}/>
                    </Form>)
                }}
            </Formik>}
    </DialogFrame>)
}

export default ItemEdit