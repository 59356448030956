import React from 'react';
import * as Yup  from 'yup';
import yupLocale from "contexts/yupLocale"

import { getSchemaValues, CreateBase, EditBase, FormFrame, FieldBase } from 'components/edit'
import { FormikProps } from 'formik';

const schema = require("realm/Yanagisawa/data_sources/mongodb-atlas/Business/firewoodProduct/schema.json").properties;

Yup.setLocale(yupLocale)
const keys = ["_id","species","type", "name", "length", "price","discountPrice","unit", "active", "remarks"]

const FormFields = ({ formik }:{ formik:FormikProps<any> }) => (
    <div className="grid grid-cols-6 gap-4">
        <FormFrame formik={formik} schema={schema}>
            { keys.map(key=> (
                <FieldBase key={key} name={key} />
            ))}
        </FormFrame>
    </div>
)

const validationSchema = Yup.object({
    _id: Yup.string().required(),
    price: Yup.number().required().positive().integer(),
})


export const Create = () => {
    return (<CreateBase title={"薪商品情報"} collection="firewoodProduct" values={getSchemaValues(schema)} Fields={FormFields} validation={validationSchema} />);
};

export const Edit = () => {
    return (<EditBase title={"薪商品情報"} collection="firewoodProduct" Fields={FormFields} validation={validationSchema} />);
};
export default Edit