import Loading from "components/Loading";
import { FormikProps } from "formik";
import { useData } from "graphql/useCollection"
import React from "react"
import { useParams } from "react-router-dom";
import { formatNumber } from "utils";

const humanCostCode = 101;

const NameItem = ({children, className}:{children:any, className?:string}) => {
    return <div className={`text-left pl-1 bg-white ${className || ""}`}>{children}</div>
}
const NumItem = ({children, className}:{children:any, className?:string}) => {
    return <div className={`text-right pr-1 bg-white ${className || ""}`}>{formatNumber(children)}</div>
}


const oilOptions:KV = {
    "mix":"混合油",
    "chain":"チェーンオイル",
    "heavychain":"チェーンオイル",
    "diesel":"免税軽油(L単価)"
    //その他軽油
    //その他ガソリン
}

const Balance = ({formik, costItem}:{formik:FormikProps<any>, costItem:KV[]}) => {
    let { id } = useParams<KV>();
    const { loading: machineLoading, Data: machineData } = useData("machine", null, { project: { forest: {_id: id } } })
    const { loading: timberLoading, Data: timberData } = useData("timber", null, { project: { forest: {_id: id } } })
    const { loading: transportLoading, Data: transportData } = useData("transport", null, { project: { forest: {_id: id } } })
    const { loading: humanLoading, Data: humanData } = useData("attendance", null, { item: { project: { forest: { _id: id } } } })
    if (machineLoading || timberLoading || transportLoading || humanLoading) return <Loading />
    /* { [machineName]:{unit:0.5(unit), distance:km}, [oilType]:Liter }   oiltype mix, chain, heavychain, diesel */
    const getMachineCost = (name:string) => !name ? 0 : (costItem.find(v => v.name === name)?.unitPrice || costItem.find(v => v.name === "高性能林業機械")?.unitPrice || 0)
    const getOilCost = (name:string) => !name ? 0 : (costItem.find(v => v.name === name)?.unitPrice || costItem.find(v => v.name === "免税軽油(L単価)")?.unitPrice || 0)
    const machineSummary = !machineData ? {} : machineData.reduce((a, r) => ({ ...a, [r.category]: { 
        cost:Number(a[r.category]?.cost || 0) + Number(r.unit || 0) * getMachineCost(r.machine),
        //distance:Number(a[r.category]?.distance || 0) + Number(r.distance || 0),
        oilCost:Number(a[r.category]?.oilCost || 0) + Number(r.oil1 || 0) * getOilCost(oilOptions[r.oilType1]) + Number(r.oil2 || 0) * getOilCost(oilOptions[r.oilType1])
    }}), {})
    /* { [truckType]:{trucks:0.5(unit), distance:km}, toll:Yen, totalCost:Yen } */
    const humanUnitPrice = costItem.find(v => v._id === humanCostCode)?.unitPrice || 0
    const transportSummary = !transportData ? {} : transportData.reduce((a, r) => ({...a, ...(r.outsource ? ({
        outsource: a.outsource + Number(r.totalCost || 0)
    }) : ({
        human: a.human + Number(r.human || 0) * humanUnitPrice,
        machine: a.machine + Number(r.unitPrice || 0) * Number(r.distance || 0),
        toll: a.toll + Number(r.toll || 0),
    }))}), { human:0, machine:0, toll:0, outsource:0})
/* { [truckType]:{quantity:unit, totalPrice:Yen } */ 
    const timberSummary = !timberData ? {} : timberData.reduce((a, r) => ({
        quantity:Number(a.quantity || 0) + Number(r.quantity || 0), 
        totalPrice:Number(a.totalPrice || 0) + Number(r.totalPrice || 0)
    }), {})
    /* { [workCategory]:{ [workItem]:0.5(day) } } */
    const humanSummary = !humanData ? {} : humanData.reduce((a, r) => {
        let aNew = a;
        r.item.forEach((eachItem:KV) => {
            const category = eachItem.work?.category
            //const item = eachItem.work?.item
            const unit = eachItem.halfday ? 0.5 : 1.0
            aNew = aNew[category] ? 
             {...aNew, [category]:(aNew[category] || 0) + unit * humanUnitPrice }
             : {...aNew, [category]:unit * humanUnitPrice }
        })
        return aNew;
    }, {})
    console.log(machineSummary)
    console.log(transportSummary)
    console.log(timberSummary)
    let summary = ({
        収入の部合計: formik.values.mainPlan.subsidy + formik.values.roadPlan.subsidy + timberSummary.totalPrice,
        主作業合計: (humanSummary.主作業 || 0) + (machineSummary.主作業?.cost || 0) + (machineSummary.主作業?.oilCost || 0),
        作業道合計: (humanSummary.作業道 || 0) + (machineSummary.作業道?.cost || 0) + (machineSummary.作業道?.oilCost || 0),
        運材合計: transportSummary.human + transportSummary.machine + transportSummary.toll + transportSummary.outsource,
        共通仮設合計: humanSummary.共通仮設 || 0,
        支出の部合計: 0,
        収支合計: 0
    })
    summary.支出の部合計 = (summary.主作業合計 || 0) + (summary.作業道合計 || 0) + (summary.運材合計 || 0) + (humanSummary.共通仮設 || 0)
    summary.収支合計 = summary.収入の部合計 - summary.支出の部合計
    return <><div className="m-4 border border-gray-500 grid grid-cols-3 bg-gray-500 gap-px">
        <div className="text-center bg-theme-200 col-span-2">項目</div>
        <div className="text-center bg-theme-200">金額</div>
        <div className="text-center bg-theme-100 col-span-3">収入の部</div>
        <NameItem className="row-span-2">造林補助金</NameItem>
        <NameItem>主作業合計</NameItem><NumItem>{formik.values.mainPlan.subsidy}</NumItem>
        <NameItem>作業道合計</NameItem><NumItem>{formik.values.roadPlan.subsidy}</NumItem>
        <NameItem className="col-span-2">造林補助金合計</NameItem><NumItem>{formik.values.mainPlan.subsidy + formik.values.roadPlan.subsidy}</NumItem>
        <NameItem className="col-span-2">材販売</NameItem><NumItem>{timberSummary.totalPrice}</NumItem>
        <NameItem className="col-span-2">収入の部合計</NameItem><NumItem>{summary.収入の部合計}</NumItem>
        <div className="text-center bg-theme-100 col-span-3">支出の部（事業費内訳）</div>
        <NameItem className="row-span-5">主作業</NameItem>
        <NameItem>人件費</NameItem><NumItem>{humanSummary.主作業 || "0"}</NumItem>
        <NameItem>機械経費</NameItem><NumItem>{machineSummary.主作業?.cost || "0"}</NumItem>
        <NameItem>燃料費</NameItem><NumItem>{machineSummary.主作業?.oilCost || "0"}</NumItem>
        <NameItem>外注費</NameItem><NumItem>0</NumItem>
        <NameItem>その他経費</NameItem><NumItem>0</NumItem>
        <NameItem className="col-span-2">主作業合計</NameItem><NumItem>{summary.主作業合計 || "0"}</NumItem>
        <NameItem className="row-span-5">作業道</NameItem>
        <NameItem>人件費</NameItem><NumItem>{humanSummary.作業道 || "0"}</NumItem>
        <NameItem>機械経費</NameItem><NumItem>{machineSummary.作業道?.cost || "0"}</NumItem>
        <NameItem>賃借料(ﾊﾞｯｸﾎｰ)</NameItem><NumItem>0</NumItem>
        <NameItem>燃料費</NameItem><NumItem>{machineSummary.作業道?.oilCost || "0"}</NumItem>
        <NameItem>外注費</NameItem><NumItem>0</NumItem>
        <NameItem className="col-span-2">作業道合計</NameItem><NumItem>{summary.作業道合計 || "0"}</NumItem>
        <NameItem className="row-span-3">運材</NameItem>
        <NameItem>人件費</NameItem><NumItem>{transportSummary.human || "0"}</NumItem>
        <NameItem>機械経費・燃料費</NameItem><NumItem>{transportSummary.machine || "0"}</NumItem>
        <NameItem>外注費</NameItem><NumItem>{transportSummary.outsource || "0"}</NumItem>
        <NameItem className="col-span-2">運材合計</NameItem><NumItem>{summary.運材合計 || "0"}</NumItem>
        <NameItem>共通仮設</NameItem>
        <NameItem>人件費</NameItem><NumItem>{humanSummary.共通仮設 || "0"}</NumItem>
        <NameItem className="col-span-2">共通仮設合計</NameItem><NumItem>{summary.共通仮設合計 || "0"}</NumItem>
        <NameItem className="col-span-2">支出の部（事業費）合計</NameItem><NumItem>{summary.支出の部合計 || "0"}</NumItem>
        <NameItem className="col-span-2">収支合計（粗利益）</NameItem><NumItem>{summary.収支合計 || "0"}</NumItem>
        <NameItem className="col-span-2">粗利率</NameItem><NumItem>{summary.収支合計 * 100 / summary.収入の部合計}</NumItem>
        
    </div>
    </>
}

export default Balance