import React from "react";
import { DataList, EditLinkTd, RowFunction } from "components/list"
import options from "contexts/options.json"

const schema = require("realm/Yanagisawa/data_sources/mongodb-atlas/Business/firewoodProduct/schema.json").properties;
const location = "/firewoodproduct"

const getTitle = (obj:KV) => obj.description?.match(/[^<>]*(?=>)/g) || obj.title || ""
const keys = ["_id", "species", "type", "name", "length", "price", "discountPrice", "unit", "active", "remarks"]

const heads = keys.map(key => ({ text:getTitle(schema[key]), field: key }))
const types = keys.map(key => schema[key].description?.replace(/\([\s\S]*?\)/g, '').replace(/\{[\s\S]*?\}/g, '').replace(/<[\s\S]*?>/g, '').split(":") || ['text'])

const Row:RowFunction = ({ datum, clickDelete }) => <>
    {
        keys.map((key, i) => (<td key={key+i}>
            <div>{types[i][0] === "select" ? options[types[i][1]][datum[key]] : types[i][0] === "check" ?  (datum[key] ? "有効" : "") : datum[key]}</div>
        </td>
        ))
    }
    <EditLinkTd location={location} id={datum._id} onClickDelete={clickDelete} />
</>

const List = () => <DataList collection="firewoodProduct" location={location} heads={heads} row={Row} />


export default List
