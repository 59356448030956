import React from "react"
import { DataList, EditLinkTd, RowFunction } from "components/list"
import options from "contexts/options.json"
import { RealmAppContextInterface, useRealmApp } from "RealmApp"
const schema = require("realm/Yanagisawa/data_sources/mongodb-atlas/Business/user/schema.json").properties;
const location = "/user"
const getTitle = (obj:KV) => obj.description?.match(/[^<>]*(?=>)/g) || obj.title || ""
const keys = ["userID", "surname", "givenName", "division", "division2", "division3", "tel", "email", "status", "supervisor", "aimSupervisor"]

const heads = keys.map<KV>(key => ({ text: getTitle(schema[key]), field: ["supervisor", "aimSupervisor"].includes(key) ? `${key}.surname` : key })).concat([{}])
const types = keys.map(key => schema[key].description?.replace(/\([\s\S]*?\)/g, '').replace(/\{[\s\S]*?\}/g, '').replace(/<[\s\S]*?>/g, '').split(":") || ['text'])

const isEditable = (app:RealmAppContextInterface, datum:KV) => app.currentUser?.id === datum._id || app.currentUser?.customData.status === "admin"

const Row:RowFunction = ({ datum, clickDelete }) =>  {
    const app = useRealmApp()
    return <>
    {
        keys.map((key, i) => (<td key={key + i}>
            <div>{types[i][0] === "select" ? options[types[i][1]][datum[key]] : ["supervisor", "aimSupervisor"].includes(key) ? `${datum[key]?.surname || ""}　${datum[key]?.givenName || ""}` : datum[key]}</div>
        </td>
        ))
    }
    {isEditable(app, datum) ? <EditLinkTd location={location} id={datum._id} onClickDelete={clickDelete} /> : <div></div> }
    </>
}

const List = () => <DataList collection="user" location={location} heads={heads} row={Row} />


export default List
