import React, { ReactNode } from "react";
import * as Realm from "realm-web";
import SignIn from 'components/signin'
import ChangePassword from 'components/changePassword'

export interface RealmAppContextInterface {
  app: Realm.App,
  currentUser: Realm.User | null,
  logIn: ((credentials: Realm.Credentials) => Promise<void>),
  logOut:() => Promise<void>
}

const RealmAppContext = React.createContext<RealmAppContextInterface>({} as RealmAppContextInterface);

export const useRealmApp = () => {
  const appContext = React.useContext(RealmAppContext);
  if (!appContext) {
    throw new Error(
      `You must call useRealmApp() inside of a <RealmAppProvider />`
    );
  }
  return appContext;
};

export const RequireLoggedInUser = ({ children }: {children: JSX.Element}): JSX.Element => {
  const realmAppContext = useRealmApp();
  const search = new URLSearchParams(window.location.search);
  const token = search.get('token')
  const tokenId = search.get('tokenId')
  return (realmAppContext.currentUser ? children :
    ((token && tokenId) ? <ChangePassword reset={async (password: string) => { await realmAppContext.app.emailPasswordAuth.resetPassword({ token:token, tokenId:tokenId, password:password })}} /> : 
    <SignIn 
      signin={(email:string, password:string) => realmAppContext.logIn(Realm.Credentials.emailPassword(email, password))}
      reset={async (email:string) => await realmAppContext.app.emailPasswordAuth.sendResetPasswordEmail({ email:email })}
    />)
  )
};

export const RealmAppProvider = ({ appId, children }: {appId: string, children: ReactNode}) => {
  const firstRun = React.useRef<boolean>(true);
  const [app, setApp] = React.useState<Realm.App>(new Realm.App(appId));
  React.useEffect(() => { 
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    setApp(new Realm.App(appId));
  }, [appId]);
  const [currentUser, setCurrentUser] = React.useState(app.currentUser);
  const logIn = async (credentials: Realm.Credentials) => {
    try {
      await app.logIn(credentials);
    } catch (e) {
      alert("エラー：メールアドレスまたはパスワードが違います")
      return
    }
    setCurrentUser(app.currentUser);
  }
  const logOut = async () => {
    await app.currentUser?.logOut();
    setCurrentUser(app.currentUser);
  }

  const realmAppContext = { app, currentUser, logIn, logOut };
  
  return (
    <RealmAppContext.Provider value={realmAppContext}>
      {children}
    </RealmAppContext.Provider>
  );
};
