import React, { ChangeEvent, MouseEvent, useState } from "react"

import { FieldSelect } from "components/uiparts"
import { useData, useLazyData } from "graphql/useCollection"
import { useRealmApp } from "RealmApp"
import { JSTNewDate, yearmonthOpitons } from "contexts/dateUtils"
import { ButtonClass } from "utils"
import { makeReport } from "./sheets/report"
import Loading from "components/Loading"
import { isAdmin } from "contexts/utils"
import { useHref } from "react-router-dom"
import { makePdf } from "contexts/pdfUtils"
import getTimesheetPdfData from "./sheets/timesheet"

const Sheet = () => {
    const app = useRealmApp()
    const yearmonthSelection = yearmonthOpitons()
    const [userID, setUserID] = useState(app.currentUser?.customData.userID || "")
    const [yearMonth, setYearMonth] = useState(Object.keys(yearmonthSelection).slice(-1)[0])
    const { loading: userLoading, Data: userData } = useData('user', null, undefined, 'USERID_ASC')
    const baseUrl = window.location.origin+useHref('/')
    //, { OR:[{ _id: app.currentUser?.id || "" }, { supervisor: { _id: app.currentUser?.id || "" } }] })
    const completed = (data:any) => {
        const user = userData?.find(v => v.userID === userID)
        try {
            makePdf(getTimesheetPdfData({data:data.attendanceViews, yearmonth:yearMonth, username:`${user?.surname || ""}　${user?.givenName || ""}`, userstatus:user?.status}), baseUrl)
        } catch (e:any) {
            if (e?.message) alert(e.message)
        }
    }
    const completedforReport = (data:any) => {
        makeReport(data.attendanceViews, userData || [], yearMonth)
    }
    const { getData, loading:attendanceLoading } = useLazyData('attendance', "view", { onCompleted: completed })
    const { getData:getDataforReport, loading:attendanceLoadingforReport } = useLazyData('attendance', "view", { onCompleted: completedforReport })

    const handleClick = (e:MouseEvent<HTMLButtonElement>) => {
        const [year, month] = yearMonth.split('/') 
        const dateMin = JSTNewDate(Number(year), Number(month), 1)
        const dateMax = JSTNewDate(Number(year), Number(month) + 1, 1)
        e.currentTarget.name === "sheet" ? 
            getData({ variables: { query: { user: { userID: userID }, start_gte: dateMin, start_lt: dateMax}, limit:100}})
             : getDataforReport({ variables: { query: { start_gte: dateMin, start_lt: dateMax}, limit:10000}})
    }
    
    return <div className="flex justify-center p-2">
        {(userLoading || attendanceLoading || attendanceLoadingforReport) && <Loading modal/>}
        <div className="p-2 grid grid-cols-2 max-w-sm">
            <FieldSelect label="対象者" name="" span={2} options={
                (isAdmin(app) ? (userData || []).filter(v => (!["1001", "2002"].includes(v.userID) || ["1001", "2002"].includes(app.currentUser?.customData.userID as string || ""))) : (userData || []).filter(v => [v.userID, v.supervisor?.userID || ""].includes(app.currentUser?.customData.userID || "")))
                .reduce((a, r) => ({...a, [r.userID]:`${r.surname}　${r.givenName}`}), {})
                } 
                props={{value: userID, onChange:(e:ChangeEvent<HTMLSelectElement>) => { setUserID(e.currentTarget.value) }}}
            />
            <FieldSelect label="対象月" name="" span={2} options={yearmonthSelection} props={{value:yearMonth, onChange:(e:any) => { setYearMonth(e.currentTarget.value) }}}></FieldSelect>
            <button className={ButtonClass} name='sheet' onClick={handleClick}>出勤簿</button>
            <button className={ButtonClass} name='report'onClick={handleClick}>勤務表</button>
        </div>
    </div>
}

export default Sheet