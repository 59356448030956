import React, { MouseEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSummaryData } from "graphql/useCollection";
import { formatCurrency } from 'utils'
import { baseFilter as firewoodFilter } from 'pages/firewood/list'
import { baseFilter as treeFilter } from 'pages/tree/list'
import Loading from "./Loading";
import options from "contexts/options.json"
import { useAppContext } from 'contexts/AppContext';

const baseFilter = {
    firewood: firewoodFilter,
    tree: treeFilter,
}

const DashItem = () => {
    const context = useAppContext()
    const navigate = useNavigate()
    const { loading: loadingFirewood, Data: firewood } =  useSummaryData({ collection: "firewood", status:"active", amountField:"total"})
    const { loading: loadingTree, Data: tree } = useSummaryData({ collection: "tree", status: "active", amountField: "dealAmount" })
    if (loadingFirewood || loadingTree) return <Loading />
    const business = [{ key: "firewood", title: "薪販売事業", statusName: "status", data: firewood }, { key: "tree", title: "樹木事業", statusName: "treeStatus", data: tree}]
    const handleClick = (e:MouseEvent<HTMLElement>) => {
        const [collection, status] = e.currentTarget.dataset.name?.split(".") || [undefined, undefined]
        if (!collection) return
        context.setState(`${collection}.filter`, {  ...(context.state[collection]?.filter || baseFilter[collection as "firewood"|"tree"]), status: status })
        navigate(`/${collection}/`)
    }
    return (<div className="container flex mx-auto w-full items-start justify-center">
            {business.map(item => <div key={item.title} className="px-4 py-5 sm:px-6 border dark:bg-gray-800 bg-white shadow m-2 rounded-md">
                <h3 className="shadow border text-lg text-center leading-6 font-medium text-theme-900 dark:text-white bg-theme-100 px-4 py-2 my-1">
                    {item.title}
                </h3>
                <ul className="flex flex-col items-center justify-center">
                    {Object.keys(options[item.statusName] || []).filter(key => !["paid", "cancel"].includes(key)).map(key => <li key={key} className="border-gray-400 flex flex-row my-1">
                        <div data-name={`${item.key}.${key}`} onClick={handleClick} className="shadow border cursor-pointer bg-white dark:bg-gray-800 rounded-md grid grid-cols-3 items-center px-4 py-2">
                            <div className="w-32">
                                <div className="px-4 py-2 text-center rounded-full text-white  bg-theme-600 ">
                                    {options[item.statusName][key]}
                                </div>
                            </div>
                            <div className="px-4 text-right text-gray-600 dark:text-gray-200">
                                {`${item.data.find(datum => datum._id === key)?.count || 0}件`}
                            </div>
                            <div className="px-4 text-right text-gray-600 dark:text-gray-200">
                                {formatCurrency(item.data.find(datum => datum._id === key)?.amount || 0)}
                            </div>
                        </div>
                    </li>)}
                </ul>
            </div>
            )}
    </div>)
}

export default DashItem
