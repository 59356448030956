import React from 'react';
import * as Yup from 'yup';

import yupLocale from "contexts/yupLocale"
import { getSchemaValues, CreateBase, EditBase, FormFrame, FieldBase } from 'components/edit'
import { FormikProps } from 'formik';

const schema = require("realm/Yanagisawa/data_sources/mongodb-atlas/Business/treeItem/schema.json").properties;

Yup.setLocale(yupLocale)
const keys = ["_id", "category1", "category2", "name", "noName", "unit", "unitPrice", "listPrice", "costType"]

const FormFields = ({ formik }:{ formik:FormikProps<any> }) => (
    <div className="grid grid-cols-6 gap-4">
        <FormFrame formik={formik} schema={schema}>
            {keys.map(key => (
                <FieldBase key={key} name={key} />
            ))}
        </FormFrame>
    </div>
)

const validationSchema = Yup.object({
    _id: Yup.number().required(),
    category1: Yup.string().required(),
})

export const Create = () => {
    return (<CreateBase title={"原価情報"} values={getSchemaValues(schema)} collection="treeItem" Fields={FormFields} validation={validationSchema} />);
};

export const Edit = () => {
    return (<EditBase title={"原価情報"} values={getSchemaValues(schema)} collection="treeItem" Fields={FormFields} validation={validationSchema} />);
};
export default Edit