import { JSTDateKanji, JSTDay, JSTTime, JSTWeekday, timeString } from 'contexts/dateUtils';
import { formatCurrency } from 'utils';
import { defaultStyle, pageStyle } from 'contexts/pdfUtils';

const columnTitleText = (text:string) => ({text:`${text}\n`, fontSize: 6, alignment: 'left'})

/**
 *
 *
 * @param {KV[]} data
 * @return {*} 
 */
const getTimesheetPdfData = (params:KV):KV => {
    const {data, yearmonth, username, userstatus} = params
    const workData = data.reduce((a:KV, r:KV) => ({ 
        work: a.work + (r.end ? (new Date(r.end).getTime() - new Date(r.start).getTime() - (r.rest || 0) - (r.nightRest || 0) - (r.over || 0)) : 0),
        over: a.over + r.over,
        night: a.night + r.night,
        holidayWork: a.holidayWork + r.holidayWork,
        car: a.car + r.car,
        paid: a.paid + r.item.reduce((a1:number, r1:KV) => a1 + (r1.holiday === "有休" ? (r.item.length === 1 ? 1 : 0.5) : 0), 0),
        alternate: a.alternate + r.item.reduce((a1:number, r1:KV) => a1 + (r1.holiday === "振休" ? (r.item.length === 1 ? 1 : 0.5) : 0), 0),
        absent: a.absent + r.item.reduce((a1:number, r1:KV) => a1 + (r1.holiday === "欠勤" ? (r.item.length === 1 ? 1 : 0.5) : 0), 0)
    }), { work: 0, over: 0, night: 0, holidayWork: 0, car:0, paid:0, alternate:0, absent:0 })
    const [year, month] = yearmonth.split('/')

    return {
        pageSize: 'A4',
        pageMargins: pageStyle.pageMargins,
        info: { title: '勤務表' },
        content: [
            {
                columns: [//height 
                    { width: 'auto', stack: [
                        { text: '  月 次 出 勤 簿  ', background: 'green', color: 'white', alignment: 'left', margin: [10, 0], fontSize: pageStyle.fontSizes.title },
                        { text: `${year}年${month}月度`, alignment: "left", margin: [10, 20], fontSize: pageStyle.fontSizes.subtitle },
                    ]},
                    { width: '*', text: '' },
                    { width: 'auto', alignment: "right", stack: [
                        { width: 'auto', text: data.invoiceDate && JSTDateKanji(new Date(data.invoiceDate)) },
                        '　',
                        {
                            width: 'auto',
                            table: {
                         
                                widths: [36,36,36,36],
                                heights: ['auto',36],
                                body: [
                                    ["申請者", "班長級", "部長", "労務"],
                                    ["", "", "", ""]
                                ]
                            },
                            fontSize: 6,
                            layout: {
                                hLineStyle: () => ({ dash: { length: 1 } }),
                                vLineStyle: () => ({ dash: { length: 1 } }),
                                paddingLeft: () => 0,
                                paddingRight: () => 0,
                                paddingTop: () => 0,
                                paddingBottom: () => 0,
                            },
                            alignment: "center"
                        },
                        '　',
                        { width: 200, text: `氏名: ${username}`, fontSize: pageStyle.fontSizes.subtitle }

                    ]}
                ]
            },
            ' ',
            {
                table: {
                    headerRows: 1,
                    widths: [20, 20, 50, 20, 25, 25, 25, 25, 25, 25, 25, 25, 25, 60],

                    body: [["日","曜日","出社先","走行距離","始業時刻","終業時刻","労働時間","残業時間","深夜労働","休日労働時間","有休申請","振休等申請","欠勤申請","承認者"].map<KV>(v => ({ text: v, fontSize: 7 }))].concat(
                        new Array(new Date(year, month, 0).getDate()).fill(0).map((v, i) => {
                            const filteredData = data.filter((dayData:KV) => JSTDay(new Date(dayData.start)) === (i+1))
                            if (filteredData.length > 1) throw new Error("同一日に重複データがあります")
                            const datum = filteredData[0]
                            return datum ? [
                                JSTDay(new Date(datum.start)),
                                JSTWeekday(new Date(datum.start)),
                                { text: datum.workplace, maxHeight: defaultStyle.fontSize + pageStyle.cellPadding * 2 },//(datum.item[0].place || "") + (datum.item[1]?.place ? `/${datum.item[1].place}` : ""),
                                datum.car || "",
                                (datum.allday && datum.item[0]?.holiday) ? '' : JSTTime(new Date(datum.start)),
                                (datum.allday && datum.item[0]?.holiday) ? '' : JSTTime(new Date(datum.end)),
                                (datum.allday && datum.item[0]?.holiday) ? '' : timeString(new Date(datum.end).getTime() - new Date(datum.start).getTime() - (datum.rest || 0) - (datum.nightRest || 0) - (datum.over || 0), "0:00"),
                                (datum.allday && datum.item[0]?.holiday) ? '' : timeString(datum.over),
                                (datum.allday && datum.item[0]?.holiday) ? '' : timeString(datum.night),
                                (datum.allday && datum.item[0]?.holiday) ? '' : timeString(datum.holidayWork),
                                datum.item.reduce((a:number, r:KV) => a + (r.holiday === "有休" ? (datum.item.length === 1 ? 1.0 : 0.5) : 0), 0) || "",
                                datum.item.reduce((a:number, r:KV) => a + (r.holiday === "振休" ? (datum.item.length === 1 ? 1.0 : 0.5) : 0), 0) || "",
                                datum.item.reduce((a:number, r:KV) => a + (r.holiday === "欠勤" ? (datum.item.length === 1 ? 1.0 : 0.5) : 0), 0) || "",
                                !datum.user?.supervisor ? "" : datum.status !== 'OK' ? "未承認" : datum.user.supervisorName
                            ] : [
                                i+1,
                                JSTWeekday(new Date(year, month-1, i+1)),
                                '', '', '', '', '', '', '', '', '', '', '', '',
                            ]
                        })).concat([
                            [
                                { text: '走行距離合計→', colSpan: 3, alignment: 'right' }, '', '',
                                { text: `${workData.car}km`, colSpan: 2, alignment: 'right' }, '',
                                { text: [columnTitleText('労働時間合計'), '\n', timeString(workData.work, "-")], rowSpan: 2, colSpan: 2, alignment: 'right' }, '',
                                { text: [columnTitleText('残業時間'), '\n', timeString(workData.over, "-")], rowSpan: 2, alignment: 'right' },
                                { text: [columnTitleText('深夜労働'), '\n', timeString(workData.night, "-")], rowSpan: 2, alignment: 'right' },
                                { text: [columnTitleText('休日労働'), '\n', timeString(workData.holidayWork, "-")], rowSpan: 2, alignment: 'right' },
                                { text: [columnTitleText('有休'), '\n', `${workData.paid || '-'}日`], rowSpan: 2, alignment: 'right' },
                                { text: [columnTitleText('振休等'), '\n', `${workData.alternate || '-'}日`], rowSpan: 2, alignment: 'right' },
                                { text: [columnTitleText('欠勤'), '\n', `${workData.absent || '-'}日`], rowSpan: 2, alignment: 'right' },
                                { text: '', rowSpan: 2 },
                            ],
                            [   
                                { text: `単価　　¥${userstatus === "temporary" ? 11 : 15}/km`, colSpan: 3, alignment: 'center', margin: [0, 5, 0, 0] },  '', '',
                                { text: [columnTitleText('交通費精算合計'), formatCurrency(workData.car*(userstatus === "temporary" ? 11 : 15))], colSpan: 2, alignment: 'right' }, '',
                                '', '', '', '', '', '', '', '', ''
                            ],
                        ]),
                }
            },
        ],
        defaultStyle: defaultStyle
    }
}

export default getTimesheetPdfData
