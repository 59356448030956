import React, {
    Fragment,
    useState,
    useEffect,
    ChangeEvent,
    MouseEvent,
    useRef,
} from "react";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import {
    ChevronUpIcon,
    ChevronDownIcon,
    PlusIcon,
} from "@heroicons/react/24/solid";

import { useDelete, useUpdate } from "graphql/useCollection";
import { useAppContext } from 'contexts/AppContext';
import { getNestData } from "utils";
import { FilterSelect } from "./list";
import { DialogFunction } from "./edit";

/**
 * Make add item button
 *
 * @param {{onClick?:()=>void}} { onClick:Function to run when button is clicked }
 * @returns Button element
 */
const AddButton = ({ onClick }: { onClick?: () => void }) => (
    <button
        type="button"
        onClick={onClick}
        className="flex justify-center items-center bg-theme-50 hover:bg-theme-100 text-theme-800 w-12 h-12 rounded-3xl transition ease-in duration-200"
    >
        <PlusIcon className="w-8 h-8" />
    </button>
);


const getDataForSort = (data: any, key: any) => {
    const dataa = getNestData(data, key);
    if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z/.test(dataa)) return new Date(dataa).getTime()
    return parseFloat(dataa) || dataa;
}

/**
 * Make List View JSX element of data in collection applying filter
 * @param obj Property object
 * @param obj.collection name of MongoDB collection
 * @param obj.location URL path of this page
 * @param obj.heads Header row contents
 * @param obj.filter function to get filter JSX Element
 * @param obj.limit limit of data count
 * @param obj.noAdd disable adding data
 * @param obj.Data Data to show derived from MongoDB
 * @param obj.Row JSX Element to draw each row
 * @param obj.Dialog Dialog to show when printing
 * @param obj.handleRowClick Function when row is clicked
 * @returns List view
 */
export const SortableListFrame = ({
    collection,
    location,
    heads,
    filter,
    limit,
    noAdd,
    Data,
    Row,
    Dialog,
    handleRowClick
}: {
    collection: string;
    location: string;
    heads: { [key: string]: string }[];
    filter?: JSX.Element;
    limit: number;
    noAdd: boolean;
    Data: KV[];
    Row: ({
        datum,
        clickDelete,
        clickCollection,
        clickPrint,
        clickUpdate,
        navigate,
    }: {
        datum: KV;
        clickDelete?: (e: MouseEvent<HTMLButtonElement>) => void;
        clickCollection?: (e: MouseEvent<HTMLButtonElement>) => void;
        clickPrint?: (e: MouseEvent<HTMLButtonElement>) => void;
        clickUpdate?: (e: MouseEvent<HTMLButtonElement>) => void;
        navigate: NavigateFunction;
    }) => JSX.Element;
    Dialog?: DialogFunction;
    handleRowClick?: (e: MouseEvent<HTMLElement>, navigate: NavigateFunction) => void
}) => {
    // Data for selected cell
    const [currentDatum, setCurrentDatum] = useState<{
        [key: string]: any;
    }>();
    const navigate = useNavigate();
    const context = useAppContext()

    //const completed = () => { history.go(0) }
    const collectionForModify = collection.endsWith("View") ? collection.slice(0, -4) : collection
    const deleteData = useDelete(collectionForModify); //, completed);
    const updateData = useUpdate(collectionForModify)
    const handleDeleteClick = (e: MouseEvent<HTMLButtonElement>) => {
        try {
            // eslint-disable-next-line no-restricted-globals
            if (confirm("削除してよろしいですか？"))
                deleteData(e.currentTarget.value);
        } catch (e: any) {
            alert("エラー：\n" + e.message);
        }
    };
    const handleCollectionClick = (e: MouseEvent<HTMLButtonElement>) => {
        const query = { ...context.state[collection].filter, customer: { ...context.state[collection].filter.customer, _id: e.currentTarget.value } }
        context.setState(collection + ".filter", query)
    };
    const handleUpdateClick = async (e: MouseEvent<HTMLButtonElement>) => {
        const id = e.currentTarget.dataset.id
        const set = JSON.parse(e.currentTarget.dataset.set || "")
        if (!id || !set) return
        try {
            updateData(id, set)
        } catch (e: any) {
            alert("エラー：\n" + e.message);
        }
    }
    const [ascend, setAscend] = useState<1 | -1>(context.state?.[collection]?.sort?.ascend || 1);
    const ascendRef = useRef<1 | -1>(1)
    ascendRef.current = ascend
    const [activeKey, setActiveKey] = useState<string>(context.state?.[collection]?.sort?.activeKey || "_id");
    const activeKeyRef = useRef("_id")
    activeKeyRef.current = activeKey
    const sortFunction = (a: KV, b: KV): number => {
        const keys = activeKey.split(":");
        let dataa = getDataForSort(a, keys[0]);
        let datab = getDataForSort(b, keys[0]);
        if (dataa && !datab) return ascend;
        if (!dataa && datab) return -1 * ascend;
        if (dataa < datab) return -1 * ascend;
        if (dataa > datab) return ascend;
        if (keys.length > 1) {
            dataa = getDataForSort(a, keys[1]);
            datab = getDataForSort(b, keys[1]);
            if (dataa && !datab) return ascend;
            if (!dataa && datab) return -1 * ascend;
            if (dataa < datab) return -1 * ascend;
            if (dataa > datab) return ascend;
        }
        return 0;
    }
    const handleHeaderClick = (e: MouseEvent<HTMLTableCellElement>) => {
        const key = e.currentTarget.dataset.name || "";
        if (!key) return
        if (key !== activeKey) {
            setActiveKey(key || "");
            setAscend(1);
        } else {
            setAscend(-1 * ascend as 1 | -1);
        }
    };
//    const handleLimitChange = (e: ChangeEvent<HTMLSelectElement>) => {
//        setActualLimit(e.target.value)
//        context.setState({ [collection]: { ...context.state[collection] || {}, limit: parseInt(e.target.value) } })
//    };
    const handleLimitChange = (e:ChangeEvent<HTMLSelectElement>) => context.setState(`${collection}.limit`, parseInt(e.target.value))

    useEffect(() => {
        return () => {
            context.setState(collection + ".sort", { ascend: ascendRef.current, activeKey: activeKeyRef.current })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <div className="absolute left-0 top-0 w-full h-full z-10 pointer-events-none">
                {!noAdd && (
                    <Link
                        className="fixed right-4 bottom-10 pointer-events-auto"
                        to={`${location}/create`}
                    >
                        <AddButton />
                    </Link>
                )}
            </div>
            <div className="relative h-full flex flex-col">
                {<div className='px-2 sm:px-4 bg-theme-100 pointer-events-auto'>
                    {filter ? filter : <div className="m-2 flex justify-end items-center gap-4">
                        <FilterSelect
                            name="limit"
                            value={String(limit)}
                            optionName="limit"
                            onChange={handleLimitChange}
                            nodefault
                            nohyphen
                        />
                        <span className="whitespace-nowrap">{Data.length}件</span>{Data.length > limit - 1 && <span className="whitespace-nowrap">{`(以降非表示)`}</span>}
                    </div>}
                </div>}
                <div className="bg-white h-2"></div>
                <div className="px-2 pb-2 grow overflow-scroll flex flex-col z-0">
                    <table className="mx-auto divide-y divide-gray-200 text-center text-sm text-gray-500">
                        <thead className="bg-theme-50">
                            <tr>
                                {heads.map((head, i) => (
                                    <th
                                        key={(head.field || "") + i}
                                        data-name={head.field}
                                        data-value={i}
                                        scope="col"
                                        className={`p-1 font-medium tracking-wider ${head.field ? "cursor-pointer" : ""}`}
                                        onClick={head.field ? handleHeaderClick : undefined}
                                    >
                                        <div className="flex justify-center">
                                            <span>{head.text}</span>
                                            {head.field && head.field === activeKey &&
                                                (ascend === 1 ? (
                                                    <ChevronUpIcon className="w-5 h-5" />
                                                ) : (
                                                    <ChevronDownIcon className="w-5 h-5" />
                                                ))}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {[...Data]
                                .sort((a, b) => sortFunction(a, b))
                                .map((datum) => (
                                    <tr key={datum._id} data-name={datum._id} className={`text-sm text-gray-900 ${handleRowClick ? "cursor-pointer hover:bg-theme-50" : ""}`} onClick={handleRowClick ? e => handleRowClick(e, navigate) : undefined}>
                                        <Row
                                            datum={datum}
                                            clickDelete={handleDeleteClick}
                                            clickCollection={handleCollectionClick}
                                            clickPrint={(e) => setCurrentDatum(datum)}
                                            clickUpdate={handleUpdateClick}
                                            navigate={navigate}
                                        />
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {Dialog && <Dialog current={currentDatum} show={Boolean(currentDatum)} setShow={setCurrentDatum} updateOnPrint />}
        </>
    );
};

/**
 * Make List View outer frame applying filter.  Tbody items must be at innnr HTML
 * @param obj Property object
 * @param obj.location URL path of this page
 * @param obj.heads Header row contents
 * @param obj.filter function to get filter JSX Element
 * @param obj.noAdd disable adding data
 * @param obj.children Child JSX Elements
 * @returns List view
 */

export const SimpleListFrame = ({
    location,
    heads,
    filter,
    noAdd,
    children,
}: {
    location?: string;
    heads: string[];
    filter?: (
        reactive: KV,
        setReactive: (key: string, data: KV) => void,
        count: number,
        handleDownload: () => void
    ) => JSX.Element;
    noAdd: boolean;
    children: JSX.Element;
}) => (
    <div className="relative m-4 flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"><>
                {filter}
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200 text-center text-sm text-gray-500">
                        <thead className="bg-theme-50">
                            <tr>
                                {heads.map((head, i) => (
                                    <th
                                        key={i}
                                        scope="col"
                                        className="p-1 font-medium tracking-wider"
                                    >
                                        {head}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {children}
                        </tbody>
                    </table>
                </div>
            </></div>
        </div>
        {!noAdd && (
            <Link to={`${location}/create`}>
                <AddButton />
            </Link>
        )}
    </div>
);