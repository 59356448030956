import React, { ChangeEvent, useEffect, useMemo, useReducer, useState } from "react";
import * as Yup from 'yup';
//import { isHoliday } from '@holiday-jp/holiday_jp';

import relations from "realm/Yanagisawa/data_sources/mongodb-atlas/Business/attendance/relationships.json"
import { CreateBase, EditBase, EditBaseLatest, FieldBase, FormButton, FormFrame, getSchemaValues, RelationField } from "components/edit";
import { useRealmApp } from "RealmApp";
import { hourOptions, JSTHourMinute, JSTToday, minuteOptions, msToHourMinute, setJSTHour } from "contexts/dateUtils";
import DatePicker from "components/datepicker";
import { FieldCheck, FieldPlain, FieldSelect } from "components/uiparts";
import { useData } from "graphql/useCollection";
import Loading from "components/Loading";
import UserSelect, { getAttendanceUserObject } from "components/userSelect";
import { useAppContext } from "contexts/AppContext";
import options from "contexts/options.json";
import { projectingDivision } from "contexts/utils";
import { FormikProps } from "formik";

const schema = require("realm/Yanagisawa/data_sources/mongodb-atlas/Business/attendance/schema.json").properties;

const initialValues = getSchemaValues(schema)

//const getWorkingTime = (start?:Date, end?:Date) => (start && end && (end.getTime() - start.getTime())) || null
//const checkHalfHoliday = (items:KV[]) => items.length > 1 && ((items[0].holiday && !items[1].holiday) || (!items[0].holiday && items[1].holiday))

const FormFields = ({ formik, completed }:{ formik:FormikProps<any>, completed?:(() => void)|null }) => {
    const app = useRealmApp()
    const userID = app.currentUser?.customData?.userID
    const context = useAppContext()
    // field name for user selection dialog.  Also used as user selection dialog show bool
    const [currentName, setCurrentName] = useState<string|null>(null)

    const [times, dispatchTimes] = useReducer((state:KV, action:KV) => ({ ...state, ...action }), {
        date: formik.values.start ? new Date(formik.values.start) : JSTToday(0),
        startHour: formik.values.start ? new Date(formik.values.start).getHours() : 9,
        startMinute: formik.values.start ? new Date(formik.values.start).getMinutes() : 0,
        endHour: formik.values.end ? new Date(formik.values.end).getHours() : "",
        endMinute: formik.values.end ? new Date(formik.values.end).getMinutes() : "",        
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const isAllHoliday = useMemo(() => Boolean(formik.values.item?.[0]?.holiday && (formik.values.item?.[1]?.holiday || !formik.values.halfday)), [formik.values.item?.[0]?.holiday, formik.values.item?.[1]?.holiday, formik.values.halfday])

    const handleClick = (name:string) => {
        setCurrentName(name)
    }

    const handleDateChange = (date?:Date) => {
        dispatchTimes({ date: date })
        const newTimes = { ...times, date: date }
        modifyTime(newTimes)
    }
    const handleTimeChange = (e:ChangeEvent<HTMLFormElement>) => {
        dispatchTimes({ [e.currentTarget.name]: e.currentTarget.value === "" ? "" : Number(e.currentTarget.value) })
        const newTimes = { ...times, [e.currentTarget.name]: e.currentTarget.value === "" ? "" : Number(e.currentTarget.value) }
        modifyTime(newTimes)
    }
    const handleFullday = (name:string, value:boolean) => {
//        if (value) {
//            dispatchTimes({ startHour: 0, startMinute: 0, endHour: 24, endMinute: 0 })
//            const newTimes = { ...times, startHour: 0, startMinute: 0, endHour: 24, endMinute: 0 }
//            modifyTime(newTimes)
//        }
        if (isAllHoliday) {
            alert("休暇の場合は定時以外は設定できません")
            return
        }
        formik.setFieldValue(name, value)
//        formik.setFieldValue('halfday', false)
    }
    const modifyTime = (newTimes:KV) => {
        let [start, end]:[Date|null, Date|null] = [null, null]
        if (newTimes.startHour === "" || newTimes.startMinute === "") {
            formik.setFieldValue("start", null)
        } else {
            start = setJSTHour(newTimes.date, newTimes.startHour, newTimes.startMinute)
            formik.setFieldValue("start", start)
        }
        if (newTimes.endHour === "" || newTimes.endMinute === "") {
            formik.setFieldValue("end", null)
        } else {
            end = setJSTHour(newTimes.date, newTimes.endHour + (newTimes.endHour < newTimes.startHour ? 24 : 0), newTimes.endMinute)
            formik.setFieldValue("end", end)
        }
    }
    const handleHolidayChange = (e:ChangeEvent<HTMLFormElement>) => {
        formik.setFieldValue(e.currentTarget.name, e.currentTarget.value)
        const fieldName = e.currentTarget.name
        const indexString = fieldName.match(/\[([0-9]+)\]/g)?.[0] || "[0]"
        const index = Number(indexString.replaceAll(/[[\]]/g, ''))
        if (!formik.values.halfday && e.currentTarget.value) formik.setFieldValue("allday", true)
        if (formik.values.halfday && formik.values.item?.[index ? 0 : 1]?.holiday && e.currentTarget.value) formik.setFieldValue("allday", true)
    }
    const handleDivisionChange = (e:ChangeEvent<HTMLFormElement>) => {
        formik.setFieldValue(e.currentTarget.name, e.currentTarget.value)
        const fieldName = e.currentTarget.name
        const indexString = fieldName.match(/\[([0-9]+)\]/g)?.[0] || "[0]"
        const index = Number(indexString.replaceAll(/[[\]]/g, ''))
        if (!Object.keys(e.currentTarget.value === "forest" ? workOptionsTreeforest : e.currentTarget.value === "tree" ? workOptionsTree : workOptionsStandard).includes(formik.values.item[index].work?._id)) formik.setFieldValue(`item[${index}].work`,null)
    }
    const handleWorkChange = (e:ChangeEvent<HTMLFormElement>) => {
        formik.setFieldValue(e.currentTarget.name, context.workProps.find(item => item._id === e.currentTarget.value))
    }
    const handleProjectChange = (e:ChangeEvent<HTMLFormElement>) => {
        const fieldName = e.currentTarget.name
        const indexString = fieldName.match(/\[([0-9]+)\]/g)?.[0] || "[0]"
        const index = Number(indexString.replaceAll(/[[\]]/g, ''))
        const { __typename, ...data } = context.state[formik.values.item[index].division]?.ongoing?.find((item:KV) => item._id === e.currentTarget.value) || { __typename: "" }
        formik.setFieldValue(`${fieldName}.${formik.values.item[index].division}`, { ...data })
    }
    const getProjectOptions = (index:number) => {
        if ( !projectingDivision(formik.values.item[index]?.division || "")) return ({ "": "" })
        let options = (context.state[formik.values.item[index].division]?.ongoing || []).reduce((a:KV, r:KV) => ({ ...a, [r._id]: `${r.fileID} - ${r.subject}` }), { "": "" })
        const initialValue = formik.initialValues.item[index].project[formik.values.item[index].division]
        if (initialValue && initialValue._id) options = { ...options, [initialValue._id]: `${initialValue.fileID} - ${initialValue.subject}` }
        return options
    }
    const handleCheck = (field:string, value:boolean) => {
        formik.setFieldValue(field, value)
        if (field === "holidayAttend") {
            if (!value) formik.setFieldValue("isHoliday", false)
        }
    }
    const projectOptions = useMemo(() => [getProjectOptions(0), getProjectOptions(1)]
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [formik.values.item[0]?.division, formik.values.item[1]?.division])
    const workOptionsTreeforest = context.workProps.reduce((a, r) => ((r.division === "treeforest" && r.active) ? { ...a, [r._id]: `${r.category || ""}${r.category ? " - " : ""}${r.item}` } : a), { "": "" })
    const workOptionsTree = context.workProps.reduce((a, r) => ((r.division === "tree" && r.active) ? { ...a, [r._id]: `${r.category || ""}${r.category ? " - " : ""}${r.item}` } : a), { "": "" })
    const workOptionsStandard = context.workProps.reduce((a, r) => ((r.division === "standard" && r.active) ? { ...a, [r._id]: `${r.category || ""}${r.category ? " - " : ""}${r.item}` } : a), { "": "" })
    const calcExcessMinutes = (v:KV) => {
        const [startTime, endTime] = [new Date(v.start).getTime(), new Date(v.end).getTime()]
        const [actualHoliday0, actualHoliday1] = [v.item?.[0]?.holiday, v.halfday && v.item?.[1]?.holiday]
        const holiday = [Boolean(actualHoliday0 && actualHoliday0 !== "振休"), Boolean(actualHoliday1 && actualHoliday1 !== "振休")]
        const frex = [actualHoliday0 === "振休", actualHoliday1 === "振休"]
        let excess = 0
        if (v.allday) { // Calc hours without start and end
            excess = (v.halfday ? [0, 1] : [0, 0]).reduce((a, r) => a + (+(!holiday[r] && !frex[r])) * (+(v.holidayAttend && !v.isHoliday)) * 14400000 - (+frex[r]) * 14400000, 0) // holiday attend and no holiday work
        } else if (startTime && endTime && !(v.isOver) && !v.isHoliday) {
            excess = endTime- startTime - (v.rest || 0) - (v.nightRest || 0) - (v.halfday ? [0, 1] : [0, 0]).reduce((a, r) => a + (+(!holiday[r] && !frex[r])) * 14400000 - (+frex[r]) * 14400000, 0)
            excess = endTime- startTime - (v.rest || 0) - (v.nightRest || 0) - (v.holidayAttend ? 0 : 28800000)
            excess = (v.halfday ? [0, 1] : [0, 0]).reduce((a, r) => a + (+holiday[r]) * 14400000, endTime- startTime - (v.rest || 0) - (v.nightRest || 0) - ((v.holidayAttend && !v.isHoliday) ? 0 : 28800000)) // holiday attend and no holiday work
        }
        formik.setFieldValue("excess", excess)
    }
    useEffect(() => {
        calcExcessMinutes(formik.values)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.start, formik.values.end, formik.values.item, formik.values.allday, formik.values.halfday, formik.values.rest, formik.values.nightRest, formik.values.holidayAttend, formik.values.isOver, formik.values.isHoliday])

    const Item = ({ title, index }:{ title:string, index:number }) => <div className="grid grid-cols-6 col-span-6 gap-4 p-2 bg-gray-200">{title && <div className="-m-2 p-2 text-white col-span-6 bg-theme-600">{title}</div>}
        <FormFrame formik={formik} schema={schema} >
            <FieldSelect label="休暇" name={`item[${index}].holiday`} span={1} options={options.holiday} props={{ value: formik.values.item[index].holiday || "", onChange: handleHolidayChange }} />
            <FieldSelect label="部門" name={`item[${index}].division`} span={1} options={options.division} props={{ value: formik.values.item?.[index]?.division, onChange: handleDivisionChange }} />
            {!formik.values.item[index].holiday && projectingDivision(formik.values.item[index].division) && <FieldSelect label="案件" name={`item[${index}].project`} span={1} options={projectOptions[index]} props={{ value: formik.values.item[index]?.project?.[formik.values.item[index].division]?._id, onChange: handleProjectChange }} />}
            {!formik.values.item[index].holiday && <FieldSelect label="労災保険" name={`item[${index}].insurance`} span={1} options={options.insurance} props={{ value: formik.values.item[index].insurance || "", onChange: formik.handleChange }} />}
            {!formik.values.item[index].holiday && <FieldSelect label="作業内容" name={`item[${index}].work`} span={1} options={formik.values.item[index].division === "forest" ? workOptionsTreeforest : formik.values.item[index].division === "tree" ? workOptionsTree : workOptionsStandard} props={{ value: formik.values.item[index]?.work?._id || "", onChange: handleWorkChange }} />}
        </FormFrame>
    </div>

    return <div className="grid grid-cols-6 gap-6">
        <FormFrame formik={formik} schema={schema}>
            <RelationField
                label="社員"
                onClick={e => handleClick("user")}
                value={formik.values.user.name}
            />
            <UserSelect name={currentName} setName={setCurrentName} formik={formik} asAttendanceObj eligibleOnly />
            <DatePicker label="日付" name="date" value={times.date} setValue={handleDateChange} />
            <FieldCheck label="定時" name="allday" span={1} props={{ value: formik.values.allday }} setFieldValue={handleFullday} />
            {!formik.values.allday && <FormFrame formik={formik} schema={schema} >
                <FieldSelect label="出勤 - 時" name="startHour" span={1} options={hourOptions} props={{ value: times.startHour, onChange: handleTimeChange }} />
                <FieldSelect label="出勤 - 分" name="startMinute" span={1} options={minuteOptions(10)} props={{ value: times.startMinute, onChange: handleTimeChange }} />
                {formik.values.create && <div className="col-span-6 px-4 bg-gray-50 text-right sm:hidden"><FormButton completed={completed} /></div>}
                <FieldBase name="rest" />
                <FieldBase name="nightRest" />
                <FieldSelect label="退勤 - 時" name="endHour" span={1} options={hourOptions} props={{ value: times.endHour, onChange: handleTimeChange }} />
                <FieldSelect label="退勤 - 分" name="endMinute" span={1} options={minuteOptions(10)} props={{ value: times.endMinute, onChange: handleTimeChange }} />
            </FormFrame>}
            <FieldPlain label="超過時間" name="excess" span={1} props={{ value:formik.values.excess ? msToHourMinute(formik.values.excess) : "", disabled: true }} />
            {!isAllHoliday && <FieldCheck label="休日に出社" name="holidayAttend" span={1} props={formik.getFieldProps("holidayAttend")} setFieldValue={handleCheck} />}
            {!isAllHoliday && <FieldBase name={formik.values.holidayAttend ? "isHoliday" : "isOver"} />}
            <Item title={formik.values.halfday ? "午前" : ""} index={0} />
            <FieldCheck label="午後半日を別にする" name="halfday" span={1} props={{ value: formik.values.halfday }} setFieldValue={handleCheck} />
            {formik.values.halfday && <Item title="午後" index={1} />}
            <FieldBase name="gearCost" />
            <FieldBase name="workplace" />
            <FieldBase name="car" />
            {formik.values.user.supervisor === userID ? <FieldBase name="status" /> : <FieldPlain label="承認" name='status' span={3} props={{ ...(formik?.getFieldProps("status") || {}), readOnly: true }} />}
            <FieldBase name="remarks" />
        </FormFrame>
    </div>
}


const validationSchema = Yup.object({

})


const writePrepare = (updates:KV, modifiedData?:KV) => {
    if (!updates.halfday && updates.item.length > 1) {
            updates.item.pop()// remove second item if fulldauy
            updates.item[0].halfday = false // non halfday flag
    }
    if (updates.halfday) updates.item.forEach((eachItem:KV) => eachItem.halfday = true)

    if (updates.allday) { // allday is 8-17
        updates.start = setJSTHour(new Date(updates.start), (updates.halfday && updates.item[0].holiday && !updates.item[1]?.holiday) ? 13 : 8)
        updates.end = setJSTHour(new Date(updates.start), (updates.halfday && !updates.item[0].holiday && updates.item[1]?.holiday) ? 12 : 17)
        updates.rest = (updates.item[0].holiday || updates.item[1]?.holiday) ? null : 3600000
        if (updates.nightRest) delete updates.nightRest
    }
    if ((!updates.halfday && updates.item[0].holiday) || (updates.halfday && updates.item[0].holiday && updates.item[1]?.holiday)) {
        updates.start = setJSTHour(new Date(updates.start), 0)
        updates.allday = true
        delete updates.end
        delete updates.rest
    } // for holiday including each half dirrerent kind of holiday, start is midnight and end is empty
    //    if (projectingDivision(updates.item[1]?.division) && updates.project2[updates.division2]._id)  updates.project2 = { [updates.division2]: updates.project2[updates.division2] }
    //    else if (updates.item[1]) delete updates.item.pop()
    if (updates.start && updates.end) {
        const [startHour, startMinute] = JSTHourMinute(new Date(updates.start))
        const [endHour, endMinute] = JSTHourMinute(new Date(updates.end))
        if (startHour > endHour) {
            if (updates.isOver) updates.over = Math.max(((24 - startHour) * 60 - startMinute + endHour * 60 + endMinute - 480) * 60000 - Number(updates.rest || 0) - Number(updates.nightRest || 0), 0)
            updates.night = Math.max(Math.min((24 - startHour) * 60 - startMinute, 120) * 60000 + Math.min(endHour * 60 + endMinute, 300) * 60000 - Number(updates.nightRest || 0), 0)
        } else { // same day
            if (updates.isOver) updates.over = Math.max(((endHour - startHour) * 60 - startMinute + endMinute - 480) * 60000 - Number(updates.rest || 0) - Number(updates.nightRest || 0), 0)
            updates.night = Math.max(Math.max((endHour - 22) * 60 + endMinute, 0) * 60000 + Math.max((5 - startHour) * 60 - startMinute, 0) * 60000 - Number(updates.nightRest || 0), 0)
        }
    }
    if (updates.isHoliday) updates.holidayWork = new Date(updates.end).getTime() - new Date(updates.start).getTime() - (updates.rest || 0) - (updates.nightRest || 0)// if work on holiday, set working hours to holiday work
    else delete updates.holidayWork
    if (!updates.isOver) delete updates.over

    if (updates.halfday && updates.item[0]?.holiday && updates.item[0]?.holiday === updates.item[1]?.holiday) throw new Error("午後半日が選択されていますが、午前と午後が同じ休暇種別になっています")
    if (modifiedData?.status === "OK" && updates.status === "OK") {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("承認済のデータは上書きできません。未承認に変更しますか？")) delete updates.status
        else throw new Error("承認済のデータのため上書きができませんでした。")
    }
    updates.item.forEach((eachItem:KV) => eachItem.work = { _id: eachItem.work?._id || null, category: eachItem.work?.category || null, item: eachItem.work?.item || null, ...(eachItem.work?.costID ? { costID: eachItem.work.costID} : {}) })
    delete updates.halfday
    delete updates.create
    delete updates.date
    console.log(updates)
}

// Put empty second item if not exist
const readPrepare = (data:KV) => {
    data.halfday = (data.item.length > 1)
    if (data.item.length === 1) {
        data.halfday = false
        data.item.push({
            division: data.item[0]?.division || "admin",
            project: { forest: {}, tree: {} },
            insurance: "",
            gearCost: "",
            car: ""
        })
    }
}


export const Create = () => {
    const app = useRealmApp()
    let actualInitialValues = { ...initialValues }
    const {loading, Data} = useData("user", null, { _id:app.currentUser?.id })
    if (!loading && Data?.length) {
        actualInitialValues.user = getAttendanceUserObject(Data[0])
        // Set two empty item
        actualInitialValues.item = [
            {
                division: app.currentUser?.customData?.division || "admin",
                project: { forest: {}, tree: {} },
                insurance: ""
            },
            {
                division: app.currentUser?.customData?.division || "admin",
                project: { forest: {}, tree: {} },
                insurance: ""
            },
        ]
        actualInitialValues.halfday = false // Non database field
        let date = new Date()
        date = new Date(date.getTime() + (10 - date.getMinutes() % 10) * 60000)
        date.setSeconds(0, 0)
        actualInitialValues.start = date //10 minutes unit
        actualInitialValues.create = true // Distinguish creating from editing
    }
    return (<>{loading ? <Loading /> : 
        <CreateBase title="勤怠情報" values={actualInitialValues} collection="attendance" relations={relations} Fields={FormFields} writePrepare={writePrepare} validation={validationSchema} />
    }</>);
};

export const Edit = ({ endNow = false }) => {
    let actualInitialValues = { ...initialValues }
    actualInitialValues.rest = 0 // Avoid overwritten to default value of one hour
    if (endNow) { // Set end time as 10 minutes unit
        let date = new Date()
        date = new Date(date.getTime() - (date.getMinutes() % 10) * 60000)
        date.setSeconds(0, 0)
        actualInitialValues.end = date
        return (<EditBaseLatest title="勤怠情報" values={actualInitialValues} collection="attendance" relations={relations} Fields={FormFields} readPrepare={readPrepare} writePrepare={writePrepare} validation={validationSchema} location={""} />);
    }
    return (<EditBase title="勤怠情報" values={actualInitialValues} collection="attendance" relations={relations} Fields={FormFields} readPrepare={readPrepare} writePrepare={writePrepare} validation={validationSchema} />);
};
export default Edit