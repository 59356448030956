/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from 'react'
import { Link, useLocation } from 'react-router-dom';

import { Menu, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon, UserIcon } from '@heroicons/react/24/outline'

import { useRealmApp } from "RealmApp";


const classNames = (...classes: string[]): string => {
    return classes.filter(Boolean).join(' ')
}

export default function Header() {
    const location = useLocation();
    const path: string = location.pathname;
    const appContext = useRealmApp();
    const signout = () => appContext.logOut();
    const navigation: { name: string, href: string, current: boolean }[] = [
        { name: 'ボード', href: '/', current: path === '/' },
        { name: '顧客', href: '/customer', current: path.startsWith('/customer') },
        { name: '薪商品', href: '/firewoodproduct', current: path.startsWith('/firewoodproduct') },
        { name: '薪販売', href: '/firewood', current: path.startsWith('/firewood') && !path.startsWith('/firewoodproduct') },
        { name: '樹木', href: '/tree', current: path.startsWith('/tree') && !path.startsWith('/treeitem') },
        { name: '原価管理', href: '/treeitem', current: path.startsWith('/treeitem') },
        { name: '山林', href: '/forest', current: path.startsWith('/forest') },
        { name: '運材', href: '/transport', current: path.startsWith('/transport') },
        { name: '材販売', href: '/timber', current: path.startsWith('/timber') },
        { name: '機械', href: '/machine', current: path.startsWith('/machine') },
        { name: '勤怠', href: '/attendance', current: path.startsWith('/attendance') },
        { name: '目標管理', href: '/aim', current: path.startsWith('/aim') },
        { name: '指標集計', href: '/summarize', current: path.startsWith('/summarize') },
    ]
    return (
        <nav className="bg-theme-800 print:hidden">
            <div className="mx-auto px-2 sm:px-6">
                <div className="relative flex items-center justify-between h-12">
                    <div className="flex-1 flex justify-start sm:items-stretch">
                        <div className="flex-shrink-0 flex items-center">
                            <img
                                className="block sm:hidden ml-12 h-8 w-auto"
                                src="/images/header_logo.gif"
                                alt="Workflow"
                            />
                            <img
                                className="hidden sm:block h-8 w-auto"
                                src="/images/logo.png"
                                alt="Workflow"
                            />
                        </div>
                        <div className="hidden sm:block sm:ml-6">
                            <div className="flex gap-1">
                                {navigation.map((item) => (
                                    <Link
                                        key={item.name}
                                        to={item.href}
                                        className={classNames(
                                            item.current ? 'bg-theme-600 text-white' : 'text-theme-100 hover:bg-theme-700 hover:text-white',
                                            'px-2 py-2 rounded-md text-sm font-medium'
                                        )}
                                        aria-current={item.current ? 'page' : undefined}
                                    >
                                        {item.name}
                                    </Link>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                        <Menu as="div" className="relative">
                            {({ open }) => <>
                                {/* Mobile menu button*/}
                                <Menu.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </Menu.Button>
                                <Menu.Items className="origin-top-left absolute left-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-20">
                                    {navigation.map((item) => (
                                        <Menu.Item key={item.name}>
                                            {item.current ? <div className="block px-4 py-2 text-sm text-gray-300 bg-gray-100">{item.name}</div> :
                                                <Link
                                                    to={item.href}
                                                    className='block px-4 py-2 text-sm text-gray-700'
                                                    aria-current={item.current ? 'page' : undefined}
                                                >
                                                    {item.name}
                                                </Link>
                                            }
                                        </Menu.Item>
                                    ))}
                                </Menu.Items>
                            </>}
                        </Menu>
                    </div>
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                        {/*                                <button className="bg-theme-600 p-1 rounded-full text-white hover:text-theme-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-theme-700 focus:ring-white">
                                    <span className="sr-only">View notifications</span>
                                    <CogIcon className="h-6 w-6" aria-hidden="true" />
                                </button>*/}
                        <span className="text-white">{`${appContext.currentUser?.customData.surname}　${appContext.currentUser?.customData.givenName}`}</span>
                        {/* Profile dropdown */}
                        <Menu as="div" className="ml-3 relative">
                            {({ open }) => (
                                <>
                                    <div>
                                        <Menu.Button className="bg-theme-600 p-1 flex text-white hover:text-theme-400 text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-theme-700 focus:ring-white">
                                            <span className="sr-only">ユーザーメニュー</span>
                                            <UserIcon className="h-6 w-6" aria-hidden="true" />
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        show={open}
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items
                                            static
                                            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-20"
                                        >
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link
                                                        to={`/user/edit/${appContext.currentUser?.id}`}
                                                        className={`${active ? 'bg-gray-100' : ''} block px-4 py-2 text-sm text-gray-700`}
                                                    >
                                                        ユーザー設定
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link
                                                        to="/user"
                                                        className={`${active ? 'bg-gray-100' : ''} block px-4 py-2 text-sm text-gray-700`}
                                                    >
                                                        ユーザー一覧
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link
                                                        to={`/work`}
                                                        className={`${active ? 'bg-gray-100' : ''} block px-4 py-2 text-sm text-gray-700`}
                                                    >
                                                        作業項目管理
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <button
                                                        className={`${active ? 'bg-gray-100' : ''} block px-4 py-2 w-full text-left text-sm text-gray-700`}
                                                        onClick={signout}
                                                    >
                                                        ログアウト
                                                    </button>
                                                )}
                                            </Menu.Item>
                                        </Menu.Items>
                                    </Transition>
                                </>
                            )}
                        </Menu>
                    </div>
                </div>
            </div>
        </nav>
    )
}
