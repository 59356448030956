import React, { KeyboardEvent, MouseEvent, useEffect, useState } from "react"
import forestItem from "contexts/forestItem.json"
import { Field, FieldProps, FormikProps } from "formik"
import DatePicker from "components/datepicker"
import { addWeekdays, JSTDate } from "contexts/dateUtils"
import { ButtonClass, formatNumber } from "utils"
import { useParams } from "react-router-dom"
import { useLazyData } from "graphql/useCollection"
import Loading from "components/Loading"

const categories:("main"|"road"|"transport")[] = ["main", "road", "transport"]

const handleKeyUp = (e:KeyboardEvent<HTMLInputElement>, recalc:() => void) => {
    e.which = e.which || e.keyCode;
    if (e.which === 13) {
        recalc()
    }
}
const InputWithMark = ({ mark, category, name, recalc }:{mark:string, category:string, name:string, recalc:() => void}) => (<Field name={`${category}.${name}`}>{({ field, form }:FieldProps) => (<div className="relative">
        <input 
            type="number"
            className="text-right w-full border-0 focus:border focus:border-theme-500 focus:shadow-tableCell py-0 pl-2 pr-16"
            {...field}
            onChange={e => {form.handleChange(e);recalc()}}
            onBlur={e => {form.handleBlur(e);recalc()}}
            onKeyUp={e => handleKeyUp(e, recalc)}
        />
        <span className="absolute w-14 inset-y-0 right-0 px-1 border-l border-gray-500">{mark}</span>
    </div>)}</Field>)
    
const ForestPlan = ({ formik, costItem }:{ formik:FormikProps<any>, costItem:KV[] }) => {
    const [plan, setPlan] = useState(false)
    const [planCalcValues, setPlanCalcValues] = useState<{main: KV, road:KV, transport:KV}>({ 
        main:Object.keys(forestItem.main).reduce((a, r) => ({...a, [r]:""}), {}),
        road:Object.keys(forestItem.road).reduce((a, r) => ({...a, [r]:""}), {}),
        transport:Object.keys(forestItem.transport).reduce((a, r) => ({...a, [r]:""}), {})
    })
    const [actualCalcValues, setActualCalcValues] = useState<{main: KV, road:KV, transport:KV}>({ 
        main:Object.keys(forestItem.main).reduce((a, r) => ({...a, [r]:""}), {}),
        road:Object.keys(forestItem.road).reduce((a, r) => ({...a, [r]:""}), {}),
        transport:Object.keys(forestItem.transport).reduce((a, r) => ({...a, [r]:""}), {})
    })

    let { id } = useParams<KV>();
    const { getData: getMachineData, loading: machineLoading, Data: machineData } = useLazyData("machine")
    const { getData: getTimberData, loading: timberLoading, Data: timberData } = useLazyData("timber")
    const { getData: getTransportData, loading: transportLoading, Data: transportData } = useLazyData("transport")
    const { getData: getHumanData, loading: humanLoading, Data: humanData } = useLazyData("attendance")


    const recalc = (isPlan:boolean = plan) => {
        let mainCalcValues = { ...(isPlan ? planCalcValues.main : actualCalcValues.main) }
        let values = isPlan ? formik.values.mainPlan : formik.values.mainActual

        mainCalcValues.carriedWood = (values.area * values.woodVolume) || 0
        mainCalcValues.woodSales = (mainCalcValues.carriedWood * values.woodUnitPrice) || 0
        mainCalcValues.mainHuman = (values.area * values.humanPerHa) || 0
        mainCalcValues.totalHuman = mainCalcValues.mainHuman + values.provisionalHuman || 0
        mainCalcValues.productivity =  Math.round(mainCalcValues.carriedWood ? (mainCalcValues.totalHuman / mainCalcValues.carriedWood * 100) : 0) / 100
        mainCalcValues.workingDays = Math.ceil(values.humanPerDay ? (mainCalcValues.totalHuman / values.humanPerDay) : 0)
        mainCalcValues.estimatedEndDate =new Date(new Date(values.beginDate).getTime() + 86400000 * mainCalcValues.workingDays * 7 / 5)
        mainCalcValues.chainsaw = mainCalcValues.carriedWood / values.chainsawPerVolume
        mainCalcValues.heavyEquipment = mainCalcValues.carriedWood / values.heavyEquipPerVolume
        mainCalcValues.diesel = mainCalcValues.heavyEquipment * values.dieselPerEquip
        mainCalcValues.mixedOil = mainCalcValues.chainsaw * values.mixedOilPerEquip
        mainCalcValues.chainOil = mainCalcValues.chainsaw * values.chainOilPerEquip
        
        let amount = mainCalcValues.totalHuman * (costItem.find((v:KV) => v._id === 101)?.unitPrice || 0)
        amount += mainCalcValues.chainsaw * (costItem.find((v:KV) => v._id === 202)?.unitPrice || 0)
        amount += values.cutter * (costItem.find((v:KV) => v._id === 203)?.unitPrice || 0)
        amount += mainCalcValues.heavyEquipment * (costItem.find((v:KV) => v._id === 212)?.unitPrice || 0)
        amount += mainCalcValues.diesel * (costItem.find((v:KV) => v._id === 302)?.unitPrice || 0)
        amount += mainCalcValues.mixedOil * (costItem.find((v:KV) => v._id === 303)?.unitPrice || 0)//!!!!!!!!!!TEMP
        amount += mainCalcValues.chainOil * (costItem.find((v:KV) => v._id === 303)?.unitPrice || 0)//!!!!!!!!!!TEMP
        amount += values.forwardingCost
        amount += values.otherCost
        mainCalcValues.amount = amount
        values.amount = amount

        let roadCalcValues = { ...(isPlan ? planCalcValues.road : actualCalcValues.road) }
        values =  isPlan ? formik.values.roadPlan : formik.values.roadActual
        roadCalcValues.mainHuman =  values.distancePerHuman ? (values.distance / values.distancePerHuman) : 0
        roadCalcValues.totalHuman = roadCalcValues.mainHuman + values.provisionalHuman
        roadCalcValues.workingDays = values.humanPerDay ? (roadCalcValues.totalHuman / values.humanPerDay) : 0
        roadCalcValues.estimatedEndDate = addWeekdays(new Date(values.beginDate), roadCalcValues.workingDays)
        roadCalcValues.backhoeCost = ((values.backhoeTerm || 0) * (values.backhoeCostPerTerm || 0)) || 0

        amount = roadCalcValues.totalHuman * (costItem.find((v:KV) => v._id === 101)?.unitPrice || 0)
        amount += values.chainsaw * (costItem.find((v:KV) => v._id === 202)?.unitPrice || 0)
        amount += values.cutter * (costItem.find((v:KV) => v._id === 203)?.unitPrice || 0)
        amount += roadCalcValues.backhoeCost
        amount += values.diesel * (costItem.find((v:KV) => v._id === 301)?.unitPrice || 0)
        amount += values.mixedOil * (costItem.find((v:KV) => v._id === 303)?.unitPrice || 0)//!!!!!!!!!!TEMP
        amount += values.chainOil * (costItem.find((v:KV) => v._id === 303)?.unitPrice || 0)//!!!!!!!!!!TEMP
        amount += values.forwardingCost
        amount += values.otherCost
        roadCalcValues.amount = amount
        values.amount = amount

        let transportCalcValues = { ...(isPlan ? planCalcValues.transport : actualCalcValues.transport) }
        values =  isPlan ? formik.values.transportPlan : formik.values.transportActual
        transportCalcValues.inTransportCost = values.inTransportUnitCost * mainCalcValues.carriedWood * values.inTransportRatio / 100
        transportCalcValues.exTransportCost = values.exTransportUnitCost * mainCalcValues.carriedWood * (100 - values.inTransportRatio) / 100
        transportCalcValues.amount = transportCalcValues.inTransportCost + transportCalcValues.exTransportCost
        values.amount = transportCalcValues.inTransportCost + transportCalcValues.exTransportCost

        isPlan ? setPlanCalcValues({main:mainCalcValues, road:roadCalcValues, transport:transportCalcValues}) : setActualCalcValues({main:mainCalcValues, road:roadCalcValues, transport:transportCalcValues})

        formik.values.progress = (formik.values.mainActual.amount + formik.values.roadActual.amount) / (formik.values.mainPlan.amount + formik.values.roadPlan.amount)
    }

    const getActualData = () => {
        getHumanData({ variables: { query: { project: { forest: {_id: id } } } } })
        getTimberData({ variables: { query: { project: { forest: {_id: id } } } } })
        getMachineData({ variables: { query: { project: { forest: {_id: id } } } } })
        getTransportData({ variables: { query: { project: { forest: {_id: id } } } } })
    }

    const DateInput = ({ category, name }:{category:string, name:string}) => <DatePicker name={`${category}.${name}`} span={1} formik={formik} notinform />
    
    useEffect(() => {
        recalc(true)
        recalc(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleTabClick = (e:MouseEvent<HTMLButtonElement>) => {
        if (e.currentTarget.name === "planning") setPlan(true)
        else setPlan(false)
        recalc(true)
        recalc(false)
    }

    if (machineLoading || timberLoading || transportLoading || humanLoading) return <Loading />
    if (machineData?.length || timberData?.length || transportData?.length || humanData?.length) {

    }
  
    return (<>
        <div className="p-0.5 rounded-lg bg-theme-600">
            <button type="button" name="planning" className={"mr-2 px-3 py-2 rounded-md text-sm font-medium " + (plan ? "bg-white text-theme-800" : "text-theme-100 bg-transparent hover:bg-theme-600")} onClick={handleTabClick}>設計</button>
            <button type="button" name="actual" className={"mr-2 px-3 py-2 rounded-md text-sm font-medium " + (!plan ? "bg-white text-theme-800" : "text-theme-100 bg-transparent hover:bg-theme-600")} onClick={handleTabClick}>実績</button>
        </div>
        <div className={`m-4 border border-gray-500 grid grid-cols-${plan ? "4" : "8"} bg-gray-500 gap-px`}>
            {categories.map(category => <React.Fragment key={category}>
                { !plan && formik.values.calcDate && <div className={`px-2 text-right text-white bg-theme-500 col-span-8`}>{JSTDate(new Date(formik.values.calcDate))}取込</div> }
                <div className={`px-2 font-medium text-white bg-theme-700 col-span-${plan ? "4" : "8"}`}>{category === "main" ? "主作業" : category === "road" ? "作業道" : "運材"}</div>
                { Array.from(Array(category === "main" ? 17 : category === "road" ? 15 : 3).keys()).map((v, i) => {
                const work = forestItem[category] as KV
                const planValues = category === "main" ? formik.values.mainPlan : category === "road" ? formik.values.roadPlan : formik.values.transportPlan
                const actualValues = category === "main" ? formik.values.mainActual : category === "road" ? formik.values.roadActual : formik.values.transportActual
                const itemKey = Object.keys(work).find(key =>   work[key].input &&  work[key].row === i )
                const secondItemKey = Object.keys( work).find(key =>  ! work[key].input &&  work[key].row === i )
                const percentage = !itemKey ? 0 : itemKey === "beginDate" ? Math.round((new Date(actualValues[itemKey]).getTime() - new Date(planValues[itemKey]).getTime()) / 86400000) : Math.round((actualValues[itemKey] || 0) * 100 / planValues[itemKey])
                if (itemKey) console.log([itemKey, actualValues[itemKey], planValues[itemKey]])
                const secondPercentage = !secondItemKey ? 0 : secondItemKey === "estimatedEndDate" ? Math.round((new Date(actualCalcValues[category][secondItemKey]).getTime() - new Date(planCalcValues[category][secondItemKey]).getTime()) / 86400000) : Math.round((actualCalcValues[category][secondItemKey] || 0) * 100 / planCalcValues[category][secondItemKey])
                return <React.Fragment key={category+i}>
                        <div className="bg-theme-100 px-2">{itemKey && work[itemKey].name}</div>
                        {!plan &&  (!itemKey ? <div />
                         : <div className="relative bg-gray-100 text-gray-500">
                             <div className="text-right pr-16" data-name={itemKey}>{itemKey === "beginDate" && planValues[itemKey] ? JSTDate(new Date(planValues[itemKey])) : formatNumber(planValues[itemKey])}</div>
                             <span className="absolute w-14 inset-y-0 right-0 px-1 border-l border-gray-500">{work[itemKey].unit}</span>
                        </div>)}
                        
                        {!itemKey ? <div /> : itemKey === "beginDate" ? <DateInput category={plan ? `${category}Plan` : `${category}Actual`} name={itemKey} /> : <InputWithMark mark={ work[itemKey].unit} category={plan ? `${category}Plan` : `${category}Actual`} name={itemKey} recalc={recalc} />}
                        
                        {!plan && (!itemKey || !planValues[itemKey] ? <div /> : itemKey === "beginDate" ? <div className="text-gray-500 bg-gray-100 px-2">{percentage}日</div> : <div className="relative bg-gray-100 text-gray-500">
                            <span className={`absolute left-0 top-0.5 bottom-0.5 ${percentage > 100 ? "bg-red-500" : "bg-theme-500"}`} style={{width:`${percentage/2}%`}}></span>
                            <span className={`absolute top-0 bottom-0 px-2${percentage > 100 ? " text-white" : ""}`} style={percentage > 100 ? {right:`${100-percentage/2}%`} : {left:`${percentage/2}%`}}>{percentage}{itemKey === "beginDate" ? "日" : "%"}</span>
                        </div>)}
                        
                        <div className="bg-theme-100 px-2">{secondItemKey &&  work[secondItemKey].name}</div>
                        {secondItemKey ? <div className="relative bg-gray-100 text-gray-500"><div className="text-right pr-16" data-name={secondItemKey}>
                            {secondItemKey === "estimatedEndDate" && planCalcValues[category][secondItemKey] ? JSTDate(planCalcValues[category][secondItemKey]) :  formatNumber(planCalcValues[category][secondItemKey])}</div>
                            <span className="absolute w-14 inset-y-0 right-0 px-1 border-l border-gray-500">{ work[secondItemKey].unit}</span>
                            </div> : <div />
                        }
                        {!plan && (secondItemKey ? <div className="relative bg-gray-100 text-gray-500"><div className="text-right pr-16" data-name={secondItemKey}>
                            {secondItemKey === "estimatedEndDate" && actualCalcValues[category][secondItemKey] ? JSTDate(actualCalcValues[category][secondItemKey]) : formatNumber(actualCalcValues[category][secondItemKey])}</div>
                            <span className="absolute w-14 inset-y-0 right-0 px-1 border-l border-gray-500">{ work[secondItemKey].unit}</span>
                            </div> : <div />)
                        }
                        {!plan && (!secondItemKey || !planCalcValues[category][secondItemKey] ? <div /> : itemKey === "beginDate" ? <div className="text-gray-500 bg-gray-100 px-2">{secondPercentage}日</div> : <div className="relative
                         bg-gray-100 text-gray-500">
                            <span className={`absolute left-0 top-0.5 bottom-0.5 ${secondPercentage > 100 ? "bg-red-500" : "bg-theme-500"}`} style={{width:`${secondPercentage/2}%`}}></span>
                            <span className={`absolute top-0 bottom-0 px-2${secondPercentage > 100 ? " text-white" : ""}`} style={secondPercentage > 100 ? {right:`${100-secondPercentage/2}%`} : {left:`${secondPercentage/2}%`}}>{secondPercentage}{itemKey === "beginDate" ? "日" : "%"}</span>
                        </div>)}
                        
                </React.Fragment>
            })}
            </React.Fragment>)}
        </div>
        <div className="px-4 text-right sm:px-6"><button className={ButtonClass} onClick={getActualData}>実績取込</button></div>
    </>)

}

export default ForestPlan