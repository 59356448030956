import React from 'react'

import { DataList, ListItem, EditLinkTd, FilterSelect, ListName, FiltersFunction, FilterDates, RowFunction } from "components/list"
import Sum from './sum'
import { useAppContext } from 'contexts/AppContext'
import options from 'contexts/options.json'
const location = "/machine"

const heads:KV[] = [
    { text: "日付", field: "date" },
    { text: "使用者", field: "user" },
    { text: "使用機械", field: "machine" },
    { text: "稼働時間", field: "hour" },
    { text: "使用台数/距離", field: "unit" },
    { text: "油種", field: "oilType" },
    { text: "給油量", field: "oil" },
    { text: "修理内容", field: "repair" },
    { text: "特記事項", field: "remarks" },
    { text: "" }
]

const Filter:FiltersFunction = (filter, updateFilter, dateParam) => {
    const context = useAppContext()
    const machineOptions = context.options.machines.reduce((a, r) => ({...a, [r._id]:`${r.rental ? "レンタル" : "自社"} - ${r.title}`}), { 0: "" })

    return <>
        <FilterSelect
            label="使用機械"
            name="machine"
            value={filter.machine}
            option={machineOptions}
            setState={(v:any) => updateFilter("machine", v)}
            status={undefined}
            nolabel
        />
        <FilterDates dateParam={dateParam} />
    </>
}

const Row:RowFunction = ({ datum, clickDelete }) => {
    const machines = useAppContext().options.machines
    const machine = machines.find(v => v._id === Number(datum.machine))?.title || ''
    return <>
        <ListItem data={[datum.date]} date />
        <ListName data={[datum.user]} />
        <ListItem data={[machine]} />
        <ListItem data={[datum.hour]} />
        <ListItem data={[datum.unit, datum.distance]} />
        <ListItem data={[options.oilType[datum.oilType1], datum.oilType2 ? options.oilType[datum.oilType2] : ""]} />
        <ListItem data={[datum.oil1, datum.oil2]} />
        <ListItem data={[datum.repair]} />
        <ListItem data={[datum.remarks]} />
        <EditLinkTd location={location} id={datum._id} custId={undefined} onClickDelete={clickDelete} onClickCollection={undefined} onClickPrint={undefined} onClickEye={undefined} onClickEdit={undefined} />
    </>
}
const List = () => {
    const context = useAppContext()
    const download = (Data: KV[]) => Data.map(datum => ({
        "ID": datum._id,
        "日付": datum.date ? new Date(datum.date).toLocaleDateString() : "",
        "使用者": `${datum.user?.surname || ""} ${datum.user?.givenName || ""}`,
        "事業種別": datum.division ? options.division[datum.division] || "" : "",
        "事業ファイルNo": (datum.division && datum.project) ? datum.project[datum.division]?.fileID || "" : "",
        "事業名": (datum.division && datum.project) ? datum.project[datum.division]?.subject : "",
        "作業区分": datum.category || "",
        "使用機械": datum.machine ? context.options.machines.find(v => v._id === Number(datum.machine))?.title || "" : "",
        "使用時間": datum.hour || "",
        "使用台数": datum.unit || "",
        "走行距離": datum.distance || "",
        "油種1": datum.oilType1 ? options.oilType[datum.oilType1] || "" : "",
        "給油量1": datum.oil1 || "",
        "油種2": datum.oilType2 ? options.oilType[datum.oilType2] || "" : "",
        "給油量2": datum.oil2 || "",
        "修理内容": datum.repair || "",
        "特記事項": datum.remarks || ""
    }))
    
    if (context.state.machine?.display?.type ==="sum") return <Sum />
    return <DataList collection="machine" location={location} type="view" heads={heads} user={['user']} filter={Filter} dateField="date" displays download={download} row={Row} dialog={undefined} noAdd={undefined} handleRowClick={undefined} />
}

export default List