import React, { ChangeEvent, FormEvent, MouseEvent, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import * as Yup from 'yup';

import yupLocale from "contexts/yupLocale"
import relations from "realm/Yanagisawa/data_sources/mongodb-atlas/Business/user/relationships.json"
import { getSchemaValues, EditBase, FormFrame, FieldBase, RelationField, DivField } from 'components/edit'
import UserSelect from 'components/userSelect';
import { useRealmApp } from 'RealmApp';
import options from 'contexts/options.json';
import { ButtonClass } from "utils"
import { FormikProps } from 'formik';
import { isAdmin } from 'contexts/utils';

const schema = require("realm/Yanagisawa/data_sources/mongodb-atlas/Business/user/schema.json").properties;

Yup.setLocale(yupLocale)
const keys = ["surname", "givenName", "division", "division2", "division3", "tel", "email", "paidHoliday"]

export const FieldInput = ({ label, name, type = "text", values, setValues }:{ label: string; name: string; type?: string; values: KV; setValues: React.Dispatch<React.SetStateAction<KV>> }) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [name]: e.currentTarget.value });
    }
    return <>
        <label htmlFor={name} className="block text-sm font-medium text-gray-700">{label}</label>
        <input
            type={type}
            name={name}
            className="mt-1 block w-full shadow-sm sm:text-sm rounded-md disabled:bg-gray-100 read-only:bg-gray-100 focus:ring-indigo-500 focus:border-indigo-500  border-gray-300"
            value={values[name]}
            onChange={handleChange}
        />
    </>
}
const FormButton = ({ submitting, back }:{ submitting: boolean, back?: (() => void) }) => {
    const handleClick = async (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        e.stopPropagation()
        switch (e.currentTarget.name) {
            case "back": back && back(); break;
            default:
        }
    }
    return <>
        <button className={ButtonClass} disabled={submitting}>
            <CheckCircleIcon className="w-5 h-5" />確定
        </button>
        { back && <button type="button" name="back" className={ButtonClass} disabled={submitting} onClick={handleClick} >
            <XCircleIcon className="w-5 h-5" />戻る
        </button> }
    </>
}


const FormFields = ({ formik }: { formik:FormikProps<any> }) => {
    const app = useRealmApp()
    // field name for user selection dialog.  Also used as user selection dialog show bool
    const [currentName, setCurrentName] = React.useState<string|null>(null)

    const handleClick = (name:string) => {　
        setCurrentName(name)
    }
    
    return <div className="grid grid-cols-6 gap-4">
        <FormFrame formik={formik} schema={schema}>
            {(isAdmin(app) && formik.initialValues.userID === formik.initialValues._id) ? <FieldBase name="userID" /> : <DivField label="ユーザーID" value={formik.values.userID} />}
            {keys.map(key => (<FieldBase key={key} name={key} />))}
            { app.currentUser?.customData.status === "admin" ? <FieldBase name='status' /> : <DivField label="ユーザーステータス" value={options.userStatus[formik.values.status]} /> }
            <RelationField
                label="勤怠管理者"
                onClick={e => handleClick("supervisor")}
                item={formik.values.supervisor}
            />
            <RelationField
                label="目標管理者"
                onClick={e => handleClick("aimSupervisor")}
                item={formik.values.aimSupervisor}
            />
            <UserSelect name={currentName} setName={setCurrentName} formik={formik} allowNull />
        </FormFrame>
    </div>
}

const validationSchema = Yup.object({
    userID: Yup.string().required(),
    surname: Yup.string().required(),
    email: Yup.string().email().required(),
})

export const Create = () => {
    const navigate = useNavigate();
    const back = () => navigate(`/user`);
    const app = useRealmApp()
    const [success, setSuccess] = useState(false)
    const [userData, setUserData] = useState<KV>({ email: "", password: "", password2: "" })
    const [submitting, setSubmitting] = useState(false)

    const handleSubmit = async (e:FormEvent) => {
        e.preventDefault()
        const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        if (!userData.email.match(validRegex)) {
            alert("不正なメールアドレスです")
        } else if (userData.password !== userData.password2) {
            alert("パスワードが一致しません")
        } else {
            setSubmitting(true)
            try {
                await app.app.emailPasswordAuth.registerUser({ email: userData.email, password: userData.password })
                setSuccess(true)
            } catch (e) {
                if (e instanceof Error) alert(`エラー：\r\n${e.message}`)
            }
            setSubmitting(false)
        }
    }
    if (!isAdmin(app)) return <div className="flex justify-center">
        <div className="pt-5 2xl:pt-0 2xl:col-span-4 w-full max-w-4xl">
            <div className="shadow sm:rounded-md">
            <div className="p-4 text-theme-800 bg-theme-50 text-xl sm:px-6">システム管理者専用</div>
                <div className="p-4">ユーザー作成はシステム管理者のみが行えます。</div>
                <div className="px-4 bg-theme-50 text-center sm:px-6">
                    <button type="button" className={ButtonClass} onClick={() => back()}>
                        <CheckCircleIcon className="w-5 h-5" />OK
                    </button>
                </div>
            </div>
        </div>
    </div>
    if (success) return <div className="flex justify-center">
        <div className="pt-5 2xl:pt-0 2xl:col-span-4 w-full max-w-4xl">
            <div className="shadow sm:rounded-md">
            <div className="p-4 text-theme-800 bg-theme-50 text-xl sm:px-6">ユーザー登録完了</div>
                <div className="p-4">ユーザーを作成しました。当該ユーザーでログインするとアカウントが有効化されます。</div>
                <div className="px-4 bg-theme-50 text-center sm:px-6">
                    <button type="button" className={ButtonClass} onClick={() => back()}>
                        <CheckCircleIcon className="w-5 h-5" />OK
                    </button>
                </div>
            </div>
        </div>
    </div>
    return <div className="flex justify-center">
        <div className="pt-5 2xl:pt-0 2xl:col-span-4 w-full max-w-4xl">
            <div className="shadow sm:rounded-md">
                <form onSubmit={handleSubmit}>
                    <div className="p-4 text-theme-800 bg-theme-50 text-xl sm:px-6">新規ユーザー作成</div>
                    <div className="px-4 py-5 bg-white space-y-4 sm:p-6">
                        <FieldInput label="メールアドレス" name="email" values={userData} setValues={setUserData} />
                        <FieldInput label="パスワード" name="password" type="password" values={userData} setValues={setUserData} />
                        <FieldInput label="パスワード(再入力)" name="password2" type="password" values={userData} setValues={setUserData} />
                    </div>
                    <div className="px-4 bg-theme-50 text-right sm:px-6">
                        <FormButton submitting={submitting} back={back} />
                    </div>
                </form>
            </div>
        </div>
    </div>
};


export const Edit = () => {
    return (<EditBase title={"ユーザー情報"} values={getSchemaValues(schema)} collection="user" relations={relations} Fields={FormFields} validation={validationSchema} />);
};
export default Edit