import React, { MouseEvent, useEffect, useState } from 'react'
import { Field, FieldProps, FormikProps } from "formik"
import forestItem from "contexts/forestItem.json"
import { PlusIcon, TrashIcon } from '@heroicons/react/24/solid'
import DatePicker from 'components/datepicker';
import { JSTToday, numberOfWeekdays } from 'contexts/dateUtils';
import { useData } from 'graphql/useCollection';
import Loading from 'components/Loading';
import { useParams } from 'react-router-dom';

const humanCostCode = 101;

const Button = ({
    icon,
    name,
    onClick,
    value,
  }: {
    icon: any;
    name?: string;
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
    value: number;
  }) => (
    <button
      name={name}
      type="button"
      onClick={onClick}
      className="m-px p-px flex justify-center items-center 
  bg-white hover:bg-theme-100 focus:ring-theme-100 focus:ring-offset-theme-200 text-theme-500 
  transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 
  w-5 h-5 rounded-lg"
      value={value}
    >
      {icon}
    </button>
  );
  

const InputWithMark = ({ mark, index, name, progresses, actual }:{mark?:string, index:number, name:string, progresses?:KV[], actual?:boolean}) => (<Field name={`block${actual ? "Actual" : "Plan"}[${index}].${name}`}>{({ field, form }:FieldProps) => (<div className="relative">
        <input 
            type={name === "blockname" ? "text" : "number"}
            className="text-right w-full border-0 focus:border focus:border-theme-500 focus:shadow-tableCell py-0 pl-2 pr-14"
            {...field}
            onKeyUp={e => {}}
        />
        <span className="absolute w-12 inset-y-0 right-0 px-1 border-l border-gray-500">{(actual && progresses) ? (progresses[index]?.[name] || 0) : mark || ""}{(actual && progresses) ? "%" : ""}</span>
    </div>)}</Field>)

const BlockNameInput = ({ index, name, onClick }:{index:number, name:string, onClick:(e:MouseEvent<HTMLButtonElement>)=>void}) => (<Field name={`blockPlan[${index}].${name}`}>{({ field, form }:FieldProps) => (<div className="relative flex">
        <input 
            type={name === "blockname" ? "text" : "number"}
            className="text-left w-full border-0 focus:border focus:border-theme-500 focus:shadow-tableCell py-0 px-2"
            {...field}
            onKeyUp={e => {}}
        />
        <span className="w-12 flex justify-center items-center border-l border-gray-500 bg-white"><Button name="delete" icon={<TrashIcon className="w-4 h-4" />} onClick={onClick} value={index} /></span>
    </div>)}</Field>)

const ForestProgress = ({ formik, costItem }:{ formik:FormikProps<any>, costItem?:KV[] }) => {
    let { id } = useParams<KV>();
    const [progresses, setProgresses] = useState<KV[]>(Array.from(Array(formik.values.blockPlan.length).keys()).map(v => ({area:0, days:0, woodVolume:0, mainHuman:0, distance:0, roadHuman:0, budget:0})))
    const [totals, setTotals] = useState<KV>({ plan:{}, actual:{}})
    const { loading: humanLoading, Data: humanData } = useData("attendance", null, { item: { project: { forest: { _id: id } } } })

    const humanUnitPrice = costItem?.find(v => v._id === humanCostCode)?.unitPrice || 0

    const items = forestItem.block as KV

    
    const handleAddItemClick = () => {
        formik.setFieldValue("blockPlan", [...formik.values.blockPlan, { blockname:"", area:"", days:"", woodVolume:"", mainHuman:"", distance:"", roadHuman:"", budget:""}])
        formik.setFieldValue("blockActual", [...formik.values.blockActual, { blockname:"", area:"", days:"", woodVolume:"", mainHuman:"", distance:"", roadHuman:"", budget:""}])
        setProgresses([...progresses, {area:0, days:0, woodVolume:0, mainHuman:0, distance:0, roadHuman:0, budget:0}])
    }
    const handleDeleteClick = (e:MouseEvent<HTMLButtonElement>) => {
        const index = Number(e.currentTarget.value)
        if (window.confirm(`ブロック${formik.values.blockPlan[index]?.blockname || ""}を消去します。よろしいですか？`)) {
            let array = [...formik.values.blockPlan]
            array.splice(index, 1)
            formik.setFieldValue("blockPlan", array)
            if (formik.values.blockActual) {
                let array = [...formik.values.blockActual]
                array.splice(index, 1)
                formik.setFieldValue("blockActual", array)
            }
            array = [...progresses]
            array.splice(index, 1)
            setProgresses(array)
        }
    }
    const calc = () => {
        let progressNew = [...progresses]
        for (const index in Array.from(Array(formik.values.blockPlan.length).keys())) {
            for (const key in items) {
                if (Number(formik.values.blockPlan[index][key])) {
                    progressNew[Number(index)][key] = Math.floor(Number(formik.values.blockActual[index][key]) * 100 / Number(formik.values.blockPlan[index][key]))
                }
            }
        }
        setProgresses(progressNew)
    }

    const calcTotal = () => {
        let totalsNew:KV = { plan:{}, actual:{} }
        for (const index in Array.from(Array(formik.values.blockPlan.length).keys())) {
            for (const key in items) {
                if (["blockname", "start", "end"].includes(key)) continue
                totalsNew.plan[key] = (totalsNew.plan[key] || 0) + (Number(formik.values.blockPlan[index][key]) || 0)
                totalsNew.actual[key] = (totalsNew.actual[key] || 0) + (Number(formik.values.blockActual[index][key]) || 0)
            }
        }
        setTotals(totalsNew)
    }

    const afterUpdateDate = (date:Date|undefined, index:number, key:string) => {
        const today = JSTToday()
        switch (key) {
            case "start":
                if (!date) formik.setFieldValue(`blockActual[${index}].days`, null)
                else {
                    const days = numberOfWeekdays(date, formik.values.blockActual[index].end || today)
                    formik.setFieldValue(`blockActual[${index}].days`, days)
                }
                break;
            case "end":
                const days = numberOfWeekdays(formik.values.blockActual[index].start, date || today)
                formik.setFieldValue(`blockActual[${index}].days`, days)
                break;
        }
        calcTotal()
    }

    useEffect(() => {
        for (const index in Array.from(Array(formik.values.blockPlan.length).keys())) {
            if (formik.values.blockActual[index].start) {
                const days = numberOfWeekdays(new Date(formik.values.blockActual[index].start), new Date(formik.values.blockActual[index].end || JSTToday()))
                console.log(index, days)
                formik.setFieldValue(`blockActual[${index}].days`, days)
            }
        }
        calc()
        calcTotal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let unitsSummary:KV = Array.from(Array(formik.values.blockPlan.length).keys()).map(v => ({ "主作業": { "工数":0, "費用":0 }, "作業道": { "工数":0, "費用":0 } }))
    unitsSummary.forEach((units:KV, i:number) => {
        if (!formik.values.blockPlan[i].start) return
        const startDate = new Date(formik.values.blockPlan[i].start).getTime()
        const nextEndDate = new Date(formik.values.blockPlan[i].end || JSTToday()).getTime() + 86400000;
        (humanData || []).filter(v => v.start && v.end && new Date(v.start).getTime() >= startDate && new Date(v.end).getTime() < nextEndDate).forEach(v => {
            v.item.forEach((eachItem:any) => {
                const category = eachItem.work?.category
                const unit = eachItem.halfday ? 0.5 : 1.0
                if (units[category]) {
                    units[category].工数 = units[category].工数 + unit
                    units[category].費用 = units[category].費用 + unit * humanUnitPrice
                }
            })
        })
    })

    if (humanLoading) return <Loading />
    return <>
    <button type="button" onClick={handleAddItemClick} className="flex justify-center items-center bg-theme-50 hover:bg-theme-100 text-theme-800 w-8 h-8 rounded-2xl transition ease-in duration-200"><PlusIcon className="w-4 h-4" /></button>

    <div className="grid grid-cols-10 border border-gray-500 bg-gray-500 gap-px">
        {Object.keys(items).map(key => <React.Fragment key={`title-${key}`}>
            <div className="p-1 bg-theme-200">{items[key].name}</div>
        </React.Fragment>)}
        {Array.from(Array(formik.values.blockPlan.length).keys()).map((v, i) => <React.Fragment key={i}>{
            Object.keys(items).map((key, index) => {
                switch (key) {
                    case "blockname": return <BlockNameInput key={`${key}-${i}`} index={i} name={key} onClick={handleDeleteClick} />
                    case "start" :
                    case "end" :
                        return <div key={`${key}-${i}`} className="flex items-center bg-white"><DatePicker name={`blockActual[${i}].${key}`} span={1} formik={formik} setParentDate={(d) => afterUpdateDate(d, i, key)} enableHistory notinform/></div>
                    default: return <div key={`${key}-${i}`} ><InputWithMark mark={items[key].unit} index={i} name={key} /><div className="h-px bg-gray-500"></div><InputWithMark index={i} name={key} progresses={progresses} actual /></div> 
                }
            })
        }
        </React.Fragment>)}
        {<React.Fragment>{
            Object.keys(items).map((key, index) => {
                switch (key) {
                    case "blockname": return <div className="p-2 bg-theme-50" key={key}>合計</div>
                    case "start" :
                    case "end" :
                        return <div  className="bg-theme-50" key={key} />
                    default: 
                        const percentage = (Number(totals.plan[key]) && Number(totals.actual[key])) ? Math.floor(Number(totals.actual[key]) * 100 / Number(totals.plan[key])) : ""
                        return <div key={key} >
                            <div className="text-right relative border-0 py-0 pl-2 pr-14 bg-theme-50">{totals.plan[key] || "　"} <span className="absolute text-left w-12 inset-y-0 right-0 px-1 border-l border-gray-500">{items[key].unit}</span></div>
                            <div className="h-px bg-gray-500"></div>
                            <div className="text-right relative border-0 py-0 pl-2 pr-14 bg-theme-50">{totals.actual[key] || "　"}<span className="absolute text-left w-12 inset-y-0 right-0 px-1 border-l border-gray-500">{percentage}{percentage ? "%" : ""}</span></div>
                        </div> 
                }
        })}</React.Fragment>}
    </div>
    </>
}

export default ForestProgress

