//import { createPdf } from 'pdfmake/build/pdfmake.min';
 
export const baseStyle = {
    font: 'GenShin',
    fontSize: 10,
    alignment: 'left',
    margin: [0, 2],
    pageMargins: [35, 35],
}

export const underlineLayout = {
    hLineWidth: function (i: number) { return i === 0 ? 0 : 1; },
    vLineWidth: function (i: number) { return 0; },
    paddingLeft: function (i: number) { return 0 },
    paddingTop: function (i: number) { return 0 },
    paddingRight: function (i: number) { return 0 },
    paddingBottom: function (i: number) { return 0 },
}

export const noborderLayout = {
    defaultBorder: false,
    paddingLeft: function (i: number) { return 0 },
    paddingTop: function (i: number) { return 0 },
    paddingRight: function (i: number) { return 6 },
    paddingBottom: function (i: number) { return 0 },
}

export const lastUnderlineLayout = {
    hLineWidth: function (i: number, node:any) { return i === node.table.body.length ? 1 : 0; },
    vLineWidth: function (i: number) { return 0; },
    paddingLeft: function (i: number) { return 0 },
    paddingTop: function (i: number) { return 0 },
    paddingRight: function (i: number) { return 0 },
    paddingBottom: function (i: number) { return 0 },
}



const address = [68, 34, 257, 104]
// address is [left excluding pageMargin, top excluding pageMargin, width, height]

export const pageStyle = {
    pageMargins: 35,
    address: { x: address[0] - baseStyle.pageMargins[0], y: Math.max(address[1] - baseStyle.pageMargins[0], 0), width: address[2] - address[0], height: address[3] - address[1] },
    height: 55,//min 52
    minHeight: 60,
    totalHeight: 515 - 30,
    headerHeight: 150,
    footerHeight: 60,
    fontSizes: { title: 16, subtitle: 14, name: 12, spot: 16 },
    margins: { spot: 16 },
    margin: 4,
    cellPadding: 5,
}

export const defaultStyle = {
    font: 'GenShin',
    fontSize: 10,
    alignment: 'center',
    margin: [0, 2]
}

// Table which contains only one cell for name
export const getNameLabelContent = ({names, margin, width}:{names:string[], margin?:number[], width?:number}) => ({
    table: {
        widths: [width || pageStyle.address.width],
        body: [[
            { text: names[0] + " " + names[1], fontSize: pageStyle.fontSizes.name },
        ]]
    },
    layout: underlineLayout,
    margin: margin || [pageStyle.address.x, 4, 0, 0],
    type: "nameLabel"
})

export const getLocaleDateString = (date: any) => date ? new Date(date).toLocaleDateString('ja-JP-u-ca-japanese', { year: 'numeric', month: 'short', day: 'numeric' }) : ''

export const formatPrice = (num: number, minusMark?:string) => num ? ((minusMark && num < 0) ? minusMark : '') + String((minusMark && num < 0) ? -num : num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') : ""

// count 2 for multibyte
export const countChar = (str: string) => {
    let len = 0;
    for (const s of str) {
        s.match(/[ -~]/) ? len += 1 : len += 2
    }
    return len;
}

// calculate multibyte index from given singlebyte index
export const multiByteIndex = (str:string, index:number) => {
    let sIndex = 0
    let mIndex = 0
    for (const s of str){
        if (sIndex < index) {
            sIndex += s.match(/[ -~ｦ-ﾟ]/) ? 1 : 2
            mIndex++
        }
    }
    return mIndex
}

export const addLineBreak = (str: string, length: number, suffix: string, rows: number = 1): string => {
    if (countChar(str) <= length * rows) return str + "　" + suffix
    let mIndex = str.lastIndexOf(" ", multiByteIndex(str, length * rows))
    if (mIndex < 0) mIndex = str.lastIndexOf("　", multiByteIndex(str, length * rows))
    if (mIndex < 0) return addLineBreak(str.slice(mIndex + 1), length, suffix, rows + 1) // if no space found, include next row
    const after = str.slice(mIndex + 1)
    return str.slice(0, mIndex) + `\r\n` + ((countChar(after) > length) ? addLineBreak(after, length, suffix) : after + "　" + suffix);
}


export const makePdf = async (combinedData: any, baseUrl: string, adjustingWidth?:any) => {
    const pdfmake = await import('pdfmake/build/pdfmake')
    const { coverNameLabelContent, nameLabelContents, ...data } = combinedData

    // make pdf contains only provided content to calculate width
    const getPdfItems = (content:any) => {
        return new Promise<any[]>((resolve, reject) => {
            try {
                const pdfDocGenerator = pdfmake.createPdf({content, defaultStyle: {font: "GenShin"}}, undefined, fonts)
                // Need to modify pdfmake.d.ts to include cb?: (doc: any) => void as second argument of getStream
                // doc._pdfMakePages[0].items[0].item.inlineWidths is actual width if content is plain text only.
                pdfDocGenerator.getStream(undefined, (doc) => {
                    const items:any[] = doc._pdfMakePages[0].items
                    resolve(items)
                });
            } catch (e) {
                reject(e)
            }
        })
    }

    const adjustFontSize = async (content:any) => {
        let items = await getPdfItems(content)
        // Maximum loop number
        let count = 10
        const targetLength = (content.table.body[0][0].text?.split('\n').length || 1) + 1
        while (items.length > targetLength && count > 0) {
            content.table.body[0][0].fontSize--
//            content.table.body[0][1].fontSize--
            items = await getPdfItems(content)
            count--
        }
        console.log(items)
    }
    
    const fonts = {
        GenShin: {
            normal: baseUrl + 'GenShinGothic-Regular.ttf',
            bold: baseUrl + 'GenShinGothic-Regular.ttf',
            italics: baseUrl + 'GenShinGothic-Regular.ttf',
            bolditalics: baseUrl + 'GenShinGothic-Regular.ttf'
        }
    };
    try {
        if (coverNameLabelContent) await adjustFontSize(coverNameLabelContent)
        if (nameLabelContents) {
            for (const i of Array.from({ length:nameLabelContents.length }, (_, i) => i)) { await adjustFontSize(nameLabelContents[i]) }
        }
        // if (items.length > 4) coverNameLabelContext.table.body[0][1].margin = [0, items[items.length-4].item.y - items[0].item.y, 0, 0]
        pdfmake.createPdf(data, undefined, fonts).open()

    } catch (e) {
        alert('エラー:\n' + (e instanceof Error ? e.message : e))
    }
}
