import React, { ChangeEvent, MouseEvent } from 'react';
import * as Yup from 'yup';
import Decimal from 'decimal.js';
import { Tab } from '@headlessui/react'
import { FormikProps } from 'formik';

import { PlusIcon } from '@heroicons/react/24/solid'

import { useData } from "graphql/useCollection";
import yupLocale from "contexts/yupLocale"
import relations from "realm/Yanagisawa/data_sources/mongodb-atlas/Business/forest/relationships.json"
import { getObjectData, getSchemaValues, CreateBase, EditBase, FormFrame, FieldGroup, FieldBase, RelationField } from 'components/edit'
import { ListItem, EditLinkTd } from "components/list"
import { Create as CreateCustomer } from 'pages/customer/edit'
import ViewCustomer from 'pages/customer/view'
import ForestBalance from 'pages/forest/balance'
import ForestBalanceDetail from 'pages/forest/balanceDetail'

import Loading from "components/Loading";
import options from "contexts/options.json"
import CustomerSearch from 'components/customerSearch'
import UserSelect from 'components/userSelect'
import ItemEdit from 'pages/tree/itemEdit'
import DialogFrame from 'components/dialog'
import ForestPlan from './plan';
import ForestProgress from './progress';
import { SimpleListFrame } from 'components/ListFrame';

const schema = require("realm/Yanagisawa/data_sources/mongodb-atlas/Business/forest/schema.json").properties;

Yup.setLocale(yupLocale)
const formatCurrency = (num?:number) => String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') + '円'


// For table view
//const InputWithMark = ({ mark, index, name }) => (<Field name={`costItem[${index}].${name}`}>{({ field, form }) => (<div className="relative col-span-2"><input type="number" className="text-right w-full border-0 focus:border focus:border-theme-500 focus:shadow-tableCell py-0 pl-2 pr-9" {...field} onChange={e => handleChange(e, index, form)} /><span className="absolute inset-y-0 right-0 pr-1">{mark}</span></div>)}</Field>)
//const InputName = ({ index, name }) => (<Field name={`costItem[${index}].${name}`}>{({ field }) => (<input className="w-full border-0 focus:border focus:border-theme-500 focus:shadow-tableCell py-0 px-2 col-span-3" {...field} />)}</Field>)
//const InputNumber = ({ index, name }) => (<Field name={`costItem[${index}].${name}`}>{({ field, form }) => (<input type="number" className="text-right w-full border-0 focus:border focus:border-theme-500 focus:shadow-tableCell py-0 px-2" {...field} onChange={e => handleChange(e, index, form)} />)}</Field>)
//const InputCheck = ({ index, name }) => (<Field name={`costItem[${index}].${name}`}>{({ field }) => (<div className='bg-white border-0 flex justify-center items-center'><input type="checkbox" className="focus:border focus:border-theme-500 focus:shadow-tableCell py-0 px-2" {...field} checked={field.value} /></div>)}</Field>)
// handle change of each cell
const handleChange = (e:ChangeEvent<HTMLInputElement>, index:number, formik:FormikProps<any>) => {
    const quantity = new Decimal((e.target.name.endsWith("quantity") ? e.target.value : formik.values.costItem[index].quantity) || 0)
    const unitPrice = new Decimal((e.target.name.endsWith("unitPrice") ? e.target.value : formik.values.costItem[index].unitPrice) || 0)
    const listPrice = new Decimal((e.target.name.endsWith("listPrice") ? e.target.value : formik.values.costItem[index].listPrice) || 0)
    const amount = formik.values.costItem[index].unit === "yen" ? unitPrice.toFixed() : quantity.times(unitPrice).floor().toFixed()
    const listAmount = formik.values.costItem[index].unit === "yen" ? listPrice.toFixed() : quantity.times(listPrice).floor().toFixed()
    formik.values.costItem[index].amount = amount === "0" ? "" : amount
    formik.values.costItem[index].listAmount = listAmount === "0" ? "" : listAmount
    recalcTotal(formik, null, formik.values.costItem[index].quoteId)
    formik.handleChange(e)
}

// quoteIndex is quotes[quoteIndex] | null, id is quoteId | undefined.  Set either quoteIndex or id.
// BuyAmount is deducted from totalCost
const recalcTotal = (formik:FormikProps<any>, quoteIndex?:number|null, id?:number) => {
    const i = quoteIndex !== null ? quoteIndex : formik.values.quotes.findIndex((v:KV) => v.quoteId === id)
    const quoteId = id !== undefined ? id : formik.values.quotes[i].quoteId
    const buyAmount = new Decimal(formik.values.quotes[i].buyQuantity || 0).times(formik.values.quotes[i].buyUnitPrice || 0).floor().toNumber()
    formik.values.quotes[i].totalCost = formik.values.costItem.filter((v:KV) => v.quoteId === quoteId).reduce((a:number, r:KV) => a + parseInt(r.amount || 0), 0) - buyAmount
    formik.values.quotes[i].totalListCost = formik.values.costItem.filter((v:KV) => v.quoteId === quoteId).reduce((a:number, r:KV) => a + parseInt(r.listAmount || 0), 0) - buyAmount
    const totalCost = new Decimal(formik.values.quotes[i].totalCost || 0)
    const marginRate = new Decimal(1).minus(new Decimal(formik.values.quotes[i].margin || 0).dividedBy(100))
    const amount = totalCost.dividedBy(marginRate).minus(new Decimal(formik.values.quotes[i].adjust || 0)).floor().toFixed()
    formik.values.quotes[i].amount = parseInt(amount === "Infinity" ? "0" : amount)
}

// i is quoteIndex
export const calcNonstoredNumbers = ((data:KV, i=0) => {
    const quoteId = data.quotes[i].quoteId
    const buyAmount = new Decimal(data.quotes[i].buyQuantity || 0).times(data.quotes[i].buyUnitPrice || 0).floor().toNumber()
    const amountAfterBuy = data.quotes[i].amount + buyAmount
    const overhead = new Decimal(amountAfterBuy || 0).times(new Decimal(data.quotes[i].overhead || 0)).dividedBy(new Decimal(data.quotes[i].overhead || 0).plus(100)).floor().toNumber()
    const remainOverhead = overhead - data.costItem.filter((item:KV) => item.quoteId === quoteId && item.code > 800).reduce((a:number, r:KV) => a + parseInt(r.listAamount || r.amount || 0), 0)
    const humanCost = parseInt(amountAfterBuy || 0) - data.costItem.filter((item:KV) => item.quoteId === quoteId && item.code !== 101 && !item.hide).reduce((a:number, r:KV) => a + parseInt(r.listAmount || r.amount || 0), 0) - remainOverhead + parseInt(data.quotes[i].adjust || 0)
    const tax = new Decimal(data.quotes[i].amount || 0).times(new Decimal(data.quotes[i].taxRate || 0).dividedBy(100)).floor().toNumber()
    const amountWithTax = parseInt(data.quotes[i].amount || 0) + tax
    return { buyAmount, remainOverhead, humanCost, tax, amountWithTax }
})


const FormFieldsNormal = ({ formik, quoteIndex }:{ formik:FormikProps<any>, quoteIndex?:number }) => {
    const [showCreateCustomer, setShowCreateCustomer] = React.useState(false)
    const [showViewCustomer, setShowViewCustomer] = React.useState(false)
    const [showCustomer, setShowCustomer] = React.useState(false)
    const [currentName, setCurrentName] = React.useState<string|null>(null)
    // use only key1.index.key2 type name
    const handleChangeNumber = (e:ChangeEvent<HTMLInputElement>) => {
        const [key1, index, key2] = e.target.name.split(".")
        formik.values[key1][index][key2] = e.target.value
        recalcTotal(formik, quoteIndex)
        formik.handleChange(e)
    }
    const handleCustomerClick = (e:MouseEvent<HTMLDivElement>) => {
        const name = e.currentTarget.getAttribute("name")
        if (name === "openCreate") setShowCreateCustomer(true)
        else if (name === "openView") setShowViewCustomer(true)
        else setShowCustomer(true)
        e.preventDefault()
    }
    const handleCreateCustomer = (id?:string, values?:KV) => {
        let data = values ? getObjectData(values) : null
        data && (data._id = id)
        id && data && formik.setFieldValue("customer", data)
        setShowCreateCustomer(false)
    }
     return (
        <div className="grid grid-cols-6 gap-6">
            <FormFrame formik={formik} schema={schema}>
                <FieldBase name="fileID" />
                <FieldBase name="status" />
                <FieldBase name="subject" />
                <FieldBase name="abbreviation" />
                <FieldBase name="complex" />
                <RelationField
                    label="受付者"
                    onClick={e => setCurrentName("receptionist")}
                    item={formik.values.receptionist}
                    span={2}
                />
                <FieldBase name="receptionDate" />
                <RelationField
                    label="設計者"
                    onClick={e => setCurrentName("designManager")}
                    item={formik.values.designManager}
                    span={2}
                />
                <RelationField
                    label="現場担当者"
                    onClick={e => setCurrentName("inCharge")}
                    item={formik.values.inCharge}
                    span={2}
                />
                <UserSelect name={currentName} setName={setCurrentName} formik={formik} />
                <FieldBase name="siteSurveyDate" />
                <RelationField
                    label="顧客名"
                    onClick={handleCustomerClick}
                    item={formik.values.customer}
                    withButton
                />
                <CustomerSearch show={showCustomer} setShow={setShowCustomer} formik={formik} />
                <FieldBase name="woodSales" />
                {/*<FieldBase name="subsidy" />
                <FieldBase name="totalCost" />
     <FieldBase name="progress" />*/}
                <FieldGroup name="operationDates">
                     <FieldBase name="startDate" />
                     <FieldBase name="endDate" />
                </FieldGroup>
                 <FieldBase name="handleDate" />
                 <FieldBase name="invoiceDate" />
                 <RelationField
                     label="請求書作成者"
                     onClick={e => setCurrentName("invoiceAuthor")}
                     item={formik.values.invoiceAuthor}
                     span={2}
                 />
                 <FieldBase name="receivedDate" />
                 <FieldBase name="attendActive" />
            </FormFrame>
            { showCreateCustomer && <DialogFrame show={showCreateCustomer} setShow={setShowCreateCustomer} wide><CreateCustomer completed={(id, values) => handleCreateCustomer(id, values)} /></DialogFrame> }
             {showViewCustomer && <DialogFrame show={showViewCustomer} setShow={setShowViewCustomer} wide><ViewCustomer id={formik.values.customer._id} canceled={e => setShowViewCustomer(false)} /></DialogFrame>}
        </div>
    )
}

const heads = ["コード", "大分類", "小分類", "項目名", "数量", "原価単価", "表示単価", "原価金額", "表示金額", "非表示", ""]
/*
const FormFieldsItemList = ({ schema, formik, quoteIndex, quoteId }) => {
    const fullName = "costItem"
    const [newItem, setNewItem] = React.useState(false)
    const [showItemInput, setShowItemInput] = React.useState(null)
    const [redrawFlag, setRedrawFlag] = React.useState(false)
    const handleClick = (e, costId) => {
        setNewItem(false)
        setShowItemInput(costId)
    }
    const handleDeleteClick = (e, id) => {
        let obj = formik.values.costItem.find(value => value.costId === id)
        obj.quantity = ""
        if (obj.unit === 'yen') {
            obj.unitPrice = ''
            obj.listPrice = ''
        }
        obj.amount = ""
        obj.listAmount = ""
        recalcTotal(formik, null, obj.quoteId)
        setRedrawFlag(!redrawFlag)
    }
    const handleAddItemClick = e => {
        setNewItem(true)
        setShowItemInput(0)
    }
    return (<>
        <div className="px-4 flex justify-between">
            <button type="button" onClick={handleAddItemClick} className="flex justify-center items-center bg-theme-50 hover:bg-theme-100 text-theme-800 w-8 h-8 rounded-2xl transition ease-in duration-200"><PlusIcon className="w-4 h-4" /></button>
            <div className="font-medium">{`合計原価: ${formatCurrency(formik.values.quotes[quoteIndex].totalCost)}　合計表示金額: ${formatCurrency(formik.values.quotes[quoteIndex].totalListCost)}`}</div>
        </div>
        <SimpleListFrame heads={heads} noAdd>
            {(formik.values.costItem?.filter(v => v.quoteId === quoteId).sort((a, b) => a.code - b.code) || []).filter(datum => datum.quantity || (datum.unit === "yen" && datum.unitPrice && datum.listPrice)).map((datum, i) => (
            <tr key={`${fullName}${i}`} className="text-sm text-gray-900">
                <ListItem data={[datum.code]} />
                <ListItem data={[datum.category1]} />
                <ListItem data={[datum.category2]} />
                <ListItem data={[datum.name]} />
                <ListItem data={datum.unit === 'yen' ? ['1式'] : [`${datum.quantity}${options.costUnit[datum.unit]}`]} />
                <ListItem data={[datum.unitPrice]} />
                <ListItem data={[datum.listPrice]} />
                <ListItem data={[datum.amount]} />
                <ListItem data={[datum.listAmount]} />
                <ListItem data={[datum.hide ? "非表示" : ""]} />
                <EditLinkTd id={i} onClickDelete={e => handleDeleteClick(e, datum.costId)} onClickEdit={e => handleClick(e, datum.costId)} />
            </tr>
        ))}
    </SimpleListFrame>
        <ItemEdit newItem={newItem} showItem={showItemInput} setShowItem={setShowItemInput} quoteId={quoteId} schema={schema.costItem.items} formik={formik} recalcTotal={recalcTotal}/>
    </>)
}
*/

const validationSchema = Yup.object({
//    _id: Yup.string().required(),
})


// Remove costItem whose quantity is empty or zero
const writePrepare = (updates:KV, original?:KV) => {
}
/*    updates.costItem = updates.costItem.filter(item => (item.quantity && item.quantity !== "0") || (item.unit === 'yen' && item.unitPrice && item.unitPrice !== "0" && item.listPrice && item.listPrice !== "0"))
    if (updates.status !== original.status) updates.statusBefore = original.status
    updates.dealAmount = updates.quotes.reduce((a, quote) => quote.active ? a + parseInt(quote.amount) : a, 0)
}*/

export const Create = () => {
    const { loading, Data: costItemData } = useData("treeItem")
    if (loading) return <Loading />
    const FormFields = ({ formik }:{ formik:FormikProps<any> }) => {
        return (<Tab.Group>
            <Tab.List className="p-0.5 rounded-lg bg-theme-700">
            {["一般項目", "設計項目", "ブロック"].map((tab, i) => <Tab key={`tab${i}`}
                    className={({ selected }) =>
                        (selected ? 'bg-white text-theme-800' : 'text-theme-100 bg-transparent hover:bg-theme-600')
                        + ' mr-2 px-3 py-2 rounded-md text-sm font-medium'
                    }
                >{tab}</Tab>
                )}
            </Tab.List>
            <Tab.Panels>
                <Tab.Panel><FormFieldsNormal formik={formik} /></Tab.Panel>
                <Tab.Panel><ForestPlan formik={formik} costItem={costItemData || []} /></Tab.Panel>
                <Tab.Panel><ForestProgress formik={formik} /></Tab.Panel>
            </Tab.Panels>
        </Tab.Group >)
    }
    return (<CreateBase title={"山林情報"} values={getSchemaValues(schema)} collection="forest" relations={relations} Fields={FormFields} writePrepare={writePrepare} validation={validationSchema} />);
};

export const Edit = () => {
    const { loading, Data: costItemData } = useData("treeItem")
    if (loading) return <Loading />
    const FormFields = ({ formik }:{ formik:FormikProps<any> }) => {
        return (<Tab.Group>
            <Tab.List className="p-0.5 rounded-lg bg-theme-700">
                {["一般項目", "設計項目", "収支集計", "原価詳細"].map((tab, i) => <Tab key={`tab${i}`}
                    className={({ selected }) =>
                        (selected ? 'bg-white text-theme-800' : 'text-theme-100 bg-transparent hover:bg-theme-600')
                        + ' mr-2 px-3 py-2 rounded-md text-sm font-medium'
                    }
                >{tab}</Tab>
                )}
            </Tab.List>
            <Tab.Panels>
                <Tab.Panel><FormFieldsNormal formik={formik} /></Tab.Panel>
                <Tab.Panel><ForestPlan formik={formik} costItem={costItemData || []} /></Tab.Panel>
                <Tab.Panel><ForestBalance formik={formik}  costItem={costItemData || []} /></Tab.Panel>
                <Tab.Panel><ForestBalanceDetail formik={formik}  costItem={costItemData || []} /></Tab.Panel>
            </Tab.Panels>
        </Tab.Group >)
    }
    return (<EditBase title={"山林情報"} values={getSchemaValues(schema)} collection="forest" relations={relations} Fields={FormFields} readPrepare={(d) => {}} writePrepare={writePrepare} validation={validationSchema} />);
};
export default Edit
