import React, { Fragment, useState, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { FormikProps } from 'formik';
import { DayPicker, DateFormatter } from 'react-day-picker';
import ja from 'date-fns/locale/ja';
import { CalendarIcon, XMarkIcon } from '@heroicons/react/24/outline'
import 'react-day-picker/dist/style.css';

import './datepicker.css';
import { setJSTHour } from 'contexts/dateUtils';
/*

.DayPicker-Caption {
    ...
    text-align: center;
}
.DayPicker-NavButton--prev {
  right: auto;
  left: 1.5em;
  background-image: ...
}

  .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff;
    color: #4a90e2;
  }
  .Selectable .DayPicker-Day {
    border-radius: 0;
  }
  .Selectable .DayPicker-Day--start {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }
  .Selectable .DayPicker-Day--end {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }
*/

const OpacityTransition = ({ children }:React.PropsWithChildren<{}>) => (
    <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
    >{children}
    </Transition.Child>
)
const TranslateTransition = ({ children }:React.PropsWithChildren<{}>) => (
    <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enterTo="opacity-100 translate-y-0 sm:scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    >{children}
    </Transition.Child>
)
const formatCaption:DateFormatter = (d:Date) => { // Do not set months prop to use this format
    return <>{`${d.getFullYear()}年 ${d.getMonth() + 1}月`}</>
}

export const DatePickerDialog = ({show, setShow, date, weekStartsOn = 1, handleDayClick, cancelButtonRef, enableHistory, noClear}:{show:boolean; setShow:React.Dispatch<boolean>; date:Date | undefined; weekStartsOn?:0|1|2|3|4|5|6; handleDayClick:(day:Date|undefined, modifiers:KV) => void; cancelButtonRef:any; enableHistory?:boolean; noClear?:boolean}) => 
    <Transition.Root show={show} as={Fragment}>
    <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={show}
        onClose={setShow}
    >
        <div className="flex items-start justify-center min-h-screen pb-4 px-4 pt-20 text-center sm:block sm:p-0">
            <OpacityTransition>
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </OpacityTransition>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <TranslateTransition>
                <div className="inline-block align-top bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xs sm:w-full">
                    <div className="bg-white p-2 sm:p-4">
                        <div className="ml-auto flex-shrink-0 flex items-center justify-center h-8 w-8 rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10">
                            <XMarkIcon className="h-6 w-6 text-gray-400" aria-hidden="true" onClick={() => setShow(false)} />
                        </div>
                        <div className="text-center sm:mt-0 sm:ml-0 sm:text-left">
                            <DayPicker
                                className="Selectable"
                                locale={ja}
                                weekStartsOn={weekStartsOn}
                                formatters={{ formatCaption }}
                                onDayClick={handleDayClick}
                                selected={date}
                                fixedWeeks
//                                enableOutsideDaysClick={false}
                                disabled={enableHistory ? [] : [{before:new Date()}]}
                            />
                            {!noClear && <div className="flex justify-center"><button type="button" className="w-18 p-2 rounded bg-gray-100" onClick={() => handleDayClick(undefined, {})}>クリア</button></div>}

                        </div>
                    </div>
                </div>
            </TranslateTransition>
        </div>
    </Dialog>
</Transition.Root>

/**
 * 
 * set either formik or value/setValue
 * @param {Object} obj Parameter object
 * @param {string} obj.name Name of input
 * @param {(string|number)?} obj.span Span of component
 * @param {string?} obj.holder Placeholder for input
 * @param {FormikProps<any>?} obj.formik Formik object
 * @param {function(Date):void} obj.setParentDate Called when day is clicked
 * @param {Date?} obj.value Value for input
 * @param {React.Dispatch<Date>?} obj.setValue Function to set value
 * @param {boolean?} obj.buttonOnly Show only button without displaying date
 * @param {boolean?} obj.enableHistory Make past date selectable
 * @param {boolean?} obj.notinform Make component smaller
 * @return {JSX.Element} DatePicker component
 */
const DatePicker = ({
    label,
    name,
    span = "3",
    holder,
    formik,
    setParentDate,
    value,
    setValue,
    buttonOnly = false,
    enableHistory = true,
    notinform = false
}:{
    label?:string,
    name:string,
    span?:string|number,
    holder?:string,
    formik?:FormikProps<any>,
    setParentDate?:(date:Date|undefined) => void,
    value?:Date,
    setValue?:React.Dispatch<Date|undefined>|((date:Date|undefined) => void),
    buttonOnly?:boolean,
    enableHistory?:boolean,
    notinform?:boolean,
}) => {
    const [show, setShow] = useState(false)
    const [date, setDate] = useState(formik?.getFieldProps(name).value ? new Date(formik.getFieldProps(name).value) : undefined)
    const handleDayClick = (day:Date|undefined, modifiers:KV = {}) => {
        const actualDay = day ? setJSTHour(day, 0) : day
        if (modifiers.disabled) return;
        if (setValue) {
            setValue(actualDay)
        } else {
            setDate(actualDay);
        }
        setParentDate && setParentDate(actualDay);
        setShow(false);
        formik?.setFieldValue(name, actualDay)
    }

    const cancelButtonRef = useRef(null)
    if (buttonOnly) return <>
        <button className="p-2 flex items-center border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" onClick={() => setShow(true)}>
            <CalendarIcon className="h-4 w-4 mr-2" aria-hidden="true" />日付指定
        </button>
        { show && <DatePickerDialog show={show} setShow={setShow} date={date} handleDayClick={handleDayClick} cancelButtonRef={cancelButtonRef} enableHistory={enableHistory} /> }
    </>
    return (
        <div className={`relative col-span-6 sm:col-span-${span}`}>
            {label && <label htmlFor={name} className="block text-sm font-medium text-gray-700">
                {label}
            </label>}
            <div className={`relative rounded-md shadow-sm${notinform ? "" : " mt-1"}`}>
                <input
                    type="text"
                    name={name}
                    id={name}
                    readOnly
                    className={`focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-4 pr-12 sm:text-sm border-gray-300 rounded-md cursor-pointer${notinform ? " py-1" : ""}`}
                    placeholder={holder}
                    onClick={() => setShow(true)}
                    value={value ? `${value.toLocaleDateString()}` : date ? `${date.toLocaleDateString()}` : ''}
                />
                <div className="absolute inset-y-0 right-0 flex items-center">
                    <CalendarIcon className="h-4 w-4 mx-4 cursor-pointer" aria-hidden="true" onClick={() => setShow(true)} />
                </div>
            </div>
           { show && <DatePickerDialog show={show} setShow={setShow} date={value || date} handleDayClick={handleDayClick} cancelButtonRef={cancelButtonRef} enableHistory={enableHistory}  /> }
        </div>)
}

export default DatePicker