import React, { useEffect, useRef, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';

import { Create as CreateCustomer, Edit as EditCustomer } from 'pages/customer/edit'
import ListCustomer from 'pages/customer/list'
import ViewCustomer from 'pages/customer/view'
import { Create as CreateFirewood, Edit as EditFirewood } from 'pages/firewood/edit'
import ListFirewood from 'pages/firewood/list'
import { Create as CreateFirewoodProduct, Edit as EditFirewoodProduct } from 'pages/firewoodProduct/edit'
import ListFirewoodProduct from 'pages/firewoodProduct/list'
import { Create as CreateTree, Edit as EditTree } from 'pages/tree/edit'
import ListTree from 'pages/tree/list'
import { Create as CreateTreeItem, Edit as EditTreeItem } from 'pages/treeItem/edit'
import ListTreeItem from 'pages/treeItem/list'
import { Create as CreateForest, Edit as EditForest } from 'pages/forest/edit'
import ListForest from 'pages/forest/list'
import BalanceForest from 'pages/forest/balance'
import { Create as CreateTimber, Edit as EditTimber } from 'pages/timber/edit'
import ListTimber from 'pages/timber/list'
import { Create as CreateTransport, Edit as EditTransport } from 'pages/transport/edit'
import ListTransport from 'pages/transport/list'
import { Create as CreateAttendance, Edit as EditAttendance } from 'pages/attendance/edit'
import { default as ListAttendance, ListMobile as ListAttendanceMobile } from 'pages/attendance/list'
import ViewAttendance from 'pages/attendance/view'
import SheetAttendance from 'pages/attendance/sheet'
import { Create as CreateMachine, Edit as EditMachine } from 'pages/machine/edit'
import ListMachine from 'pages/machine/list'
import { Create as CreateUser, Edit as EditUser } from 'pages/user/edit'
import { Create as CreateAim, Edit as EditAim } from 'pages/aim/edit'
import ListAim from 'pages/aim/list'
import ListUser from 'pages/user/list'
import { Create as CreateWork, Edit as EditWork } from 'pages/work/edit'
import ListWork from 'pages/work/list'
import ListMessage from 'pages/message/list'
import Talk from 'pages/message/talk'
import Summarize from 'pages/summarize/list';

import { RealmAppProvider, RequireLoggedInUser, useRealmApp } from "RealmApp";
import RealmApolloProvider from "graphql/RealmApolloProvider";
import AppContextProvider from 'contexts/AppContext';
import Header from 'components/Header';
import DashItem from 'components/dashitem';
import DashItemMobile from 'components/dashitemMobile';
import { isAdmin, isRetired, isTemporary } from 'contexts/utils';
import { ButtonClass } from 'utils';
import { ErrorBoundary } from 'react-error-boundary';

export const APP_ID = "yanagisawa-afscl";

const ErrorPage = () => <div className="pt-16 text-red-600 w-full h-full flex justify-center"><ExclamationTriangleIcon className="w-6 h-6" />このページを表示する権限がないか、存在しないページです</div>

const Document = () => {
  const app = useRealmApp()
  const [mobile, setMobile] = useState<boolean>(window.matchMedia("(max-width: 640px)").matches)
  const mobileRef = useRef(false)
  mobileRef.current = mobile

  useEffect(() => {
    const handleResize = () => {
      if (mobileRef.current !== window.matchMedia("(max-width: 640px)").matches) setMobile(!mobileRef.current)
      // old check method for mobile document.getElementById('breakpoint-sm')?.offsetParent 
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <BrowserRouter>
    <Header />
    <div className='grow overflow-scroll'>
      <AppContextProvider>
        <Routes>
          <Route path='/' element={mobile === null ? <div></div> : mobile ? <DashItemMobile /> : <DashItem />} />
          {!isTemporary(app) && !isRetired(app) && <>
            <Route path='customer'>
              <Route index element={<ListCustomer />} />
              <Route path='create' element={<CreateCustomer completed={undefined} />} />
              <Route path='edit/:id' element={<EditCustomer />} />
              <Route path='view/:id' element={<ViewCustomer id={undefined} canceled={undefined} />} />
            </Route>
            <Route path='firewood'>
              <Route index element={<ListFirewood />} />
              <Route path='create' element={<CreateFirewood />} />
              <Route path='edit/:id' element={<EditFirewood />} />
            </Route>
            <Route path='firewoodproduct'>
              <Route index element={<ListFirewoodProduct />} />
              <Route path='create' element={<CreateFirewoodProduct />} />
              <Route path='edit/:id' element={<EditFirewoodProduct />} />
            </Route>
            <Route path='tree'>
              <Route index element={<ListTree />} />
              <Route path='create' element={<CreateTree />} />
              <Route path='edit/:id' element={<EditTree />} />
            </Route>
            <Route path='treeitem'>
              <Route index element={<ListTreeItem />} />
              <Route path='create' element={<CreateTreeItem />} />
              <Route path='edit/:id' element={<EditTreeItem />} />
            </Route>
            <Route path='forest'>
              <Route index element={<ListForest />} />
              <Route path='create' element={<CreateForest />} />
              <Route path='edit/:id' element={<EditForest />} />
              {/*<Route path='/forest/balance/:id' element={<BalanceForest />} />*/}
            </Route>
            <Route path='timber'>
              <Route index element={<ListTimber />} />
              <Route path='create' element={<CreateTimber />} />
              <Route path='edit/:id' element={<EditTimber />} />
            </Route>
            <Route path='transport'>
              <Route index element={<ListTransport />} />
              <Route path='create' element={<CreateTransport />} />
              <Route path='edit/:id' element={<EditTransport />} />
            </Route>
            <Route path='summarize'>
              <Route index element={<Summarize />} />
            </Route>
          </>}
          {!isRetired(app) && <>
            <Route path='machine'>
              <Route index element={<ListMachine />} />
              <Route path='create' element={<CreateMachine />} />
              <Route path='edit/:id' element={<EditMachine />} />
            </Route>
            <Route path='attendance'>
              <Route index element={mobile === null ? <div></div> : mobile ? <ListAttendanceMobile /> : <ListAttendance />} />
              <Route path='create' element={<CreateAttendance />} />
              <Route path='view/:id' element={<ViewAttendance id={undefined} />} />
              <Route path='edit/:id' element={<EditAttendance />} />
              <Route path='editend/' element={<EditAttendance endNow />} />
              <Route path='sheet' element={<SheetAttendance />} />
            </Route>
            <Route path='aim'>
              <Route index element={<ListAim />} />
              <Route path='create' element={<CreateAim />} />
              <Route path='edit/:id' element={<EditAim />} />
            </Route>
            <Route path='/message/' element={<ListMessage />} />
            <Route path='/message/talk/:id/:name' element={<Talk />} />
            <Route path='user'>
              {isAdmin(app) && <>
                <Route index element={<ListUser />} />
                <Route path='create' element={<CreateUser />} />
              </>}
              <Route path='edit/:id' element={<EditUser />} />
            </Route>
          </>}
          {isAdmin(app) && <Route path='work'>
            <Route index element={<ListWork />} />
            <Route path='create' element={<CreateWork />} />
            <Route path='edit/:id' element={<EditWork />} />
          </Route>
          }
          <Route path='*' element={<ErrorPage />} />
        </Routes>
      </AppContextProvider>
    </div>
  </BrowserRouter>
}


const InternalErrorPage = ({ error }: { error: any }) => <div className="p-4 flex flex-col gap-4 items-center">
  <span className="p-2 w-full sm:max-w-4xl text-center text-xl font-bold text-red-700 bg-red-300">エラー</span>
  <span className="text-red-700">内部エラーが発生しました。</span>
  <span className="sm:max-w-4xl">{error?.message || ''}</span>
  <a href={'/'} className={ButtonClass}>トップ画面</a>
</div>

const App = () => <ErrorBoundary FallbackComponent={InternalErrorPage}>
  <RealmAppProvider appId={APP_ID}>
    <RequireLoggedInUser><>
      <RealmApolloProvider>
        <div className="App flex flex-col print:inline-block h-full">
          <Document />
          <footer className="p-1 text-center text-sm bg-theme-800 text-white print:hidden" >k intelligence　柳沢林業</footer>
        </div>
      </RealmApolloProvider>
    </></RequireLoggedInUser>
  </RealmAppProvider>
</ErrorBoundary>


export default App;
