import Loading from "components/Loading";
import { FormikProps } from "formik";
import { useData } from "graphql/useCollection"
import React from "react"
import { useParams } from "react-router-dom";
import { formatNumber } from "utils";
import { getHumanSummary, getMachineSummary, getOilSummary, getTransportSummary } from "./calc";



const NameItem = ({children, className}:{children:any, className?:string}) => {
    return <div className={`text-left pl-1 bg-white ${className || ""}`}>{children}</div>
}
const NumItem = ({children, className}:{children:any, className?:string}) => {
    return <div className={`text-right pr-1 bg-white ${className || ""}`}>{formatNumber(children)}</div>
}



const BalanceDetail = ({formik, costItem}:{formik:FormikProps<any>, costItem:KV[]}) => {
    let { id } = useParams<KV>();
    const { loading: machineLoading, Data: machineData } = useData("machine", null, { project: { forest: {_id: id } } })
    const { loading: timberLoading, Data: timberData } = useData("timber", null, { project: { forest: {_id: id } } })
    const { loading: transportLoading, Data: transportData } = useData("transport", null, { project: { forest: {_id: id } } })
    const { loading: humanLoading, Data: humanData } = useData("attendance", null, { item: { project: { forest: { _id: id } } } })
    if (machineLoading || timberLoading || transportLoading || humanLoading) return <Loading />
    /* { [machineName]:{unit:0.5(unit), distance:km}, [oilType]:Liter }   oiltype mix, chain, heavychain, diesel */
    //const getItemCost = (category:string, name:string) => !name ? 0 : (costItem.find(v => v.category1 === category && v.name === name)?.unitPrice || 0)
    const machineSummary:KV = !machineData ? {} : getMachineSummary(machineData, costItem);
    const oilSummary:KV = !machineData ? {} : getOilSummary(machineData, costItem);
    const transportSummary:KV = !transportData ? {} : getTransportSummary(transportData, costItem)
    /* { [truckType]:{trucks:0.5(unit), distance:km}, toll:Yen, totalCost:Yen } */
/* { [truckType]:{quantity:unit, totalPrice:Yen } */ 
    const timberSummary:KV = !timberData ? {} :  timberData.reduce((a, r) => ({
        quantity:Number(a.quantity || 0) + Number(r.quantity || 0), 
        totalPrice:Number(a.totalPrice || 0) + Number(r.totalPrice || 0)
    }), {})
    /* { [workCategory]:{ [workItem]:0.5(day) } } */
    const humanSummary:KV =  !humanData ? {} : getHumanSummary(humanData, costItem)
    console.log(humanData)
    console.log(humanSummary)
    console.log(transportSummary)
    console.log(timberSummary)
    return <><div className="m-4 border border-gray-500 grid grid-cols-5 bg-gray-500 gap-px">
        <div className="text-center bg-theme-200">大項目</div>
        <div className="text-center bg-theme-200">中項目</div>
        <div className="text-center bg-theme-200">小項目</div>
        <div className="text-center bg-theme-200">補助項目</div>
        <div className="text-center bg-theme-200">金額</div>

        <NameItem className={`row-span-${3+Object.keys(machineSummary.主作業).length+Object.keys(oilSummary.主作業).length}`}>主作業</NameItem>
        <NameItem className="col-span-3">人件費</NameItem><NumItem>{humanSummary.主作業 || 0}</NumItem>
        <NameItem className={`row-span-${Object.keys(machineSummary.主作業).length}`}>機械経費</NameItem>
        {Object.keys(machineSummary.主作業).map(key => <React.Fragment key={key}><NameItem className="col-span-2">{key}</NameItem><NumItem>{machineSummary.主作業[key]?.machineCost || 0}</NumItem></React.Fragment>)}
        <NameItem className={`row-span-${Object.keys(oilSummary.主作業).length}`}>燃料費</NameItem>
        {Object.keys(oilSummary.主作業).map(key => <React.Fragment key={key}><NameItem className="col-span-2">{key}</NameItem><NumItem>{oilSummary.主作業[key] || 0}</NumItem></React.Fragment>)}
        <NameItem>外注費</NameItem><NameItem className="col-span-2">重機回送費</NameItem><NumItem>{formik.values.mainActual?.forwardingCost || 0}</NumItem>
        <NameItem className="col-span-3">その他経費</NameItem><NumItem>{formik.values.mainActual?.otherCost}</NumItem>

        <NameItem className={`row-span-${3+Object.keys(machineSummary.作業道).length+Object.keys(oilSummary.作業道).length}`}>作業道</NameItem>
        <NameItem className="col-span-3">人件費</NameItem><NumItem>{humanSummary.作業道 || 0}</NumItem>
        {Boolean(Object.keys(machineSummary.作業道).length) && <NameItem className={`row-span-${Object.keys(machineSummary.作業道).length}`}>機械経費</NameItem>}
        {Object.keys(machineSummary.作業道).map(key => <React.Fragment key={key}><NameItem className="col-span-2">{key}</NameItem><NumItem>{machineSummary.作業道[key]?.machineCost || 0}</NumItem></React.Fragment>)}
        <NameItem className="col-span-3">賃借料(ﾊﾞｯｸﾎｰ)</NameItem><NumItem>{formik.values.roadActual?.bacfhoeCost || 0}</NumItem>
        {Boolean(Object.keys(oilSummary.作業道).length) && <NameItem className={`row-span-${Object.keys(oilSummary.作業道).length}`}>燃料費</NameItem>}
        {Object.keys(oilSummary.作業道).map(key => <React.Fragment key={key}><NameItem className="col-span-2">{key}</NameItem><NumItem>{oilSummary.作業道[key] || 0}</NumItem></React.Fragment>)}
        <NameItem>外注費</NameItem><NameItem className="col-span-2">重機回送費</NameItem><NumItem>{formik.values.roadActual?.forwardingCost || 0}</NumItem>

        <NameItem className="row-span-3">運材</NameItem>
        <NameItem className="col-span-3">人件費</NameItem><NumItem>{transportSummary.human || 0}</NumItem>
        {Boolean(Object.keys(transportSummary.machine).length) && <NameItem className={`row-span-${Object.keys(transportSummary.machine).length}`}>機械経費・燃料費</NameItem>}
        {Object.keys(transportSummary.machine).map(key => <React.Fragment key={key}><NameItem className="col-span-2">{key}</NameItem><NumItem>{transportSummary.machine[key]?.machineCost || 0}</NumItem></React.Fragment>)}
        <NameItem>外注費</NameItem><NameItem className="col-span-2">運材外注費</NameItem><NumItem>{transportSummary.outsource || 0}</NumItem>
        <NameItem>共通仮設</NameItem>
        <NameItem className="col-span-3">人件費</NameItem><NumItem>{humanSummary.共通仮設 || "0"}</NumItem>
        
    </div>
    </>
}

export default BalanceDetail