import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { XCircleIcon, PencilSquareIcon } from '@heroicons/react/24/solid'
import { useDatum } from 'graphql/useCollection'
import Loading from "components/Loading";
import options from "contexts/options.json"
import { JSTMonthDate, JSTTime } from "contexts/dateUtils";

const attendance = require("realm/Yanagisawa/data_sources/mongodb-atlas/Business/attendance/schema.json").properties;
const keys = ["user", "date", "start", "end", "rest", "nightRest", "isOver", "isHoliday", "item", "gearCost", "workplace", "car", "status", "remarks"]
const TitleCell = ({ title, children }:{ title:string, children:JSX.Element }) => <tr><td className="text-right bg-theme-200">{title}</td><td>{children}</td></tr>
const title = (key:string) => key ==="date" ? '日付' : (attendance[key].title || attendance[key].description?.match(/[^<>]*(?=>)/g)?.[0])
const Item = (items:KV[]) => <div className="flex justify-start">
    <div className="grid grid-cols-[repeat(4,max-content)] gap-px p-px bg-gray-500 [&>*]:px-2">
    {["部門", "案件", "作業内容", "労災保険"].map(v => <div className="bg-theme-200">{v}</div>)}
    {items.map(item => <><div className="bg-theme-50">{item.holiday || options.division[item.division]}</div>
        <div className="bg-white">{item.project?.forest?.subject || item.project?.tree?.subject || ''}</div>
        <div className="bg-white">{item.work?.item || ''}</div>
        <div className="bg-white">{item.insurance ? options.insurance[item.insurance] : ''}</div>
    </>)}
    </div>
</div>
const DataView = ({id, canceled}:{id?:string, canceled?:() => void}) => {
    let onCancel = canceled || (() => { window.close() })
    let { id: idParam } = useParams();
    let actualId = id || idParam
    const navigate = useNavigate();
    const { loading, Data } = useDatum("attendance", { _id: actualId })
    console.log(Data)
    return ( loading ? <Loading /> : 
        <div className="relative m-4 flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200 text-left text-sm text-gray-500">
                            <tbody>{keys.filter(key => key !== "__typename").map(key => (<TitleCell key={key} title={title(key)}>{
                                ((key === "date") && JSTMonthDate(new Date(Data.start), true)) ||
                                (["start", "end"].includes(key) && (Data[key] && JSTTime(new Date(Data[key])))) ||
                                (["rest", "nightRest"].includes(key) && `${(Number(Data[key]) / 60000 || 0)}分`) ||
                                (["isOver", "isHoliday"].includes(key) && (Data[key]) ? '◯' : '') ||
                                ((key === "status") && options.attendanceStatus[Data.start]) ||
                                ((key === "user") && (Data.user?.name || " ")) ||
                                ((key === "item") && (Data.item?.length ? Item(Data.item) : "項目なし")) ||
                                (Data[key] || "")
                            }</TitleCell>))}</tbody> 
                        </table>
                    </div>
                </div>
            </div>
            <div className="px-4 bg-gray-50 text-right sm:px-6">
                <button
                    className="inline-flex justify-center m-3 py-2 px-4 w-28 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-theme-600 hover:bg-theme-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-500"
                    onClick={e => { navigate(`/attendance/edit/${actualId}`) }}
                >
                    <PencilSquareIcon className="w-5 h-5" />編集
                </button>
            <button
                className="inline-flex justify-center m-3 py-2 px-4 w-28 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-theme-600 hover:bg-theme-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-500"
                onClick={onCancel}
            >
                <XCircleIcon className="w-5 h-5" />
                閉じる
            </button>
            </div>
        </div>
    )
}

export default DataView