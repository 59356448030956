import React from "react"

import { DataList, EditLinkTd, ListItem, RowFunction } from "components/list"
import options from "contexts/options.json"
const schema = require("realm/Yanagisawa/data_sources/mongodb-atlas/Business/work/schema.json").properties;
const location = "/work"

const getTitle = (obj:KV) => obj.description?.match(/[^<>]*(?=>)/g) || obj.title || ""
const keys = ["_id", "costID", "division", "category", "item", "remarks"]

const heads = keys.map<KV>(key => ({ text:getTitle(schema[key]), field: key })).concat([{text:"有効"}, {}])
const types = keys.map(key => schema[key].description?.replace(/\([\s\S]*?\)/g, '').replace(/\{[\s\S]*?\}/g, '').replace(/<[\s\S]*?>/g, '').split(":") || ['text'])

const Row:RowFunction = ({ datum, clickDelete }) =>  <>
    {
        keys.map((key, i) => (<td key={key+i}>
            <div>{types[i][0] === "select" ? options[types[i][1]][datum[key]] : datum[key]}</div>
        </td>
        ))
    }
    <ListItem data={[datum.active ? "有効" : ""]} badge />
    <EditLinkTd location={location} id={datum._id} noEdit={datum.active}  onClickDelete={clickDelete} />
</>

const List = () => <DataList collection="work" location={location} heads={heads} row={Row} />


export default List
