import Loading from "components/Loading"
import { FilterSelect } from "components/list"
import { JSTYear, getTermDates } from "contexts/dateUtils"
import options from "contexts/options.json"
import { useData } from "graphql/useCollection"
import React, { useState } from "react"

const Summarize = () => {
  const [year, setYear] = useState(String(JSTYear()))
  const [term, setTerm] = useState("quarter1")
  const [startDate, endDate] = getTermDates(year, term)
  const divisions = [...Object.keys(options.division).filter(v => Boolean(v) && !["admin", "camp", "ecoclub"].includes(v))]
  const { loading, Data } = useData("attendance", "view", { start_gte: startDate, start_lt: endDate }, undefined, 100000)
  const { loading: machineLoading, Data: machineData } = useData("machine", "view", { date_gte: startDate, date_lt: endDate }, undefined, 100000)

  const summarizeHuman = (Data || []).reduce((acc, datum) => {
    const key = (datum.item[0]?.division && datum.item[0].division !== "admin") ? datum.item[0].division : undefined // summarizing target division or undefined
    let key1 = (datum.item[1]?.division && datum.item[1]?.division !== "admin") ? datum.item[1].division : undefined // summarizing target division or undefined
    return { ...acc, ...(key ? { [key]: acc[key] + (datum.item[1] ? 0.5 : 1) } : {}), ...(key1 ? { [key1]: acc[key1] + 0.5 } : {}) }
  }, divisions.reduce((a, r) => ({ ...a, [r]: 0 }), {}))
  const totalHuman = divisions.reduce((a, r) => a + summarizeHuman[r], 0)
  
  const summarizeHumanOnsite = (Data || []).reduce((acc, datum) => {
    const key = (datum.item[0]?.division && datum.item[0].division !== "admin") ? datum.item[0].division : undefined // summarizing target division or undefined
    let key1 = (datum.item[1]?.division && datum.item[1]?.division !== "admin") ? datum.item[1].division : undefined // summarizing target division or undefined
    const inclusive = [false, false]
    if (Number(datum.item[0]?.work?._id || "9999") < 103 || ["201", "202"].includes(datum.item[0]?.work?._id || "0")) inclusive[0] = true
    if (datum.item[1] && (Number(datum.item[1].work?._id || "9999") < 103 || ["201", "202"].includes(datum.item[1].work?._id || "0"))) inclusive[1] = true
    if (!inclusive[0] && !inclusive[1]) return acc
    return { ...acc, ...(key ? { [key]: acc[key] + (inclusive[0] ? (datum.item[1] ? 0.5 : 1) : 0) } : {}), ...(inclusive[1] ? { [key1]: acc[key1] + 0.5 } : {}) }
  }, divisions.reduce((a, r) => ({ ...a, [r]: 0 }), {}))
  const totalHumanOnsite = divisions.reduce((a, r) => a + summarizeHumanOnsite[r], 0)

  const summarizeLightMachine = (machineData || []).reduce((acc, datum) => {
    if (Number(datum.machine) > 300 || Number(datum.machine) < 200) return acc
    const key = datum.division
    if (!key || key === "admin") return acc
    return { ...acc, [key]: acc[key] + (Number(datum.hour) || (Number(datum.unit || 0) * 8) || 0) }
  }, divisions.reduce((a, r) => ({ ...a, [r]: 0 }), {}))
  const totalLightMachine = divisions.reduce((a, r) => a + summarizeLightMachine[r], 0)

  const summarizeMachine = (machineData || []).reduce((acc, datum) => {
    if (Number(datum.machine) < 300) return acc
    const key = datum.division
    if (!key || key === "admin") return acc
    return { ...acc, [key]: acc[key] + (Number(datum.hour) || (Number(datum.unit || 0) * 8) || 0) }
  }, divisions.reduce((a, r) => ({ ...a, [r]: 0 }), {}))
  const totalMachine = divisions.reduce((a, r) => a + summarizeMachine[r], 0)

  const summarizeTransport = (machineData || []).reduce((acc, datum) => {
    if (Number(datum.machine) > 200) return acc
    const key = datum.division
    if (!key || key === "admin") return acc
    return { ...acc, [key]: acc[key] + (Number(datum.distance) || 0) }
  }, divisions.reduce((a, r) => ({ ...a, [r]: 0 }), {}))
  const totalTransport = divisions.reduce((a, r) => a + summarizeTransport[r], 0)

  const summarizeMixOil = (machineData || []).reduce((acc, datum) => {
    const key = datum.division
    if (!key || key === "admin") return acc
    return { ...acc, [key]: acc[key] + (datum.oilType1 === "mix" ? (Number(datum.oil1) || 0) : 0) + (datum.oilType2 === "mix" ? (Number(datum.oil2) || 0) : 0) }
  }, divisions.reduce((a, r) => ({ ...a, [r]: 0 }), {}))
  const totalMixOil = divisions.reduce((a, r) => a + summarizeMixOil[r], 0)

  const summarizeOil = (machineData || []).reduce((acc, datum) => {
    const key = datum.division
    if (!key || key === "admin") return acc
    return { ...acc, [key]: acc[key] + (datum.oilType1 === "diesel" ? (Number(datum.oil1) || 0) : 0) + (datum.oilType2 === "diesel" ? (Number(datum.oil2) || 0) : 0) }
  }, divisions.reduce((a, r) => ({ ...a, [r]: 0 }), {}))
  const totalOil = divisions.reduce((a, r) => a + summarizeOil[r], 0)

  return <div className="p-4">
    <div className="p-2 bg-theme-100">
      <FilterSelect
        label="年"
        name="year"
        value={year}
        width={24}
        optionName="year"
        onBlur={(e) => setYear(e.target.value)}
        nolabel
        nodefault
        nohyphen
      />
      <FilterSelect
        label="期"
        name="term"
        value={term}
        width={30}
        optionName="term"
        onBlur={(e) => setTerm(e.target.value)}
        nolabel
        nodefault
        nohyphen
      />
    </div>
    {(loading || machineLoading) ? <Loading /> : <table className="mt-2">
      <thead className="text-center"><tr><th></th>{divisions.map((v) => <th className="w-24" key={v}>{options.division[v] || "その他"}</th>)}</tr></thead>
      <tbody className="text-right">
        <tr className="border-b"><td className="text-left bg-theme-50">{"人工数(人日)"}</td>{divisions.map((v) => <td key={v}>{Math.round(summarizeHuman[v] * 100) / 100}</td>)}</tr>
        <tr className="border-b-2"><td className="text-left bg-theme-50">人工比率</td>{divisions.map((v) => <td key={v}>{totalHuman ? (summarizeHuman[v] * 100 / totalHuman).toFixed(2) : 0}%</td>)}</tr>
        <tr className="border-b"><td className="text-left bg-theme-50">{"現業人工数(人日)"}</td>{divisions.map((v) => <td key={v}>{Math.round(summarizeHumanOnsite[v] * 100) / 100}</td>)}</tr>
        <tr className="border-b-2"><td className="text-left bg-theme-50">現業人工比率</td>{divisions.map((v) => <td key={v}>{totalHumanOnsite ? (summarizeHumanOnsite[v] * 100 / totalHumanOnsite).toFixed(2) : 0}%</td>)}</tr>
        <tr className="border-b"><td className="text-left bg-theme-50">{"軽機(時間)"}</td>{divisions.map((v) => <td key={v}>{Math.round(summarizeLightMachine[v] * 100) / 100}</td>)}</tr>
        <tr className="border-b-2"><td className="text-left bg-theme-50">軽機比率</td>{divisions.map((v) => <td key={v}>{totalLightMachine ? (summarizeLightMachine[v] * 100 / totalLightMachine).toFixed(2) : 0}%</td>)}</tr>
        <tr className="border-b"><td className="text-left bg-theme-50">{"軽機燃料(l)"}</td>{divisions.map((v) => <td key={v}>{Math.round(summarizeMixOil[v] * 100) / 100}</td>)}</tr>
        <tr className="border-b-2"><td className="text-left bg-theme-50">軽機燃料比率</td>{divisions.map((v) => <td key={v}>{totalMixOil ? (summarizeMixOil[v] * 100 / totalMixOil).toFixed(2) : 0}%</td>)}</tr>
        <tr className="border-b"><td className="text-left bg-theme-50">{"重機(時間)"}</td>{divisions.map((v) => <td key={v}>{Math.round(summarizeMachine[v] * 100) / 100}</td>)}</tr>
        <tr className="border-b-2"><td className="text-left bg-theme-50">重機比率</td>{divisions.map((v) => <td key={v}>{totalMachine ? (summarizeMachine[v] * 100 / totalMachine).toFixed(2) : 0}%</td>)}</tr>
        <tr className="border-b"><td className="text-left bg-theme-50">{"重機燃料(l)"}</td>{divisions.map((v) => <td key={v}>{Math.round(summarizeOil[v] * 100) / 100}</td>)}</tr>
        <tr className="border-b-2"><td className="text-left bg-theme-50">重機燃料比率</td>{divisions.map((v) => <td key={v}>{totalOil ? (summarizeOil[v] * 100 / totalOil).toFixed(2) : 0}%</td>)}</tr>
        <tr className="border-b"><td className="text-left bg-theme-50">{"車両(km)"}</td>{divisions.map((v) => <td key={v}>{Math.round(summarizeTransport[v] * 100) / 100}</td>)}</tr>
        <tr className="border-b-2"><td className="text-left bg-theme-50">車両比率</td>{divisions.map((v) => <td key={v}>{totalTransport ? (summarizeTransport[v] * 100 / totalTransport).toFixed(2) : 0}%</td>)}</tr>
      </tbody>
    </table>}


  </div>
}
export default Summarize