import React, { ChangeEvent } from 'react';
import * as Yup from 'yup';
import Decimal from 'decimal.js';
import { Form, Formik, FormikProps } from 'formik';
import DialogFrame from "components/dialog"
import { FormFrame, FormButton, FieldBase, DivField, ItemEditFunction } from 'components/edit'
import { FieldSelect, FieldWithMark } from "components/uiparts"
import options from "contexts/options.json"
import { XCircleIcon } from '@heroicons/react/24/solid';
import { ButtonClass } from 'utils';

const validationSchema = Yup.object({
})

// Dialog to input treeItem
const ItemEdit:ItemEditFunction = ({ newItem, showItem, setShowItem, quoteId, schema, formik, recalcTotal }) => {
    const fullName = "costItem"
    // calculating new amount
    const handleChange = (e:ChangeEvent<HTMLInputElement|HTMLSelectElement>, itemFormik:FormikProps<any>) => {
        let item:KV|undefined;
        switch (e.target.name) {
            case "code": item = formik.values[fullName].find((datum:KV) => datum.code === parseInt(e.target.value) && datum.quoteId === quoteId); break;
            case "category1": item = formik.values[fullName].find((datum:KV) => datum.category1 === e.target.value && datum.quoteId === quoteId); break;
            case "category2": item = formik.values[fullName].find((datum:KV) => datum.category1 === itemFormik.values.category1 && datum.category2 === e.target.value && datum.quoteId === quoteId); break;
            case "name": item = formik.values[fullName].find((datum:KV) => datum.category1 === itemFormik.values.category1 && datum.category2 === itemFormik.values.category2 && datum.name === e.target.value && datum.quoteId === quoteId); break;
            default:
                const quantity = new Decimal((e.target.name === "quantity" ? e.target.value : itemFormik.values.quantity) || 0)
                const actualQuantity = new Decimal((e.target.name === "actualQuantity" ? e.target.value : itemFormik.values.actualQuantity) || 0)
                const unitPrice = new Decimal((e.target.name === "unitPrice" ? e.target.value : itemFormik.values.unitPrice) || 0)
                const actualUnitPrice = new Decimal((e.target.name === "actualUnitPrice" ? e.target.value : itemFormik.values.actualUnitPrice) || 0)
                const listPrice = new Decimal((e.target.name === "listPrice" ? e.target.value : itemFormik.values.listPrice) || 0)
                const amount = itemFormik.values.unit === "yen" ? unitPrice.toFixed() : quantity.times(unitPrice).floor().toFixed()
                itemFormik.values.amount = amount === "0" ? "" : amount
                const actualAmount = itemFormik.values.unit === "yen" ? actualUnitPrice.toFixed() : actualQuantity.times(actualUnitPrice.equals(new Decimal(0)) ? unitPrice : actualUnitPrice).floor().toFixed()
                //const actualAmount = itemFormik.values.unit === "yen" ? (actualUnitPrice.equals(new Decimal(0)) ? unitPrice : actualUnitPrice).toFixed() : actualQuantity.times(actualUnitPrice.equals(new Decimal(0)) ? unitPrice : actualUnitPrice).floor().toFixed()
                itemFormik.values.actualAmount = actualAmount === "0" ? "" : actualAmount
                const listAmount = itemFormik.values.unit === "yen" ? (listPrice.equals(new Decimal(0)) ? unitPrice : listPrice).toFixed() : quantity.times(listPrice).floor().toFixed()
                itemFormik.values.listAmount = listAmount === "0" ? "" : listAmount
        }
        if (item) Object.keys(itemFormik.values).forEach(key => itemFormik.values[key] = ["_id", "code"].includes(key) ? parseInt(item?.[key]) : item?.[key])
        itemFormik.handleChange(e)
    }
    const category1s = Array.from(new Set<string>(formik.values[fullName].map((datum:KV) => datum.category1))).reduce((a,r) => ({...a, [r]:r}), {})
    const getCategory2s = (category1:string) => Array.from(new Set(formik.values[fullName].filter((datum:KV) => datum.category1 === category1).map((datum:KV) => datum.category2))).reduce((a:KV, r:any) => ({ ...a, [r]: r }), {})
    const getNames = (category1:string, category2:string) => Array.from(new Set(formik.values[fullName].filter((datum:KV) => datum.category1 === category1 && datum.category2 === category2).map((datum:KV) => datum.name))).reduce((a:KV, r:any) => ({ ...a, [r]: r }), {})
    const isShow = Boolean(showItem) || (showItem === 0)
    let initialValues = undefined
    if (isShow) {
        const itemValues = newItem ? formik.values[fullName].find((v:KV) => v.quoteId === quoteId && !v.quantity) : formik.values[fullName].find((datum:KV) => datum.costId === showItem)
        initialValues = itemValues ? {...itemValues} : undefined
    }
    
    if (isShow && !initialValues) return (<DialogFrame show={isShow} setShow={setShowItem}>
        <div>表示できる項目がありません</div>
        <button
            className={ButtonClass}
            onClick={e => { e.preventDefault(); setShowItem(undefined); }}
        >
            <XCircleIcon className="w-5 h-5" />
            戻る
        </button>
    </DialogFrame>)

    return (<DialogFrame show={isShow} setShow={setShowItem}>
        { isShow &&
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting }) => {
                    let updates:KV = Object.keys(values).reduce((a, r) => ({ ...a, [r]: values[r]}), {})//getUpdatingData({}, values)
                    //                    writePrepare(updates)
                    const index = formik.values[fullName].findIndex((datum:KV) => datum.costId === updates.costId)
                    formik.values[fullName][index] = updates
                    setSubmitting(false);
                    recalcTotal && recalcTotal(formik, null, quoteId)
                    setShowItem(undefined)
                }}
            >
                {itemFormik => {
                itemFormik.values.code = parseInt(itemFormik.values.code)// updated _id is string
                    return (<Form>
                        <div className="grid grid-cols-6 gap-6">
                            <FormFrame schema={schema.properties} formik={itemFormik}> 
                                <FieldSelect label="大分類" name="category1" span={3} options={category1s} props={{ ...itemFormik.getFieldProps("category1"), onChange: (e:ChangeEvent<HTMLSelectElement>) => handleChange(e, itemFormik)}} />
                                <FieldSelect label="小分類" name="category2" span={3} options={getCategory2s(itemFormik.values.category1)} props={{ ...itemFormik.getFieldProps("category2"), onChange: (e:ChangeEvent<HTMLSelectElement>) => handleChange(e, itemFormik) }} />
                                { [501, 502, 901].includes(itemFormik.values.code) ? 
                                    (newItem ? <FieldSelect label="コード" name="_id" span={3} options={itemFormik.values.code > 900 ? {901:901} : {501:501, 502:502}} props={{ ...itemFormik.getFieldProps("code"), onChange: (e:ChangeEvent<HTMLSelectElement>) => handleChange(e, itemFormik) }} />
                                        : <DivField label="コード" value={itemFormik.values.code} span={3} />
                                     ) : 
                                    <FieldSelect label="項目名" name="name" span={3} options={getNames(itemFormik.values.category1, itemFormik.values.category2)} props={{ ...itemFormik.getFieldProps("name"), onChange: (e:ChangeEvent<HTMLSelectElement>) => handleChange(e, itemFormik) }} />
                                }
                                { [501, 502, 901].includes(itemFormik.values.code) && <FieldBase name="name" /> }

                                {itemFormik.values.unit !== "yen" && <FieldWithMark label="設計数量" name="quantity" mark={options.costUnit[itemFormik.values.unit]} props={{ ...itemFormik.getFieldProps("quantity"), onChange: (e:ChangeEvent<HTMLInputElement>) => handleChange(e, itemFormik) }} />}
                                {itemFormik.values.unit !== "yen" && <FieldWithMark label="実績数量" name="actualQuantity" mark={options.costUnit[itemFormik.values.unit]} props={{ ...itemFormik.getFieldProps("actualQuantity"), onChange: (e:ChangeEvent<HTMLInputElement>) => handleChange(e, itemFormik) }} />}
                                <FieldBase name="unitPrice" onChange={e => handleChange(e, itemFormik)} />
                                <FieldBase name="actualUnitPrice" onChange={e => handleChange(e, itemFormik)} />
                                <FieldBase name="listPrice" onChange={e => handleChange(e, itemFormik)} />
                                <FieldBase name="amount" />
                                <FieldBase name="actualAmount" />
                                <FieldBase name="listAmount" />
                                <FieldBase name="hide" />
                            </FormFrame>
                        </div>
                        <FormButton completed={() => setShowItem(undefined)} />
                    </Form>)
                }}
            </Formik>}
    </DialogFrame>)
}


export default ItemEdit