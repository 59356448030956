import Loading from "components/Loading";
import { useBaseQueryContext } from "graphql/RealmApolloProvider";
import React, { createContext, useContext, useEffect, useReducer, useState } from "react";
import { useRealmApp } from "RealmApp";
import { JSTMonth, JSTNewDate, JSTYear } from "./dateUtils";

export interface AppContextInterface {
  /** States of application.  Keys are "branch" and each collection. */
  state: KV
  setState: (key: string | undefined | null, data: any) => void,
  /** Dispatcher to change state */
  workProps: KV[];
  forwardingPriceProps: KV;
  options: {[key:string]:KV[]};
}

const AppContext = createContext<AppContextInterface>({ state: {}, setState: () => null, workProps: [], forwardingPriceProps: [], options: {} });

export const useAppContext = () => {
  const appContext = useContext(AppContext);
  if (!appContext)
    throw new Error(
      `You must call useAppContext() inside of a <AppContextProvider />`
    );
  return appContext;
};

/**
 * Provider for reducer.
 *
 * @param {{ children: React.PropsWithChildren<{}> }} { children }
 * @return {*} 
 */
const AppContextProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const baseQueryContext = useBaseQueryContext()
  const startInit = JSTNewDate(JSTYear(), JSTMonth(), 1)
  const endInit = JSTNewDate(JSTYear(), JSTMonth() + 1, 1)
  const app = useRealmApp()
  const [workProps, setWorkProps] = useState<KV[]|undefined>()
  const [forwardingPriceProps, setForwardingPriceProps] = useState<KV|undefined>()
  const [options, setOptions] = useState<{[key:string]:KV[]}|undefined>()
  const { division, division2, division3, status } = app.currentUser?.customData as KV || {}
  const [state, dispatch] = useReducer(
    (state: KV, action: KV) => ({
      ...state,
      ...action,
    }),
    {
      customer: {},
      firewood: { filter: { status: "inquiry" } },
      firewoodProduct: {},
      forest: { ongoing: baseQueryContext.forest },
      tree: { ongoing: baseQueryContext.tree, sort: { activeKey: "fileID", ascend: -1} },
      treeItem: {},
      attendance: { filter: { 
        user: { name: `${app.currentUser?.customData?.surname || ""}　${app.currentUser?.customData?.givenName || ""}` },
        start_gte: startInit,
        start_lt: endInit 
      }},
      machine: { filter: {
        date_gte: startInit,
        date_lt: endInit
      }},
      timber: { filter: {
        deliveryDate_gte: startInit,
        deliveryDate_lt: endInit
      }},
      transport: { filter: {
        date_gte: startInit,
        date_lt: endInit
      }},
      work: {},
      user: { sort: { activeKey: "userID", ascend: 1} },
      userStatus: {
        divisions: [division, division2, division3],
        status: status
      }
    }
  );
  const setState:AppContextInterface["setState"] = (key, data) => {
    if (!key) {
      dispatch(data)
      return
    }
    let newState = { ...state }
    key.split(".").reduce((a: any, r: string, i: number, array: string[]) => {
      if (i === array.length - 1) a[r] = data
      if (!a[r]) a[r] = {}
      return a[r]
    }, newState)
    dispatch(newState)
  }

  useEffect(() => {
    async function fetchProps() {
      let response = await fetch('/contexts/work.json')
      let data = await response.json()
      setWorkProps(data)
      response = await fetch('/contexts/forwardingPriceList.json')
      data = await response.json()
      setForwardingPriceProps(data)
      response = await fetch('/contexts/options.json')
      data = await response.json()
      setOptions(data)
    }
    fetchProps()
  }, [])
  if (!workProps || !forwardingPriceProps || !options) return <Loading />
  return (
    <AppContext.Provider value={{ state: state, setState: setState, workProps: workProps, forwardingPriceProps: forwardingPriceProps, options:options }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
