import React, { useState } from 'react'
import { Tab } from '@headlessui/react'
import { Form, Formik, FormikProps } from 'formik';
import { XCircleIcon, PrinterIcon } from '@heroicons/react/24/solid'

import getTreeQuotePdf from "pages/tree/quote";
import getTreeInvoice from "pages/tree/invoice";

//const schema = require("realm/Yanagisawa/data_sources/mongodb-atlas/Business/tree/schema.json").properties;
import { DataList, ListItem, ListName, EditLinkTd, FilterInput, FilterSelect, FilterCheck, FiltersFunction, RowFunction, StatusIcon } from "components/list"
import { FieldPlain, FieldWithMark, FieldCheck, FieldArea } from 'components/uiparts'
import DialogFrame from 'components/dialog'
import { ButtonClass } from "utils"
import options from "contexts/options.json"
import { useHref } from 'react-router-dom';
import { makePdf } from 'contexts/pdfUtils';
import { useRealmApp } from 'RealmApp';
import { useUpdate } from 'graphql/useCollection';
import { DialogFunction } from 'components/edit';
import { getInvoiceAdjust } from './edit';
const location = "/tree"

const heads: KV[] = [
    { text: "状況", field: "status" },
    { text: "No", field: "fileID" },
    { text: "件名", field: "subject" },
    { text: "顧客", field: "customer.name.surnameKana" },
    { text: "取引分類", field: "datum.customer.type.tree" },
    { text: "現地調査日", field: "siteSurveyDate" },
    { text: "受付/担当者", field: "inCharge.surname" },
    { text: "送付期限", field: "sendDeadline" },
    { text: "売上", field: "dealAmount" },
    { text: "予実費用", field: "dealCost" },
    { text: "予実利益率", field: "dealCost" },
    { text: "勤怠" },
    { text: "" }
]

export const baseFilter = { status: "", mydeal: false, customer: { _id: "", corporateName: "", name: { surnameKana: "" } } }

const Filter: FiltersFunction = (filter, updateFilter) => {
    const app = useRealmApp()
    return <>
        <FilterSelect
            label="進捗"
            name="status"
            value={filter.status || ""}
            optionName="treeStatus"
            setState={v => updateFilter("status", v)}
            status
            nolabel
        />
        <FilterInput
            label="案件No"
            name="fileID"
            value={filter.fileID || ""}
            setState={v => updateFilter("fileID", v)}
            nolabel
        />
        <FilterInput
            label="件名"
            name="subject"
            value={filter.subject || ""}
            setState={v => updateFilter("subject", v)}
            nolabel
        />
        <FilterInput
            label="顧客ID"
            name="customer._id"
            value={filter.customer?._id || ""}
            setState={v => updateFilter("customer._id", v)}
            nolabel
        />
        <FilterInput
            label="顧客姓(かな)"
            name="customer.name.surnameKana"
            value={filter.customer?.name?.surnameKana || ""}
            setState={v => updateFilter("customer.name.surnameKana", v)}
            nolabel
        />
        <FilterInput
            label="会社名ふりがな"
            name="customer.corporateNameKana"
            value={filter.customer?.corporateNameKana || ""}
            setState={v => updateFilter("customer.corporateNameKana", v)}
            nolabel
        />
        <FilterCheck
            label="マイ案件"
            name="mydeal"
            state={Boolean(filter.mydeal)}
            setState={(v: boolean) => updateFilter("mydeal", v ? app.currentUser?.id : "")}
        />
    </>
}

interface PrintProps {
    messages?: {
        inner?: string;
        lower?: string;
        invoice?: string;
    };
    summarize?: Boolean;
    attachDoc?: {
        name: string;
        count: number|string;
    }[]
}

// If formik is given(called from edit, not list), values are linked to formik.values(curent is not needed).  Otherwise, current is used.
export const PrintDialog:DialogFunction = ({current, show, setShow, formik, updateOnPrint}) => {
    const updateTree = useUpdate("tree")
    const [tab, setTab] = useState(0)
    const baseUrl = window.location.origin + useHref('/')

    const quotePdf = (data:KV|null, values:KV) => {
//        if (!data?.customer) { alert("顧客情報が入力されていません"); return null; }
//        if (!data?.customer.name) { alert("顧客名が入力されていません"); return null; }
        if (!data?.inCharge) { alert("担当者が入力されていません"); return null; }
        makePdf(getTreeQuotePdf(data, values), baseUrl)
    }

    const handleCancel = (dialogFormik:FormikProps<PrintProps>) => {
        if (formik) {
            formik.setFieldValue("messages", dialogFormik.values.messages)
            formik.setFieldValue("attachDoc", dialogFormik.values.attachDoc)
        }
        setShow(undefined)
    }

    const currentValues = current || formik?.values || {}
    if (formik) {
        currentValues.messages = formik.values.messages
        currentValues.attachDoc = formik.values.attachDoc
    }
    const initialValues: PrintProps = { messages: { inner: currentValues?.messages?.inner || "", lower: currentValues?.messages?.lower || "", invoice: currentValues?.messages?.invoice || "" }, summarize: false, attachDoc: [...(currentValues?.attachDoc || []), ...Array(3 - (currentValues?.attachDoc?.length || 0)).fill({name: "", count: ""})] }
    return (<DialogFrame show={show} setShow={setShow}>
        <Formik
            initialValues = {initialValues}
            onSubmit = { async (values, { setSubmitting }) => {
                // If from list or existing data, update printing values when printed.
                if (currentValues._id && updateOnPrint) {
                    await updateTree(currentValues._id, { messages: values.messages, attachDoc: values.attachDoc?.filter((v:KV) => v.name && v.count) })
                }
                tab === 0 ? quotePdf(currentValues, values) : (current && makePdf(getTreeInvoice(current, values), baseUrl))
                setSubmitting(false);
            }}
        >
            {dialogFormik => <Form>
            <Tab.Group onChange={(index) => setTab(index)}>
                <Tab.List className="p-0.5 rounded-lg bg-theme-700">
                    {["見積書", "請求書"].map((tab, i) => <Tab key={`tab${i}`}
                        className={({ selected }) =>
                            (selected ? 'bg-white text-theme-800' : 'text-theme-100 bg-transparent hover:bg-theme-600')
                            + ' mr-2 px-3 py-2 rounded-md text-sm font-medium'
                        }
                    >{tab}</Tab>
                    )}
                </Tab.List>
                <Tab.Panels className="mt-4 h-[28rem]">
                    <Tab.Panel>
                        <FieldArea label="メッセージ(本文内)" name="innerMessage" rows={5} props={dialogFormik.getFieldProps('messages.inner')} />
                        <FieldArea label="メッセージ(下部)" name="message" rows={5} props={dialogFormik.getFieldProps('messages.lower')} />
                    </Tab.Panel>
                    <Tab.Panel className="grid grid-cols-4 gap-2">
                        <FieldArea label="メッセージ" name="message" rows={5} props={dialogFormik.getFieldProps('messages.invoice')} />
                        <FieldCheck label="1枚に統合" name="summarize" props={dialogFormik.getFieldProps('summarize')} setFieldValue={dialogFormik.setFieldValue} />
                        {[0,1,2].map((v:number) => <React.Fragment key={v}>
                            <FieldPlain label={`添付書類${v+1}`} name={`attachDoc[${v}].name`} span={3} props={dialogFormik.getFieldProps(`attachDoc[${v}].name`)} />
                            <FieldWithMark label="　" name={`attachDoc[${v}].count`} mark="部" span={1} props={dialogFormik.getFieldProps(`attachDoc[${v}].count`)} />
                        </React.Fragment>)}
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group >
            <div className="px-4 bg-gray-50 text-right sm:px-6">
                <button className={ButtonClass} type="submit"><PrinterIcon className="w-5 h-5" />作成</button>
                <button className={ButtonClass} onClick={e => { e.preventDefault(); handleCancel(dialogFormik); }}><XCircleIcon className="w-5 h-5" />戻る</button>
            </div>
        </Form>}
        </Formik>
    </DialogFrame>)
}


/*const download = (Data) => Data.map(v => ({
    "担当": v.inCharge?.surname || "",
    "顧客": `${v.customer?.corporateName || ""} ${v.customer?.name?.surname || ""} ${v.customer?.name?.givenName || ""}`,
    "工事名": v.subject || "",
    "場所": v.place || "",
    "合計金額": v.dealAmount || "",
    "実績金額": v.dealCost || "",
    "ステータス": v.status ? options.treeStatus[v.status] : "",
    "請求書発行日": v.invoiceDate ? new Date(v.invoiceDate).toLocaleDateString() : "",
    "入金確認日": v.receivedDate ? new Date(v.receivedDate).toLocaleDateString() : "",
}))*/

const getCostItem = (datum: KV, v: KV, code: number) => datum.costItem.find((item: KV) => item.quoteId === v.quoteId && item.code === code)?.quantity || ""
const getCostItemAmount = (datum: KV, v: KV, code: number) => datum.costItem.find((item: KV) => item.quoteId === v.quoteId && item.code === code)?.actualUnitPrice || ""

const download = (Data: KV[]) => Data.map(datum => datum.quotes.filter((v: KV) => v.active).map((v: KV) => ({
    "案件番号": datum.fileID || "",
    "案件状況": datum.status ? options.treeStatus[datum.status] : "",
    "案件名": v.subject || datum.subject || "",
    "場所": datum.place || "",
    "依頼主": `${datum.customer?.corporateName || ""} ${datum.customer?.name?.surname || ""} ${datum.customer?.name?.givenName || ""}`,
    "依頼主区分": datum.customer.corporation ? "法人・団体" : "個人",
    "見積額": v.amount || "",
    "設計原価": v.totalCost || "",
    "請求額": v.actualAmount || "",
    "実績原価": v.totalActualCost || "",
    "着工日": datum.operationDates?.startDate ? new Date(datum.operationDates.startDate).toLocaleDateString() : "",
    "竣工日": datum.operationDates?.endDate ? new Date(datum.operationDates.endDate).toLocaleDateString() : "",
    "請求日": datum.invoiceDate ? new Date(datum.invoiceDate).toLocaleDateString() : "",
    "設計担当者": datum.inCharge?.surname || "",
    "現場責任者": datum.inCharge?.surname || "",
    "作業日数": datum.operationDates?.startDate && datum.operationDates?.endDate ? Math.round((new Date(datum.operationDates.endDate).getTime() - new Date(datum.operationDates.startDate).getTime()) / 86400000) + 1 : "",
    "出役人工": getCostItem(datum, v, 101),
    "時間外": getCostItem(datum, v, 102),
    "２t": getCostItem(datum, v, 222),
    "４t": getCostItem(datum, v, 223),
    "Hiab": getCostItem(datum, v, 224),
    "0.18㎥": getCostItem(datum, v, 211),
    "0.25㎥(高機能林業機械)": getCostItem(datum, v, 212),
    "4t深ダンプ(賃借) ": getCostItem(datum, v, 501),
    "クレーン": [601, 602, 603, 604, 605].reduce((amount, code) => amount + (getCostItem(datum, v, code) || 0), 0) || "",
    "高所作業車": [511, 512, 513, 514].reduce((amount, code) => amount + (getCostItem(datum, v, code) || 0), 0) || "",
    "道路許可": getCostItemAmount(datum, v, 803) ? "◯" : "",
    "松くい補助金申請": getCostItemAmount(datum, v, 801) ? "◯" : "",
    "交通誘導員": getCostItem(datum, v, 622) ? "◯" : ""
}))).flat()


const downloadDetail = (Data: KV[]) => Data.map(v => {
    const active = v.quotes.filter((value: KV) => value.active)
    const amount = active.reduce((a: number, r: KV) => a + (r.amount || 0), 0)
    const woodSale = active.reduce((a: number, r: KV) => a + (r.buyQuantity || 0) * (r.buyUnitPrice || 0), 0)
    return ({
        "担当": `${v.inCharge?.surname || ""} ${v.inCharge?.givenName || ""}`,
        "工事No": v.fileID || "",
        "工事名": v.subject || "",
        "請求先": v.customer?.corporation ? (v.customer?.corporateName || "") : `${v.customer?.name?.surname || ""} ${v.customer?.name?.givenName || ""}`,
        "発注者区分": v.customer?.corporation ? "法人・団体" : "個人",
        "場所": v.place || "",
        "工事実施日(自)": (v.operationDates?.startDate && new Date(v.operationDates?.startDate).toLocaleDateString()) || "",
        "工事実施日(至)": (v.operationDates?.endDate && new Date(v.operationDates?.endDate).toLocaleDateString()) || "",
        "請負金額": amount,
        "材木売上": woodSale,
        "売上高": amount + woodSale,
        "原価": active.reduce((a: number, r: KV) => a + (r.totalCost || 0), 0),
        "ステータス": v.status ? options.treeStatus[v.status] : "",
        "請求書発行日": v.invoiceDate ? new Date(v.invoiceDate).toLocaleDateString() : "",
    })
})
const Row: RowFunction = ({ datum, clickDelete, clickUpdate, clickPrint }) => {
    const margin = (datum.planCost && datum.dealAmount) ? (1.0 - datum.planCost / datum.dealAmount) : undefined
    const actualMargin = (datum.dealCost && datum.dealAmount) ? (1.0 - datum.dealCost / (datum.dealAmount + datum.quotes.filter((v:KV) => Boolean(v.active)).reduce((a:number ,r:KV) => a + getInvoiceAdjust(r), 0))) : undefined
    return <>
        <ListItem data={[options.treeStatus[datum.status]]} badge />
        <ListItem data={[datum.fileID]} />
        <ListItem data={[datum.abbreviation, datum.place]} left />
        <ListName data={[datum.customer]} />
        <ListItem data={[options.customerType[datum.customer?.type?.tree || 'na']]} badge />
        <ListItem data={[datum.siteSurveyDate]} date />
        <ListName data={[datum.receptionist, datum.inCharge]} />
        <ListItem data={[datum.sendDeadline]} date />
        <ListItem data={[datum.dealAmount]} />
        <ListItem data={[datum.planCost, datum.dealCost]} />
        <td className="w-24 px-2 py-1 text-gray-500"><div className="relative h-10 bg-gray-100">
            {margin !== undefined && <>
                <span className={`absolute left-0 top-0 bottom-5 ${margin > 1 ? "bg-red-500" : "bg-theme-500"}`} style={{ width: `${margin * 100}%` }}></span>
                <span className={`absolute top-0 bottom-5 px-2${margin > 0.5 ? " text-white" : ""}`} style={margin > 0.5 ? { right: `${100 - margin * 100}%` } : { left: `${margin * 100}%` }}>{Math.round(margin * 100)}%</span>
            </>}
            {actualMargin !== undefined && <>
                <span className={`absolute left-0 top-5 bottom-0 ${actualMargin > 1 ? "bg-red-500" : "bg-theme-500"}`} style={{ width: `${actualMargin * 100}%` }}></span>
                <span className={`absolute top-5 bottom-0 px-2${actualMargin > 0.5 ? " text-white" : ""}`} style={actualMargin > 0.5 ? { right: `${100 - actualMargin * 100}%` } : { left: `${actualMargin * 100}%` }}>{Math.round(actualMargin * 100)}%</span>
            </>}
        </div></td>
        <td><button className="w-8 h-8" data-id={datum._id} data-set={`{"attendActive":"${!datum.attendActive ? true : false}"}`} onClick={clickUpdate}><StatusIcon status={datum.attendActive ? "OK" : "NG"} /></button></td>
        <EditLinkTd location={location} id={datum._id} onClickDelete={clickDelete} onClickPrint={clickPrint} />
    </>
}

const List = () => <DataList collection="tree" type="search" location={location} heads={heads} user={['receptionist', 'inCharge', 'invoiceAuthor']} filter={Filter} download={download} row={Row} dialog={PrintDialog} />

export default List
