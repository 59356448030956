import Decimal from 'decimal.js';



export const formatNumber = (num) => num ? String(Math.round(num*100)/100).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') : ""
export const formatCurrency = (num) => String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') + '円'
export const isObject = (value) => (value && typeof value === 'object' && value.constructor === Object)
export const isKeyValueObject = (value) => (value && typeof value === 'object' && value.constructor === Object && !(value instanceof Date))


export const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || ""

// no use
export const times = (a, b) => {
    const x = new Decimal(a || 0)
    const y = new Decimal(b || 0)
    return x.times(y).floor()
}
// no use
export const getParams = (schema, prefix = "") => {
    return Object.keys(schema).map(key => schema[key].bsonType === "object" ? getParams(schema[key].properties, key) : (prefix + schema[key].title)).flat(Infinity)
}

// nested json type object by dotted string key
export const getNestData = (data, key) => key.split(".").reduce((a, r) => a ? a[r] : null, data)
export const setNestData = (data, key) => key.split(".").reverse().reduce((a, r) => ({ [r] : a }), data)

export const setAddress = async (zipcode, parent, formik) => {
    if (!(/^[0-9]{3}[-]?[0-9]{4}$/.test(zipcode))) return
    try {
        const res = await fetch(`https://kmdsbng.github.io/zipcode_jp/zip_code/${zipcode.substr(0, 3)}/${zipcode.replace('-', '')}.json`)
        const json = await res.json()
        formik.setFieldValue(`${parent}.state`, json[0].prefecture_jis_code)
        formik.setFieldValue(`${parent}.citystr`, json[0].city_name + json[0].town_name)
    } catch { }
}

export const ButtonClass = "inline-flex justify-center m-3 py-2 px-4 w-28 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-theme-600 hover:bg-theme-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-500"
export const IconHeaderButtonClass ='inline-flex p-1 w-[1.875rem] h-[1.875rem] border rounded-md text-white bg-theme-600 hover:bg-theme-700 cursor-pointer'

export const IconButtonClass = "m-px py-1 px-2 flex justify-center items-center bg-white hover:bg-theme-100 focus:ring-theme-100 focus:ring-offset-theme-200 text-theme-500 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 w-8 h-8 rounded-lg"

export const flatten = (obj, path = "") => {
    if (!(obj instanceof Object)) return { [path.replace(/\.$/g, "")]: obj };
    return Object.keys(obj).reduce((output, key) => {
      return obj instanceof Array
        ? { ...output, ...flatten(obj[parseInt(key)], path + "[" + key + "].") }
        : { ...output, ...flatten(obj[key], path + key + ".") };
    }, {});
};