import React, { useState } from 'react'
import { useHref } from 'react-router-dom';
import { Form, Formik, FormikProps } from 'formik';
import { Tab } from '@headlessui/react';
import { PrinterIcon, XCircleIcon } from '@heroicons/react/24/solid';

//import { properties as firewood } from "realm/Yanagisawa/data_sources/mongodb-atlas/Business/firewood/schema.json"
import { DataList, ListItem, ListName, EditLinkTd, FilterInput, FilterSelect, FiltersFunction, FilterCheck, FilterDates, RowFunction } from "components/list"
import options from "contexts/options.json"
import { makePdf } from 'contexts/pdfUtils';
import getDeliveryData from './delivery';
import getInvoiceData from './invoice';
import { useRealmApp } from 'RealmApp';
import DialogFrame from 'components/dialog';
import { ButtonClass } from 'utils';
import { FieldArea } from 'components/uiparts';
const location = "/firewood"

const heads:KV[] = [
    { text: "進捗", field: "status" },
    { text: "問合せ日", field: "receptionDate" },
    { text: "顧客", field: "customer.name.surnameKana" },
    { text: "担当", field: "sales.surname" },
    { text: "対応", field: "responseDate" },
    { text: "納期", field: "deliveringDate" },
    { text: "契約日", field: "contractDate" },
    { text: "配達", field: "delivery" },
    { text: "請求", field: "invoiceDate" },
    { text: "金額", field: "total" },
    { text: "" }
]

export const baseFilter = { status: "", mydeal: false, customer: { _id: "", corporateName: "", name: { surnameKana: "" } } }

const Filter: FiltersFunction = (filter, updateFilter, dateParam) => {
    const app = useRealmApp()
    return <>
        <FilterSelect
            label="進捗"
            name="status"
            value={filter.status}
            optionName="status"
            setState={v => updateFilter("status", v)}
            status
            nolabel
        />
        <FilterInput
            label="顧客ID"
            name="customer._id"
            value={filter.customer?._id || ""}
            setState={v => updateFilter("customer._id", v)}
            nolabel
        />
        <FilterInput
            label="顧客姓(かな)"
            name="customer.name.surnameKana"
            value={filter.customer?.name?.surnameKana || ""}
            setState={v => updateFilter("customer.name.surnameKana", v)}
            nolabel
        />
        <FilterInput
            label="会社名ふりがな"
            name="customer.corporateNameKana"
            value={filter.customer?.corporateNameKana || ""}
            setState={v => updateFilter("customer.corporateNameKana", v)}
            nolabel
        />
        <FilterDates dateParam={dateParam} />
        <FilterCheck
            label="マイ案件"
            name="mydeal"
            state={Boolean(filter.mydeal)}
            setState={(v:boolean) => updateFilter("mydeal", v ?  app.currentUser?.id : "")} // set id when using custom resolver(no graphql in function)
        />
    </>
}

const PrintDialog = ({current, show, setShow, formik}:{current?:KV, show?:boolean, setShow:React.Dispatch<any>, formik?:FormikProps<any>}) => {
    const [tab, setTab] = useState(0)
    const baseUrl = window.location.origin + useHref('/')

    const quotePdf = (data:KV|null, values:KV) => {
        if (!data?.customer) { alert("顧客情報が入力されていません"); return null; }
        if (!data?.customer.name) { alert("顧客名が入力されていません"); return null; }
        if (!data?.customer.address) { alert("送付先住所が入力されていません"); return null; }
        makePdf(getDeliveryData(data), baseUrl)
    }

    return (<DialogFrame show={Boolean(current)} setShow={setShow}>
        <Formik
            initialValues={{ message: "", summarize: false, document1: "", document1Count: 0, document2: "", document2Count: 0, document3: "", document3Count: 0, }}
            onSubmit={async (values, { setSubmitting }) => {
                tab === 0 ? quotePdf(current || {}, values) : (current && makePdf(getInvoiceData(current), baseUrl))
                setSubmitting(false);
            }}
        >
            {formik => <Form>
                <Tab.Group onChange={(index) => setTab(index)}>
                    <Tab.List className="p-0.5 rounded-lg bg-theme-700">
                        {["納品書", "請求書"].map((tab, i) => <Tab key={`tab${i}`}
                            className={({ selected }) =>
                                (selected ? 'bg-white text-theme-800' : 'text-theme-100 bg-transparent hover:bg-theme-600')
                                + ' mr-2 px-3 py-2 rounded-md text-sm font-medium'
                            }
                        >{tab}</Tab>
                        )}
                    </Tab.List>
                    <Tab.Panels className="mt-4 h-[28rem]">
                        <Tab.Panel>
                        </Tab.Panel>
                        <Tab.Panel className="grid grid-cols-4 gap-2">
                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group >
                <div className="px-4 bg-gray-50 text-right sm:px-6">
                    <button className={ButtonClass} type="submit"><PrinterIcon className="w-5 h-5" />作成</button>
                    <button className={ButtonClass} onClick={e => { e.preventDefault(); setShow(undefined); }}><XCircleIcon className="w-5 h-5" />戻る</button>
                </div>
            </Form>}
        </Formik>
    </DialogFrame>)
}

const download = (Data:KV[]) => {
    let data = Data.map(v => ({
        "名前": `${v.customer?.corporateName || ""} ${v.customer?.name?.surname || ""} ${v.customer?.name?.givenName || ""}`,
        "薪価格": v.item?.reduce((a:number, r:KV) => a + (r.unitPrice * r.quantity || 0), 0) || "",
        "運賃": v.item?.reduce((a:number, r:KV) => a + (r.deliveryFare || 0), 0) || "",
        "合計金額": v.total || "",
        "ステータス": v.status ? options.status[v.status] : "",
        "請求書発行日": v.invoiceDate ? new Date(v.invoiceDate).toLocaleDateString() : "",
        "個別商品": v.item?.map((item:KV) => ({
            "樹種": item.species || "",
            "商品": item.type || "",
            "長さ等": item.length || "",
            "数量": item.quantity || "",
            "薪価格": item.unitPrice * item.quantity,
            "割引": item.discount || "",
            "運賃": item.deliveryFare || "",
        })) || '',
    }))
    const max = data.reduce((a, r) => Math.max(a, r.個別商品.length), 0);
    while (data[0].個別商品.length < max) {
        data[0].個別商品.push({
            [`樹種`]: "",
            [`商品`]: "",
            [`長さ等`]: "",
            [`数量`]: "",
            [`薪価格`]: "",
            [`割引`]: "",
            [`運賃`]: ""
        })
    }
    return data
}


const Row:RowFunction = ({ datum, clickDelete, clickCollection, clickPrint }) => {
    const baseUrl = window.location.origin + useHref('/')

    return <>
        <ListItem data={[options.status[datum.status]]} badge />
        <td>
            <div className="text-gray-500">{datum.receptionDate && new Date(datum.receptionDate).toLocaleDateString()}</div>
            <div>{options.customerType[datum.customer?.type?.firewood || "na"]}</div>
        </td>
        <ListName data={[datum.customer]} />
        <ListName data={[datum.receptionist, datum.sales]} />
        <td>
            <div className="text-gray-500">{datum.responseDate && new Date(datum.responseDate).toLocaleDateString()}</div>
            <div className="text-left">{datum.response?.substring(0, 10)}{datum.response?.length > 10 && "..."}</div>
        </td>
        <ListItem data={[datum.deliveringDate]} date />
        <ListItem data={[datum.contractDate]} date />
        <ListItem data={[options.deliveryType[datum.deliveryType]]} badge />
        <td>
            <div className="text-gray-500">{datum.invoiceDate && new Date(datum.invoiceDate).toLocaleDateString()}</div>
            <div>{datum.invoiceNo}</div>
        </td>
        <td>
            <div>{datum.total}{datum.total && "円"}</div>
            <div>{datum.receivedDate && new Date(datum.receivedDate).toLocaleDateString()}</div>
        </td>
        <EditLinkTd location={location} id={datum._id} custId={datum.customer?._id || null} onClickDelete={clickDelete} onClickCollection={clickCollection} onClickPrint={clickPrint} />
    </>
}

const List = () => <DataList collection="firewood" type="search" location={location} heads={heads} user={['receptionist', 'sales', 'invoiceAuthor']} filter={Filter} dateField="invoiceDate" download={download} row={Row} dialog={PrintDialog}/>


export default List