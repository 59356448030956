import React, { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

const OpacityTransition: React.FC<{ children: JSX.Element }> = ({
  children,
}): JSX.Element => (
  <Transition.Child
    as={Fragment}
    enter="ease-out duration-300"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="ease-in duration-200"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    {children}
  </Transition.Child>
);
const TranslateTransition: React.FC<{ children: JSX.Element }> = ({
  children,
}) => (
  <Transition.Child
    as={Fragment}
    enter="ease-out duration-300"
    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    enterTo="opacity-100 translate-y-0 sm:scale-100"
    leave="ease-in duration-200"
    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
  >
    {children}
  </Transition.Child>
);

/**
 * Dialog Frame
 * @param [show, setShow]: react state to show this Dialog, wide: wider dialog whose max is screen
 * @returns Dialog Frame
 */
const DialogFrame: React.FC<{ show:boolean | undefined, setShow:React.Dispatch<any>, wide?: Boolean, children?: JSX.Element|JSX.Element[]|false }> = ({ show, setShow, wide, children }) => {
    const cancelButtonRef = useRef(null)
    return (<>{show && (<Transition.Root show={show} as={Fragment}>
        <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            initialFocus={cancelButtonRef}
            open={show}
            onClose={setShow}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <OpacityTransition>
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </OpacityTransition>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <TranslateTransition>
                    <div className={`inline-block align-top bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle ${wide ? "sm:max-w-screen-lg" : "sm:max-w-2xl"} sm:w-full`}>
                        <div className="bg-white p-2 sm:p-4">
                            <div className="ml-auto mb-2 flex-shrink-0 flex items-center justify-center h-8 w-8 rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10">
                                <XMarkIcon className="h-6 w-6 text-gray-400" aria-hidden="true" onClick={() => setShow(undefined)} />
                            </div>
                            {children}
                        </div>
                    </div>
              </TranslateTransition>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </>
  );
};

export default DialogFrame;
