import React, { ChangeEvent, useMemo, useState } from "react";
import * as Yup from 'yup';

import relations from "realm/Yanagisawa/data_sources/mongodb-atlas/Business/machine/relationships.json"
import { CreateBase, EditBase, FieldBase, FormFrame, getSchemaValues, RelationField } from "components/edit";
import { FieldSelect } from "components/uiparts";
import { useData } from "graphql/useCollection";
import Loading from "components/Loading";
import UserSelect from "components/userSelect";
import { useAppContext } from "contexts/AppContext";
import { projectingDivision } from "contexts/utils";
import { useRealmApp } from "RealmApp";
import { FormikProps } from "formik";

const schema = require("realm/Yanagisawa/data_sources/mongodb-atlas/Business/machine/schema.json").properties;
const initialValues = getSchemaValues(schema)

const FormFields = ({ formik }: { formik: FormikProps<any> }) => {
    const context = useAppContext()
    const isVehicle = (_id: any) => context.options.machines.find(v => Number(v._id) === Number(_id))?.category === "車両"
    const machineOptions = context.options.machines.reduce((a, r) => ({...a, [r._id]:`${r.rental ? "レンタル" : "自社"} - ${r.title}`}), { 0: "" })
    // field name for user selection dialog.  Also used as user selection dialog show bool
    const [currentName, setCurrentName] = useState<string | null>(null)

    const getProjectOptions = () => {
        if (!projectingDivision(formik.values.division || "")) return ({ "": "" })
        let options = (context.state[formik.values.division]?.ongoing || []).reduce((a: KV, r: KV) => ({ ...a, [r._id]: `${r.fileID} - ${r.subject}` }), { "": "" })
        const initialValue = formik.initialValues.project[formik.values.division]
        if (initialValue && initialValue._id) options = { ...options, [initialValue._id]: `${initialValue.fileID} - ${initialValue.subject}` }
        return options
    }
    const projectOptions = useMemo(() => getProjectOptions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [formik.values.division])

    const handleClick = (name: string) => {
        setCurrentName(name)
    }
    const handleProjectChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const { __typename, ...data } = context.state[formik.values.division]?.ongoing?.find((item: KV) => item._id === e.currentTarget.value) || { __typename: "" }
        console.log(data)
        formik.setFieldValue(`project.${formik.values.division}`, { ...data })
    }
    return <div className="grid grid-cols-6 gap-6">
        <FormFrame formik={formik} schema={schema}>
            <FieldBase name="date" />
            <RelationField
                label="社員"
                onClick={e => handleClick("user")}
                value={`${formik.values.user.surname || ""} ${formik.values.user.givenName || ""}`}
            />
            <UserSelect name={currentName} setName={setCurrentName} formik={formik} />
            <FieldBase name="division" />
            {projectingDivision(formik.values.division) && <FieldSelect label="案件" name={`project`} span={3} options={projectOptions} props={{ value: formik.values.project?.[formik.values.division]?._id, onChange: handleProjectChange }} />}
            {formik.values.division === "forest" && <FieldBase name="category" />}
            <FieldSelect label="使用機械" name="machine" span={3} options={machineOptions} props={formik.getFieldProps("machine")} />
            {isVehicle(formik.values.machine) ?
                <FieldBase name="distance" />
                : <FormFrame formik={formik} schema={schema}>
                    <FieldBase name="hour" />
                    <FieldBase name="unit" />
                    <FieldBase name="oilType1" />
                    <FieldBase name="oil1" />
                    <FieldBase name="oilType2" />
                    <FieldBase name="oil2" />
                </FormFrame>
            }
            <FieldBase name="repair" />
            <FieldBase name="remarks" />
        </FormFrame>
    </div>
}

const validationSchema = Yup.object({
})


const writePrepare = (updates: KV) => {
    return null;
}


export const Create = () => {
    const app = useRealmApp()
    let actualInitialValues = { ...initialValues }
    const { loading, Data } = useData("user", null, { _id: app.currentUser?.id })
    if (!loading && Data?.length) {
        const { __typename, ...data } = Data[0]
        actualInitialValues.user = data
    }
    return (<>{loading ? <Loading /> :
        <CreateBase title="機械情報" values={actualInitialValues} collection="machine" relations={relations} Fields={FormFields} writePrepare={writePrepare} validation={validationSchema} />
    }</>);
};

export const Edit = () => {
    return (<EditBase title="機械情報" values={initialValues} collection="machine" relations={relations} Fields={FormFields} writePrepare={writePrepare} validation={validationSchema} />);
};
export default Edit 