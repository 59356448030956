import React from "react"
import { DataList, EditLinkTd } from "components/list"
import options from "contexts/options.json"
const schema = require("realm/Yanagisawa/data_sources/mongodb-atlas/Business/treeItem/schema.json").properties;
const location = "/treeitem"

const getTitle = (obj:KV) => obj.description?.match(/[^<>]*(?=>)/g) || obj.title || ""
const keys = ["_id", "category1", "category2", "name", "noName", "unit", "unitPrice", "listPrice", "costType"]

const heads = keys.map<KV>(key => ({ text: getTitle(schema[key]), field: key })).concat([{}])
const types = keys.map(key => schema[key].description?.replace(/\([\s\S]*?\)/g, '').replace(/\{[\s\S]*?\}/g, '').replace(/<[\s\S]*?>/g, '').split(":") || ['text'])

const Row = ({ datum, clickDelete }:{ datum: KV; clickDelete?: ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void); }) =>  <>
    {
        keys.map((key, i) => (<td key={key + i}>
            <div>{types[i][0] === "select" ? options[types[i][1]][datum[key]] : datum[key] === true ? '◯' : datum[key]}</div>
        </td>
        ))
    }
    <EditLinkTd location={location} id={datum._id} onClickDelete={clickDelete} />
</>

const List = () => <DataList collection="treeItem" location={location} heads={heads} row={Row} />


export default List
