import Decimal from 'decimal.js';

import options from "contexts/options.json"
import { defaultStyle, pageStyle, underlineLayout } from 'contexts/pdfUtils';
import { JSTDateKanji } from 'contexts/dateUtils';
const titlelogo = require("components/titlelogo.json").titlelogo;

//const formatPrice = (num?:number) => num ? String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') : ""
const times = (a:any, b:any) => {
    const x = new Decimal(a || 0)
    const y = new Decimal(b || 0)
    return x.times(y).floor()
}

const getFirewoodPdfData = (data:KV) => {
//    const productPrices:Decimal[] = data.item.map((datum:KV) => times(datum.quantity, datum.unitPrice))//Decimal
//    const productPrice = productPrices.reduce((a, r) => a.plus(r)).floor().toNumber() //int
//    const deliveryTotal = data.item.reduce((a:number, r:KV) => a + r.deliveryFare, 0) //int
//    const subTotal = data.item.reduce((a:number, r:KV) => a + r.amount, 0) //int
//    const tax = new Decimal(subTotal).times(new Decimal(data.taxRate || 0)).dividedBy(100).floor().toNumber()
//    const discountTotal = data.item.reduce((a:number, r:KV) => a + r.discount, 0)
    let corporateName = ""
    let displayName = `${data.customer.name?.surname || ""}　${data.customer.name?.givenName || ""}`
    if (displayName === "　") displayName = `${data.customer.corporateName || ""}　${data.customer.division || ""}`
    else corporateName = `${data.customer.corporateName || ""}　${data.customer.division || ""}`
    const invoiceAddress = data.customer.invoiceAddress || data.customer.address


    const lastDeliveryDate = data.item.map((v:KV) => new Date(v.deliveryDate || null).getTime()).sort((a:number , b:number) => b > a)[0]

    return {
        pageSize: 'A4',
        pageMargins: pageStyle.pageMargins,
        info: { title: '納品書' },
        content: [
            {
                columns: [//height 
                    { width: 'auto', stack: [
                        { text: '御 納 品 明 細 書', background: 'green', color: 'white', alignment: 'left', margin: [pageStyle.address.x, 0, 0, 0], fontSize: pageStyle.fontSizes.title },
                        { text: '〒' + invoiceAddress.zip, alignment: 'left', margin: [pageStyle.address.x, 0, 0, 0] },
                        { text: options.prefecture[invoiceAddress.state] + invoiceAddress.citystr, alignment: 'left', margin: [pageStyle.address.x, 0, 0, 0] },
                        { text: invoiceAddress.room || '　', alignment: 'left', margin: [pageStyle.address.x, 0, 0, 0] },
                        { text: corporateName, fontSize: pageStyle.fontSizes.name, alignment: 'left', margin: [pageStyle.address.x, 0, 0, 0] },
                        {
                            table: {
                                widths: [pageStyle.address.width],
                                body: [[{ text: `${displayName}　様`, fontSize: pageStyle.fontSizes.name, alignment: 'right' }]]
                            },
                            layout: underlineLayout,
                            margin: [pageStyle.address.x, 1, 0, 0]
                        },
                        { text: '下記のとおり、納品いたしました。', alignment: "left", margin: [0, 20] },
                    ]},
                    { width: '*', text: '' },
                    { width: 'auto', alignment: "right", stack: [
                        {text: `No.${data.invoiceNo}`, margin: [0, 10, 0, 0] },
                        { width: 'auto', text: data.invoiceDate && JSTDateKanji(new Date(lastDeliveryDate)) },
                        '　',
                        { width: 200, image: titlelogo },
                        { stack: ['長野県松本市岡田下岡田774-1', 'TEL (0263) 87-5361', 'FAX (0263) 87-5362'], width: 'auto' },
                        { text: '合法木材 長野県木連-045' },
                        {
                            width: 150, alignment: 'right', stack: [
                                `営業担当: ${data.sales?.surname || ""} ${data.sales?.givenName || ""}`
                            ],
                            margin: [0,10]
                        },
                    ]}
                ]
            },
            ' ',
            {
                table: {
                    headerRows: 1,
                    widths: [100, 65, '*', '*', 65, 65],

                    body: [(['納材日', '種別', '樹種', '区分', '数量', '単位'] as any[])].concat(
                            data.item.map((datum:KV, i:number) => [
                                datum.deliveryDate && new Date(datum.deliveryDate).toLocaleDateString(),
                                datum.type,
                                datum.species,
                                datum.length,
                                datum.quantity,
                                options.unit[datum.unit]
                            ])
                        )
                }
            }
        ],
        defaultStyle: defaultStyle
    }
}

export default getFirewoodPdfData
