import React, { MouseEvent, useState } from 'react';
import * as Yup from 'yup';
import Decimal from 'decimal.js';
import { PlusIcon } from '@heroicons/react/24/solid'

import relations from "realm/Yanagisawa/data_sources/mongodb-atlas/Business/aim/relationships.json"
import { getSchemaValues, CreateBase, EditBase, FormFrame, FieldBase, RelationField, FieldArrayFunction } from 'components/edit'
import { ListItem, EditLinkTd } from "components/list"
import ItemEdit from 'pages/aim/itemEdit'
import UserSelect from 'components/userSelect'
import { useRealmApp } from 'RealmApp';
import { halfyearOptions } from 'contexts/dateUtils';
import { FieldSelect } from 'components/uiparts';
import { SimpleListFrame } from 'components/ListFrame';
import { FormikProps } from 'formik';

const schema = require("realm/Yanagisawa/data_sources/mongodb-atlas/Business/aim/schema.json").properties;

//const digitsOnly = (value) => /^\d*[\.{1}\d*]\d*$/.test(value) || value.length === 0
const initialValues = getSchemaValues(schema)

const heads = ["目標概要", "目標詳細", "自己評価", "一次管理者コメント", "二次管理者コメント", "自己評点", "一次評点", "二次評点", "評点ウェイト", ""]

const FieldArray:FieldArrayFunction = ({ 
    schema,
    formik,
    name,
    parent,
    showItem,
    setShowItem,
    newItem,
    setNewItem
}) => {
    const fullName = (parent ? parent + "." : "") + name
    const [, setItemCount] = React.useState(-1)
    const handleClick = (e:MouseEvent, i:number) => {
        setNewItem(false)
        setShowItem(i)
    }
    const handleDeleteClick = (e:MouseEvent, i:number) => {
        formik.values[fullName].splice(i, 1)
        setItemCount(formik.values[fullName].length)
    }
    return( <><SimpleListFrame heads={heads} noAdd>
        {(formik.values[name] || []).map((datum:KV, i:number) => (
            <tr key={`${name}${i}`} className="text-sm text-gray-900">
                <ListItem data={[datum.abstract]} left wrap />
                <ListItem data={[datum.detail]} left wrap/>
                <ListItem data={[datum.selfAssessment]} left wrap />
                <ListItem data={[datum.firstComment]} left wrap />
                <ListItem data={[datum.secondComment]} left wrap />
                <ListItem data={[datum.selfScore]} />
                <ListItem data={[datum.firstScore]} />
                <ListItem data={[datum.secondScore]} />
                <ListItem data={[datum.weight]} />
                <EditLinkTd id={String(i)} onClickDelete={e => handleDeleteClick(e, i)} onClickEdit={e => handleClick(e, i)} />
            </tr>
        ))}
    </SimpleListFrame>
    <ItemEdit newItem={newItem} showItem={showItem} setShowItem={setShowItem} name={name} parent={parent} schema={schema[name].items} formik={formik} />
    </>)
}

const FormFields = ({ formik }:{ formik:FormikProps<any> }) => {
    // adding new aimItem bool
    const [newItem, setNewItem] = useState(false)
    // aimItem input dialog (items array index)
    const [showItemInput, setShowItemInput] = useState<number>()
    // field name for user selection dialog.  Also used as user selection dialog show bool
    const [currentName, setCurrentName] = useState<string|null>(null)
    
    const handleClick = (name:string) => {
        setCurrentName(name)
    }
    // handling add aimItem button. adding new aimItem
    const handleAddItemClick = (e:MouseEvent) => {
        const count = formik.values.item?.length || 0
        setNewItem(true)
        setShowItemInput(count)
    }
    // Recalc total amount
    React.useEffect(() => {
        const x = new Decimal((formik.values.aims || []).reduce((a:number, r:KV) => a + Number(r.selfScore || 0) * Number(r.weight || 0), 0))
        formik.setFieldValue("selfScore",  x.dividedBy(100).floor().toFixed())
        const y = new Decimal((formik.values.aims || []).reduce((a:number, r:KV) => a + Number(r.firstScore || 0) * Number(r.weight || 0), 0))
        formik.setFieldValue("firstScore",  y.dividedBy(100).floor().toFixed())
        const z = new Decimal((formik.values.aims || []).reduce((a:number, r:KV) => a + Number(r.secondScore || 0) * Number(r.weight || 0), 0))
        formik.setFieldValue("secondScore",  z.dividedBy(100).floor().toFixed())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [formik.values.aims])
    return (
    <div className="grid grid-cols-6 gap-6">
        <FormFrame formik={formik} schema={schema}>
            <RelationField
                label="社員"
                onClick={e => handleClick("user")}
                item={formik.values.user}
            />
            <UserSelect name={currentName} setName={setCurrentName} formik={formik} />
            <FieldSelect label="対象期間" name="term" span={3} options={halfyearOptions} props={formik.getFieldProps("term")} />
            <FieldBase name="selfScore" />
            <FieldBase name="firstScore" />
            <FieldBase name="secondScore" />
            <FieldBase name="remarks" />
            <div className="relative col-span-6">
                <label className="block text-sm font-medium text-gray-700">目標</label>
                <button type="button" onClick={handleAddItemClick} className="absolute left-16 top-0 flex justify-center items-center bg-theme-50 hover:bg-theme-100 text-theme-800 w-8 h-8 rounded-2xl transition ease-in duration-200"><PlusIcon className="w-4 h-4" /></button>
                <div className="-mx-4"><FieldArray name="aims" newItem={newItem} setNewItem={setNewItem} showItem={showItemInput} setShowItem={setShowItemInput} schema={schema} formik={formik} /></div>
            </div>
        </FormFrame>
    </div>
)}

const validationSchema = Yup.object({
})


const writePrepare = () => {
    return null;
}

export const Create = () => {
    const app = useRealmApp()
    const user = app.currentUser?.customData
    const actualInitialValues = user ? { ...initialValues, user: user, term: Object.keys(halfyearOptions)[0] } : { ...initialValues, term: Object.keys(halfyearOptions)[0] }
    return (<>
        <CreateBase title={"目標管理情報"} values={actualInitialValues} collection="aim" relations={relations} Fields={FormFields} writePrepare={writePrepare} validation={validationSchema} />
        </>);
};

export const Edit = () => {
    return (<EditBase title="目標管理情報" values={initialValues} collection="aim" relations={relations} Fields={FormFields} writePrepare={writePrepare} validation={validationSchema} />);
};
export default Edit