import React from 'react'

//import { properties as aim } from "realm/Yanagisawa/data_sources/mongodb-atlas/Business/aim/schema.json"
import { DataList, ListItem, EditLinkTd, FilterInput, FilterSelect, FilterCheck, FiltersFunction, RowFunction } from "components/list"
import { halfyearOptions } from 'contexts/dateUtils'
import { useRealmApp } from 'RealmApp'
import { useLazyData } from 'graphql/useCollection'
import Loading from 'components/Loading'
import { useAppContext } from 'contexts/AppContext'
const location = "/aim"

const heads:KV[] = [
    { text: "期間", field: "term" },
    { text: "社員", field: "user.userID" },
    { text: "自己評点", field: "selfScore" },
    { text: "一次評点", field: "firstScore" },
    { text: "二次評点", field: "secondScore" },
    { text: "" }
]

const Filter:FiltersFunction = (filter, updateFilter) => {
    const app = useRealmApp()
    const context = useAppContext()
    if (!context.state.aim?.filter) context.setState("aim.filter", { 
        user: { userID: app.currentUser?.customData?.userID, surname: app.currentUser?.customData?.surname, givenName: app.currentUser?.customData?.givenName },
        term: Object.keys(halfyearOptions)[0],
    })
    const updateUser = (value:any) => {
        if (!value) {
            updateFilter("user", { 
                userID:app.currentUser?.customData?.userID,
                surname: app.currentUser?.customData?.surname,
                givenName: app.currentUser?.customData?.givenName
            })
            updateFilter("term", Object.keys(halfyearOptions)[0])
        } else {
            updateFilter("user", { supervisor: app.currentUser?.customData.userID })
        }
    }
    return <>
        <FilterInput
            label="社員ID"
            name="user.userID"
            value={filter.user?.userID || ""}
            setState={(v) => updateFilter("user.userID", v)}
            nolabel
        />
        <FilterInput
            label="姓"
            name="user.surname"
            value={filter.user?.surname || ""}
            setState={(v) => updateFilter("user.surname", v)}
            nolabel
        />
        <FilterInput
            label="名"
            name="user.givenName"
            value={filter.user?.givenName || ""}
            setState={(v) => updateFilter("user.givenName", v)}
            nolabel
        />
        <FilterSelect
            label="期間"
            name="term"
            value={filter.term || ""}
            optionName="halfyear"
            setState={(v:any) => updateFilter("term", v)}
            status={undefined}
            nolabel
        />
        <FilterCheck
            label="管理下全員"
            name="supervising"
            state={Boolean(filter.user?.supervisor)}
            setState={(v:any) => updateUser(v)}
        />
    </>
}

const Row:RowFunction = ({ datum, clickDelete }) => <>
    <ListItem data={[datum.term]} />
    <ListItem data={[`${datum.user?.surname || ""}　${datum.user?.givenName || ""}`]} />
    <ListItem data={[datum.selfScore]} />
    <ListItem data={[datum.firstScore]} />
    <ListItem data={[datum.secondScore]} />
    <EditLinkTd location={location} id={datum._id} onClickDelete={clickDelete} />
</>

const List = () => {
    const app = useRealmApp()
    const context = useAppContext()
    const { getData, loading, Data, notLoaded } = useLazyData("user")
    if (context.state.aim?.filter?.supervising) {
        if (notLoaded && !loading) {
            try {
                getData({ variables: { query: { supervisor: { _id: app.currentUser?.customData?._id } } } })
            } catch (e) {
                console.log(e)
            }
        } else if (!loading && context.state.aim?.filter?.supervising === true) {
            context.setState("aim.filter", { term: context.state.aim?.filter?.term || "", supervising: Data?.map(datum => datum._id) })            
        }
    }
    if (context.state.aim?.filter?.supervising === true || loading) return <Loading />
    return <DataList collection="aim" location={location} heads={heads} user={['user']} filter={Filter} download={undefined} row={Row} dialog={undefined} noAdd={undefined} handleRowClick={undefined} />
}

export default List