import React, { useState } from "react"
import Loading from "components/Loading"
import { useData } from "graphql/useCollection"
import { ButtonClass } from "utils"
import DialogFrame from "components/dialog"
import { JSTDate } from "contexts/dateUtils"
import { useAppContext } from "contexts/AppContext"

const Sum = () => {
    const context = useAppContext()
    const { loading, Data } = useData("machine")
    const [showRepair, setShowRepair] = useState<boolean|undefined>(false)
    const [repairs, setRepairs] = useState<KV[]>([])
    const handleClick = () => {
        context.setState("machine.display", { type:"normal" })
    }
    const handleRepairClick = (repair:KV[]) => {
        setRepairs(repair)
        setShowRepair(true)
    }
    const displayData = context.options.machines.map(machine => {
        return Data?.filter(datum => Number(machine._id) === Number(datum.machine)).reduce((a, r) => ({
            name: a.name,
            hours: a.hours + r.unit * 8,
            distance: a.distance + Number(r.distance),
            repair: r.repair ? [...a.repair, { date: r.date, content: r.repair }]: a.repair  
        }), { name:machine.title, hours:0, distance:0, repair:[] }) || []
    })
    if (loading) return <Loading />
    return <div>
        <div className=""><button className={ButtonClass} onClick={handleClick}>個別表示</button></div>
        <div className="grid grid-cols-4 gap-px border border-gray-500 bg-gray-500">
            <div className="p-2 text-center bg-theme-100">機械</div>
            <div className="p-2 text-center bg-theme-100">稼働時間</div>
            <div className="p-2 text-center bg-theme-100">稼働距離</div>
            <div className="p-2 text-center bg-theme-100">修理点検</div>
            {displayData.map(datum => <React.Fragment key={datum.name}>
                <div className="p-2 text-right bg-white">{datum.name}</div>
                <div className="p-2 text-right bg-white">{datum.hours}</div>
                <div className="p-2 text-right bg-white">{datum.distance}</div>
                <div className="p-2 text-right bg-white cursor-pointer" onClick={() => handleRepairClick(datum.repair)} >{datum.repair.length}</div>
            </React.Fragment>)}
        </div>
        <DialogFrame show={showRepair} setShow={setShowRepair} wide children={<div className="grid grid-cols-6 gap-1">{
            repairs.map((repair, i) => <React.Fragment key={i}>
                <div>{JSTDate(new Date(repair.date))}</div>
                <div className="col-span-5">{repair.content}</div>
            </React.Fragment>)
        }</div>} />
    </div>
}

export default Sum