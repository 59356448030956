import { ArrowUpTrayIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import Loading from "components/Loading";
import { JSTDate, JSTTime } from "contexts/dateUtils";
import { useAdd, useData } from "graphql/useCollection";
import React, { MouseEvent, useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { useRealmApp } from "RealmApp";

const LeftMessage = (datum:KV) => <div className="flex w-full">
    <span className="bubble-left">{datum.message.split("\n").map((v:string, i:number) => <React.Fragment key={i}>{v}<br /></React.Fragment>)}</span>
    <span className="flex-none flex p-2 text-xs items-start">{JSTDate(new Date(datum.sent))}<br />{JSTTime(new Date(datum.sent))}</span>
</div>

const RightMessage = (datum:KV) => <div className="flex flex-row-reverse">
    <span className="bubble-right">{datum.message.split("\n").map((v:string, i:number) => <React.Fragment key={i}>{v}<br /></React.Fragment>)}</span>
    <span className="flex-none flex p-2 text-xs text-right items-end">{JSTDate(new Date(datum.sent))}<br />{JSTTime(new Date(datum.sent))}</span>
</div>

const Talk = () => {
    const app = useRealmApp()
    const id = app.currentUser?.id || ""
    const { id:opponent, name } = useParams<KV>();
    const displayName = '　' + name.replace('_', '　')
    const [message, setMessage] = useState('')
    const addData = useAdd("message")
    const handleClick = (e:MouseEvent<HTMLButtonElement>) => {
        if (!message) return null
        addData({
            from: id,
            to: opponent,
            sent: new Date(),
            message: message
        })
    }

    const { loading, Data } = useData("message", null, { OR: [{from:id, to:opponent}, {from:opponent, to:id}] }, "SENT_ASC", 100)
    useEffect(() => {
        let myDiv = document.getElementById("talkHistory")?.parentElement;
        if (myDiv) myDiv.scroll(0,myDiv.scrollHeight);
    }, [Data])
    if (loading) return <Loading />
    return <>
        <div id="talkHistory" className='px-2 pt-10 pb-20'>
            <div className='fixed left-0 right-0 top-12 p-2 z-10 flex bg-theme-100'><UserCircleIcon className="w-6 h-6 text-theme-800"/>{displayName}</div>
            {(Data || []).map(datum => <React.Fragment key={datum._id}>{datum.from === id ? RightMessage(datum) : LeftMessage(datum)}</React.Fragment>)}
            <div className="flex w-full"><span className="bubble-left">テストですテストですテストですテストですテストですテストですテストですテストですテストですテストですテストですテストですテストですテストですテストですテストですテストですテストですテストですテストですテストです</span><span className="flex-none p-2 text-xs">{"TIME"}</span></div>
            <div className="fixed left-0 right-0 bottom-6 p-2 z-10 flex items-center bg-theme-100">
                <textarea className="flex-grow" rows={2} value={message} onChange={e => setMessage(e.currentTarget.value)} />
                <button className="m-1 p-1 flex-none bg-theme-600 hover:bg-theme-800 w-8 h-8 rounded-lg cursor-pointer" onClick={handleClick}>
                    <ArrowUpTrayIcon className="w-6 h-6 text-white cursor-pointer"/>
                </button>
            </div>
        </div>
    </>
}

export default Talk