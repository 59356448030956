import React, { ChangeEvent, KeyboardEvent, MouseEvent, useEffect, useRef } from 'react';
import { Field, FormikProps } from 'formik';
import Decimal from 'decimal.js';
import { FocusableElement, tabbable } from 'tabbable';

import { EyeIcon } from '@heroicons/react/24/solid'

import options from "contexts/options.json"
import DialogFrame from 'components/dialog'
import { recalcTotal } from './edit';
import { useAppContext } from 'contexts/AppContext';
import { ButtonClass } from 'utils';
import { gql, useApolloClient } from '@apollo/client';

const formatCurrency = (num:number) => String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') + '円'
const InputWithMark = ({ mark, index, name, tabIndex }:{ mark:string, index:number, name:string, tabIndex:number }) => (<Field name={`costItem[${index}].${name}`}>{({ field, form }:{ field:KV, form:FormikProps<any> }) => (<div className="relative"><input type="number" className="text-right w-full border-0 focus:border focus:border-theme-500 focus:shadow-tableCell py-0 pl-2 pr-9" {...field} onChange={e => handleChange(e, index, form)} onKeyDown={handleEnter} tabIndex={tabIndex} /><span className="absolute inset-y-0 right-0 pr-1">{mark}</span></div>)}</Field>)
const InputName = ({ index, name, tabIndex }:{ index:number, name:string, tabIndex:number }) => (<Field name={`costItem[${index}].${name}`}>{({ field }:{ field:KV }) => (<input className="w-full border-0 focus:border focus:border-theme-500 focus:shadow-tableCell py-0 px-2 col-span-3" tabIndex={tabIndex} {...field} onKeyDown={handleEnter} />)}</Field>)
const InputNumber = ({ index, name, tabIndex }:{ index:number, name:string, tabIndex:number }) => (<Field name={`costItem[${index}].${name}`}>{({ field, form }:{ field:KV, form:FormikProps<any> }) => (<input type="number" className="text-right w-full border-0 focus:border focus:border-theme-500 focus:shadow-tableCell py-0 px-2" tabIndex={tabIndex} {...field} onChange={e => handleChange(e, index, form)} onKeyDown={handleEnter} />)}</Field>)
const InputCheck = ({ index, name, tabIndex }:{ index:number, name:string, tabIndex:number }) => (<Field name={`costItem[${index}].${name}`}>{({ field }:{ field:KV }) => (<div className='bg-white border-0 flex justify-center items-center'><input type="checkbox" className="focus:border focus:border-theme-500 focus:shadow-tableCell py-0 px-2" tabIndex={tabIndex} {...field} checked={field.value} onKeyDown={handleEnter} /></div>)}</Field>)
const handleEnter = (e:KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && !e.nativeEvent?.isComposing) {
        const tabIndex = Number(e.currentTarget.getAttribute('tabindex') || -1)
        if (!(tabIndex >= 0)) return
        const tabbables = e.currentTarget.form ? tabbable(e.currentTarget.form).filter(v => Number(v?.getAttribute("tabindex") || 0) > 0) : []
        const index = tabbables.findIndex(v => Number(v.getAttribute('tabindex')) === tabIndex)
        if (index < 0) return
        const nextTabIndex = (tabbables[index + 1] || tabbables[0]).getAttribute('tabindex')
        const form = e.currentTarget.form
        const next = Array.from((form as HTMLFormElement)?.elements || []).find((v:KV) => v.getAttribute('tabindex') === nextTabIndex);
        (next as HTMLElement)?.focus();
    }
}

// newValue is used instead of e if directly call this function
const handleChange = (e:ChangeEvent<HTMLInputElement>|undefined, index:number, formik:FormikProps<any>, newValue?:KV) => {
    const quantity = new Decimal((e?.target.name.endsWith("quantity") ? e.target.value : newValue?.quantity ?? formik.values.costItem[index].quantity) || 0)
    const actualQuantity = new Decimal((e?.target.name.endsWith("actualQuantity") ? e.target.value : newValue?.actualQuantity ?? formik.values.costItem[index].actualQuantity) || 0)
    const unitPrice = new Decimal((e?.target.name.endsWith("unitPrice") ? e.target.value : newValue?.unitPrice ?? formik.values.costItem[index].unitPrice) || 0)
    const actualUnitPrice = new Decimal((e?.target.name.endsWith("actualUnitPrice") ? e.target.value : newValue?.actualUnitPrice ?? formik.values.costItem[index].actualUnitPrice) || 0)
    const listPrice = new Decimal((e?.target.name.endsWith("listPrice") ? e.target.value : newValue?.listPrice ?? formik.values.costItem[index].listPrice) || 0)
    // calculated values
    const amount = formik.values.costItem[index].unit === "yen" ? unitPrice.toFixed() : quantity.times(unitPrice).floor().toFixed()
    const actualAmount = formik.values.costItem[index].unit === "yen" ? actualUnitPrice.toFixed() : actualQuantity.times(actualUnitPrice.equals(new Decimal(0)) ? unitPrice : actualUnitPrice).floor().toFixed()
    //const actualAmount = formik.values.costItem[index].unit === "yen" ? (actualUnitPrice.equals(new Decimal(0)) ? unitPrice : actualUnitPrice).toFixed() : actualQuantity.times(actualUnitPrice.equals(new Decimal(0)) ? unitPrice : actualUnitPrice).floor().toFixed()
    const listAmount = formik.values.costItem[index].unit === "yen" ? (listPrice.equals(new Decimal(0)) ? unitPrice : listPrice).toFixed() : quantity.times(listPrice.equals(new Decimal(0)) ? unitPrice : listPrice).floor().toFixed()
    //console.log([formik.values.costItem[index].unit, quantity.toFixed(), actualQuantity.toFixed(), unitPrice.toFixed(), actualUnitPrice.toFixed(), listPrice.toFixed(), amount, actualAmount, listAmount])
    //console.log(quantity.toFixed(), actualQuantity.toFixed(), unitPrice.toFixed(), actualUnitPrice.toFixed(), listPrice.toFixed(), amount, actualAmount, listAmount)
    formik.values.costItem[index].amount = amount === "0" ? "" : amount
    formik.values.costItem[index].actualAmount = actualAmount === "0" ? "" : actualAmount
    formik.values.costItem[index].listAmount = listAmount === "0" ? "" : listAmount
    //console.log(actualAmount === "0" ? "" : actualAmount)
    //console.log(formik.values.costItem[index])
    recalcTotal(formik, null, formik.values.costItem[index].quoteId)
    e && formik.handleChange(e)
}

const sumHuman = gql`query($query:AttendanceQueryInput!){
    attendances(query:$query) {
        _id
        item {
            project {
                tree {
                    _id
                }
            }
            halfday
        }
        gearCost
        car
    }
}`
const sumMachine = gql`query($query:MachineQueryInput!){
    machines(query:$query) {
        project {
            tree {
                    _id
                }
        }
        machine
        distance
        unit
        oilType1
        oil1
        oilType2
        oil2
    }
}`
const sumTimber = gql`query($query:TimberQueryInput!){
    timbers(query:$query) {
        totalPrice
        amount
    }
}`

const FormFieldsItemTable = ({ formik, quoteIndex, quoteId }:{ formik:FormikProps<any>, quoteIndex:number, quoteId:number }) => {
    const client = useApolloClient()
    const context = useAppContext()
    const setCost = (code:number|string, quantity?:number, price:number|null = null) => {
        const index = formik.values.costItem.findIndex((v:KV) => v.quoteId === quoteId && v.code === Number(code))
        const newValue:KV = {}
        if (index > -1 ) {
            if (quantity !== null) {
                formik.setFieldValue(`costItem[${index}].actualQuantity`, quantity)
                newValue.actualQuantity = quantity
            }
            if (price !== null) {
                formik.setFieldValue(`costItem[${index}].actualUnitPrice`, price)
                newValue.actualUnitPrice = price
            }
            if (Object.keys(newValue)?.length) handleChange(undefined, index, formik, newValue)
        }
    }
    const getActualData = async () => {
        // Humans(Attendance)
        const humans = await client.query({query:sumHuman, variables:{query:{ item: { project: { tree: { _id: formik.values._id }}}}}})
        const data = humans.data.attendances.reduce((a:KV[], attendance:KV) => [...a, ...(attendance.item || []).filter((item:KV) => item.project?.tree?._id === formik.values._id).map((v:KV) => ({ quantity: v.halfday ? 0.5 : 1, gearCost:attendance.gearCost / (v.halfday ? 2 : 1), car:attendance.car / (v.halfday ? 2 : 1) }))], [])
        const humanQuantities = data.reduce((a:KV, r:KV) => ({quantity: a.quantity + r.quantity, gearCost: a.gearCost + (r.gearCost || 0), car: a.car + (r.car || 0)}), { quantity: 0, gearCost: 0, car: 0 })
        setCost(101, humanQuantities.quantity)
        setCost(226, humanQuantities.car)
        setCost(311, humanQuantities.car)
        setCost(521, undefined, humanQuantities.gearCost)
        // Machines
        const machines = await client.query({query:sumMachine, variables:{query:{ project: { tree: { _id: formik.values._id }}}}})
        const machineQuantities = machines.data.machines.reduce((acc:KV, machine:KV) => {
            const machineProps = context.options.machines.find(v => v._id === Number(machine.machine)) || {}
            const oil1Id = machine.oilType1 && machine.oil1 ? context.options.oilTypes.find(v => v.key === machine.oilType1)?.cost : undefined
            const oil2Id = machine.oilType2 && machine.oil2 ? context.options.oilTypes.find(v => v.key === machine.oilType2)?.cost : undefined
            
            return {...acc, 
                [machineProps.cost]: { actualQuantity: (acc[machineProps.cost]?.actualQuantity || 0) + Number(machine.distance || machine.unit || 0) },
                ...(oil1Id ? {[oil1Id]:{ actualQuantity:(acc[oil1Id]?.actualQuantity || 0) + Number(machine.oil1 || 0) }} : {}),
                ...(oil2Id ? {[oil2Id]:{ actualQuantity:(acc[oil2Id]?.actualQuantity || 0) + Number(machine.oil2 || 0) }} : {}),
                ...(machineProps.oilCost > 306 ? { [machineProps.oilCost]: { actualQuantity: (acc[machineProps.oilCost]?.actualQuantity || 0) + Number(machine.distance || machine.unit || 0) } } : {})
            }
        }, {})
        Object.keys(machineQuantities).forEach(key => setCost(key, machineQuantities[key].actualQuantity))
        // Timbers
        const timbers = await client.query({query:sumTimber, variables:{query:{ project: { tree: { _id: formik.values._id }}}}})
        const timber:number = timbers.data.timbers.reduce((acc:number, timber:KV) => acc + timber.totalPrice, 0)
        setCost(902, undefined, -timber)
        alert("実績値を取り込みました")
    }
    
    const forwardingPriceList = useAppContext().forwardingPriceProps
    const [showPriceList, setShowPriceList] = React.useState(false)
    const handlePriceListClick = (e:MouseEvent) => {
        setShowPriceList(true)
        e.preventDefault()
    }
    const tabbables = useRef<FocusableElement[]>()
    const tabRoot = useRef<HTMLDivElement|null>(null)

    useEffect(() => {
        tabbables.current = tabRoot.current ? tabbable(tabRoot.current) : undefined
    }, [])

    return (<><div ref={tabRoot} className="m-4 border border-gray-500 grid grid-cols-12 bg-gray-500 gap-px">
        <div className="text-right px-2 font-medium bg-white col-span-6">{`合計原価: ${formatCurrency(formik.values.quotes[quoteIndex].totalCost)}　実績原価: ${formatCurrency(formik.values.quotes[quoteIndex].totalActualCost)}　合計表示金額: ${formatCurrency(formik.values.quotes[quoteIndex].totalListCost)}`}</div>
        <div className="text-center bg-white">設計工数</div>
        <div className="text-center bg-white">実績工数</div>
        <div className="text-center bg-white">原価単価</div>
        <div className="text-center bg-white">実績単価</div>
        <div className="text-center bg-white">表示単価</div>
        <div className="text-center bg-white">非表示</div>
        {formik.values.costItem.filter((v:KV) => v.quoteId === quoteId).sort((a:KV, b:KV) => a.code - b.code).map((datum:KV, i:number, items:KV[]) => {
            const index = formik.values.costItem.findIndex((item:KV) => quoteId === item.quoteId && datum.code === item.code)
            return (<React.Fragment key={datum.costId}>
                {(i === 0 || datum.category1 !== items[i - 1].category1) ? <div className="bg-theme-100 px-2">{datum.category1}</div> : <div className="bg-theme-100 px-2 -mt-px" />}
                {(i === 0 || datum.category1 !== items[i - 1].category1 || datum.category2 !== items[i - 1].category2) ? <div className="bg-theme-100 px-2 col-span-2">{datum.category2 || ""}</div> : <div className="bg-theme-100 px-2 -mt-px col-span-2" />}
                {datum.noName ?
                    <InputName index={index} tabIndex={100 + i} name="name" />
                    : (<div className={"relative bg-theme-100 px-2 col-span-3"}>
                        {`${datum.code}　${datum.name}`}{datum.code === 621 && <EyeIcon name="openView" className="absolute right-1 bottom-0 w-6 h-6 cursor-pointer" onClick={handlePriceListClick} />}
                    </div>)}
                {datum.unit === 'yen' ? <div className="bg-gray-100 text-right px-2">1　式</div> : <InputWithMark index={index} tabIndex={200 + i} name="quantity" mark={options.costUnit[datum.unit]} />}
                {datum.unit === 'yen' ? <div className="bg-gray-100 text-right px-2">1　式</div> : <InputWithMark index={index} tabIndex={300 + i} name="actualQuantity" mark={options.costUnit[datum.unit]} />}
                <InputNumber index={index} tabIndex={400 + i} name="unitPrice" />
                <InputNumber index={index} tabIndex={500 + i} name="actualUnitPrice" />
                <InputNumber index={index} tabIndex={600 + i} name="listPrice" />
                <InputCheck index={index} tabIndex={700 + i} name="hide" />
            </React.Fragment>)
        })}
    </div>
    <div className="px-4 text-right sm:px-6"><button type="button" className={ButtonClass} onClick={getActualData}>実績取込</button></div>
        {showPriceList && <DialogFrame show={showPriceList} setShow={setShowPriceList}>
            <table>
                <thead className="border-b text-center">
                    <tr>
                        <th>距離</th>{forwardingPriceList.heavyMachineTransport.rowHead.map((v:string) => <th key={v}>{v}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {forwardingPriceList.heavyMachineTransport.columnHead.map((v:string, i:number) => <tr key={v}>
                        <td>{v}</td>{forwardingPriceList.heavyMachineTransport.data[i].map((v:number, j:number) => <td key={i + "-" + j}>{formatCurrency(v)}</td>)}
                    </tr>)}
                </tbody>
            </table>
            <button className="m-2 p-2 rounded text-white bg-theme-800" onClick={() => setShowPriceList(false)}>閉じる</button>
        </DialogFrame>}
    </>)
}

export default FormFieldsItemTable
