import { JSTDateKanji } from "contexts/dateUtils";
import options from "contexts/options.json"
import { baseStyle, formatPrice, getNameLabelContent, pageStyle, underlineLayout } from "contexts/pdfUtils";
import { calcNonstoredNumbersNew, getInvoiceAdjust } from "./edit"
import { getAddressLabel, getNameLabel, invoiceRegisterNo } from "contexts/utils";
const titlelogo = require("components/titlelogo.json").titlelogo;


const getTreeInvoice = (data:KV, values:KV) => {
    const printingInvoices:number[] = data.quotes.filter((v:KV) => v.active).map((v:KV) => data.quotes.findIndex((quote:KV) => quote.quoteId === v.quoteId))
    console.log(printingInvoices)
    const names = getNameLabel(data)
    const coverNameLabelContent = getNameLabelContent({names})
    const nameLabelContents = (values.summarize ? [true] : printingInvoices).map(() => getNameLabelContent({names, margin:[0, baseStyle.fontSize * 2, 0, baseStyle.fontSize], width:200}))// Cannot reuse.  Make object for each page

    return {
        pageSize: 'A4',
        pageMargins: baseStyle.pageMargins,
        info: { title: '請求書' },
        content: [...coverPage(data, values, coverNameLabelContent), ...(values.summarize ? invoicePage(data, nameLabelContents[0], undefined, printingInvoices) : printingInvoices.map((i, index:number) => invoicePage(data, nameLabelContents[index], i)).flat())],
        styles: {
        },
        defaultStyle: baseStyle,
        coverNameLabelContent,
        nameLabelContents
    }
}

const getActualAmount = (quote:KV):number => quote.amount + getInvoiceAdjust(quote)

// if indexes(array of active quotes index) is set, summarize
const invoicePage = (data:KV, nameLabelContent:KV, i = 0, indexes:number[]|null = null) => {
    const { tax, amountWithTax } = indexes ? indexes.map(v => calcNonstoredNumbersNew(data, v, true)).reduce((a:KV, r:KV) => ({ tax: a.tax + r.tax, amountWithTax: a.amountWithTax + r.amountWithTax }), { tax: 0, amountWithTax: 0 }) : calcNonstoredNumbersNew(data, i, true)
    const startDate = data.operationDates?.startDate//indexes ? indexes.map(v => data.quotes[v].startDate).reduce((a, r) => (r && (!a || (new Date(r).getTime() < new Date(a).getTime()))) ? r : a) : (data.quotes[i].startDate || data.operationDates?.startDate)
    const endDate = data.operationDates?.endDate//indexes ? indexes.map(v => data.quotes[v].endDate).reduce((a, r) => (r && (!a || (new Date(r).getTime() > new Date(a).getTime()))) ? r : a) : (data.quotes[i].endDate || data.operationDates?.endDate)
    // Invoice adjust is added if it is positive -> not add even if positive
    const amount = indexes ?  indexes.reduce((a:number, r:number) => a + data.quotes[r].amount, 0) : data.quotes[i].amount
    const actualAmount = indexes ?  indexes.reduce((a:number, r:number) => a + getActualAmount(data.quotes[r]), 0) : getActualAmount(data.quotes[i])
    // Separated invoice adjust item if it is negative -> show even if it is positive
    const invoiceAdjusts = [
        { title: data.quotes[indexes ? indexes[0] : i].invoiceAdjustTitle || "調整額", value: indexes ? indexes.reduce((a, r) => a + (data.quotes[r].invoiceAdjust || 0), 0) : (data.quotes[i].invoiceAdjust || 0) },
        { title: data.quotes[indexes ? indexes[0] : i].invoiceAdjustTitle2 || "調整額②", value: indexes ? indexes.reduce((a, r) => a + (data.quotes[r].invoiceAdjust2 || 0), 0) : (data.quotes[i].invoiceAdjust2 || 0) },
        { title: data.quotes[indexes ? indexes[0] : i].invoiceAdjustTitle3 || "調整額③", value: indexes ? indexes.reduce((a, r) => a + (data.quotes[r].invoiceAdjust3 || 0), 0) : (data.quotes[i].invoiceAdjust3 || 0) }
    ]
    return [
        {
            pageBreak: "before",
            columns: [//height 
                {
                    width: 'auto', stack: [
                        { text: ' 御 請 求 書 ', background: 'green', color: 'white', fontSize: 20 },
                        nameLabelContent,
                        { text: '下記の通り御請求申し上げます。', margin: [0, baseStyle.fontSize*2] },
                        {
                            table: {
                                widths: [100, '*'],
                                body: [
                                    [{ text: '請求金額', margin: [0, 6, 0, 0] }, { text: formatPrice(amountWithTax) + "円", bold: true, fontSize: 16, alignment: 'right' }]
                                ],

                            },
                            layout: underlineLayout,
                        },
                        { text: '(消費税及び地方消費税相当額を含む)', alignment: 'right', fontSize: 9 },
                    ]
                },
                { width: '*', text: '' },
                {
                    width: 'auto', alignment: 'right', stack: [
                        { text: `請求No.${data.fileID}`, margin: [0, 10, 0, 0] },
                        { text: data.invoiceDate && JSTDateKanji(new Date(data.invoiceDate)) },
                        { width: 200, image: titlelogo, margin: [0, baseStyle.fontSize] },
                        { text: invoiceRegisterNo },
                        { stack: ['長野県松本市岡田下岡田774-1', 'TEL (0263) 87-5361', 'FAX (0263) 87-5362'], width: 'auto' },
                        { columns:[
                            { width: "*", text:''},
                            {
                                width: 'auto',
                                table: {
                                    widths: [36,36,36],
                                    heights: ['auto',36],
                                    body: [
//                                        ["工事責任者", "樹木事業部長", "承認者"],
                                        [" ", " ", " "],
                                        ["", "", ""]
                                    ]
                                },
                                fontSize: 6,
                                layout: {
                                    hLineStyle: () => ({ dash: { length: 1 } }),
                                    vLineStyle: () => ({ dash: { length: 1 } }),
                                    paddingLeft: () => 0,
                                    paddingRight: () => 0,
                                    paddingTop: () => 0,
                                    paddingBottom: () => 0,
                                },
                                alignment: "center"
                            }
                        ]}
                    ]
                },
            ]
        },
        {
            table: {
                widths: [100, '*'],
                body: [
                    ['件名', (!indexes && data.quotes[i].subject) || data.subject],
                    ['場所', data.place],
                    ['実施日', `${startDate ? JSTDateKanji(new Date(startDate)) : ""} ~ ${endDate ? JSTDateKanji(new Date(endDate)) : ""}`],
                    ['引渡日', data.handleDate && JSTDateKanji(new Date(data.handleDate))],
                ]
            },
            margin: [0, baseStyle.fontSize * 2],
            layout: {
                hLineWidth: (i:number) => i ? 1 : 0,
                vLineWidth: () => 0,
                hLineStyle: () => ({ dash: { length: 1 } })
            },
        },
        '　',
        {
            table: {
                headerRows: 1,
                widths: ['*', 55, 35, 25, 55],

                body:
                    [[
                        { text: '項目・内容', alignment: 'center' },
                        { text: '単価', alignment: 'center' },
                        { text: '数量', alignment: 'center' },
                        { text: '単位', alignment: 'center' },
                        { text: '金額', alignment: 'center' }
                    ],
                    [
                        { text: '上記工事の請負代金', margin: [baseStyle.fontSize * 2, 0] },
                        { text: '' },
                        { text: '1', alignment: 'center' },
                        { text: '式', alignment: 'center' },
                        { text: formatPrice(amount), alignment: 'right' }
                    ],
                    ['　', '', '', '', ''],
                    ...(invoiceAdjusts.map(v => v.value ?  [{ text: v.title, margin: [baseStyle.fontSize * 2, 0] }, '', '', '', { text: (v.value < 0 ? '▲' : '') + formatPrice(Math.abs(v.value)), alignment: 'right' } ] : ['　', '', '', '', ''])),
                    ['　', '', '', '', ''],
                    [
                        { text: '消費税', alignment: 'right', margin: [baseStyle.fontSize * 2, 0] },
                        { text: '' },
                        { text: indexes ? data.quotes[indexes[0]].taxRate : data.quotes[i].taxRate, alignment: 'center' },
                        { text: '%', alignment: 'center' },
                        { text: formatPrice(tax), alignment: 'right' }
                    ],
                    ['　', '', '', '', ''],
                    [
                        { text: '合計', alignment: 'right', margin: [baseStyle.fontSize * 2, 0] },
                        '', '', '',
                        { text: formatPrice(amountWithTax), alignment: 'right' }
                    ]]
            }
        },
        '　',
        {
            table: {
                widths: ['auto','auto','auto','auto','*'],
                body: [
                    ["振込口座", "", "", "", ""],
                    [" ", "", "", "", ""],
                    ["松本信用金庫", "浅間温泉支店", "普通", "0193370", "(株)柳沢林業"],
                    ["長野銀行", "松本北支店", "普通", "8819432", "(株)柳沢林業"],
                    ["ゆうちょ銀行", "一一八支店", "普通", "4326058", "(株)柳沢林業"],
                    [" ", "", "", "", ""],
                    [{ text:"請求日より1か月以内に上記指定口座にお振込みください。", colSpan:5 }, "", "", "", ""],
                    [{ text:"恐れ入りますが、振込手数料はお客様のご負担でお願い致します。", colSpan:5 }, "", "", "", ""],
                ]
            },
            margin: [0, 10],
            layout: {
                hLineWidth: (i:number, node:KV) => i === 0 || i === node.table.body.length ? 1 : 0,
                vLineWidth: (i:number, node:KV) => i === 0 || i === node.table.widths.length ? 1 : 0
            },
        },
    ]
}


const coverMessage = '平素は格別のご高配を賜り、厚く御礼申し上げます。先日無事工事が終了致しました。\n下記の通り請求書を同封しますので、ご査収の程よろしくお願い申し上げます。\n'

const coverPage = (data:KV, values:KV, coverNameLabelContent: KV) => {
    let documents = [["請求書", { text:"1", alignment: "center"}, '部']]
    values.attachDoc?.filter((v:KV) => v.name && v.count).forEach((v:KV) => documents.push([v.name, { text: v.count, alignment: "center" }, '部']))
    const addresses = getAddressLabel(data)

    return [
        {
            columns: [//height 
                {
                    width: 'auto', stack: [
                        { text: '御請求書', margin: [pageStyle.address.x, 0, 0, 0] },
                        { text: addresses[0], margin: [pageStyle.address.x, 0, 0, 0] },
                        { text: addresses[1], margin: [pageStyle.address.x, 0, 0, 0] },
                        { text: addresses[2], margin: [pageStyle.address.x, 0, 0, 0] },
                        coverNameLabelContent,                    ]
                },
                { width: '*', text: '' },
                {
                    width: 'auto', alignment: 'right', stack: [
                        { text: data.invoiceDate && JSTDateKanji(new Date(data.invoiceDate)) },
                        { width: 200, image: titlelogo, margin: [0, baseStyle.fontSize] },
                        { stack: ['樹木事業部', '長野県松本市岡田下岡田774-1', 'TEL (0263) 87-5361', 'FAX (0263) 87-5362'], width: 'auto' },
                    ]
                },
            ]
        },
        { width: '*', text: "御請求書の送付について", alignment: "center", fontSize: 12, margin: [0, 20] },
        { width: '*', text: coverMessage, margin: 20 },
        { width: '*', text: '記', alignment: "center", margin: 20 },
        {
            columns: [
                { width: '*', text: '' },
                {
                    width: 'auto',
                    table: {
                        widths: [200, 30, 'auto'],
                        body: documents
                    },
                    layout: 'noBorders'
                },
                { width: '*', text: '' }
            ]
        },
        { width: '*', text: values.messages?.invoice || '', margin: 20 },
        { width: '*', text: '以上', alignment: "right", margin: 20 },
        {
            columns: [
                { width: '*', text: '' },
                {
                    width: 'auto',
                    alignment: "right",
                    margin: [20, 0],
                    stack: [
                        `担当　　${data.inCharge.surname} ${data.inCharge.givenName}`,
                        `TEL ${data.inCharge.tel || ""}`
                    ]
                }
            ],
            absolutePosition: { x: baseStyle.pageMargins[0], y: 760 }
        },
    ]
}

export default getTreeInvoice
