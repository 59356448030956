import React from "react"
import { useData } from "graphql/useCollection"
import Loading from "components/Loading"
import { UserCircleIcon } from "@heroicons/react/24/solid"
import { Link } from "react-router-dom"

const List = () => {
    const { loading, Data } = useData("user")
    if (loading) return <Loading />
    return <>
        {(Data || []).map(datum => 
            <Link key={datum._id} to={`message/talk/${datum._id}/${datum.surname || ""}_${datum.givenName || ""}`}><div data-name={datum._id} className="flex p-2 border-2 cursor-pointer hover:bg-theme-100"><UserCircleIcon className="w-6 h-6 text-theme-800"/> {`　${datum.surname || ""}　${datum.givenName || ""}`}</div></Link>
        )}
    </>
}
export default List