import Decimal from 'decimal.js';

import options from "contexts/options.json"
import { defaultStyle, pageStyle, underlineLayout } from 'contexts/pdfUtils';
import { JSTDateKanji } from 'contexts/dateUtils';
import { invoiceRegisterNo } from 'contexts/utils';
const titlelogo = require("components/titlelogo.json").titlelogo;

const formatPrice = (num?:number) => num ? String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') : ""
const times = (a:any, b:any) => {
    const x = new Decimal(a || 0)
    const y = new Decimal(b || 0)
    return x.times(y).floor()
}

const getFirewoodPdfData = (data:KV) => {
    const productPrices:Decimal[] = data.item.map((datum:KV) => times(datum.quantity, datum.unitPrice))//Decimal
    const productPrice = productPrices.reduce((a, r) => a.plus(r)).floor().toNumber() //int
    const deliveryTotal = data.item.reduce((a:number, r:KV) => a + r.deliveryFare, 0) //int
    const subTotal = data.item.reduce((a:number, r:KV) => a + r.amount, 0) //int
    const tax = new Decimal(subTotal).times(new Decimal(data.taxRate || 0)).dividedBy(100).floor().toNumber()
    const discountTotal = data.item.reduce((a:number, r:KV) => a + r.discount, 0)
    let corporateName = ""
    let displayName = `${data.customer.name?.surname || ""}　${data.customer.name?.givenName || ""}`
    if (displayName === "　") displayName = `${data.customer.corporateName || ""}　${data.customer.division || ""}`
    else corporateName = `${data.customer.corporateName || ""}　${data.customer.division || ""}`
    const invoiceAddress = data.customer.invoiceAddress || data.customer.address 
    return {
        pageSize: 'A4',
        pageMargins: pageStyle.pageMargins,
        info: { title: '請求書' },
        content: [
            {
                columns: [//height 
                    { width: 'auto', stack: [
                        { text: '御 請 求 明 細 書', background: 'green', color: 'white', alignment: 'left', margin: [pageStyle.address.x, 0, 0, 0], fontSize: pageStyle.fontSizes.title },
                        { text: '〒' + invoiceAddress.zip, alignment: 'left', margin: [pageStyle.address.x, 0, 0, 0] },
                        { text: options.prefecture[invoiceAddress.state] + invoiceAddress.citystr, alignment: 'left', margin: [pageStyle.address.x, 0, 0, 0] },
                        { text: invoiceAddress.room || '　', alignment: 'left', margin: [pageStyle.address.x, 0, 0, 0] },
                        { text: corporateName, fontSize: pageStyle.fontSizes.name, alignment: 'left', margin: [pageStyle.address.x, 0, 0, 0] },
                        {
                            table: {
                                widths: [pageStyle.address.width],
                                body: [[{ text: `${displayName}　様`, fontSize: pageStyle.fontSizes.name, alignment: 'right' }]]
                            },
                            layout: underlineLayout,
                            margin: [pageStyle.address.x, 1, 0, 0]
                        },
                        { text: '下記のとおり、ご請求申し上げます。', alignment: "left", margin: [0, 20] },
                        { table: {
                            widths: [100, 120],
                            body: [
                                [{ text: 'ご請求額', bold: true, fontSize: 16 }, { text: formatPrice(data.total) + "円", bold: true, fontSize: 16 }]
                            ],

                        }},
                    ]},
                    { width: '*', text: '' },
                    { width: 'auto', alignment: "right", stack: [
                        {text: `No.${data.invoiceNo}`, margin: [0, 10, 0, 0] },
                        { width: 'auto', text: data.invoiceDate && JSTDateKanji(new Date(data.invoiceDate)) },
                        '　',
                        { width: 200, image: titlelogo },
                        { text: invoiceRegisterNo },
                        { stack: ['長野県松本市岡田下岡田774-1', 'TEL (0263) 87-5361', 'FAX (0263) 87-5362'], width: 'auto' },
                        { text: '合法木材 長野県木連-045' },
                        {
                            width: 150, alignment: 'right', stack: [
                                `営業担当: ${data.sales?.surname || ""} ${data.sales?.givenName || ""}`,
                                `経理担当: ${data.invoiceAuthor?.surname || ""} ${data.invoiceAuthor?.givenName || ""}`
                            ],
                            margin: [0,10]
                        },
                    ]}
                ]
            },
            ' ',
            {
                table: {
                    headerRows: 1,
                    widths: discountTotal ? [55, 55, 'auto', 'auto', 'auto', 'auto', 40, 45, 40, 40, '*'] : [55, 55, 'auto', 'auto', 'auto', 'auto', 50, 50, 50, '*'],

                    body: discountTotal ? 
                        [(['納材日', '種別', '樹種', '区分', '数量', '単位', '単価', '薪価格', '割引額', '運賃', '合計金額'] as any[])].concat(
                            data.item.map((datum:KV, i:number) => [
                                datum.deliveryDate && new Date(datum.deliveryDate).toLocaleDateString(),
                                datum.type,
                                datum.species,
                                datum.length,
                                datum.quantity,
                                options.unit[datum.unit],
                                formatPrice(datum.unitPrice),
                                formatPrice(productPrices[i].floor().toNumber()),
                                formatPrice(datum.discount),
                                formatPrice(datum.deliveryFare),
                                { text: formatPrice(datum.amount), alignment: 'right' }
                            ])
                        ).concat([
                            [{ text: '小計', colSpan: 6, fontSize: 11, alignment: 'left' }, '', '', '', '', '', '', formatPrice(productPrice), formatPrice(discountTotal), formatPrice(deliveryTotal), { text: formatPrice(subTotal), alignment: 'right' }],
                            [{ text: '　', colSpan: 8, alignment: 'left' }, '', '', '', '', '', '', '', '', '', ''],
                            [{ text: '消費税合計', colSpan: 8, alignment: 'left' }, '', '', '', '', '', '', '', '', data.taxRate + '%', { text: formatPrice(tax), alignment: 'right' }],
                            [{ text: 'ご請求金額', colSpan: 9, fontSize: 12, alignment: 'left' }, '', '', '', '', '', '', '', '', '', { text: formatPrice(data.total), alignment: 'right' }],
                        ])
                         :
                        [(['納材日', '種別', '樹種', '区分', '数量', '単位', '単価', '薪価格', '運賃', '合計金額'] as any[])].concat(
                            data.item.map((datum:KV, i:number) => [
                                datum.deliveryDate && new Date(datum.deliveryDate).toLocaleDateString(),
                                datum.type,
                                datum.species,
                                datum.length,
                                datum.quantity,
                                options.unit[datum.unit],
                                formatPrice(datum.unitPrice),
                                formatPrice(productPrices[i].floor().toNumber()),
                                formatPrice(datum.deliveryFare),
                                { text: formatPrice(datum.amount), alignment: 'right' }
                            ])
                        ).concat([
                            [{ text: '小計', colSpan: 6, fontSize: 11, alignment: 'left' }, '', '', '', '', '', '', formatPrice(productPrice), formatPrice(deliveryTotal), { text: formatPrice(subTotal), alignment: 'right' }],
                            [{ text: '　', colSpan: 7, alignment: 'left' }, '', '', '', '', '', '', '', '', ''],
                            [{ text: '消費税合計', colSpan: 7, alignment: 'left' }, '', '', '', '', '', '', '', data.taxRate+'%', { text: formatPrice(tax), alignment: 'right' }],
                            [{ text: 'ご請求金額', colSpan: 8, fontSize: 12, alignment: 'left' }, '', '', '', '', '', '', '', '', { text: formatPrice(data.total), alignment: 'right' }],
                        ]),
                }
            },
            {
                table: {
                    widths: '*',
                    body: [[{
                        alignment: 'left',
                        stack: [
                            '松本信用金庫　浅間温泉支店　普通 0193370',
                            '長野銀行　　　松本北支店　　普通 8819432',
                            'ゆうちょ銀行　一一八支店　　普通 4326058',
                            'カ) ヤナギサワリンギョウ'
                        ]
                    }]]
                },
                margin: [0,20,0,0]
            },
        ],
        defaultStyle: defaultStyle
    }
}

export default getFirewoodPdfData
