import React, { MouseEvent, useState } from 'react'

import { DataList, ListItem, EditLinkTd, FilterInput, FilterCheck, FiltersFunction, FilterDates, RowFunction } from "components/list"
import { ButtonClass } from 'utils'
import options from "contexts/options.json"
import { JSTDate, JSTMonthDate, JSTTime, msToHourMinute } from 'contexts/dateUtils'
import { useRealmApp } from 'RealmApp'
import { Link } from 'react-router-dom'
import { CheckIcon, CheckCircleIcon, EllipsisHorizontalCircleIcon, EllipsisVerticalIcon, XCircleIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { Menu } from '@headlessui/react'
import UserSelect from 'components/userSelect'
const location = "/attendance"

const StatusIcon = ({ status, disabled }: { status: string, disabled?:boolean }) => <>{
    !status ? <EllipsisHorizontalCircleIcon className={`w-6 h-6 text-yellow-300 ${disabled ? '' : 'hover:text-yellow-500'}`} /> :
        status === "OK" ? <CheckCircleIcon className={`w-6 h-6 text-theme-400 ${disabled ? '' : 'hover:text-theme-600'}`} /> : <XCircleIcon className={`w-6 h-6 text-red-400 ${disabled ? '' : 'hover:text-red-600'}`} />
}</>



const heads: KV[] = [
    { text: "社員", field: "user.userID:start" },
    { text: "日付", field: "start:user.userID" },
    { text: "休暇", field: "" },
    { text: "開始時刻", field: "" },
    { text: "終了時刻", field: "" },
    { text: "超過時間", field: "" },
    { text: "休日に出社", field: "" },
    { text: "部門", field: "" },
    { text: "案件", field: "" },
    { text: "" },
    { text: "承認" },
]

const headsMobile: KV[] = [
    { text: "社員", field: "user.userID:start" },
    { text: "日付", field: "start:user.userID" },
    { text: "休暇", field: "" },
    { text: "開始/終了", field: "" },
    { text: "" },
    { text: "承認" },
]

const menuItems = [
    { text: "出勤打刻", location: `${location}/create` },
    { text: "退勤打刻", location: `${location}/editend` },
    { text: "勤務表", location: `${location}/sheet` },
]

const Filter:FiltersFunction = (filter, updateFilter, dateParam) => {
    const app = useRealmApp()
    const [userSelectShow, setUserSelectShow] = useState<string|null|undefined>()// for string, use "user" as dummy(no meaning)
    const supervisor = filter.user?.supervisor || ""
    const updateSupervisor = (value: any) => {
       updateFilter("user", value ? { supervisor: app.currentUser?.customData.userID } : { name: `${app.currentUser?.customData?.surname || ""}　${app.currentUser?.customData?.givenName || ""}` })
    }
    const handleClick = (e:MouseEvent<HTMLButtonElement>) => {
        if (e.currentTarget.name === "username") setUserSelectShow("user")
        if (e.currentTarget.name === "supervise") {
            updateSupervisor(!supervisor)
        }
    }
    // For attribute 'update', only userID and name is used and filtered
    const updateUser = (update?:KV|null|undefined) => {
        updateFilter("user", update ? {userID:update.userID, name:update.name} : null)
    }
    return <>
        <div className="hidden sm:inline"><FilterInput
            label="社員ID"
            name="user.userID"
            value={filter.user?.userID || ""}
            setState={(v) => updateFilter("user", { userID: v, name: "" })}
            nolabel
        /></div>
        <div className="hidden sm:inline"><FilterInput
            label="社員名"
            name="user.name"
            value={filter.user?.name || ""}
            setState={(v) => updateFilter("user", { userID: "", name: v })}
            nolabel
        /></div>
        <FilterDates dateParam={dateParam} />
        <div className="hidden sm:inline"><FilterCheck
            label="管理下全員"
            name="supervise"
            state={Boolean(filter.user?.supervisor)}
            setState={(v:boolean) => {updateSupervisor(v)}}
        /></div>
        {menuItems.map(item => <Link key={item.location} to={item.location}><button className={'hidden sm:inline ' + ButtonClass.replace('m-3 py-2 px-4 w-28', 'mx-1 my-0 p-1 w-24')}>{item.text}</button></Link>)}
        <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
            <Menu as="div" className="relative text-sm text-gray-700">
                {({ open }) => <>
                    <Menu.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                        <span className="sr-only">Open main menu</span>
                        {open ? <XMarkIcon className="block h-6 w-6" aria-hidden="true" /> : <EllipsisVerticalIcon className="block h-6 w-6" aria-hidden="true" />}
                    </Menu.Button>
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-20">
                        <Menu.Item><button name="username" className="block px-4 py-2" onClick={handleClick}>社員：{filter.user?.name || "指定なし"}</button></Menu.Item>
                        <Menu.Item><button name="supervise" className="block px-4 py-2" onClick={handleClick}>管理下全員　{supervisor && <CheckIcon className="inline h-4 w-4"/>}</button></Menu.Item>
                        {menuItems.map((item) => <Menu.Item key={item.location}>
                            <Link to={item.location} className='block px-4 py-2'>{item.text}</Link>
                        </Menu.Item>)}
                    </Menu.Items>
                </>}
            </Menu>
        </div>
        <UserSelect name={userSelectShow} setName={setUserSelectShow} onClick={updateUser} asAttendanceObj eligibleOnly/>
    </>
}

export const ListMobile = () => {
    const app = useRealmApp()
    const row = ({ datum, clickDelete, clickCollection, clickUpdate }: { datum: KV, clickDelete?: (e: MouseEvent<HTMLButtonElement>) => void, clickCollection?: (e: MouseEvent<HTMLButtonElement>) => void, clickUpdate?: (e: MouseEvent<HTMLButtonElement>) => void }) => <>
        <ListItem data={[datum.user.name.split(/[ 　]/)[0]]} />
        <ListItem data={[datum.start ? JSTMonthDate(new Date(datum.start), true) : ""]} />
        <ListItem data={datum.item.length === 1 ? [datum.item[0].holiday] : datum.item.map((item: KV, i:number) => item.holiday ? `${i ? "午後" : "午前"}${item.holiday}` : ' ')} />
        <ListItem data={[
            ((!datum.item[0]?.holiday || (datum.item[1] && !datum.item[1].holiday)) && datum.start) ? JSTTime(new Date(datum.start)) : "",
            datum.end ? JSTTime(new Date(datum.end)) : ""
        ]} />
        {([datum.user.userID, datum.user.supervisor].includes(app.currentUser?.customData.userID) || app.currentUser?.customData.status === "admin") ? <EditLinkTd location={location} id={datum._id} onClickEye={(e) => window.open(`/attendance/view/${datum._id}`,'_blank', 'rel=noopener noreferrer')} onClickDelete={datum.status !== "OK" ? clickDelete : undefined} /> : <td></td>}
        {/*  Specify data-id and data-set to update.  Definition of clickUpdate is handleUpdateClick in SortableListFrame*/}
        <td>{app.currentUser?.customData.userID === datum.user.supervisor ?
            <button className="w-8 h-8" data-id={datum._id} data-set={`{"status":"${!datum.status ? 'OK' : datum.status === 'OK' ? 'NG' : ''}"}`} onClick={clickUpdate}><StatusIcon status={datum.status} /></button>
            : <StatusIcon status={datum.status} disabled />
        }</td>
    </>
    return <DataList collection="attendance" type="search" location={location} heads={headsMobile} filter={Filter} instantFilter dateField="start" download={undefined} row={row} dialog={undefined} noAdd={undefined} handleRowClick={undefined} />
}

const List = () => {
    const app = useRealmApp()
    const download = (Data: KV[]) => Data.map(datum => {
        return ({
            "社員名": datum.user.name,
            "日付": datum.start ? JSTDate(new Date(datum.start)) : "",
            "開始": datum.start ? JSTTime(new Date(datum.start)) : "",
            "退勤時刻": datum.end ? JSTTime(new Date(datum.end)) : "",
            "休憩時間": datum.rest ? msToHourMinute(datum.rest) : "",
            "深夜休日休憩時間": datum.nightRest ? msToHourMinute(datum.nightRest) : "",
            "超過時間": datum.excess ? msToHourMinute(datum.excess) : "",
            "超過を時間外扱にする": datum.isOver ? "◯" : "",
            "休日出勤扱にする": datum.isHoliday ? "◯" : "",
            "休日に出社": datum.holidayAttend ? "◯" : "",
            "有給/代休/欠勤": datum.item?.[0]?.holiday || "",
            "部門": datum.item?.[0]?.division ? options.division[datum.item?.[0]?.division] || "" : "",
            "案件": datum.item?.[0]?.project?.forest?.subject || datum.item?.[0]?.project?.tree?.subject || "",
            "作業内容": datum.item?.[0]?.work?.item || "",
            "労災区分": datum.item?.[0]?.insurance ? options.insurance[datum.item?.[0]?.insurance] || "" : "",
            "有給/代休/欠勤2": datum.item?.[0]?.holiday || "",
            "部門2": datum.item?.[1]?.division ? options.division[datum.item?.[1]?.division] || "" : "",
            "案件2": datum.item?.[1]?.project?.forest?.subject || datum.item?.[1]?.project?.tree?.subject || "",
            "作業内容2": datum.item?.[1]?.work?.item || "",
            "労災区分2": datum.item?.[1]?.insurance ? options.insurance[datum.item?.[1]?.insurance] || "" : "",
            "器具等使用損料": datum.gearCost || "",
            "出社先": datum.workplace || "",
            "マイカー使用距離": datum.car || "",
            "承認": datum.status ? options.attendanceStatus[datum.status] || "" : "",
            "備考": datum.remarks || ""
        })
    })

    const Row:RowFunction = ({ datum, clickDelete, clickUpdate }) => <>
        <ListItem data={[datum.user.name]} />
        <ListItem data={[datum.start ? JSTMonthDate(new Date(datum.start), true) : ""]} />
        <ListItem data={datum.item.length === 1 ? [datum.item[0].holiday] : datum.item.map((item: KV, i:number) => item.holiday ? `${i ? "午後" : "午前"}${item.holiday}` : ' ')} />
        <ListItem data={[((!datum.item[0]?.holiday || (datum.item[1] && !datum.item[1].holiday)) && datum.start) ? JSTTime(new Date(datum.start)) : ""]} />
        <ListItem data={[datum.end ? JSTTime(new Date(datum.end)) : ""]} />
        <ListItem data={[datum.excess ? msToHourMinute(datum.excess) : ""]} />
        <ListItem data={[datum.holidayAttend ? "◯" : ""]} />
        <ListItem data={datum.item.map((item: KV) => options.division[item.division])} />
        <ListItem data={datum.item.map((item: KV) => item.project?.[item.division]?.subject || "")} />
        {([datum.user.userID, datum.user.supervisor].includes(app.currentUser?.customData.userID) || app.currentUser?.customData.status === "admin") ? <EditLinkTd location={location} id={datum._id} onClickEye={(e) => window.open(`/attendance/view/${datum._id}`,'_blank', 'rel=noopener noreferrer')} onClickDelete={datum.status !== "OK" ? clickDelete : undefined} /> : <td></td>}
        <td>{app.currentUser?.customData?.userID === datum.user.supervisor ?
            <button className="w-8 h-8" data-id={datum._id} data-set={`{"status":"${!datum.status ? 'OK' : datum.status === 'OK' ? 'NG' : ''}"}`} onClick={clickUpdate}><StatusIcon status={datum.status} /></button>
            : <StatusIcon status={datum.status} disabled />
        }</td>
    </>
    return <DataList collection="attendance" type="search" location={location} heads={heads} filter={Filter} dateField="start" download={download} row={Row} dialog={undefined} limit={500} noAdd={undefined} handleRowClick={undefined} />
}

export default List