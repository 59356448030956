import Decimal from "decimal.js";

import options from "contexts/options.json"
import { formatNumber } from 'utils';
import { addLineBreak, baseStyle, formatPrice, getNameLabelContent, pageStyle, underlineLayout } from "contexts/pdfUtils";
import { JSTDateKanji } from "contexts/dateUtils";
import { getNameLabel, invoiceRegisterNo } from "contexts/utils";
const titlelogo = require("components/titlelogo.json").titlelogo;

const getTimberInvoicePdf = (data:KV[]) => {
    const names = getNameLabel(data[0])
    const nameLabel = getNameLabelContent({names, margin:[pageStyle.address.x, baseStyle.fontSize / 2, 0, baseStyle.fontSize], width:200})
    return {
        pageSize: 'A4',
        pageMargins: baseStyle.pageMargins,
        info: { title: '請求書' },
        content: invoicePage(data, nameLabel),
        styles: {
        },
        defaultStyle: baseStyle
    }
}

// if indexes(array of active quotes index) is set, summarize
const invoicePage = (rawData:KV[], nameLabel:KV) => {
    const data = [...rawData]
    data.sort((a, b) => new Date(a.deliveryDate).getTime() - new Date(b.deliveryDate).getTime())
    const sendTo = [
        ...(data[0].customer?.corporateName ? [[
            { text: addLineBreak(data[0].customer.corporateName, 26, " 様"), fontSize: pageStyle.fontSizes.name, alignment: 'left'},
        ]] : []),
        ...(data[0].customer?.name?.givenName ? [[
            { text: addLineBreak(`${data[0].customer?.name?.surname || ""}　${data[0].customer?.name?.givenName || ""}`, 26, "様"), fontSize: pageStyle.fontSizes.name, alignment: 'right'},
        ]] : []),
    ]
        
//        ${data[0].customer?.name?.surname || ""}　${data[0].customer?.name?.givenName || ""}${data[0].customer?.name?.surname ? "　様" : ""}`, fontSize: pageStyle.fontSizes.name, alignment: 'left' }]]

    const { timbers, quantity, totalPrice, amount } = data.reduce((a, r) => ({ 
        timbers: a.timbers + r.timbers,
        quantity: new Decimal(a.quantity || 0).plus(new Decimal(r.quantity || 0)),
        totalPrice: new Decimal(a.totalPrice || 0).plus(new Decimal(r.totalPrice || 0)),
        amount: new Decimal(a.amount || 0).plus(new Decimal(r.amount || 0)) 
    }), { timbers: 0, quantity: 0, totalPrice: 0, amount:0 })
    const taxRate = data[0]?.taxRate || 0
    const taxAmount = new Decimal(amount).minus(new Decimal(totalPrice)).floor().toFixed()//new Decimal(totalPrice).times(new Decimal(taxRate).dividedBy(new Decimal(100))).floor().toFixed()
    const amountWithTax = new Decimal(totalPrice).plus(new Decimal(taxAmount)).floor().toNumber()
    console.log(amountWithTax, totalPrice, taxAmount, amount)
    const invoiceAddress = data[0].customer.invoiceAddress || data[0].customer.address 
    return [
        {
            columns: [//height 
                {
                    width: 'auto', stack: [
                        { text: ' 御 請 求 書 ', background: 'green', color: 'white', fontSize: 20, margin: [pageStyle.address.x, 0, 0, 0] },
                        { text: '〒' + invoiceAddress.zip, alignment: 'left', margin: [pageStyle.address.x, 0, 0, 0] },
                        { text: options.prefecture[invoiceAddress.state] + invoiceAddress.citystr, alignment: 'left', margin: [pageStyle.address.x, 0, 0, 0] },
                        { text: invoiceAddress.room || '　', alignment: 'left', margin: [pageStyle.address.x, 0, 0, 0] },
                        nameLabel,
                        { text: '下記の通り御請求申し上げます。', margin: [0, baseStyle.fontSize * 2] },
                        {
                            table: {
                                widths: [100, '*'],
                                body: [
                                    [{ text: '請求金額', margin: [0, 6, 0, 0] }, { text: formatPrice(amountWithTax) + "円", bold: true, fontSize: 16, alignment: 'right' }]
                                ],

                            },
                            layout: underlineLayout,
                        },
                        { text: '(消費税及び地方消費税相当額を含む)', alignment: 'right', fontSize: 9 },
                    ]
                },
                { width: '*', text: '' },
                {
                    width: 'auto', alignment: 'right', stack: [
                        { text: `請求No.${data[0].invoiceNo}`, margin: [0, 10, 0, 0] },
                        { text: data[0].invoiceDate && JSTDateKanji(new Date(data[0].invoiceDate)) },
                        { width: 200, image: titlelogo, margin: [0, baseStyle.fontSize] },
                        { text: invoiceRegisterNo },
                        { stack: ['長野県松本市岡田下岡田774-1', 'TEL (0263) 87-5361', 'FAX (0263) 87-5362'], width: 'auto' },
                        '合法木材－長野県木連－045',
                        '　',
                        `担当　${data[0].sales?.surname || ""}　 ${data[0].sales?.givenName || ""}　　${data[0].invoiceAuthor?.surname || ""}　 ${data[0].invoiceAuthor?.givenName || ""}`,
                    ]
                },
            ]
        },
        '　',
        {
            table: {
                headerRows: 1,
                widths: data[0].asDetail ? [40, 55, 50, 30, 30, 30, 50, 50, '*'] : [40, 55, 50, 30, 50, 60, 60, '*'],
                body:
                    [data[0].asDetail ? [
                        { text: '明細NO', alignment: 'center' },
                        { text: '納材日', alignment: 'center' },
                        { text: '樹種', alignment: 'center' },
                        { text: '長さ', alignment: 'center' },
                        { text: '径級', alignment: 'center' },
                        { text: '本数', alignment: 'center' },
                        { text: '材積', alignment: 'center' },
                        { text: '単価', alignment: 'center' },
                        { text: '金額', alignment: 'center' }
                    ] : [
                        { text: '明細NO', alignment: 'center' },
                        { text: '納材日', alignment: 'center' },
                        { text: '樹種', alignment: 'center' },
                        { text: '単位', alignment: 'center' },
                        { text: '数量', alignment: 'center' },
                        { text: '金額', alignment: 'center' },
                        { text: '消費税(10%)', alignment: 'center' },
                        { text: '合計金額', alignment: 'center' }
                    ],

                    /*
                    "単価": datum.unitPrice || "",
                    "金額": datum.totalPrice || "",
                    "消費税率": datum.taxRate || "",
                    "合計金額": datum.amount || "",
                    */


                    ...data.map(datum => data[0].asDetail ? [
                        datum.invoiceNo,
                        { text: datum.deliveryDate ? new Date(datum.deliveryDate).toLocaleDateString() : "", alignment: 'center' },
                        { text: datum.species ? options.timberSpecies[datum.species] : "", alignment: 'center' },
                        { text: (datum.length || "") + "m", alignment: 'center' },
                        { text: (datum.diameter || "") + "cm", alignment: 'center' },
                        { text: (datum.timbers || "") + "本", alignment: 'center' },
                        { text: (datum.quantity || "") + "", alignment: 'center' },
                        { text: "¥" + formatNumber(datum.unitPrice || ""), alignment: 'right' },
                        { text: "¥" + formatNumber(datum.totalPrice || ""), alignment: 'right' },
                    ] : [
                        datum.invoiceNo,
                        { text: datum.deliveryDate ? new Date(datum.deliveryDate).toLocaleDateString() : "", alignment: 'center' },
                        { text: datum.species ? options.timberSpecies[datum.species] : "", alignment: 'center' },
                        { text: !datum.unit ? "" : datum.unit === "㎥" ? "立米" : options.timberUnit[datum.unit], alignment: 'center' },
                        { text: datum.quantity || "", alignment: 'center' },
                        { text: "¥" + formatNumber(datum.totalPrice || ""), alignment: 'right' },
                        { text: "¥" + formatNumber(new Decimal(datum.amount || 0).minus(new Decimal(datum.totalPrice || 0).toFixed())), alignment: 'right' },
                        { text: "¥" + formatNumber(datum.amount || ""), alignment: 'right' },
                    ]),

                    data[0].asDetail ? [
                        { text: '小計', colSpan: 6, alignment: 'right', margin: [baseStyle.fontSize * 2, 0] }, '', '', '', '',
                        { text: (timbers || "") + "本", alignment: 'center' },
                        { text: formatNumber(quantity.toFixed()) + "", alignment: 'center' },
                        '',
                        { text: "¥" + formatNumber(totalPrice || ""), alignment: 'right' },
                    ] : [
                        { text: '小計', colSpan: 5, alignment: 'right', margin: [baseStyle.fontSize * 2, 0] }, '', '', '',
                        { text: formatNumber(quantity.toFixed()), alignment: 'center' },
                        { text: "¥" + formatNumber(totalPrice.floor().toFixed()), alignment: 'right' },
                        { text: "¥" + formatNumber(amount.minus(totalPrice).toFixed()), alignment: 'right' },
                        { text: "¥" + formatNumber(amount.floor().toFixed()), alignment: 'right' },
                    ],
                    ...data[0].asDetail ? [[
                        { text: '消費税', colSpan: 8, alignment: 'right', margin: [baseStyle.fontSize * 2, 0] }, '', '', '', '', '', '',
                        { text: formatNumber(taxRate) + '%', alignment: 'center' },
                        { text: "¥" + formatNumber(taxAmount), alignment: 'right' }
                    ],
                    [
                        { text: '合計', colSpan: 9, alignment: 'right', margin: [baseStyle.fontSize * 2, 0] }, '', '', '', '', '', '', '',
                        { text: formatPrice(amountWithTax), alignment: 'right' }
                    ]] : []
                ]
            }
        },
        '　',
        {
            table: {
                widths: ['auto', 'auto', 'auto', 'auto', '*'],
                body: [
                    ["振込口座", "", "", "", ""],
                    [" ", "", "", "", ""],
                    ["松本信用金庫", "浅間温泉支店", "普通", "0193370", "(株)柳沢林業"],
                    ["長野銀行", "松本北支店", "普通", "8819432", "(株)柳沢林業"],
                    ["ゆうちょ銀行", "一一八支店", "普通", "4326058", "(株)柳沢林業"],
                    [" ", "", "", "", ""],
                    [{ text: "請求日より1か月以内に上記指定口座にお振込みください。", colSpan: 5 }, "", "", "", ""],
                ]
            },
            margin: [0, 10],
            layout: {
                hLineWidth: (i:number, node:KV) => i === 0 || i === node.table.body.length ? 1 : 0,
                vLineWidth: (i:number, node:KV) => i === 0 || i === node.table.widths.length ? 1 : 0
            },
        },
    ]
}

export default getTimberInvoicePdf