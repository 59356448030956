import React from 'react'

import { DataList, ListItem, EditLinkTd, ListName, FiltersFunction, FilterDates, RowFunction } from "components/list"
import options from 'contexts/options.json'
const location = "/transport"

const heads: KV[] = [
    { text: "納材日", field: "date" },
    { text: "車種", field: "truckType" },
    { text: "工数", field: "human" },
    { text: "事業種別", field: "division" },
    { text: "事業名", field: "subject" },
    { text: "発地【現場名】", field: "from" },
    { text: "着地【納材先】", field: "to" },
    { text: "卸先", field: "customer" },
    { text: "運材経費合計", field: "totalCost" },
    { text: "材積", field: "woodVolume" },
    { text: "" }
]
//outsource	
//unitPrice	単価
//distance	走行距離
//toll	高速料
const Filter: FiltersFunction = (filter, updateFilter, dateParam) => <FilterDates dateParam={dateParam} />

const download = (Data: KV[]) => Data.map(datum => ({
    "ID": datum._id,
    "納材日": datum.date ? new Date(datum.date).toLocaleDateString() : "",
    "車種": datum.truckType || "",
    "工数": datum.human || "",
    "事業種別": datum.division ? options.category[datum.division] : "",
    "事業ファイルNo": (datum.division && datum.project) ? datum.project[datum.division]?.fileID : "",
    "事業名": (datum.division && datum.project) ? datum.project[datum.division]?.subject : "",
    "発地【現場名】": datum.from || "",
    "着地【納材先】": datum.to || "",
    "卸先": `${datum.customer?.corporateName || ""} ${datum.customer?.name?.surname || ""} ${datum.customer?.name?.givenName || ""}`,
    "運材外注先": datum.outsource ? options.transportOutsourcer[datum.outsource] : "",
    "単価": datum.unitPrice || "",
    "走行距離": datum.distance || "",
    "高速料": datum.toll || "",
    "運材経費合計": datum.totalCost || "",
    "材積": datum.woodVolume || "",
    "備考": datum.remarks || ""
}))

const Row:RowFunction = ({ datum, clickDelete }) => <>
    <ListItem data={[datum.date]} date />
    <ListItem data={[datum.truckType || ""]} />
    <ListItem data={[datum.human]} />
    <ListItem data={[datum.division ? options.category[datum.division] : ""]} />
    <ListItem data={[(datum.division && datum.project) ? datum.project[datum.division]?.subject : ""]} />
    <ListItem data={[datum.from]} />
    <ListItem data={[datum.to]} />
    <ListName data={[datum.customer]} />
    <ListItem data={[datum.totalCost]} />
    <ListItem data={[datum.woodVolume]} />
    <EditLinkTd location={location} id={datum._id} onClickDelete={clickDelete} onClickPrint={undefined} />
</>

const List = () => {
    return <DataList collection="transport" location={location} heads={heads} user={['user']} filter={Filter} dateField="date" download={download} row={Row} handleRowClick={undefined} />
}

export default List